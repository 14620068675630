import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import ReactLoading from 'react-loading';



import './SetupForm.css';

const SetupForm = ({cancel_update_payment, is_dev}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [awaiting, setAwaiting] = useState(false);

  const handleSubmit = async (event) => {
    setErrorMessage('');
    setAwaiting(true);

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Add the new card to the customer
    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${is_dev ? 'http://localhost:3000' : 'https://app.plusidentity.com'}/dashboard/settings/billing/confirmation`,
      }
    });

    if (error) {
      console.log('in error')
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (e.g., payment
      // details incomplete)
      setErrorMessage(error.message);
      setAwaiting(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form 
      onSubmit={handleSubmit}
      className='setup-form__container'
    >
      <PaymentElement />
      {
        awaiting
        ? <div 
            className='stripe__awaiting'
          >
            <ReactLoading
              type='spokes'
              color='#ffffff'
              height={20}
              width={20}
            />
          </div>
        : <>
            <button 
              className="setup-form__submit-button" 
              disabled={!stripe}
            >
              Submit
            </button>
            <div className="setup-form__action-items">
              <a
                className='setup-form__cancel'
                onClick={() => {
                  cancel_update_payment();
                }}
              >
                Cancel
              </a>
            </div>
          </>
      }
      {/* Show error message to your customers */}
      {errorMessage && <div className='setup-form__error'>{errorMessage}</div>}
    </form>
  )
};

export default SetupForm;