import React from 'react';
import './BillingConfirmation.css';

import { Elements } from '@stripe/react-stripe-js';
// import {ElementsConsumer, PaymentElement} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

import PaymentStatus from './PaymentStatus';


import { auth_axios } from '../../../../../../auth/util';



/*****************************************
* PRODUCTION / DEVELOPMENT TOGGLE
*****************************************/
const IS_DEV = false;
/*****************************************
* END OF TOGGLE
*****************************************/


const STRIPE_TEST_PUBLISHABLE_KEY = 'pk_test_51K2mH1JBd8M7u9KfZoRebYvWZ2gffhBBCmpK3qVu3Ss0kXT7x9xLiOrA5j2QkmlaY2RvEcbKUnElBwmzUnn79XRe001gmLKBgP';
const STRIPE_LIVE_PUBLISHABLE_KEY = 'pk_live_51K2mH1JBd8M7u9KfuwH0od3TkhtjnXELm7rKu1EHaQvnSPeUAg6CwhZYt03RbsbWlpL6k0XHJ5u86kQjB0u5D0U800iwvHfIyr';



const stripe_promise = loadStripe(IS_DEV ? STRIPE_TEST_PUBLISHABLE_KEY : STRIPE_LIVE_PUBLISHABLE_KEY);


class Billing extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  validate_team_id = (query_team_id) => {

    return [true, query_team_id];
  }

  
  back_to_billing = async () => {
    await this.props.history.push('/dashboard/settings/billing');
  }


  componentDidMount = async () => {
    try {
      // Protect against url paste access
      if (!this.props.is_admin) {
        // 
        alert('You do not have permission to view this page');
        await this.props.history.push('/dashboard/settings');
        return;
      }

      // Perform payment methods cleanup
      const payment_methods_cleanup_res = await auth_axios.delete(`/api/teams/${this.props.selected_team_id}/billing/payment-methods-cleanup`);

      if (!payment_methods_cleanup_res.data.success) {
        // 
        alert('Something went wrong with payment methods cleanup');
        return;
      } 

    }
    catch {
      // 
      alert('Something went wrong that needs investigating...');
      await this.props.history.push('/dashboard/settings');
    }
  }





  
  render() {
    

    return (
      <div className='billing_confirmation__container'>
        
        <Elements stripe={stripe_promise}>
          <PaymentStatus />
        </Elements>
        <button 
          className='billing_confirmation__dashboard-button'
          onClick={this.back_to_billing}
        >
          Back to billing
        </button>
      </div>
    );
  }
}

export default Billing;
