import React from 'react';
import './EditDisplayNameModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

import { auth_axios } from '../../../../../auth/util';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


class EditDisplayNameModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      new_display_name_holder: props.user_display_name,
      input_valid: false,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  reset_new_display_name = () => {
    this.setState({
      new_display_name_holder: this.props.user_display_name,
    })
  }


  handle_new_display_name_change = (e) => {
    const new_display_name = e.target.value;

    this.setState({ 
      new_display_name_holder: new_display_name,  
      input_valid: (new_display_name !== this.props.user_display_name)
    });
  }


  edit_display_name = async () => {

    //
    this.setState({
      error_message: '',
      awaiting_server_response: true
    });


    /**************************************
    * 1. Call the edit display name API call
    ***************************************/

    const edit_display_name_res = await auth_axios.put(`/api/users/${this.props.user_id}/display-name`, {
      new_display_name: this.state.new_display_name_holder
    });

    if (!edit_display_name_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with edit display name'
      });
      return;
    }

    /**************************************
    * 2. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false
    });



    /**************************************
    * 3. Mixpanel tracking
    ***************************************/

    mixpanel.track('web_edit_display_name_modal_submitted', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });

  
    /**************************************
    * 4. Refresh page to see changes on dashboard immediately!
    ***************************************/

    // Reload
    window.location.reload(false);
  }



  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_edit_display_name_modal_close();
            this.reset_new_display_name();
          }
        }}
        style={{
          content: {
            height: 200,
            width: 300
          }
        }}
      >
        <div className="edit-display-name-modal__container">
          <div
            className='edit-display-name-modal__text' 
          >
            Enter the new display name.
          </div>
          <input
            className='edit-display-name-modal__new-display-name-input'
            placeholder={'New display name'}
            type='text'
            value={ this.state.new_display_name_holder }
            onChange={ this.handle_new_display_name_change }
            disabled={ this.state.awaiting_server_response }
          />
          <div className="edit-display-name-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    this.state.input_valid
                    ? <input
                        type='submit'
                        className={`edit-display-name-modal__update-button`}
                        value={ 'Update display name' }
                        onClick={ this.edit_display_name }
                      />
                    : <input
                        type='submit'
                        className={`edit-display-name-modal__update-button disabled`}
                        value={ 'Update display name' }
                      />
                  }
                  <a
                    className='edit-display-name-modal__cancel'
                    onClick={() => {
                      this.props.on_edit_display_name_modal_close();
                      this.reset_new_display_name();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default EditDisplayNameModal;
