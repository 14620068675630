import React from 'react';
import './Account.css';


import ReactLoading from 'react-loading';



import EditDisplayNameModal from './EditDisplayNameModal';
import UpdatePrimaryEmailModal from './UpdatePrimaryEmailModal';
import AddEmailAddressModal from './AddEmailAddressModal'

import ChangeMasterPasswordModal from './ChangeMasterPasswordModal';
import ChangeLogoutIntervalModal from './ChangeLogoutIntervalModal';

import DeleteAccountModal from './DeleteAccountModal';
import DeleteUserModal from './DeleteUserModal';

import RequestRecoveryModal from './RequestRecoveryModal';

import ExportItemsModal from './ExportItemsModal';


import {auth_axios} from '../../../../../auth/util';

import axios from 'axios';

// Mixpanel
import mixpanel from 'mixpanel-browser';

const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 





const beautify_team_status = (team_status) => {
  const mapping = {
    'active': 'Active',
    'inactive': 'Inactive',
    'locked': 'Locked',
    'team_locked': 'Team Locked'
  }

  if (mapping[team_status]) {
    return mapping[team_status];
  }

  else {
    return team_status;
  }
}


const logout_interval_mappings = {
  '3600000': '1 hour',
  '7200000': '2 hours',
  '10800000': '3 hours',
  '14400000': '4 hours',
  '18000000': '5 hours',
  '21600000': '6 hours',
  '43200000': '12 hours',
  '86400000': '1 day',
  '172800000': '2 days',
  '259200000': '3 days',
  '345600000': '4 days',
  '432000000': '5 days',
  '518400000': '6 days',
  '604800000': '7 days',
}

const beautify_session_logout_interval = (session_logout_interval) => {

  if (logout_interval_mappings[session_logout_interval]) {
    return logout_interval_mappings[session_logout_interval];
  }

  else {
    return `${session_logout_interval} milliseconds`;
  }
}








class Account extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      edit_display_name_modal_open: false,
      update_primary_email_modal_open: false,
      add_email_address_modal_open: false,
      change_master_password_modal_open: false,
      change_logout_interval_modal_open: false,
      delete_account_modal_open: false,
      delete_user_modal_open: false,
      request_recovery_modal_open: false,
      export_items_modal_open: false,

      items_owned: true,
      admin_recovery_available: false,
      teamless: false,

      error_message: '',

    }
  }


  on_delete_account_modal_open = () => {
    this.setState({
      delete_account_modal_open: true
    });

    // Mixpanel web_delete_account_modal_opened
    mixpanel.track('web_delete_account_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_delete_account_modal_close = () => {
    this.setState({
      delete_account_modal_open: false
    });
  }


  on_delete_user_modal_open = () => {
    this.setState({
      delete_user_modal_open: true
    });

    // Mixpanel web_delete_user_modal_opened
    mixpanel.track('web_delete_user_modal_opened', {
      distinct_id: this.props.user_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_delete_user_modal_close = () => {
    this.setState({
      delete_user_modal_open: false
    });
  }

  on_edit_display_name_modal_open = () => {
    this.setState({
      edit_display_name_modal_open: true
    });

    // Mixpanel web_edit_display_name_modal_opened
    mixpanel.track('web_edit_display_name_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_edit_display_name_modal_close = () => {
    this.setState({
      edit_display_name_modal_open: false
    });
  }

  on_update_primary_email_modal_open = () => {
    this.setState({
      update_primary_email_modal_open: true
    });

    // Mixpanel web_update_primary_email_modal_opened
    mixpanel.track('web_update_primary_email_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_update_primary_email_modal_close = () => {
    this.setState({
      update_primary_email_modal_open: false
    });
  }

  on_add_email_address_modal_open = () => {
    this.setState({
      add_email_address_modal_open: true
    });

    // Mixpanel web_add_email_address_modal_opened
    mixpanel.track('web_add_email_address_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_add_email_address_modal_close = () => {
    this.setState({
      add_email_address_modal_open: false
    });
  }

  on_change_master_password_modal_open = () => {
    this.setState({
      change_master_password_modal_open: true
    });

    // Mixpanel web_change_master_password_modal_opened
    mixpanel.track('web_change_master_password_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_change_master_password_modal_close = () => {
    this.setState({
      change_master_password_modal_open: false
    });
  }

  on_change_logout_interval_modal_open = () => {
    this.setState({
      change_logout_interval_modal_open: true
    });

    // Mixpanel web_change_logout_interval_modal_opened
    mixpanel.track('web_change_logout_interval_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_change_logout_interval_modal_close = () => {
    this.setState({
      change_logout_interval_modal_open: false
    });
  }



  on_request_recovery_modal_open = () => {
    this.setState({
      request_recovery_modal_open: true
    });

    // Mixpanel web_request_recovery_modal_opened
    mixpanel.track('web_request_recovery_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_request_recovery_modal_close = () => {
    this.setState({
      request_recovery_modal_open: false
    });
  }

  on_export_items_modal_open = () => {
    this.setState({
      export_items_modal_open: true
    });
  }

  on_export_items_modal_close = () => {
    this.setState({
      export_items_modal_open: false
    });
  }


  
  componentDidMount = async () => {
    try {
      
      if (this.props.selected_team_id === 'pT_NO_TEAM') {
        // const user_info_res = await auth_axios.get(`/api/users/${this.props.user_id}/data`);

        // if (!user_info_res.data.success) {
        //   console.error('get email addresses failed')
        //   return;
        // }

        this.setState({
          teamless: true,
          awaiting_server_response: false,
        });
        return;
      }

      // Get account info
      const account_info_res = await auth_axios.get(`/api/users/${this.props.user_id}/accounts/${this.props.selected_team_id}/data`);

      if (!account_info_res.data.success) {
        console.error('get account info failed')
        return;
      }

      const items_owned = account_info_res.data.items_owned;
      const admin_recovery_available = account_info_res.data.admin_recovery_available;

      this.setState({
        awaiting_server_response: false,
        items_owned: items_owned,
        admin_recovery_available: admin_recovery_available,
      });

      // Mixpanel web_account_visited
      mixpanel.track('web_account_visited', {
        distinct_id: this.props.user_id,
        is_dev: this.props.mode_info.is_dev
      });
      

    }
    catch {
      // 
      alert('Something went wrong that needs investigating...');
      await this.props.history.push('/dashboard');
    }
  }


  
  render() {
    


    return (
      <div className='account__container'>
        {
          this.state.awaiting_server_response
          ? 
          <div className="account__loading-container">
            <ReactLoading
              type='spokes'
              color='#9696ad'
              height={20}
              width={20}
            />
          </div>
          : <div className='account__contents'>
              <div className='account__section'>
                <div className='account__section-left'>
                  Profile
                </div>
                <div className='account__section-right'>
                  <div className="account__section-right-field">
                    <div className='account__section-right-field-name'>
                      Display name
                    </div>
                    <div className='account__section-right-field-value'>
                      {this.props.user_id_display_name_maps[this.props.user_id]}
                    </div>  
                    <div className="account__section-right-action-container">
                      <button 
                        className="account__edit-display-name-button"
                        onClick={ this.on_edit_display_name_modal_open }
                      >
                        Edit
                      </button> 
                    </div>
                  </div>
                  <div className="account__section-right-field">
                    <div className='account__section-right-field-name'>
                      Email addresses
                    </div>
                    <div className='account__section-right-field-value'>
                      {this.props.email_addresses.map((email_address, index) => 
                        <div 
                          key={email_address}
                          className={`account__section-right-field-value-item ${index === 0 ? 'primary-email' : ''}`}
                        >
                          <span>{email_address}</span>
                          {
                            index === 0
                            ? <button
                                className='account__section-primary-email'
                                onClick={ this.on_update_primary_email_modal_open }
                              >
                                Primary
                              </button>
                            : <></>
                          }
                        </div>
                      )}
                      
                    </div>  
                    <div className="account__section-right-action-container">
                      <button 
                        className="account__add-email-button"
                        onClick={ this.on_add_email_address_modal_open }
                      >
                        Add email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="account__separator-horizontal"></div>

              <div className='account__section'>
                <div className='account__section-left'>
                  Authentication
                </div>
                <div className='account__section-right'>
                  <div className="account__section-right-field">
                    <div className='account__section-right-field-name'>
                      Master password
                    </div>
                    <div className='account__section-right-field-value'>
                      ********
                    </div>  
                    <div className="account__section-right-action-container">
                      <button 
                        className="account__change-master-password-button"
                        onClick={ this.on_change_master_password_modal_open }
                      >
                        Change master password
                      </button> 
                    </div>
                  </div>
                  <div className="account__section-right-field">
                    <div className='account__section-right-field-name'>
                      Automatically logout after
                    </div>
                    <div className='account__section-right-field-value'>
                      { beautify_session_logout_interval(this.props.preference_settings.session_logout_interval.toString()) } of inactivity
                    </div>  
                    <div className="account__section-right-action-container">
                      <button 
                        className="account__change-logout-interval-button"
                        onClick={ this.on_change_logout_interval_modal_open }
                      >
                        Change logout interval
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
              
              
              <div className="account__separator-horizontal"></div>

              <div className='account__section'>
                <div className='account__section-left'>
                  Team
                </div>
                <div className='account__section-right'>
                  <div className="account__section-right-field">
                    <div className='account__section-right-field-name'>
                      Current team
                    </div>
                    <div className='account__section-right-field-value'>
                      {this.props.selected_team_name}
                    </div>  
                    <div className="account__section-right-action-container">
                      {/* <button 
                        className="______"
                        onClick={ ____ }
                      >
                        _____
                      </button>  */}
                    </div>
                  </div>
                  <div className="account__section-right-field">
                    <div className='account__section-right-field-name'>
                      Account status
                    </div>
                    <div className={`account__section-right-field-value ${(this.props.selected_team_status !== 'active') ? 'danger' : ''}`}>
                      {beautify_team_status(this.props.selected_team_status)}
                    </div>  
                    <div className="account__section-right-action-container">
                      {
                        (this.props.selected_team_status === 'locked')
                        ? this.state.admin_recovery_available
                          ? <button 
                              className="account__request-recovery-button"
                              onClick={ this.on_request_recovery_modal_open }
                            >
                              Request account recovery
                            </button>
                          : <button 
                              className="account__request-recovery-button disabled"
                            >
                              Request account recovery
                              <div
                                className='account__request-recovery-error-text'
                              >
                                Admin-initiated account recovery is not available at this time. Contact your team admin to enable the admin-initiated account recovery functionality.
                              </div>
                            </button>
                        : <></>
                      }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="account__separator-horizontal"></div>

              <div className='account__section'>
                <div className='account__section-left'>
                  Decrypt & download all items
                </div>
                <div className='account__section-right'>
                  <div className="account__section-right-field">
                    <div className="account__section-right-description">
                      PlusIdentity is shutting down, please decrypt & download all items at your earliest convenience.
                    </div>

                    <div className="account__section-right-action-container">
                      <button 
                        className="account__delete-user-button"
                        onClick={ this.on_export_items_modal_open }
                      >
                        Export items
                      </button>
                    </div>  
                  </div>
                  
                </div>
              </div>

              <div className="account__separator-horizontal"></div>

              {
                this.state.teamless
                ? <></>
                : <>
                    <div className='account__section'>
                      <div className='account__section-left'>
                        Delete account
                      </div>
                      <div className='account__section-right'>
                        
                        <div className="account__section-right-field">

                          <div className="account__section-right-description">
                            To delete your account from {this.props.selected_team_name}, you must first delete or transfer ownership of all of your items on {this.props.selected_team_name}.
                          </div>
                          <div className="account__section-right-action-container">
                            {(!this.props.is_admin)
                              ? (!this.state.items_owned)
                                ? <button 
                                    className="account__delete-account-button"
                                    onClick={ this.on_delete_account_modal_open }
                                  >
                                    Delete account
                                  </button>
                                : <button 
                                    className="account__delete-account-button disabled"
                                  >
                                    Delete account
                                    <div
                                      className='account__delete-account-error-text'
                                    >
                                      To delete account from {this.props.selected_team_name}, make sure to delete or transfer ownership of all items on {this.props.selected_team_name}
                                    </div>
                                  </button>
                              : <button 
                                  className="account__delete-account-button disabled"
                                >
                                  Delete account
                                  <div
                                    className='account__delete-account-error-text'
                                  >
                                    Team admin cannot delete their account
                                  </div>
                                </button>
                            }
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="account__separator-horizontal"></div>
                  </>
              }

              <div className='account__section'>
                <div className='account__section-left'>
                  Delete permanently
                </div>
                <div className='account__section-right'>
                  <div className="account__section-right-field">
                    <div className="account__section-right-description">
                      To delete your account permanently from PlusIdentity, you must first delete all of your existing accounts from teams that you currently belong to.
                    </div>

                    <div className="account__section-right-action-container">
                      {this.props.selected_team_id === 'pT_NO_TEAM'
                      ? <button 
                          className="account__delete-user-button"
                          onClick={ this.on_delete_user_modal_open }
                        >
                          Permanently delete
                        </button>
                      : <button 
                          className="account__delete-user-button disabled"
                        >
                          Permanently delete
                          <div
                            className='account__delete-user-error-text'
                          >
                            You cannot belong to any team to take this action.
                          </div>
                        </button>
                      }
                    </div>  
                  </div>
                  
                </div>
              </div>
            </div>
        }
        
        
        <EditDisplayNameModal
          is_open={this.state.edit_display_name_modal_open}
          on_edit_display_name_modal_close={this.on_edit_display_name_modal_close}

          selected_team_id={this.props.selected_team_id}

          user_id={this.props.user_id}
          user_display_name={this.props.user_id_display_name_maps[this.props.user_id]}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <UpdatePrimaryEmailModal
          is_open={this.state.update_primary_email_modal_open}
          on_update_primary_email_modal_close={this.on_update_primary_email_modal_close}

          selected_team_id={this.props.selected_team_id}

          user_id={this.props.user_id}

          primary_email={this.props.email_addresses[0]}
          email_addresses={this.props.email_addresses}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <AddEmailAddressModal
          is_open={this.state.add_email_address_modal_open}
          on_add_email_address_modal_close={this.on_add_email_address_modal_close}

          selected_team_id={this.props.selected_team_id}

          user_id={this.props.user_id}

          email_addresses={this.props.email_addresses}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <ChangeMasterPasswordModal
          is_open={this.state.change_master_password_modal_open}
          on_change_master_password_modal_close={this.on_change_master_password_modal_close}

          selected_team_id={this.props.selected_team_id}

          primary_email={this.props.email_addresses[0]}
          user_id={this.props.user_id}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <ChangeLogoutIntervalModal
          is_open={this.state.change_logout_interval_modal_open}
          on_change_logout_interval_modal_close={this.on_change_logout_interval_modal_close}

          selected_team_id={this.props.selected_team_id}

          logout_interval_mappings={logout_interval_mappings}
          preference_settings={this.props.preference_settings}
          user_id={this.props.user_id}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <DeleteAccountModal
          is_open={this.state.delete_account_modal_open}
          on_delete_account_modal_close={this.on_delete_account_modal_close}

          selected_team_id={this.props.selected_team_id}
          selected_team_name={this.props.selected_team_name}
          user_id={this.props.user_id}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <DeleteUserModal
          is_open={this.state.delete_user_modal_open}
          on_delete_user_modal_close={this.on_delete_user_modal_close}

          user_id={this.props.user_id}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <RequestRecoveryModal
          is_open={this.state.request_recovery_modal_open}
          on_request_recovery_modal_close={this.on_request_recovery_modal_close}

          selected_team_id={this.props.selected_team_id}

          user_id={this.props.user_id}

          user_id_display_name_maps={this.props.user_id_display_name_maps}
          selected_team_admin_user_ids={this.props.selected_team_admin_user_ids}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <ExportItemsModal
          is_open={this.state.export_items_modal_open}
          on_export_items_modal_close={this.on_export_items_modal_close}

          selected_team_id={this.props.selected_team_id}

          user_id={this.props.user_id}
          item_keys={this.props.item_keys}
          account_private_key__unenc__uint8_array={this.props.keychain.account_private_key__unenc__uint8_array}

          email_addresses={this.props.email_addresses}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
      </div>

    );
  }
}

export default Account;
