import React from 'react';
import './RemoveLinkSubmodal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';


import { send_slack_notification } from '../../../../slack/util';

import { auth_axios } from '../../../../auth/util';



// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


ReactModal.setAppElement('body');



class RemoveLinkSubmodal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {            
      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.is_open !== this.props.is_open) {
      
    }
  }

  remove_link = async () => {

    this.setState({
      error_message: '',
      awaiting_server_response: true
    });

    const link_id = this.props.to_remove_link.link_id

    /**************************************
    * 1. Remove link entry
    ***************************************/

    const remove_link_res = await auth_axios.put(`/api/links/${link_id}/remove`, {
      item_id: this.props.selected_item.item_id
    });

    if (!remove_link_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with remove link'
      });
    }

    /**************************************
    * 2. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false
    });

    /**************************************
    * 3. Close modal and trigger dashboard items pull and links update
    ***************************************/

    await this.props.on_remove_link_submodal_close();

    await this.props.update_dashboard_items_links();
  }



  render() {
    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            await this.props.on_remove_link_submodal_close(false);
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 200 : 420,
            width: screen_width > 690 ? 400 : 300
          }
        }}
      >
        <div className='remove-link-submodal__container'>
          <div className='remove-link-submodal__top-line'>
            <div className='remove-link-submodal__title'>
              REMOVE LINK
            </div>
          </div>

          <div className='remove-link-submodal__content'>
            <div
              className='remove-link-submodal__text' 
            >
              Are you sure you want to permanently delete this link <span className='remove-link-submodal__text-bold'>{ `(${this.props.to_remove_link.link_name || ''})` }</span>?
            </div>
          </div>

          <div className='remove-link-submodal__bottom-line'>
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  <input
                    className='remove-link-submodal__remove-button'
                    type='submit'
                    value={ 'Remove' }
                    onClick={ this.remove_link }
                  />
                  <button
                    className='remove-link-submodal__close'
                    onClick={async () => {
                      await this.props.on_remove_link_submodal_close();
                    }}
                  >
                    Close
                  </button>
                </>
            }
          </div>
          
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default RemoveLinkSubmodal;
