import React from 'react';
import './LinkViewLoadingModal.css';

import ReactModal from 'react-modal';

import ReactLoading from 'react-loading';




// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


ReactModal.setAppElement('body');



class LinkViewLoadingModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
      // progress_message: 'Loading file',
    }
  }

  componentDidMount = async () => {
    try {
      
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.is_open !== this.props.is_open) {


    }

  }

  clear_holders = () => {
    this.setState({
      awaiting_server_response: true,
      // progress_message: 'Loading file',
    });
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          // if (!this.state.awaiting_server_response) {
          //   this.clear_holders();
          // }
        }}
        style={{
          content: {
            height: 200,
            width: 200
          }
        }}
      >
        <div className='link-view-loading-modal__container'>
          {
            this.props.is_open
            ? <div className="link-view-loading-modal__loading">
                <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
                <div className="link-view-loading-modal__progress_message">
                  <span>{this.props.progress_message}</span>
                </div>
              </div>
            : <></>
          }
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default LinkViewLoadingModal;
