import React from 'react';
import './DeleteItemModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';


import { send_slack_notification } from '../../../slack/util';

import { auth_axios } from '../../../auth/util';



// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


class DeleteItemModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }


  delete_item = async () => {

    //
    this.setState({
      error_message: '',
      awaiting_server_response: true
    });


    /**************************************
    * 1. Call the comprehensive delete item API call
    ***************************************/

    const delete_item_res = await auth_axios.put(`/api/items/${this.props.selected_item.item_id}/remove`, {
      user_id: this.props.user_id
    });

    if (!delete_item_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with delete item'
      });
      return;
    }

    /**************************************
    * 2. Slack notification request
    ***************************************/

    const team_id = this.props.selected_team_id;

    const user_id = this.props.user_id;
    const display_name = this.props.user_id_display_name_maps[user_id];

    const item_name = this.props.selected_item.item_name;

    const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

    // TURNED THIS OFF FOR NOW: Send message to item adder (this user)

    // const message = `You deleted ${item_name}.`;
    // await send_slack_notification(url, team_id, user_id, message, {

    // });

    // Send message to users who have access to the

    const shared_user_ids = Object.keys(this.props.selected_item.permissions).filter((permission_user_id) => {
      return !(permission_user_id === user_id);
    })

    const message2 = `SLACK_USER_ID (${display_name}) deleted ${item_name}.`;
    
    for (const shared_user_id of shared_user_ids) {
      await send_slack_notification(url, team_id, shared_user_id, message2, {
        TO_FORMAT_USER_ID: user_id
      });
    }

    // const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/testing`;

    // const res = await axios.post(url, {
    //   testing: 'this post'
    // })

    // console.log(res);

    /**************************************
    * 3. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false
    });


    /**************************************
    * 4. Trigger dashboard items pull and close modal
    ***************************************/

    await this.props.on_delete_item_modal_close(true);
    await this.props.update_dashboard_items();



    /**************************************
    * 5. Mixpanel
    ***************************************/

    mixpanel.track('web_delete_item_modal_submitted', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });

    

  
    /**************************************
    * 5. Trigger items pull and close modal
    ***************************************/

    // Redirect and reload
    //await this.props.history.push(`/dashboard`);
    // window.location.reload(false);

    


  }


  render() {
    let item_name;

    if (this.props.selected_item) {
      item_name = this.props.selected_item.item_name;
    }

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            await this.props.on_delete_item_modal_close(false);
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 165 : 165,
            width: screen_width > 690 ? 360 : 300
          }
        }}
      >
        <div className="delete-item-modal__container">
          <div
            className='delete-item-modal__text' 
          >
            Are you sure you want to permanently delete <span className='delete-item-modal__text-bold'>{item_name}</span>?
          </div>
          {
            this.state.awaiting_server_response
            ? <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            : <>
                
                <input
                  type='submit'
                  className='delete-item-modal__delete-button'
                  value={ 'Delete' }
                  onClick={ this.delete_item }
                />
                <a
                  className='delete-item-modal__cancel'
                  onClick={async () => {
                    await this.props.on_delete_item_modal_close(false);
                  }}
                >
                  Cancel
                </a>
              </>
          }
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default DeleteItemModal;
