

import axios from 'axios';


import { get as dbGet, del as dbDel, set as dbSet } from 'idb-keyval';

import { base64_to_uint8_array } from '../crypto/util';
import { SYM_DECRYPT } from '../crypto/crypto';

export const enforce_https = () => {
  if (window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
  }
}


export const on_load = async () => {
  if (!window.indexedDB) {
    console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
  };

  
  // Load data from IndexedDb

  let session_token__unenc__base64 = await dbGet('session_token__unenc');

  let account_secret_key__unenc__base64 = await dbGet('account_secret_key__unenc');
  
  let account_private_key__enc_auk__base64 = await dbGet('account_private_key__enc_auk');
  let account_private_key__enc_sk__base64 = await dbGet('account_private_key__enc_sk');

  let account_public_key__unenc__base64 = await dbGet('account_public_key__unenc');

  let device_private_key__unenc__base64 = await dbGet('device_private_key__unenc');
  let device_public_key__unenc__base64 = await dbGet('device_public_key__unenc');

  let server_public_key__unenc__base64 = await dbGet('server_public_key__unenc');
  let team_public_keys = await dbGet('team_public_keys');

  // Check if any of the non- session related data is tampered with (allow the absence of account_private_key__enc_sk__base64 upon logout)
  if (!account_secret_key__unenc__base64 || !account_private_key__enc_auk__base64 || !account_public_key__unenc__base64 || !device_private_key__unenc__base64 || !device_public_key__unenc__base64 || !server_public_key__unenc__base64 || !team_public_keys || !session_token__unenc__base64) {

    // If so, redirect user to long term logout flow
    await dbDel('cached_data'); // Delete local cache

    return ['SESSION_INVALID', {}, '', ''];
  }



  // 1. Check if the stored session_id is active according to the server

  let status, session_status_res;
  do {
    session_status_res = await axios.get('/api/sessions/status', {
      headers: {
        'Authorization': `web session_token=${session_token__unenc__base64}`
      }
    });

    status = session_status_res.data.status;
  } while (!status);


  if (status === 'SESSION_VALID') {
    try {
      const session_key__unenc__uint8_array = base64_to_uint8_array(session_status_res.data.session_key__unenc);

      const account_private_key__enc_sk__uint8_array = base64_to_uint8_array(account_private_key__enc_sk__base64);

      const account_private_key__unenc__uint8_array = await SYM_DECRYPT(session_key__unenc__uint8_array, account_private_key__enc_sk__uint8_array);

      // Create keychain
      const account_public_key__unenc__uint8_array = base64_to_uint8_array(account_public_key__unenc__base64);
      const server_public_key__unenc__uint8_array = base64_to_uint8_array(server_public_key__unenc__base64);
      // const team_public_keys = base64_to_uint8_array(team_public_key__base64);

      const user_id = session_status_res.data.user_id;
      const device_id = session_status_res.data.device_id;

      const keychain = {
        account_private_key__unenc__uint8_array,
        account_public_key__unenc__uint8_array,
        server_public_key__unenc__uint8_array,
        team_public_keys
      };

      // Rotate the session token (save in client perseverance)
      const new_session_token__unenc__base64 = session_status_res.data.new_session_token__unenc;
      await dbDel('session_token__unenc');
      await dbSet('session_token__unenc', new_session_token__unenc__base64);

      return ['SESSION_VALID', keychain, user_id, device_id]
    }


    catch (error) {
      console.log(error);
      console.log('SESSION KEY UNLOCK FAILED');
      
      await dbDel('cached_data'); // Delete local cache

      return ['SESSION_INVALID', {}, '', '']
    }
  }

  else if (status === 'SESSION_TIMEOUT') {
    // Create keychain

    const account_public_key__unenc__uint8_array = base64_to_uint8_array(account_public_key__unenc__base64);
    const server_public_key__unenc__uint8_array = base64_to_uint8_array(server_public_key__unenc__base64);
    // const team_public_keys = base64_to_uint8_array(team_public_keys__unenc__base64);

    const device_id = session_status_res.data.device_id;

    const keychain = {
      account_public_key__unenc__uint8_array,
      server_public_key__unenc__uint8_array,
      team_public_keys
    };

    // Rotate the session token (save in client perseverance)
    const new_session_token__unenc__base64 = session_status_res.data.new_session_token__unenc;
    await dbDel('session_token__unenc');
    await dbSet('session_token__unenc', new_session_token__unenc__base64);

    await dbDel('cached_data'); // Delete local cache

    return ['SESSION_TIMEOUT', keychain, '', device_id]
  }

  else if (status === 'SESSION_INVALID') {
    await dbDel('cached_data'); // Delete local cache

    return ['SESSION_INVALID', {}, '', '']
  }



  // Return the validity status (SESSION_TIMEOUT or SESSION_INVALID)
  else {
    await dbDel('cached_data'); // Delete local cache

    return [status, {}, '', ''];
  }
}

export const auth_axios = {
  get: async (uri) => {
    const session_token__unenc__base64 = await dbGet('session_token__unenc');

    const res = await axios.get(uri, {
      headers: {
        'Authorization': `web session_token=${session_token__unenc__base64}`
      }
    });

    if (res.data.destroy_session) {
      await dbDel('account_private_key__enc_sk');
      window.location.reload(false);
      return;
    } 
    else {
      return res;
    }
  },
  post: async (uri, body) => {
    const session_token__unenc__base64 = await dbGet('session_token__unenc');

    const res = await axios.post(uri, body, {
      headers: {
        'Authorization': `web session_token=${session_token__unenc__base64}`
      }
    });

    if (res.data.destroy_session) {
      await dbDel('account_private_key__enc_sk');
      window.location.reload(false);
      return;
    } 
    else {
      return res;
    }
  },
  delete: async (uri) => {
    const session_token__unenc__base64 = await dbGet('session_token__unenc');

    const res = await axios.delete(uri, {
      headers: {
        'Authorization': `web session_token=${session_token__unenc__base64}`
      }
    });

    if (res.data.destroy_session) {
      await dbDel('account_private_key__enc_sk');
      window.location.reload(false);
      return;
    } 
    else {
      return res;
    }
  },
  put: async (uri, body) => {
    const session_token__unenc__base64 = await dbGet('session_token__unenc');

    const res = await axios.put(uri, body, {
      headers: {
        'Authorization': `web session_token=${session_token__unenc__base64}`
      }
    });

    if (res.data.destroy_session) {
      await dbDel('account_private_key__enc_sk');
      window.location.reload(false);
      return;
    } 
    else {
      return res;
    }
  },
}

export const sleep = function (duration) {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve()
		}, duration * 1000)
	})
}