
import axios from 'axios';


// INPUT
// OUTPUT
export const send_slack_notification = async (url, team_id, user_id, message, metadata) => {

  try {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  
    const params = new URLSearchParams()
  
    params.append('team_id', team_id)
    params.append('user_id', user_id)
    params.append('message', message);
    params.append('metadata', JSON.stringify(metadata))
  
    const post_trigger_res = await axios.post(url, params, config);
  }

  catch (error) {
    console.log(error);
  }
}

