import React from 'react';
import './ViewHistoryModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';


import { send_slack_notification } from '../../../slack/util';

import { auth_axios, sleep } from '../../../auth/util';

import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';






// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');




class ViewHistoryModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      actions_clicked_link_index: undefined,

      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.is_open !== this.props.is_open) {
      console.log('this.props.selected_item', this.props.selected_item)
      
    }
  }

  update_dashboard_items_links = async () => {
    await this.props.update_dashboard_items();

    console.log('this.props.selected_item', this.props.selected_item)
  }


  on_history_elm_actions_click = (index) => {
    this.setState({
      actions_clicked_link_index: index
    });
  }

  on_history_elm_actions_leave = () => {
    this.setState({
      actions_clicked_link_index: undefined
    });
  }






  render() {
    let item_name;
    let history_reverse = [];

    if (this.props.selected_item) {
      item_name = this.props.selected_item.item_name;
      history_reverse = [...this.props.selected_item.history].reverse();
    }

    const get_name = (identifier) => {
      const [viewer_type, viewer_identifier] = identifier.split(':');

      switch (viewer_type) {
        case 'user': {
          return this.props.user_id_display_name_maps[viewer_identifier];
        }
        case 'link': {
          return viewer_identifier;
        }
        default: {
          return;
        }
      }

    }

    const get_type = (history_elm) => {

      const mapping = {
        'web__add': 'Item added (web)',
        'web__edit': 'Item edited (web)',
        'web__field_copied': 'Field copied (web)',
        'web__dashboard_file_preview': 'File viewed (web)',
        'web__dashboard_file_download': 'File downloaded (web)',
        'web__link_access': `Share link accessed (${history_elm.history_metadata.link_name})`,
        'web__link_file_preview': `File viewed via share link (${history_elm.history_metadata.link_name})`,
        'web__link_file_download': `File downloaded via share link (${history_elm.history_metadata.link_name})`,
    
        'slack__add': 'Item added (Slack)',
    
        'extension__autologin': 'Auto-login (extension))',
        'extension__autofill': 'Autofill (extension)',
      }

      return mapping[history_elm.type];

      // const [viewer_type, viewer_identifier] = history_elm.identifier.split(':');

      // const history_metadata = history_elm.history_metadata;
      // const type =  history_elm.type

      // switch (viewer_type) {
      //   case 'user': {
      //     return this.props.user_id_display_name_maps[viewer_identifier];
      //   }
      //   case 'link': {
      //     return viewer_identifier;
      //   }
      //   default: {
      //     return;
      //   }
      // }

    }



    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            await this.props.on_view_history_modal_close(false);
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 600 : 450,
            width: screen_width > 690 ? 800 : 300
          }
        }}
      >
        <div className='view-history-modal__container'>
          <div className='view-history-modal__top-line'>
            <div className='view-history-modal__title'>
              HISTORY ({item_name})
            </div>
          </div>

          <div className='view-history-modal__content'>
            <div className='view-history-modal__content-header'>
              <div className='view-history-modal__content-header-time'>
                Time
              </div>
              <div className='view-history-modal__content-header-name'>
                Name
              </div>
              <div className='view-history-modal__content-header-type'>
                Access type
              </div>
              <div className='view-history-modal__content-header-actions'>
                More
              </div>
            </div>
            <div className='view-history-modal__content-history'>
            {history_reverse.map((history_elm, index) => 
              <div 
                key={index}
                data-item_id={index}
                className='view-history-modal__content-history-elm'
              >

                <div
                  className='view-history-modal__content-history-elm-time'
                >
                  { `${new Date(history_elm.occurred_at).toLocaleString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})` }
                </div>

                <div
                  className='view-history-modal__content-history-elm-name'
                >
                  { get_name(history_elm.identifier) }
                </div>

                <div
                  className='view-history-modal__content-history-elm-type'
                >
                  { get_type(history_elm) }
                </div>

                <div
                  className='view-history-modal__content-history-elm-actions'
                  onClick={() => this.on_history_elm_actions_click(index)}
                  onMouseLeave={this.on_history_elm_actions_leave}
                >
                  ...
                  <div 
                    className='view-history-modal__content-history-elm-actions-menu-container'
                  >
                    <div
                      className={`view-history-modal__content-history-elm-actions-menu ${index === this.state.actions_clicked_link_index ? 'shown' : ''}`}
                    > 
                      <div 
                        className='view-history-modal__content-history-elm-actions-menu-button'
                        // onClick={() => this.______(___)}
                      >
                        More ...
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            )}  
            </div>
          </div>

          <div className='view-history-modal__bottom-line'>
            <button
              className='view-history-modal__close'
              onClick={async () => {
                await this.props.on_view_history_modal_close();
              }}
            >
              Close
            </button>
          </div>
          
          <span>{this.state.error_message}</span>
        </div>




      </ReactModal>
    );
  }
}

export default ViewHistoryModal;
