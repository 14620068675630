import React from 'react';
import './LinkView.css';

import { withRouter } from 'react-router';

import axios from 'axios';
import ReactLoading from 'react-loading';


import { HKDF, XOR, SYM_ENCRYPT, SYM_DECRYPT } from '../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { auth_axios, sleep } from '../../../auth/util';

import { get as dbGet, set as dbSet, del as dbDel } from 'idb-keyval';

import { ReactComponent as PlusLock } from '../../../logos/plusidentity-lock.svg';
import { ReactComponent as InfoIcon } from './media/svg/info-icon.svg';

import { ReactComponent as AccountIconConfig } from './media/svg/account-icon-config-white.svg';
import { ReactComponent as AccountIconNonconfig } from './media/svg/account-icon-nonconfig-white.svg';
import { ReactComponent as FileIcon } from './media/svg/file-icon-white.svg'; 
import { ReactComponent as CustomIcon } from './media/svg/custom-icon-white.svg'; 

import { ReactComponent as PreviewIcon } from './media/svg/preview-icon.svg'; 
import { ReactComponent as DownloadIcon } from './media/svg/download-icon.svg'; 

import LinkViewField from './LinkViewField';
import LinkViewFileViewerModal from './LinkViewFileViewerModal';
import LinkViewLoadingModal from './LinkViewLoadingModal'



import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 






const beautify_field_name = (field_name) => {
  const mapping = {
    'email_address': 'Email address',
    'password': 'Password',
    'username': 'Username',
    'phone_number': 'Phone number',
    'notes': 'Additional notes',

    // config specific fields
    'amplitude:org_url': 'Org URL',
    'awsiam:account_id': 'AWS account ID',
    'godaddy:customer_number': 'Customer number',
    'metabase:org_url': 'Org URL',
    'slack:org_url': 'Org URL',
  }

  if (field_name.startsWith('custom:')) {
    return field_name.slice(7);
  }

  else if (mapping[field_name]) {
    return mapping[field_name];
  }

  else {
    return field_name;
  }
}






class LinkView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      view_locked: true,

      link: {},
      item_unprotected_data: {},

      email_address_holder: '',
      passcode_holder: '',

      email_verification_code_sent: false,
      email_verification_code_holder: '',
      email_verification_code_registration_id: '',

      email_address_valid: false,
      inputs_valid: false,

      link_view_file_viewer_modal_open: false,
      preview_file_metadata: {},
      viewer_id: '',

      error_message: '',
      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {

    const link_id = this.props.match.params.link_id;

    // Fetch link

    const get_link_res = await axios.get(`/api/links/${link_id}`);

    if (!get_link_res || !get_link_res.data.success) {

      alert('The link is not valid.')

      await this.props.history.push(`/dashboard`);

      return;

      // // TODO show link does not exist
      // console.error('get link failed');
      // this.setState({
      //   awaiting_server_response: false,
      //   error_message: 'get link failed'
      // });
    }

    const link = get_link_res.data.link;

    const item_id = link.link_metadata.item_id;

    // Fetch item unprotected data

    const get_item_res = await axios.get(`/api/items/${item_id}/link-data/fetch`);

    if (!get_item_res || !get_item_res.data.success) {
      console.error('get item failed');
      this.setState({
        awaiting_server_response: false,
        error_message: 'get item failed'
      });
      return;
    }

    const item_unprotected_data = get_item_res.data.item_unprotected_data;

    // Check if the 'access' button should be available right away (no email, no email verification, no passcode)
    const link_metadata = link.link_metadata
    if (!link_metadata.require_email && !link_metadata.require_email_verification && !link_metadata.passcode) {
      this.setState({
        inputs_valid: true
      });
    }


    // Set link and item_unprotected_data
    this.setState({
      link: link, 
      item_unprotected_data: item_unprotected_data,
      awaiting_server_response: false
    });

    // mixpanel.track('web_login_visited', {
    //   is_dev: this.props.mode_info.is_dev
    // });
  }

  handle_email_address_change = (e) => {
    this.setState({ 
      email_address_holder: e.target.value, 
      email_address_valid: this.validate_email_address(e.target.value),
      error_message: '' 
    }, () => {
      this.check_inputs_valid();
    });
  }

  handle_email_verification_code_change = (e) => {
    this.setState({ email_verification_code_holder: e.target.value, error_message: '' }, () => {
      this.check_inputs_valid();
    });
  }

  handle_passcode_change = (e) => {
    this.setState({ passcode_holder: e.target.value, error_message: '' }, () => {
      this.check_inputs_valid();
    });
  }

  check_inputs_valid = () => {

    const link_metadata = this.state.link.link_metadata;

    const require_email_valid = ((!link_metadata.require_email) || (this.validate_email_address(this.state.email_address_holder)));
    const require_email_verification_valid = ((!link_metadata.require_email_verification) || (this.state.email_verification_code_holder));
    const passcode_valid = ((!link_metadata.passcode) || (this.state.passcode_holder));

    this.setState({
      inputs_valid: require_email_valid && require_email_verification_valid && passcode_valid
    })
  }

  validate_email_address = (email_address) => {
    // const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    let contains_one_at = (((email_address).match(/@/g) || []).length === 1);
    let at_least_one_dot_right = ((((email_address).split('@')[1] || '').match(/\./g) || []).length > 0);
  
    // TODO do more checking than just seeing if @ is contained...
    return (contains_one_at && at_least_one_dot_right);
  }

  on_verification_code_send = async () => {

    this.setState({
      awaiting_server_response: true,
      error_message: '',
    });

    /**************************************
    * 1. Create a registration
    ***************************************/

    const create_registration_res = await axios.post(`/api/registrations/share-link-verification-code/create`, {
      email_address: this.state.email_address_holder,
      item_name: this.state.item_unprotected_data.item_name
    });

    if (!create_registration_res || !create_registration_res.data.success) {

      // TODO show link does not exist

      console.error('create registration failed');
      this.setState({
        awaiting_server_response: false,
        error_message: 'create registration failed'
      });
      return;
    }

    const registration_id = create_registration_res.data.registration_id;

    this.setState({
      email_verification_code_registration_id: registration_id,
      email_verification_code_sent: true,

      awaiting_server_response: false,
    })
  }
 
  on_access = async () => {
    try {

      this.setState({
        awaiting_server_response: true
      });

      /**************************************
      * 1. Validate the typed in credentials
      ***************************************/

      const link_metadata = this.state.link.link_metadata


      if (link_metadata.require_email) {
        // Nothing to do specially here
      }

      if (link_metadata.require_email_verification) {
        // Validate email verification registration
        const validate_registration_res = await axios.post(`/api/registrations/${this.state.email_verification_code_registration_id}/validate`, {
          email_address: `${this.state.email_address_holder}-${this.state.email_verification_code_holder}`,
          type: 'share-link-verification-code'
        });

        if (!validate_registration_res || !validate_registration_res.data.success) {
          // TODO show link does not exist
          console.error('validate registration failed');
          this.setState({
            email_verification_code_holder: '',
            email_verification_code_sent: false,
            
            error_message: 'Incorrect email verification code. Please try again.',
            awaiting_server_response: false,
          });
          return;
        }
      }

      if (link_metadata.passcode) {
        if (link_metadata.passcode !== this.state.passcode_holder) {
          this.setState({
            passcode_holder: '',
    
            error_message: 'Incorrect passcode. Please try again.',
            awaiting_server_response: false,
          });
          return;
        }
      }

      /**************************************
      * 2. If success, delete the registration
      ***************************************/

      if (link_metadata.require_email_verification) {
        await axios.delete(`/api/registrations/${this.state.email_verification_code_registration_id}`);
      }


      /**************************************
      * 3. Trigger current access count count and delete link if max access reached, also send email notification to item owner
      ***************************************/

      let viewer_identifier;
      if (this.state.link.link_metadata.require_email) {
        viewer_identifier = this.state.email_address_holder;
      } else {
        viewer_identifier = 'viewer_without_email';
      }

      const register_access_res = await axios.put(`/api/links/${this.state.link.link_id}/register-access`, {
        viewer_identifier: viewer_identifier,
        history_metadata: {
          link_id: this.state.link.link_id,
          link_name: this.state.link.link_name,
        },

        item_id: this.state.item_unprotected_data.item_id,
        owner_user_id: this.state.item_unprotected_data.owner_user_id,
      });

      if (!register_access_res || !register_access_res.data.success) {
        // TODO show link does not exist
        console.error('register access failed');
        this.setState({
          error_message: 'register access failed',
          awaiting_server_response: false,
        });
        return;
      }

      /**************************************
      * 4. Unlock view
      ***************************************/

      this.setState({
        view_locked: false,

        awaiting_server_response: false,
      });
      
      
    }
    

    catch (error) {
      console.log(error)
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong'
      });
    }

  }





  on_link_view_file_viewer_modal_open = () => {
    this.setState({
      link_view_file_viewer_modal_open: true,
    });

    // // Mixpanel web_file_viewer_modal_opened
    // mixpanel.track('web_file_viewer_modal_opened', {
    //   distinct_id: this.props.user_id,
    //   team_id: this.props.selected_team_id,
    //   is_dev: this.props.mode_info.is_dev
    // });
  }

  on_link_view_file_viewer_modal_close = async () => {
    this.setState({
      link_view_file_viewer_modal_open: false,
      preview_file_metadata: {},
    });
  } 


  preview_file = async (file_metadata) => {

    const file_id = file_metadata.file_id;

    let viewer_id;

    if (this.state.link.link_metadata.require_email) {
      viewer_id = this.state.email_address_holder.replace(/\@/g, '_').replace(/\./g, '_'); // Replace @ and . with _
    } else {
      viewer_id = 'viewer_without_email'
    }

    this.setState({
      link_view_file_viewer_modal_open: true,

      viewer_id: viewer_id,
    })

    const file_preview_res = await axios.post(`/api/files/${this.state.item_unprotected_data.team_id}/${file_id}/preview/share-link`, {
      viewer_id: viewer_id,

      item_id: this.state.item_unprotected_data.item_id,
      history_metadata: {
        link_id: this.state.link.link_id,
        link_name: this.state.link.link_name
      },
      email_address: this.state.email_address_holder,
      
      watermark: this.state.link.link_metadata.watermark,
      watermark_mimetype: file_metadata.mimetype,
      watermark_date: `${new Date().toLocaleString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`,
    }); 

    if (!file_preview_res || !file_preview_res.data.success) {
      return;
    }

    await sleep(1.0);

    this.setState({
      preview_file_metadata: file_metadata,
    });
  }


  download_file = async (file_metadata) => {

    const file_id = file_metadata.file_id;
    const file_name = file_metadata.file_name;

    let viewer_id;

    if (this.state.link.link_metadata.require_email) {
      viewer_id = this.state.email_address_holder.replace(/\@/g, '_').replace(/\./g, '_'); // Replace @ and . with _
    } else {
      viewer_id = 'viewer_without_email'
    }

    this.setState({
      link_view_loading_modal_open: true,
      link_view_loading_modal_progress_message: 'Downloading file ...',

      viewer_id: viewer_id,
    })

    const get_file_res = await axios.post(`/api/files/${this.state.item_unprotected_data.team_id}/${file_id}/fetch/share-link`, {
      viewer_id: viewer_id,

      item_id: this.state.item_unprotected_data.item_id,
      history_metadata: {
        link_id: this.state.link.link_id,
        link_name: this.state.link.link_name
      },
      email_address: this.state.email_address_holder,

      watermark: this.state.link.link_metadata.watermark,
      watermark_mimetype: file_metadata.mimetype,
      watermark_date: `${new Date().toLocaleString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`,
    }); 

    const file__unenc__base64 = get_file_res.data.file__unenc;

    const file__unenc__uint8_array = base64_to_uint8_array(file__unenc__base64);

    // Invisible link for the download
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const blob = new Blob([file__unenc__uint8_array], {type: 'application/octet-stream'});
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = file_name;
    a.click();
    window.URL.revokeObjectURL(url);

    this.setState({
      link_view_loading_modal_open: false,
      link_view_loading_modal_progress_message: ''
    })
  }



  render() {

    let selected_item;

    if (this.state.item_unprotected_data) {
      selected_item = this.state.item_unprotected_data;
    }

    const item_icon = (item, clickable) => {
      if (item.item_type === 'account') {
        if (item.metadata.square_logo_url) {
          return (
            <div
              className='link-view__left-side-item-logo-with-config-container'
              data-item_id={item.item_id}
              onClick={clickable ? this.handle_item_click : undefined}
            >
              <div className="link-view__left-side-item-config-logo-wrapper">
                <img 
                  className='link-view__left-side-item-config-logo'
                  src={(() => {
                    let logo_url;
                    if (item.metadata.square_logo_url) {
                      logo_url = item.metadata.square_logo_url;
                    } else {
                      logo_url = 'https://plusidentity-config-assets.s3.us-west-1.amazonaws.com/logos/DEFAULT-square.png';
                    }
                    return logo_url
                  })()}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src='https://plusidentity-config-assets.s3.us-west-1.amazonaws.com/logos/DEFAULT-square.png';}} // Show default if there is error
                  data-item_id={item.item_id}
                  onClick={clickable ? this.handle_item_click : undefined}
                />
              </div>
              <AccountIconConfig
                className='link-view__left-side-item-logo'
                key={`link-view__left-side-item-icon-${item.item_id}`}
                data-item_id={item.item_id}
                onClick={clickable ? this.handle_item_click : undefined}
                pointerEvents='none'
              />
            </div>
          )
        } else {
          return (
            <AccountIconNonconfig
              className='link-view__left-side-item-logo'
              key={`link-view__left-side-item-icon-${item.item_id}`}
              data-item_id={item.item_id}
              onClick={clickable ? this.handle_item_click : undefined}
              pointerEvents='none'
            />
          )
        }
      } 

      else if (item.item_type === 'file') {
        return (
          <FileIcon 
            className='link-view__left-side-item-logo'
            key={`link-view__left-side-item-icon-${item.item_id}`}
            data-item_id={item.item_id}
            onClick={clickable ? this.handle_item_click : undefined}
            pointerEvents='none'
          />
        )
      }

      else if (item.item_type === 'custom') {
        return (
          <CustomIcon 
            className='link-view__left-side-item-logo'
            key={`link-view__left-side-item-icon-${item.item_id}`}
            data-item_id={item.item_id}
            onClick={clickable ? this.handle_item_click : undefined}
            pointerEvents='none'
          />
        )
      }
    }



    return (
      <div className="link-view__container">
        {
          this.state.view_locked
          ? <>
              <div className="link-view__top">
                {/* <span className="link-view__title">L I N K &nbsp;&nbsp;&nbsp; V I E W</span> */}
                <span className="link-view__title">{this.state.item_unprotected_data.item_name}</span>
                <div className="link-view__plus-lock"><PlusLock/></div>
              </div>
              <div className="link-view__bottom">
                {
                  this.state.awaiting_server_response
                  ? <ReactLoading
                      type='spokes'
                      color='#ffffff'
                      height={20}
                      width={20}
                    />
                  : <>
                      {
                        this.state.link.link_metadata.require_email
                        ? <>
                            <div
                              className='link-view__bottom-text'
                            >
                              Enter your email address
                              <div className='link-view__info-container'>
                                <InfoIcon 
                                  className='link-view__info-icon'
                                />
                                <div className='link-view__info-description'>
                                  The email you enter here will be used to identify your access to this link.
                                </div>
                              </div>
                            </div>
                            <div
                              className='link-view__bottom-input-box'
                            >
                              <input 
                                className="link-view__bottom-input-field"
                                type="text" 
                                placeholder="Email address"
                                autoFocus
                                // onKeyPress={ this.handleContinueKeypress }
                                value={ this.state.email_address_holder }
                                onChange={ this.handle_email_address_change }
                                disabled={ this.state.awaiting_server_response }
                                autoComplete="new-password"
                              />
                            </div>
                          </>
                        : <></>
                      }
                      {
                        this.state.link.link_metadata.require_email_verification
                        ? this.state.email_verification_code_sent
                          ? <div
                              className='link-view__bottom-input-box'
                            > 
                              <input 
                                className="link-view__bottom-input-field"
                                type="text" 
                                placeholder="Verification code"
                                autoFocus
                                // onKeyPress={ this.handleContinueKeypress }
                                value={ this.state.email_verification_code_holder }
                                onChange={ this.handle_email_verification_code_change }
                                disabled={ this.state.awaiting_server_response }
                                autoComplete="new-password"
                              />
                            </div>
                          : <input
                              className={`link-view__bottom-verification-code-send-button ${this.state.email_address_valid ? '' : 'disabled'}`}
                              type="submit"
                              value="Send verification code"
                              disabled={ !this.state.email_address_valid || this.state.awaiting_server_response }
                              onClick={ this.on_verification_code_send }
                            />
                        : <></>
                      }
                      {
                        this.state.link.link_metadata.passcode
                        ? <>
                            <div
                              className='link-view__bottom-text'
                            >
                              Enter the passcode
                              <div className='link-view__info-container'>
                                <InfoIcon 
                                  className='link-view__info-icon'
                                />
                                <div className='link-view__info-description'>
                                  If unsure, ask the link owner for the passcode for this link.
                                </div>
                              </div>
                            </div>
                            <div
                              className='link-view__bottom-input-box'
                            >
                              <input 
                                className="link-view__bottom-input-field"
                                type={this.state.passcode_holder ? "password" : "text"}
                                placeholder="Passcode"
                                autoFocus
                                // onKeyPress={ this.handleSubmitKeypress }
                                value={ this.state.passcode_holder }
                                onChange={ this.handle_passcode_change }
                                disabled={ this.state.awaiting_server_response }
                                autoComplete="new-password"
                              />
                            </div>
                          </>
                        : <></>
                      }
                      <input
                        className={`link-view__bottom-access-button ${this.state.inputs_valid ? '' : 'disabled'}`}
                        type="submit"
                        value="Access"
                        disabled={ !this.state.inputs_valid || this.state.awaiting_server_response}
                        onClick={ this.on_access }
                      />
                    </>
                }
                <span className="link-view__error-message">{ this.state.error_message }</span>
              </div>
            </>
          : <div className='link-view__unlocked_container'>
              <div className="link-view__right-side-item">
                <div className="link-view__right-side-item-title">
                  <div 
                    className='link-view__right-side-item-title-logo-wrapper'
                  >
                    { item_icon(selected_item, false) }
                  </div>
                  <div
                    className='link-view__right-side-item-title-text'
                  >
                    {(() => {
                      if (!selected_item.metadata.config_display_name) {
                        return selected_item.item_name
                      } else {
                        return selected_item.item_name
                      }
                    })()}
                  </div>
                </div>
                { 
                  this.state.link.link_metadata.only_file
                  ? <></>
                  : selected_item.display_data__unenc.filter((field) => (field[1])).length > 0 // there is at least one non-empty field
                    ? <>
                        <div 
                          className='link-view__right-side-separator-horizontal'
                        ></div>

                        <div className="link-view__right-side-item-info-field-list">
                          {selected_item.display_data__unenc.map((field) => 
                            (!((field[0] === 'notes') && (field[1] === '')))
                            ? <div
                                key={`${this.state.selected_item_id}-${field[0]}`}
                                className="link-view__right-side-item-info-field"
                              >
                                <span className="link-view__right-side-item-info-field-name">{beautify_field_name(field[0])}</span>
                                <div className="link-view__right-side-item-info-field-value">
                                  <LinkViewField 
                                    key={`${this.state.selected_item_id}-${field[0]}`}
                                    field_name={field[0]}
                                    field_value={field[1]}
                                    is_protected={field[1] === '********'} // This needs to be changed later
                                    account_private_key__unenc__uint8_array={this.state.account_private_key__unenc__uint8_array}
                                    item_keys={this.props.item_keys}
                                    selected_item_id={this.state.selected_item_id}
                                    selected_item_name={selected_item.item_name}
                                    permissions={selected_item.permissions}
                                    // permission={selected_item.permissions[this.props.user_id]}
                                    // user_id={this.props.user_id}
                                    selected_team_id={this.state.item_unprotected_data.team_id}

                                    handle_dashboard_pull_upon_item_change={this.handle_dashboard_pull_upon_item_change}
                                    update_dashboard_items={this.update_dashboard_items}

                                    mode_info={this.props.mode_info}
                                  />
                                </div>
      
                              </div>
                            : <React.Fragment key={`${this.state.selected_item_id}-${field[0]}`}></React.Fragment> // To suppress must have unique key error
                          )}
                        </div>

                      </>
                    : <></>
                }
                {
                  selected_item.files_metadata.length > 0
                  ? <>
                      <div 
                        className='link-view__right-side-separator-horizontal'
                      ></div>

                      <div className="link-view__right-side-item-files-list">
                        {selected_item.files_metadata.map((file_metadata) => 
                          <div
                            key={`${this.state.selected_item_id}-${file_metadata.file_id}`}
                            className="link-view__right-side-item-file"
                          >
                            <div className="link-view__right-side-item-file-name">
                              {file_metadata.file_name}
                            </div>
                            <div className="link-view__right-side-item-file-actions">
                              <div
                                className='link-view__right-side-item-file-actions-preview'
                                onClick={async () => await this.preview_file(file_metadata)}
                              >
                                <PreviewIcon
                                  className='link-view__right-side-item-file-actions-preview-icon'
                                />
                                <div 
                                  className='link-view__right-side-item-file-actions-preview-description'
                                >
                                  Preview file
                                </div>
                              </div>
                              {
                                this.state.link.link_metadata.allow_downloads
                                ? <div
                                    className='link-view__right-side-item-file-actions-download'
                                    onClick={async () => await this.download_file(file_metadata)}
                                  >
                                    <DownloadIcon 
                                      className='link-view__right-side-item-file-actions-download-icon'
                                    />
                                    <div 
                                      className='link-view__right-side-item-file-actions-download-description'
                                    >
                                      Download file
                                    </div>
                                  </div>
                                : <></>
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  : <></>
                }
              </div>
            </div>
        }


        <LinkViewFileViewerModal
          is_open={this.state.link_view_file_viewer_modal_open}
          on_link_view_file_viewer_modal_close={this.on_link_view_file_viewer_modal_close}

          preview_file_metadata={this.state.preview_file_metadata}
          selected_team_id={this.state.item_unprotected_data.team_id}
          viewer_id={this.state.viewer_id}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <LinkViewLoadingModal
          is_open={this.state.link_view_loading_modal_open}
          // on_file_viewer_modal_close={this.on_loading_modal_close}

          progress_message={this.state.link_view_loading_modal_progress_message}

          mode_info={this.props.mode_info}
        />


      </div>
    );
  }
}

export default withRouter(LinkView);
