import React from 'react';
import './DeleteAccountModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import {auth_axios} from '../../../../../auth/util';

import { FaTimes } from 'react-icons/fa';

import { set as dbSet, get as dbGet } from 'idb-keyval';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


class DeleteAccountModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      confirm_text_holder: '',
      delete_confirmed: false

    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }


  handle_confirm_text_change = (e) => {
    const confirm_text = e.target.value;

    this.setState({ 
      confirm_text_holder: confirm_text,  
      delete_confirmed: (confirm_text === `Delete from ${this.props.selected_team_name}`)
    });
  }

  clear_confirm_text = () => {
    this.setState({
      confirm_text_holder: '',
      delete_confirmed: false
    })
  }


  delete_account = async () => {

    //
    this.setState({
      error_message: '',
      awaiting_server_response: true
    });


    /**************************************
    * 1. Call the comprehensive delete account API call
    ***************************************/

    const delete_account_res = await auth_axios.put(`/api/users/${this.props.user_id}/accounts/${this.props.selected_team_id}/remove`, {});

    if (!delete_account_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with delete account'
      });
      return;
    }

    /**************************************
    * 2. Delete the team public key from the client-side team_public_keys 
    ***************************************/

    const original_team_public_keys = await dbGet('team_public_keys');
    let new_team_public_keys = {...original_team_public_keys}
    delete new_team_public_keys[this.props.selected_team_id];

    // set
    await dbSet('team_public_keys', new_team_public_keys)

    /**************************************
    * 2. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false
    });


    /**************************************
    * 3. Mixpanel tracking
    ***************************************/

    mixpanel.track('web_delete_account_modal_submitted', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });

  
    /**************************************
    * 4. Refresh page to see changes on dashboard immediately!
    ***************************************/

    // Reload
    window.location.reload(false);
  }


  render() {

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_delete_account_modal_close();
            this.clear_confirm_text();
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 240 : 340,
            width: screen_width > 690 ? 400 : 300
          }
        }}
      >
        <div className="delete-account-modal__container">
          <div
            className='delete-account-modal__text' 
          >
            You are about to delete your account from {this.props.selected_team_name}. To proceed, please type <span className='delete-account-modal__text-bold'>Delete from {this.props.selected_team_name}</span> below to confirm.
          </div>
          <input
            className='delete-account-modal__confirm-text-input'
            placeholder={`Delete from ${this.props.selected_team_name}`}
            type='text'
            value={ this.state.confirm_text_holder }
            onChange={ this.handle_confirm_text_change }
            disabled={ this.state.awaiting_server_response }
          />
          <div className="delete-account-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    this.state.delete_confirmed
                    ? <input
                        type='submit'
                        className={`delete-account-modal__delete-button`}
                        value={ 'Delete account' }
                        onClick={ this.delete_account }
                      />
                    : <input
                        type='submit'
                        className={`delete-account-modal__delete-button disabled`}
                        value={ 'Delete account' }
                      />
                  }
                  <a
                    className='delete-account-modal__cancel'
                    onClick={() => {
                      this.props.on_delete_account_modal_close();
                      this.clear_confirm_text();
                    }}
                  >
                    Cancel
                  </a>
                </>
            }
          </div>
          <span className='delete-account-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default DeleteAccountModal;
