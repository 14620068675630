import React from 'react';
import './RequestAccessModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

import {auth_axios} from '../../../auth/util'

import { uint8_array_to_base64, string_to_uint8_array } from '../../../crypto/util';
import { send_slack_notification } from '../../../slack/util';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


class RequestAccessModal extends React.Component {
  
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }


  request_access = async () => {
    try {
      //
      this.setState({
        error_message: '',
        awaiting_server_response: true
      });


      /**************************************
      * 1. Call the request access API call
      ***************************************/

      const request_access_res = await auth_axios.post(`/api/items/${this.props.selected_item.item_id}/access/request`, {
        user_id: this.props.user_id,
      });

      if (!request_access_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with request access'
        });
        return;
      }


      /**************************************
      * 2. Stop loading 
      ***************************************/

      this.setState({
        awaiting_server_response: false
      });


      



      /**************************************
      * 4. Slack notification request
      ***************************************/


      

      // Message to requester

      const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

      const team_id = this.props.selected_team_id;
      const user_id = this.props.user_id;

      const owner_user_id = this.props.selected_item.owner_user_id;

      const item_name = this.props.selected_item.item_name
      const owner_display_name = this.props.user_id_display_name_maps[owner_user_id]

      
      const message = `You requested access to ${item_name} from SLACK_USER_ID (${owner_display_name}).`;
      await send_slack_notification(url, team_id, user_id, message, {TO_FORMAT_USER_ID: owner_user_id});






      // Message to item owner

      const incoming_request_url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

      const requestor_display_name = this.props.user_id_display_name_maps[user_id]

      const accept_url_query_params__base64 = uint8_array_to_base64(string_to_uint8_array(`modal_type=accept_access&item_id=${this.props.selected_item.item_id}&access_request_user_id=${user_id}`));

      const incoming_request_message = `SLACK_USER_ID (${requestor_display_name}) requested access to ${item_name}.`;
      await send_slack_notification(incoming_request_url, team_id, this.props.selected_item.owner_user_id, incoming_request_message, {
        TO_FORMAT_USER_ID: user_id, 
        ADDITIONAL_ACTIONS: 'incoming_request_action_buttons',
        ACCEPT_URL: `https://app.plusidentity.com/dashboard?${accept_url_query_params__base64}`,
        DECLINE_INFO: {
          item_id: this.props.selected_item.item_id,
          requestor_user_id: user_id
        }
      });
      


      /**************************************
      * 3. Trigger dashboard items pull and close modal
      ***************************************/

      await this.props.on_request_access_modal_close(true);
      await this.props.update_dashboard_items();

      

      /**************************************
      * 5. Mixpanel
      ***************************************/

      mixpanel.track('web_request_access_modal_submitted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });




      /**************************************
      * 4. Refresh page to see changes on dashboard immediately!
      ***************************************/

      // Redirect and reload
      //await this.props.history.push(`/dashboard`);
      // window.location.reload(false);
    }



    catch (error) {
      console.log(error);
    }

  }


  render() {
    let item_name = ''
    let owner_display_name = '';

    if (this.props.selected_item) {
      item_name = this.props.selected_item.item_name
      owner_display_name = this.props.user_id_display_name_maps[this.props.selected_item.owner_user_id]
    }

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            await this.props.on_request_access_modal_close(false);
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 165 : 165,
            width: screen_width > 690 ? 360 : 300
          }
        }}
      >
        <div className="request-access-modal__container">
          <div
            className='request-access-modal__text' 
          >
            Do you want to request access for <span className='request-access-modal__text-bold'>{item_name}</span> from <span className='request-access-modal__text-bold'>{owner_display_name}</span>?
          </div>
          {
            this.state.awaiting_server_response
            ? <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            : <>
                <input
                  type='submit'
                  className='request-access-modal__delete-button'
                  value={ 'Request access' }
                  onClick={ this.request_access }
                />
                <a
                  className='request-access-modal__cancel'
                  onClick={async () => {
                    await this.props.on_request_access_modal_close(false);
                  }}
                >
                  Cancel
                </a>
              </>
          }
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default RequestAccessModal;
