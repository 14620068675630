import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { base64_to_uint8_array, uint8_array_to_string } from '../crypto/util';

const UnprotectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route 
      {...rest} 
      render={
        props => {
          if (rest.session_status === 'SESSION_VALID') {

            // Check if any intended
            const query__base64 = props.location.search.split('?')[1] || '';
            const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));
            const url_params = new URLSearchParams(`?${query__string}`);

            const intended_pathname = url_params.get('intended_pathname');
            const intended_search = url_params.get('intended_search');

            return <Redirect to={
              {
                pathname: `${intended_pathname ? intended_pathname : '/dashboard'}`,
                search: `${intended_search ? intended_search : ''}`,
              }
            } />
          } 
          else {
            return <Component {...rest} {...props} />
          } 
        }
      } 
    />
  )
}

export default UnprotectedRoute;
