//

// INPUT
// data__string : String
// OUTPUT
// data__uint8_array : Uint8Array
export const string_to_uint8_array = (data__string) => {
  const UTF8 = new TextEncoder();
  const data__uint8_array = UTF8.encode(data__string);

  return data__uint8_array;
}

// INPUT
// data__uint8_array : Uint8Array
// OUTPUT
// data__string : String
export const uint8_array_to_string = (data__uint8_array) => {
  const UTF8 = new TextDecoder();
  const data__string = UTF8.decode(data__uint8_array);

  return data__string;
}

// INPUT
// data__uint8_array : Uint8Array
// OUTPUT
// data__base64 : String (Base64)
export const uint8_array_to_base64 = (data__uint8_array) => {
  let data__buffer = Buffer.from(data__uint8_array);
  let data__base64 = data__buffer.toString('base64');

  return data__base64;
}

// INPUT
// data__base64 : String (Base64)
// OUTPUT
// data__uint8_array : Uint8Array
export const base64_to_uint8_array = (data__base64) => {
  let data__uint8_array = Buffer.from(data__base64, 'base64');

  return data__uint8_array;
}

// INPUT
// (unspecified arguments) : Uint8Array (1 or more)
// OUTPUT
// data__uint8_array : Uint8Array
export function concat_uint8_arrays () {
  let newLength = 0;

  for (var i=0; i<arguments.length; i++) {
    newLength += arguments[i].length;
  }

  let result__uint8_array = new Uint8Array(newLength);
  
  result__uint8_array.set(arguments[0]);
  let counter = arguments[0].length
  for (let i=1; i<arguments.length; i++) {
    result__uint8_array.set(arguments[i], counter);
    counter += arguments[i].length;
  }

  return result__uint8_array;
}



