import React from 'react';
import './AddEmailAddressModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { auth_axios } from '../../../../../auth/util';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



const validate_email_address = (email) => {
  let contains_one_at = (((email).match(/@/g) || []).length == 1);
  let one_dot_right = ((((email).split('@')[1] || '').match(/\./g) || []).length > 0);

  // TODO do more checking than just seeing if @ is contained...
  return (contains_one_at && one_dot_right);
}



ReactModal.setAppElement('body');


class AddEmailModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      new_email_address_holder: '',
      input_valid: false,

      magic_link_sent: false,
      status_message: '',
      error_message: ''
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  reset_modal = () => {
    this.setState({
      new_email_address_holder: '',
      input_valid: false,

      magic_link_sent: false,
      status_message: '',
      error_message: ''
    })
  }


  handle_new_email_address_change = (e) => {
    const new_email_address = e.target.value;

    this.setState({ 
      new_email_address_holder: new_email_address,  
      input_valid: (new_email_address !== '') && (!this.props.email_addresses.includes(new_email_address)) && validate_email_address(new_email_address)
    });
  }


  send_add_email_magic_link = async () => {

    //
    this.setState({
      error_message: '',
      status_message: '',
      awaiting_server_response: true
    });

    /**************************************
    * 1. Check if email already exists
    ***************************************/

    const email_check_res = await axios.post('/api/util/email-address/exists', {
      email_address: this.state.new_email_address_holder
    });

    if (email_check_res.data.email_exists) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'There already exists an account with that email.',
      });
      return;
    }


    /**************************************
    * 2. Call the add email API call
    ***************************************/

    const add_email_address_res = await auth_axios.post('/api/util/email-address/send-add-email-address-magic-link', {
      user_id: this.props.user_id,
      new_email_address: this.state.new_email_address_holder
    });

    if (!add_email_address_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with add email'
      });
      return;
    }

    /**************************************
    * 3. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false,
      magic_link_sent: true,
      status_message: 'Magic link was sent to your email.',
    });



    /**************************************
    * 4. Mixpanel tracking
    ***************************************/

    mixpanel.track('web_add_email_address_modal_magic_link_sent', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });

  
    // /**************************************
    // * 5. Refresh page to see changes on dashboard immediately!
    // ***************************************/

    // // Reload
    // window.location.reload(false);
  }



  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_add_email_address_modal_close();
            this.reset_modal();
          }
        }}
        style={{
          content: {
            height: 250,
            width: 320
          }
        }}
      >
        <div className="add-email-address-modal__container">
          <div
            className='add-email-address-modal__text' 
          >
            Enter the new email address to add to your account.
          </div>
          <input
            className='add-email-address-modal__new-email-address-input'
            placeholder={'New email'}
            type='text'
            value={ this.state.new_email_address_holder }
            onChange={ this.handle_new_email_address_change }
            disabled={ this.state.awaiting_server_response }
          />
          <div className="add-email-address-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    this.state.input_valid && (!this.state.magic_link_sent)
                    ? <input
                        type='submit'
                        className={`add-email-address-modal__update-button`}
                        value={ 'Send magic link' }
                        onClick={ this.send_add_email_magic_link }
                      />
                    : <input
                        type='submit'
                        className={`add-email-address-modal__update-button disabled`}
                        value={ 'Send magic link' }
                      />
                  }
                  <a
                    className='add-email-address-modal__cancel'
                    onClick={() => {
                      this.props.on_add_email_address_modal_close();
                      this.reset_modal();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          {
            this.state.magic_link_sent
            ? <span className='add-email-address-modal__status-message'>{this.state.status_message}</span>
            : <></>
          }
          <span className='add-email-address-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default AddEmailModal;
