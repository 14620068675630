import React from 'react';
import './MakeAdminModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';

import { get as dbGet, set as dbSet } from 'idb-keyval';
import { CSPRNG, PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT, ASYM_DECRYPT } from '../../../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../../../crypto/util';

import {auth_axios} from '../../../../../auth/util';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


class MakeAdminModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      request_sent: false,

      status_message: '',
      error_message: ''
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({        
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  reset_modal = () => {
    
  }


  on_make_admin = async () => {

    try {
      //
      this.setState({
        error_message: '',
        awaiting_server_response: true
      });

      /**************************************
      * 1. Pre-processing
      ***************************************/

      const selected_user_id = this.props.to_make_admin_user_id;

      /**************************************
      * 2. Fetch and decrypt the team unlock key
      ***************************************/

      const team_unlock_key_res = await auth_axios.get(`/api/users/${this.props.user_id}/accounts/${this.props.selected_team_id}/team-unlock-key`);

      const team_unlock_key__enc_apubk__base64 = team_unlock_key_res.data.team_unlock_key__enc_apubk

      const team_unlock_key__enc_apubk__uint8_array = base64_to_uint8_array(team_unlock_key__enc_apubk__base64);

      const team_unlock_key__unenc__uint8_array = await ASYM_DECRYPT(this.props.keychain.account_private_key__unenc__uint8_array, team_unlock_key__enc_apubk__uint8_array);

      /**************************************
      * 3. Fetch the account public key of the selected user
      ***************************************/

      // First, fetch and encrypt using account public key
      const get_account_public_key_res = await axios.get(`/api/users/${selected_user_id}/account-public-key`);

      if (!get_account_public_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get account public key'
        });
      }

      const selected_user_account_public_key__unenc__base64 = get_account_public_key_res.data.account_public_key__unenc;

      const selected_user_account_public_key__unenc__uint8_array = base64_to_uint8_array(selected_user_account_public_key__unenc__base64);

      /**************************************
      * 4. Make the add admin API call
      ***************************************/

      const team_unlock_key__enc_selected_apubk__uint8_array = await ASYM_ENCRYPT(selected_user_account_public_key__unenc__uint8_array, team_unlock_key__unenc__uint8_array);

      const team_unlock_key__enc_selected_apubk__base64 = uint8_array_to_base64(team_unlock_key__enc_selected_apubk__uint8_array)

      /**************************************
      * 5. Make the add admin API call
      ***************************************/

      const add_admin_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/add`, {
        team_owner_user_id: this.props.user_id,
        new_admin_user_id: selected_user_id,
        team_unlock_key__enc_apubk: team_unlock_key__enc_selected_apubk__base64
      });

      if (!add_admin_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with add admin'
        });
        return;
      }

      /**************************************
      * 6. Stop loading 
      ***************************************/

      this.setState({
        awaiting_server_response: false,
        request_sent: true,
        status_message: `${this.props.user_id_display_name_maps[selected_user_id]} has been successfully added as admin.`,
      });

      /**************************************
      * 7. Mixpanel tracking
      ***************************************/

      mixpanel.track('web_make_admin_modal_submitted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });

      /**************************************
      * 9. Reload 
      ***************************************/
      window.location.reload(false);
    }




    catch (error) {
      console.log(error)
      this.setState({
        awaiting_server_response: false,
        error_message: 'Error occurred while making admin'
      });
    }
  }



  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_make_admin_modal_close();
            this.reset_modal();
          }
        }}
        style={{
          content: {
            height: 220,
            width: 340
          }
        }}
      >
        <div className="make-admin-modal__container">
          <div
            className='make-admin-modal__text' 
          >
            Confirm to make {this.props.user_id_display_name_maps[this.props.to_make_admin_user_id]} a team admin.
          </div>
          
          <div className="make-admin-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    (!this.state.request_sent)
                    ? <input
                        type='submit'
                        className={`make-admin-modal__update-button`}
                        value={ 'Make an admin' }
                        onClick={ this.on_make_admin }
                      />
                    : <input
                        type='submit'
                        className={`make-admin-modal__update-button disabled`}
                        value={ 'Make an admin' }
                      />
                  }
                  <a
                    className='make-admin-modal__cancel'
                    onClick={() => {
                      this.props.on_make_admin_modal_close();
                      this.reset_modal();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          {
            this.state.request_sent
            ? <span className='make-admin-modal__status-message'>{this.state.status_message}</span>
            : <></>
          }
          <span className='make-admin-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default MakeAdminModal;
