import React from 'react';
import './ForgotPassword.css';

import axios from 'axios';
import ReactLoading from 'react-loading';


import { PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT, ASYM_DECRYPT } from '../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import {auth_axios} from '../../../auth/util'

import { set as dbSet, del as dbDel } from 'idb-keyval';


import { ReactComponent as PlusLock } from '../../../logos/plusidentity-lock.svg';
import { ReactComponent as ContinueButton } from './media/svg/continue-button.svg';


import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const url_params = new URLSearchParams(`?${query__string}`);

    const email_address_param = url_params.get('email_address');
    const user_id_param = url_params.get('user_id');



    const [email_valid, email_address__string] = this.validate_email_address(email_address_param);

    const [user_id_valid, user_id__string] = this.validate_user_id(user_id_param);

    this.state = {
      link_valid: email_valid && user_id_valid,

      email_address: email_valid ? email_address__string : '',
      user_id: user_id_valid ? user_id__string : '',

      user_agreed: false,
      magic_link_sent: false,
      error_message: '',
      awaiting_server_response: true
    }
  }

  validate_email_address = (query_email_address) => {
    // const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    if (!query_email_address) {
      return [false, ''];
    }

    let contains_one_at = (((query_email_address).match(/@/g) || []).length === 1);
    let one_dot_right = ((((query_email_address).split('@')[1] || '').match(/\./g) || []).length > 0);
  
    // TODO do more checking than just seeing if @ is contained...
    return [(contains_one_at && one_dot_right), query_email_address];
  }

  validate_user_id = (query_user_id) => {  
    // TODO
    return [true, query_user_id];
  }

  handle_user_agreed = () => {
    this.setState(({
      user_agreed: !this.state.user_agreed
    }))
  }

  componentDidMount = async () => {
    // If URL is invalid, then redirect to login
    if (!this.state.link_valid) {
      await this.props.history.push(`/login`);
      return;
    }

    this.setState({
      awaiting_server_response: false
    })

    mixpanel.track('web_forgot_password_visited', {        
      is_dev: this.props.mode_info.is_dev,
      user_id: this.state.user_id
    });
  }

  // Email address is entered in
  on_send_registration_link = async () => {

    this.setState({
      awaiting_server_response: true,
    });

    // Trigger magic link send
    const send_magic_link_res = await axios.post('/api/util/email-address/send-recover-account-magic-link', {
      email_address: this.state.email_address,
      user_id: this.state.user_id
    });

    if (send_magic_link_res) {

      if (send_magic_link_res.data.success) {
        this.setState({
          magic_link_sent: true,
          awaiting_server_response: false,
        });

        // Mixpanel
        mixpanel.track('web_recover_account_link_sent', {  
          distinct_id: this.state.user_id,
          is_dev: this.props.mode_info.is_dev
        });

      }

      else {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with the server... sorry about that!'
        });
      }
    }

    // Nothing came back from the server...
    else {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went terribly wrong with the server... sorry about that!'
      });
    }
  }

  render() {
    return (
      <div className="forgot-password__container">
        <div className="forgot-password__top">
          <span className="forgot-password__title">F O R G O T &nbsp;&nbsp;&nbsp; P A S S W O R D</span>
          <div className="forgot-password__plus-lock"><PlusLock/></div>
        </div>
        <div className="forgot-password__bottom"> 
          <div
            className='forgot-password__bottom-input-box'
          >
            <input 
              className="forgot-password__bottom-input-field disabled"
              value={ this.state.email_address }
              type="text" 
              disabled
            />
          </div>

          <div
            className='forgot-password__bottom-agreed'
            onClick={this.state.magic_link_sent ? undefined : this.handle_user_agreed}
          >
            <input
              type='checkbox'
              checked={this.state.user_agreed}
              value={this.state.user_agreed}
              onChange={this.state.magic_link_sent ? undefined : this.handle_user_agreed}
              disabled={this.state.awaiting_server_response || this.state.magic_link_sent}
              className='forgot-password__bottom-agreed-checkbox'
            />
            <div
              className='forgot-password__bottom-agreed-text'
            >
              I understand that PlusIdentity never stores my master password or any of its derivatives, and that PlusIdentity may not be able to recover my account.
            </div>
          </div> 

          <div
            className='forgot-password__bottom-send-link-action'
          >
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : this.state.magic_link_sent
                ? <span 
                    className='forgot-password__bottom-send-link-ack'
                  >
                    Magic link was sent to your email.
                  </span>
                : this.state.user_agreed
                  ? <input
                      className='forgot-password__bottom-send-link-button'
                      type="submit"
                      value="Initiate account recovery"
                      onClick={ this.on_send_registration_link }
                    />
                  : <input
                      className='forgot-password__bottom-send-link-button disabled'
                      type="submit"
                      value="Initiate account recovery"
                    />
            }
          </div>
          <span className="forgot-password__error-message">{ this.state.error_message }</span>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
