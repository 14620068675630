import React from 'react';
import './RemoveAccountModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';

import { get as dbGet, set as dbSet } from 'idb-keyval';
import { CSPRNG, PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT, ASYM_DECRYPT } from '../../../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../../../crypto/util';

import {auth_axios} from '../../../../../auth/util';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


class RemoveAccountModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      request_sent: false,

      confirm_text_holder: '',
      remove_account_confirmed: false,

      status_message: '',
      error_message: ''
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({        
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    try {
      if (this.props.to_remove_account_user_id !== prevProps.to_remove_account_user_id) {
        this.setState({
          request_sent: false,
  
          confirm_text_holder: '',
          remove_account_confirmed: false,
  
          status_message: '',
          error_message: ''
        })
      }
    }

    catch (error) {
      console.log(error);
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  reset_modal = () => {
    this.setState({      
      confirm_text_holder: '',
      remove_account_confirmed: false,
    })
  }

  handle_confirm_text_change = (e) => {
    const confirm_text = e.target.value;

    this.setState({ 
      confirm_text_holder: confirm_text,  
      remove_account_confirmed: (confirm_text === `Remove ${this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]} from ${this.props.selected_team_name}`)
    });
  }


  on_remove_user = async () => {

    try {
      //
      this.setState({
        error_message: '',
        awaiting_server_response: true,
        status_message: `This may take a moment ...`,
      });

      /**************************************
      * 1. Pre-processing
      ***************************************/

      const to_remove_account_user_id = this.props.to_remove_account_user_id;

      const pre_action_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/delete-account-pre-actions/${to_remove_account_user_id}`, {
        admin_user_id: this.props.user_id
      });

      if (!pre_action_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with pre action'
        });
        return;
      }

      const delete_item_ids = pre_action_res.data.delete_item_ids;
      const admin_add_item_ids = pre_action_res.data.admin_add_item_ids;
      const admin_add_items_permissions = pre_action_res.data.admin_add_items_permissions;
      const change_owner_item_ids = pre_action_res.data.change_owner_item_ids;

      /**************************************
      * 2. Remove all items that to_remove_account_user_id owns and has not shared with anyone
      ***************************************/

      for (const delete_item_id of delete_item_ids) {
        const delete_item_res = await auth_axios.put(`/api/items/${delete_item_id}/remove`, {
          user_id: to_remove_account_user_id
        });
    
        if (!delete_item_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with delete item'
          });
          return;
        }
      }

      /**************************************
      * 3. Add admin access to admin_add_item_ids
      ***************************************/

      const account_public_key__unenc__uint8_array = this.props.keychain.account_public_key__unenc__uint8_array;

      const account_private_key__unenc__uint8_array = this.props.keychain.account_private_key__unenc__uint8_array;


      const team_unlock_key_res = await auth_axios.get(`/api/users/${this.props.user_id}/accounts/${this.props.selected_team_id}/team-unlock-key`);

      const team_private_key_res = await auth_axios.get(`/api/teams/${this.props.selected_team_id}/team-private-key`);

      if (!team_private_key_res.data.success || !team_unlock_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get team private / unlock key command'
        });
        return;
      }

      const team_unlock_key__enc_apubk__base64 = team_unlock_key_res.data.team_unlock_key__enc_apubk;

      const team_unlock_key__enc_apubk__uint8_array = base64_to_uint8_array(team_unlock_key__enc_apubk__base64);

      const team_private_key__enc_tuk__base64 = team_private_key_res.data.team_private_key__enc_tuk

      const team_private_key__enc_tuk__uint8_array = base64_to_uint8_array(team_private_key__enc_tuk__base64);

      const team_unlock_key__unenc__uint8_array = await ASYM_DECRYPT(account_private_key__unenc__uint8_array, team_unlock_key__enc_apubk__uint8_array);

      const team_private_key__unenc__uint8_array = await SYM_DECRYPT(team_unlock_key__unenc__uint8_array, team_private_key__enc_tuk__uint8_array);
      

      const get_item_keys_res = await auth_axios.get(`/api/teams/${this.props.selected_team_id}/item-keys`);

      if (!get_item_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get item keys command'
        });
        return;
      }

      const team_item_keys = get_item_keys_res.data.item_keys;


      for await (const admin_add_item_id of admin_add_item_ids) {

        // First, derive the item key using team private key

        const item_key__enc_tpubk__base64 = team_item_keys[admin_add_item_id];

        const item_key__enc_tpubk__uint8_array = base64_to_uint8_array(item_key__enc_tpubk__base64);

        const item_key__unenc__uint8_array = await ASYM_DECRYPT(team_private_key__unenc__uint8_array, item_key__enc_tpubk__uint8_array)



        // Second, encrypt using admin's account public key

        const item_key__enc_apubk__uint8_array = await ASYM_ENCRYPT(account_public_key__unenc__uint8_array, item_key__unenc__uint8_array);

        const item_key__enc_apubk__base64 = uint8_array_to_base64(item_key__enc_apubk__uint8_array); // TO SAVE



        // Third, fetch and encrypt using device public keys

        const get_device_public_keys_res = await axios.get(`/api/users/${this.props.user_id}/device-public-keys`);

        if (!get_device_public_keys_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with get device public keys'
          });
          return;
        }

        const device_public_keys = get_device_public_keys_res.data.device_public_keys

        let item_keys__enc_dpubk = {}; // TO PARSE AND SAVE

        for (const device_id in device_public_keys) { // TODO okay that this does not have await?
          const shared_user_device_public_key__unenc__base64 = device_public_keys[device_id];

          const shared_user_device_public_key__unenc__uint8_array = base64_to_uint8_array(shared_user_device_public_key__unenc__base64);

          const item_key__enc_shared_user_dpubk__uint8_array = await ASYM_ENCRYPT(shared_user_device_public_key__unenc__uint8_array, item_key__unenc__uint8_array);

          const item_key__enc_shared_user_dpubk__base64 = uint8_array_to_base64(item_key__enc_shared_user_dpubk__uint8_array); // TO SAVE

          item_keys__enc_dpubk[device_id] = item_key__enc_shared_user_dpubk__base64;
        }


        // Fourth, call insert item key API call

        const insert_item_key_to_user_res = await auth_axios.put(`/api/users/${this.props.user_id}/item-key`, {
          item_id: admin_add_item_id,
          item_key__enc_apubk: item_key__enc_apubk__base64,
          item_keys__enc_dpubk: item_keys__enc_dpubk
        });

        if (!insert_item_key_to_user_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with insert item key to user'
          });
          return;
        }


        // Fifth, update item permissions with admin user's permission and make API call

        const new_permissions = {
          ...admin_add_items_permissions[admin_add_item_id],
          [this.props.user_id]: 'read_write'
        }

        const update_item_permissions_res = await auth_axios.put(`/api/items/${admin_add_item_id}/permissions`, {
          permissions: new_permissions
        });
    
        if (!update_item_permissions_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with update permissions'
          });
          return;
        }

      }

        

      /**************************************
      * 4. Transfer ownership of all change_owner_item_ids from to_remove_account_user_id to admin 
      ***************************************/

      const change_owner_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/delete-account-change-owner/${to_remove_account_user_id}`, {
        admin_user_id: this.props.user_id,
        change_owner_item_ids: change_owner_item_ids
      });

      if (!change_owner_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with change owner'
        });
        return;
      }


      /**************************************
      * 4. Delete the account from team entry's admins object, since the normal delete account requires that one is not team admin before being able to delete, so for this case, we need to actually check
      ***************************************/

      if (this.props.to_remove_account_user_type === 'admin') {
        const remove_admin_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/remove`, {
          to_revoke_admin_user_id: to_remove_account_user_id,
        });
  
        if (!remove_admin_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with remove admin'
          });
          return;
        }
      }

      /**************************************
      * 5. Now, call the comprehensive delete account API call
      ***************************************/

      const delete_account_res = await auth_axios.put(`/api/users/${to_remove_account_user_id}/accounts/${this.props.selected_team_id}/remove`, {});

      if (!delete_account_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with delete account'
        });
        return;
      }

      

      /**************************************
      * 5. Delete the team public key from the client-side team_public_keys 
      ***************************************/

      // This will be done automatically by the device of the to_remove_account_user_id, which will do team_public_keys sync reload
      

      /**************************************
      * 6. Stop loading 
      ***************************************/

      this.setState({
        awaiting_server_response: false,
        request_sent: true,
        status_message: `${this.props.user_id_display_name_maps[to_remove_account_user_id]} has been removed from the team.`,
      });

      /**************************************
      * 7. Mixpanel tracking
      ***************************************/

      mixpanel.track('web_remove_account_modal_submitted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });

      /**************************************
      * 9. Reload 
      ***************************************/
      window.location.reload(false);
    }




    catch (error) {
      console.log(error)
      this.setState({
        awaiting_server_response: false,
        error_message: 'Error occurred while removing account'
      });
    }
  }



  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_remove_account_modal_close();
            this.reset_modal();
          }
        }}
        style={{
          content: {
            height: 440,
            width: 440
          }
        }}
      >
        <div className="remove-account-modal__container">
          <div
            className='remove-account-modal__text' 
          >
            The following will occur when you remove {this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]} from {this.props.selected_team_name}.
          </div>
          <div
            className='remove-account-modal__text' 
          >
            1. The items that {this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]} owns and has not shared with anyone will be deleted permanently and will not be accessible by anyone including {this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]}.
          </div>
          <div
            className='remove-account-modal__text' 
          >
            2. The ownership of items that {this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]} currently owns and has shared with others in {this.props.selected_team_name} will be transferred over to you. {this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]} will no longer have access to these items.
          </div>
          <div
            className='remove-account-modal__text' 
          >
            3. Any items owned by others and shared with {this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]} will no longer be accessible by {this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]}.
          </div>

          <div
            className='remove-account-modal__text' 
          >
            To proceed, please type <span className='remove-account-modal__text-bold'>Remove {this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]} from {this.props.selected_team_name}</span> below to confirm.
          </div>
          <input
            className='remove-account-modal__confirm-text-input'
            placeholder={`Remove ${this.props.user_id_display_name_maps[this.props.to_remove_account_user_id]} from ${this.props.selected_team_name}`}
            type='text'
            value={ this.state.confirm_text_holder }
            onChange={ this.handle_confirm_text_change }
            disabled={ this.state.awaiting_server_response }
          />
          <div className="remove-account-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    ((this.state.remove_account_confirmed) && (!this.state.request_sent))
                    ? <input
                        type='submit'
                        className={`remove-account-modal__update-button`}
                        value={ 'Remove user from team' }
                        onClick={ this.on_remove_user }
                      />
                    : <input
                        type='submit'
                        className={`remove-account-modal__update-button disabled`}
                        value={ 'Remove user from team' }
                      />
                  }
                  <a
                    className='remove-account-modal__cancel'
                    onClick={() => {
                      this.props.on_remove_account_modal_close();
                      this.reset_modal();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          <span className='remove-account-modal__status-message'>{this.state.status_message}</span>
          <span className='remove-account-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default RemoveAccountModal;
