import React from 'react';
import './ExtensionAutologin.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_ENCRYPT, ASYM_ENCRYPT, CSPRNG, GEN_KEY_PAIR } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string, uint8_array_to_base64 } from '../../../crypto/util';

import { send_slack_notification } from '../../../slack/util';

import { auth_axios, sleep } from '../../../auth/util';

import { set as dbSet, del as dbDel } from 'idb-keyval';


import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';




// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




class ExtensionAutologin extends React.Component {
  constructor(props) {
    super(props);

    // Safety remove
    window.localStorage.removeItem('extension_autologin_action');
    window.localStorage.removeItem('extension_autologin_item_id');
    window.localStorage.removeItem('extension_autologin_user_id');


    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const url_params = new URLSearchParams(`?${query__string}`);

    const action = url_params.get('action');
    const item_id = url_params.get('item_id');
    const user_id = url_params.get('user_id');

    const url_valid = (!((!action) || (!item_id) || (!user_id) || (props.user_id !== user_id)));

    // const url_valid = true; // (!((!registration_id) || (!email_address))); //  || (!existing_email_address) || (!new_email_address) || (!slack_team_id) || (!slack_user_id) || (!is_installer)));

    this.state = {
      awaiting_server_response: true,

      action: action,
      item_id: item_id,
      user_id: user_id,

      url_valid: url_valid,
    }
  }

  componentDidMount = async () => {
    try {

      window.localStorage.setItem('extension_autologin_action', this.state.action);
      window.localStorage.setItem('extension_autologin_item_id', this.state.item_id);
      window.localStorage.setItem('extension_autologin_user_id', this.state.user_id);
      
      this.setState({
        awaiting_server_response: false
      }); 

      await sleep(1.0);

      window.localStorage.removeItem('extension_autologin_action');
      window.localStorage.removeItem('extension_autologin_item_id');
      window.localStorage.removeItem('extension_autologin_user_id');
    
    }
    catch (error) {
      console.error(error)
      this.setState({
        error_message: 'fatal error',
        fatal_error_occurred: true
      });
    }
  }

  render() {
    return (
      <div className='extension-autologin__container'>
        {
          this.state.url_valid
          ? this.state.awaiting_server_response
            ? <div className="extension-autologin__awaiting_response">
                <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              </div>
            : <div className="extension-autologin__main">
                <CheckLogo 
                  className='extension-autologin__copied-check'
                />
                <div className="extension-autologin__text">
                  Redirecting you to auto-login . . .
                </div>
                
              </div>
          : <div className='extension-autologin__error-message'>Invalid URL</div>
        }
      </div>
    );
  }
}

export default ExtensionAutologin;
