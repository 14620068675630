import React from 'react';
import './Field.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_DECRYPT, ASYM_DECRYPT } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { send_slack_notification } from '../../../slack/util';

import { auth_axios, sleep } from '../../../auth/util';


import { ReactComponent as ShowIcon } from './media/svg/field__show-icon.svg';
import { ReactComponent as HideIcon } from './media/svg/field__hide-icon.svg';
import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 





const beautify_field_name = (field_name) => {
  const mapping = {
    'email_address': 'Email address',
    'password': 'Password',
    'username': 'Username',
    'phone_number': 'Phone number',
    'notes': 'Additional notes',

    // config specific fields
    'amplitude:org_url': 'Org URL',
    'awsiam:account_id': 'AWS account ID',
    'godaddy:customer_number': 'Customer number',
    'metabase:org_url': 'Org URL',
    'slack:org_url': 'Org URL',
  }

  if (field_name.startsWith('custom:')) {
    return field_name.slice(7);
  }

  else if (mapping[field_name]) {
    return mapping[field_name];
  }

  else {
    return field_name;
  }
}



class Field extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: false,
      awaiting_clipboard_copy: false,

      is_hidden: true,
      decrypted_value: '',

      field_value_copied: false
    }
  }


  componentDidMount = async () => {
    // Toggle off when switching between items
    this.setState({
      is_hidden: true,
      decrypted_value: ''
    })
  }

  decrypt_value = async () => {
    
    /**************************************
    * Fetch and decrypt
    ***************************************/

    // Preprocess account private key
    const account_private_key__unenc__uint8_array = this.props.account_private_key__unenc__uint8_array

    // Unlock item key
    const item_key__enc_apubk__base64 = this.props.item_keys[this.props.selected_item_id];

    const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);

    const item_key__unenc__uint8_array = await ASYM_DECRYPT(account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array)

    // Fetch and unlock item data
    const get_protected_data_res = await auth_axios.post(`/api/items/${this.props.selected_item_id}/protected-data/fetch`, {
      user_id: this.props.user_id
    });

    if (!get_protected_data_res.data.success) {
      // TODO show error message here
      return;
    }

    const protected_data__enc_ik__base64 = get_protected_data_res.data.protected_data;

    const protected_data__enc_ik__uint8_array = base64_to_uint8_array(protected_data__enc_ik__base64);

    const protected_data__unenc__uint8_array = await SYM_DECRYPT(item_key__unenc__uint8_array, protected_data__enc_ik__uint8_array);

    const protected_data__unenc__string = uint8_array_to_string(protected_data__unenc__uint8_array);

    const decrypted_data = JSON.parse(protected_data__unenc__string);

    const decrypted_value = decrypted_data[this.props.field_name];

    this.setState({
      decrypted_value: decrypted_value
    });

    /**************************************
    * Slack notification request
    ***************************************/

    const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

    // TURNED THIS OFF FOR NOW: Send message to item adder (this user)

    // const message = `You accessed the ${beautify_field_name(this.props.field_name)} field for ${this.props.selected_item_name}.`;
    // await send_slack_notification(url, this.props.selected_team_id, this.props.user_id, message, {});
  }

  handle_toggle_show = async () => {
    // Decrpyt and show!
    if (this.state.is_hidden) {
      
      /**************************************
      * 1. Decrpyt value and set hidden to false
      ***************************************/

      // First show loading
      this.setState({
        awaiting_server_response: true
      })

      await this.decrypt_value();

      this.setState({
        is_hidden: false,
        awaiting_server_response: false
      });

      /**************************************
      * 2. If permission was one_time, delete user's access and update item_entry
      ***************************************/

      if (this.props.permission === 'one_time') {

        // load_dashboard_pull_upon_item_change
        this.props.handle_dashboard_pull_upon_item_change(true);
        
        // Update item entry -> permissions property
        let new_permissions = {...this.props.permissions};
        delete new_permissions[this.props.user_id]

        const update_item_res = await auth_axios.put(`/api/items/${this.props.selected_item_id}/permissions`, {
          permissions: new_permissions
        });
    
        if (!update_item_res.data.success) {
          // TODO
        }

        // Update user entry -> item_keys and device_keychains properties

        const delete_access_res = await auth_axios.delete(`/api/items/${this.props.selected_item_id}/access/${this.props.user_id}`);

        if (!delete_access_res.data.success) {
          // TODO
        }
      }




      // Mixpanel web_protected_field_decrypted
      mixpanel.track('web_protected_field_decrypted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });
    }


    // Hide by emptying decrypted_value
    else {
      this.setState({
        is_hidden: true,
        decrypted_value: '',
        field_value_copied: false
      })

      // If permission is one_time, then pull item info afresh
      if (this.props.permission === 'one_time') {
        this.props.handle_dashboard_pull_upon_item_change(false);
        // Pull dashboard items to update
        await this.props.update_dashboard_items();
        // window.location.reload(false);
      }
    }
  }


  handle_copy_protected_to_clipboard = async () => {

    if (!this.state.decrypted_value) {
      // First show loading
      this.setState({
        awaiting_clipboard_copy: true
      })

      await this.decrypt_value();

      // First show loading
      this.setState({
        awaiting_clipboard_copy: false
      })
    }

    // Copy and change to checkmark
    await navigator.clipboard.writeText(this.state.decrypted_value);

    this.setState({
      field_value_copied: true
    });

    // Wait 1 second and change back to text
    await sleep(1.0);

    this.setState({
      field_value_copied: false
    });

    // Mixpanel web_protected_field_copied
    mixpanel.track('web_protected_field_copied', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  handle_copy_unprotected_to_clipboard = async () => {
    // Copy and change to checkmark
    await navigator.clipboard.writeText(this.props.field_value);

    this.setState({
      field_value_copied: true
    });

    // Wait 1 second and copy back to text
    await sleep(1.0);

    this.setState({
      field_value_copied: false
    });

    // Mixpanel web_protected_field_copied
    mixpanel.track('web_protected_field_copied', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  render() {
    return (
      <>
        {
          this.props.is_protected
          ? (this.props.permission === 'owner' || this.props.permission === 'read_write' || this.props.permission === 'read_only' || this.props.permission === 'one_time')
            ? this.state.is_hidden 
                ? <>
                    {
                      this.state.awaiting_clipboard_copy
                      ? <div 
                          className='dashboard__right-side-item-info-field-value-loading'
                        >
                          <ReactLoading
                            type='spokes'
                            color='#ffffff'
                            height={20}
                            width={20}
                          />
                        </div>
                      
                      : this.state.field_value_copied
                        ? <CheckLogo 
                            className='dashboard__right-side-item-info-field-value-check'
                          />
                        : <div 
                            className="dashboard__right-side-item-info-field-value-text"
                            onClick={this.handle_copy_protected_to_clipboard}
                          >
                            ********
                            <div
                              className='dashboard__right-hover-value-text'
                            >
                              Click text to copy
                            </div>
                          </div>
                    }
                    {
                      this.state.awaiting_server_response
                      ? <div 
                          className='dashboard__right-side-item-info-field-value-toggle'
                        >
                          <ReactLoading
                            type='spokes'
                            color='#ffffff'
                            height={20}
                            width={20}
                          />
                        </div>
                      
                      : <ShowIcon 
                          className='dashboard__right-side-item-info-field-value-toggle'
                          onClick={this.handle_toggle_show}
                        />
                    }
                    
                  </>
                : <>
                    {
                      this.state.awaiting_clipboard_copy
                      ? <div 
                          className='dashboard__right-side-item-info-field-value-loading'
                        >
                          <ReactLoading
                            type='spokes'
                            color='#ffffff'
                            height={20}
                            width={20}
                          />
                        </div>
                      
                      : this.state.field_value_copied
                        ? <CheckLogo 
                            className='dashboard__right-side-item-info-field-value-check'
                          />
                        : <div 
                            className="dashboard__right-side-item-info-field-value-text"
                            onClick={this.handle_copy_protected_to_clipboard}
                          >
                            {this.state.decrypted_value}
                            <div
                              className='dashboard__right-hover-value-text'
                            >
                              Click text to copy
                            </div>
                          </div>
                    }
                    
                    <HideIcon
                      className='dashboard__right-side-item-info-field-value-toggle'
                      onClick={this.handle_toggle_show}
                    />
                  </>
            : <>
                <div className="dashboard__right-side-item-info-field-value-text">
                  ********
                  {/* Does not have permission case - cannot copy */}
                </div>
              </>
          : this.state.field_value_copied
            ? <CheckLogo 
                className='dashboard__right-side-item-info-field-value-check'
              />
            : <div 
                className="dashboard__right-side-item-info-field-value-text"
                onClick={this.handle_copy_unprotected_to_clipboard}
              >
                {this.props.field_value}
                <div
                  className='dashboard__right-hover-value-text'
                >
                  Click text to copy
                </div>
              </div>
        }
      </>
    );
  }
}

export default Field;
