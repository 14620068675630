import React from 'react';
import './Clipboard.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_DECRYPT, ASYM_DECRYPT } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { auth_axios } from '../../../auth/util';

import { send_slack_notification } from '../../../slack/util';

import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



const beautify_field_name = (field_name) => {
  const mapping = {
    'email_address': 'Email address',
    'password': 'Password',
    'username': 'Username',
    'phone_number': 'Phone number',
    'notes': 'Additional notes',

    // config specific fields
    'amplitude:org_url': 'Org URL',
    'awsiam:account_id': 'AWS account ID',
    'godaddy:customer_number': 'Customer number',
    'metabase:org_url': 'Org URL',
    'slack:org_url': 'Org URL',
  }

  if (field_name.startsWith('custom:')) {
    return field_name.slice(7);
  }

  else if (mapping[field_name]) {
    return mapping[field_name];
  }

  else {
    return field_name;
  }
}



class Clipboard extends React.Component {
  
  constructor(props) {
    super(props);

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const url_params = new URLSearchParams(`?${query__string}`);

    const item_id = url_params.get('item_id');
    const field_name = url_params.get('field_name');

    const url_valid = (!((!item_id) || (!field_name)));


    // Browser testing results:
    // Chrome: Not secure browser
    // Safari: Secure browser
    // Firefox: Secure browser
    // Opera: Not secure browser
    // Edge: Not secure browser

    const is_safari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    const is_firefox = typeof InstallTrigger !== 'undefined';

    // const is_opera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // const is_chrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // const isEdge = !isIE && !!window.StyleMedia;

    const is_secure_browser = is_safari || is_firefox;





    this.state = {
      awaiting_server_response: true,

      fatal_error_occurred: false,

      error_message: '',

      account_private_key__unenc__uint8_array: props.keychain.account_private_key__unenc__uint8_array,

      account_public_key__unenc__uint8_array: props.keychain.account_public_key__unenc__uint8_array,

      server_public_key__unenc__uint8_array: props.keychain.server_public_key__unenc__uint8_array,

      team_public_keys: props.keychain.team_public_keys,
      
      item_id: item_id,
      field_name: field_name,
      url_valid: url_valid,

      is_secure_browser: is_secure_browser,
      secure_browser_copying: false,
      secure_browser_copied: false,
      secure_browser_item_data: {},

      decrypted_value: ''
    }
  }

  componentDidMount = async () => {
    try {
      // Preprocess account private key
      const account_private_key__unenc__uint8_array = this.state.account_private_key__unenc__uint8_array

      // Unlock item key
      const item_key__enc_apubk__base64 = this.props.item_keys[this.state.item_id];

      if (!item_key__enc_apubk__base64) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'You do not have the credentials to view this data'
        })
        return;
      }

      const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);

      const item_key__unenc__uint8_array = await ASYM_DECRYPT(account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array)

      const get_protected_data_res = await auth_axios.post(`/api/items/${this.state.item_id}/protected-data/fetch`, {
        user_id: this.props.user_id
      });

      if (!get_protected_data_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'You do not have permission to view this data'
        })
        return;
      }

      const protected_data__enc_ik__base64 = get_protected_data_res.data.protected_data;

      const protected_data__enc_ik__uint8_array = base64_to_uint8_array(protected_data__enc_ik__base64);

      const protected_data__unenc__uint8_array = await SYM_DECRYPT(item_key__unenc__uint8_array, protected_data__enc_ik__uint8_array);

      const protected_data__unenc__string = uint8_array_to_string(protected_data__unenc__uint8_array);

      const decrypted_data = JSON.parse(protected_data__unenc__string);

      const decrypted_value = decrypted_data[this.state.field_name];


      // If secure_browser, show Copy to clipboard button
      if (this.state.is_secure_browser) {
        this.setState({
          decrypted_value: decrypted_value,
          awaiting_server_response: false,
          secure_browser_item_data: get_protected_data_res.data
        });
        return;
      }

      // Otherwise, copy to clipboard

      await navigator.clipboard.writeText(decrypted_value);

      this.setState({
        decrypted_value: decrypted_value,
        awaiting_server_response: false
      })
      



      // Mixpanel web_clipboard_visited
      mixpanel.track('web_clipboard_visited', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });


      /**************************************
      * 11. Slack notification request
      ***************************************/

      const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

      // TURNED THIS OFF FOR NOW: Send message to item adder (this user)

      // const message = `You accessed the ${this.state.field_name} field for ${get_protected_data_res.data.item_name}.`;
      // await send_slack_notification(url, this.props.selected_team_id, this.props.user_id, message, {});



      // If launch URL exists, then redirect to that URL
      const launch_url = get_protected_data_res.data.launch_url;

      // Redirect to the launch page
      if (launch_url) {
        
        window.location.href = launch_url;
      }

      return;
    }
    catch (error) {
      console.error(error)
      this.setState({
        error_message: 'fatal error',
        fatal_error_occurred: true
      });
    }
  }

  secure_browser_copy_to_clipboard = async () => {
    if (this.state.is_secure_browser) {

      // Show loading
      this.setState({
        secure_browser_copying: true
      });





      /**************************************
      * 1. Copy to clipboard
      ***************************************/

      await navigator.clipboard.writeText(this.state.decrypted_value);

      this.setState({
        secure_browser_copying: false,
        secure_browser_copied: true
      });


      // Mixpanel web_clipboard_visited
      mixpanel.track('web_clipboard_visited', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });


      /**************************************
      * 2. Slack notification request
      ***************************************/

      

      const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

      // TURNED THIS OFF FOR NOW: Send message to item adder (this user)

      // const message = `You accessed the ${this.state.field_name} field for ${this.state.secure_browser_item_data.item_name}.`;
      // await send_slack_notification(url, this.props.selected_team_id, this.props.user_id, message, {});



      // If launch URL exists, then redirect to that URL
      const launch_url = this.state.secure_browser_item_data.launch_url;

      // Redirect to the launch page
      if (launch_url) {
        
        window.location.href = launch_url;
      }

      return;




    }
  }

  render() {
    return (
      <div 
        className='clipboard__container'
      >
        {
          this.state.url_valid
          ? this.state.awaiting_server_response
            ? <div className="clipboard__awaiting_response">
                <div className='clipboard__text'>
                  Securely decrypting your data
                </div>
                <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              </div>
            : this.state.is_secure_browser 
              ? this.state.secure_browser_copied
                ? <div className="clipboard__copied">
                    <CheckLogo 
                      className='clipboard__copied-check'
                    />
                    <span
                      className='clipboard__copied-message'
                    >{beautify_field_name(this.state.field_name)} has been copied to your clipboard</span>
                    <span
                      className='clipboard__error-message'
                    >{this.state.error_message}</span>
                  </div>
                : this.state.secure_browser_copying
                  ? <div className="clipboard__awaiting_response">
                      <div className='clipboard__text'>
                        Securely copying {beautify_field_name(this.state.field_name)} to clipboard
                      </div>
                      <ReactLoading
                        type='spokes'
                        color='#9696ad'
                        height={20}
                        width={20}
                      />
                    </div>
                  : <div>
                      <button
                        className='clipboard__clipboard-button'
                        onClick={this.secure_browser_copy_to_clipboard}
                      >
                        Copy to clipboard
                      </button>
                    </div>
              : <div className="clipboard__copied">
                  <CheckLogo 
                    className='clipboard__copied-check'
                  />
                  <span
                    className='clipboard__copied-message'
                  >{beautify_field_name(this.state.field_name)} has been copied to your clipboard</span>
                  <span
                    className='clipboard__error-message'
                  >{this.state.error_message}</span>
                </div>
          : <span>url is not valid</span>
        }
      </div>
    );
  }
}

export default Clipboard;
