import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footer__bottom-row">
        <div className="footer__copyright">Copyright © 2021 PlusIdentity, Inc. All rights reserved.</div>
        <span className="footer__dot"></span>
        <a href="https://www.plusidentity.com/privacy" target="_blank">Privacy</a>
        <span className="footer__dot"></span>
        <a href="https://www.plusidentity.com/terms" target="_blank">Terms</a>
      </div>
    </div>
  )
}

export default Footer;
