import React from 'react';
import './FileViewerModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';


import { ReactComponent as PDFPrevWhite } from './media/svg/pdf-prev-white.svg';
import { ReactComponent as PDFNextWhite } from './media/svg/pdf-next-white.svg';




import Papa from "papaparse";

import { Document as PDFDocument, Page as PDFPage } from 'react-pdf/dist/esm/entry.webpack';



// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


ReactModal.setAppElement('body');



class FileViewerModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {

      // PDF
      pdf_load_success: true,
      pdf_page_number: 1,
      pdf_num_pages: 0,

      // Text
      text_loading: true,
      text_load_success: true,
      text_string: '',

      // CSV
      csv_loading: true,
      csv_load_success: true,
      csv_parsed_data: [],
      csv_table_rows: [],
      csv_values: [],



      awaiting_server_response: true,
      progress_message: 'Decrypting and loading file ...',
    }
  }

  componentDidMount = async () => {
    try {
      
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    // if (prevProps.is_open !== this.props.is_open) {

    //   if (!this.props.is_open) {
    //     console.log('resetting awaiting_server_response')
    //     this.setState({
    //       awaiting_server_response: true
    //     })
    //   }
    // }

    if (prevProps.preview_file_metadata.file_name !== this.props.preview_file_metadata.file_name) {

      if (this.props.is_open) {
        this.setState({
          awaiting_server_response: false,
          progress_message: ''
        })
      } else {
        this.setState({
          awaiting_server_response: true,
          progress_message: 'Decrypting and loading file ...'
        });
      }
    }
  }

  clear_holders = () => {
    this.setState({
      // PDF
      pdf_load_success: true,
      pdf_page_number: 1,
      pdf_num_pages: 0,

      // Text
      text_loading: true,
      text_load_success: true,
      text_string: '',

      // CSV
      csv_loading: true,
      csv_load_success: true,
      csv_parsed_data: [],
      csv_table_rows: [],
      csv_values: [],
      
      awaiting_server_response: true,
      progress_message: 'Loading file',
    });
  }

  on_pdf_load_success = ({numPages}) => {
    this.setState({
      pdf_load_success: true,
      pdf_page_number: 1,
      pdf_num_pages: numPages
    })
  }


  get_view_container = () => {
    const team_id = this.props.selected_team_id;
    const user_id = this.props.user_id;
    const file_id = this.props.preview_file_metadata.file_id;

    const mimetype = this.props.preview_file_metadata.mimetype

    switch (mimetype) {

      // Use the <img> tag (.png, .jpeg/.jpg, .gif, .svg)
      case 'image/png':
      case 'image/jpeg':
      case 'image/gif':
      case 'image/svg+xml': {


        return (
          <>
            <div className='file-viewer-modal__view-content'>
              <img 
                src={`https://plusidentity-tmp${this.props.mode_info.is_dev ? '-dev' : ''}.s3.amazonaws.com/${team_id}-${user_id}-${file_id}`} 
                alt='img load failed' 
              />
            </div>
            <div className='file-viewer-modal__view-controls'>
              
            </div>
          </>
        )
      }

      // Use the <video> tag (.mp4, .webm)
      case 'video/mp4':
      case 'video/webm': {

        return (
          <>
            <div className='file-viewer-modal__view-content'>
              <video width='320' height='240' controls>
                <source 
                  src={`https://plusidentity-tmp${this.props.mode_info.is_dev ? '-dev' : ''}.s3.amazonaws.com/${team_id}-${user_id}-${file_id}`} 
                  type={mimetype} 
                />
              </video>
            </div>
            <div className='file-viewer-modal__view-controls'>
              
            </div>
          </>
        )
      }

      // Use the <audio> tag (.mp3, .wav)
      case 'audio/mpeg':
      case 'audio/mpeg': {


        return (
          <>
            <div className='file-viewer-modal__view-content'>
              <audio controls>
                <source 
                  src={`https://plusidentity-tmp${this.props.mode_info.is_dev ? '-dev' : ''}.s3.amazonaws.com/${team_id}-${user_id}-${file_id}`} 
                  type={mimetype} 
                />
              </audio>
            </div>
            <div className='file-viewer-modal__view-controls'>
              
            </div>
          </>
        )
      }

      // For application/pdf, use react-pdf library
      case 'application/pdf': {
        
        return (
          <>
            <div className='file-viewer-modal__view-content'>
              <div
                className='file-viewer-modal__view-content-pdf-container'
              >
                <PDFDocument 
                  file={`https://plusidentity-tmp${this.props.mode_info.is_dev ? '-dev' : ''}.s3.amazonaws.com/${team_id}-${user_id}-${file_id}`}
                  onLoadSuccess={this.on_pdf_load_success}
                  onLoadError={() => {this.setState({ pdf_load_success: false })}}
                  pageLayout={'oneColumn'}
                > 
                  <PDFPage pageNumber={this.state.pdf_page_number} />
                </PDFDocument>
              </div>
            </div>
            <div className='file-viewer-modal__view-controls'>
              <div 
                className={`file-viewer-modal__view-controls-pdf-previous ${this.state.pdf_page_number === 1 ? 'hidden' : this.state.pdf_load_success ? '' : 'hidden'}`}
                onClick={() => this.setState({pdf_page_number: this.state.pdf_page_number - 1})}
              >
                <PDFPrevWhite />
              </div>
              <div className='file-viewer-modal__view-controls-pdf-text'>
                {
                  this.state.pdf_load_success
                  ? `Page ${this.state.pdf_page_number} of ${this.state.pdf_num_pages}`
                  : ''
                }
              </div>
              <div 
                className={`file-viewer-modal__view-controls-pdf-next ${this.state.pdf_page_number === this.state.pdf_num_pages ? 'hidden' : this.state.pdf_load_success ? '' : 'hidden'}`}
                onClick={() => this.setState({pdf_page_number: this.state.pdf_page_number + 1})}
              >
                <PDFNextWhite />
              </div>
            </div>
          </>
        )
      }

      // For text/plain, just parse raw text
      case 'text/plain': {
        try {
          fetch(`https://plusidentity-tmp${this.props.mode_info.is_dev ? '-dev' : ''}.s3.amazonaws.com/${team_id}-${user_id}-${file_id}`).then((r)=>{r.text().then((data) => {
            this.setState({
              text_loading: false,
              text_load_success: true,
              text_string: data
            });
          })});

          // this.setState({
          //   text_loading: false,
          //   text_load_success: true,
          // });
          
        }
        catch (error) {
          console.log(error)
        }
        
        return (
          <>
            {
              this.state.text_loading 
              ? <>Text file is loading...</>
              : this.state.text_load_success
                ? <div className='file-viewer-modal__view-content'>
                    <p>{this.state.text_string}</p>
                    {/* <object data={`./tmp/${team_id}-${user_id}-${file_id}`} width="300" height="200">
                      Not supported
                    </object> */}
                    {/* <p><iframe src={`./tmp/${team_id}-${user_id}-${file_id}`} frameBorder="0" height="400"
                    width="95%"></iframe></p> */}
                  </div>
                : <>Text file is empty</>
            }
          </>
        );
      }

      // For text/csv, use react-csv-reader library
      case 'text/csv': {
        fetch(`https://plusidentity-tmp${this.props.mode_info.is_dev ? '-dev' : ''}.s3.amazonaws.com/${team_id}-${user_id}-${file_id}`).then((r)=>{r.text().then((data) => {

          Papa.parse(data, {
            header: true,
            complete: (results) => {
              if (results.data.length === 0) {
                this.setState({
                  csv_loading: false,
                  csv_load_success: false
                })
                return;
              }

              const rowsArray = [];
              const valuesArray = [];

              // Iterating data to get column name and their values
              results.data.map((d) => {
                rowsArray.push(Object.keys(d));
                valuesArray.push(Object.values(d));
              });

              this.setState({
                csv_loading: false,
                csv_load_success: true,
                csv_parsed_data: results.data,
                csv_table_rows: rowsArray[0],
                csv_values: valuesArray
              })


            }
          });
        })})


        return (
          <>
            {
              this.state.csv_loading 
              ? <>CSV file is loading...</>
              : this.state.csv_load_success
                ? <div className='file-viewer-modal__view-content'>
                    <table className='file-viewer-modal__view-content-csv-table'>
                      <thead>
                        <tr>
                          {this.state.csv_table_rows.map((rows, index) => {
                            return <th key={index}>{rows}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.csv_values.map((value, index) => {
                          return (
                            <tr key={index}>
                              {value.map((val, i) => {
                                return <td key={i}>{val}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                : <>CSV file is empty</>
            }
          </>
        );
      }

      // Use the react-pdf library (.doc, .docx, .ppt, .pptx, .xls, .xlsx)
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 



      

      // For non-matching case, show cannot preview
      default: {
        return (
          <>
            This file format is not supported for preview. Please download to view.
          </>
        );
      }
    }



  }

  delete_tmp_file = async () => {
    // Trigger tmp file remove
    await axios.put(`/api/files/${this.props.selected_team_id}/${this.props.preview_file_metadata.file_id}/preview/delete`, {
      identifier: this.props.user_id,
    }); 
  }

  render() {

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;


    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            await this.delete_tmp_file();

            this.clear_holders();
            await this.props.on_file_viewer_modal_close(false);
          }
        }}
        style={{
          content: {
            height: this.state.awaiting_server_response ? 200 : (screen_height > 680 ? 600 : 580),
            width: this.state.awaiting_server_response ? 200 : (screen_width > 690 ? 900 : 780)
          }
        }}
      >
        <div className='file-viewer-modal__container'>
          {
            this.props.is_open
            ? this.state.awaiting_server_response
              ? <div className="file-viewer-modal__loading">
                  <ReactLoading
                    type='spokes'
                    color='#9696ad'
                    height={20}
                    width={20}
                  />
                  <div className="file-viewer-modal__progress_message">
                    <span>{this.state.progress_message}</span>
                  </div>
                </div>
              : <>
                  <div className='file-viewer-modal__title'>
                    {this.props.preview_file_metadata.file_name}
                  </div>
                  <div 
                    className='file-viewer-modal__view-container'
                    onContextMenu={(e)=> e.preventDefault()} // Prevent right click
                  >
                    {this.get_view_container()}
                  </div>
                </>
            : <></>
          }
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default FileViewerModal;
