import React from 'react';
import './EditItemModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Collapsible from 'react-collapsible';


import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT, ASYM_DECRYPT } from '../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';


import { ReactComponent as FiltersOpenIcon } from './media/svg/filters-open-icon.svg';
import { ReactComponent as FiltersClosedIcon } from './media/svg/filters-closed-icon.svg';

import { ReactComponent as InfoIcon } from './media/svg/info-icon.svg';


import { auth_axios, sleep } from '../../../auth/util';

import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';

import { send_slack_notification } from '../../../slack/util';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


const default_field_name_options = [
  {label: 'Email address', value: 'email_address'},
  {label: 'Username', value: 'username'},
  {label: 'Phone number', value: 'phone_number'},
  {label: 'Password', value: 'password'},
];


class EditItemModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
      awaiting_password_generation: false,
      error_message: '',
      progress_message: '',

      item_name_holder: '',
      item_type_holder: 'account',
      config_holder: '',
      fields_holder: [], // [['custom:Phone number', 6281002930, false, false], ...] field_name, field_value, protected, pre/generated/copied
      notes_holder: '',

      file_dragging: false,

      original_files_metadata: [], // [{file_id: 'pF12345', name: 'Test File', mimetype}, ...]
      new_files_holder: [], // [<File>, ...]
      files_changed: false,

      field_name_options: [],

      item_visibility_holder: 'public',

      permissions_section_open: false,

      is_current_owner: false,
      owner_holder: '',

      read_write_list_holder: [],
      read_only_list_holder: [],
      one_time_list_holder: [],

      config_options: [],
      config_login_credentials_maps: {},
      config_display_name_maps: {},

      decrypted_data: {},
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        progress_message: 'Loading configs ...'
      })

      const get_configs_res = await auth_axios.get('/api/configs');

      if (!get_configs_res.data.success) {
        console.error('get configs failed');
        this.setState({
          awaiting_server_response: false,
          error_message: 'get configs failed',
          progress_message: ''
        });
        return;
      }

      this.setState({
        config_options: get_configs_res.data.options,
        config_login_credentials_maps: get_configs_res.data.login_credentials_maps,
        config_display_name_maps: get_configs_res.data.display_name_maps,

        progress_message: ''
      })

      
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    try {
      if (this.props.item !== prevProps.item || this.props.is_open !== prevProps.is_open) {

        console.log('componentDidUpdate ing')

        this.setState({
          progress_message: 'Fetching item data ...'
        })

        // If item is not selected, go forth no more
        if (!this.props.item) {
          return
        }


        // If edit secret, show this special state edit item modal
        if (this.props.edit_secret) {

          let owner_user_id = this.props.item.owner_user_id;

          if (!owner_user_id) {
            owner_user_id = this.props.user_id;
          }

          const read_write_list_holder = Object.keys(this.props.item.permissions).filter((user_id) => user_id !== owner_user_id)

          this.setState({
            awaiting_server_response: false,
            item_name_holder: this.props.item.item_name,
            item_type_holder: 'account',
            config_holder: '',
            fields_holder: [
              ['email_address', '', false, 'pre'],
              ['password', '', true, 'pre']
            ],
            notes_holder: this.props.item.notes,
  
            field_name_options: default_field_name_options,
  
            item_visibility_holder: 'public', 
  
            permissions_section_open: true,
  
            is_current_owner: (this.props.user_id === owner_user_id),
            owner_holder: owner_user_id,
            read_write_list_holder: read_write_list_holder,
            read_only_list_holder: [],
            one_time_list_holder: [],
          });

          return;
        }

        

        // Awaiting server response // TODO
        this.setState({
          awaiting_server_response: true
        })

        

        const field_status = this.props.item.field_status;
        const item_id = this.props.item.item_id;
        const item_name = this.props.item.item_name;
        const item_type = this.props.item.item_type;
        const visibility = this.props.item.visibility;

        const permissions = this.props.item.permissions;

        const original_files_metadata = this.props.item.files_metadata

        // If this user does not have the cryptographic credentials, go forth no more
        if (!(this.props.user_id in permissions)) {
          return;
        }

        const item_key__enc_apubk__base64 = this.props.item_keys[item_id];
        
        const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);

        const item_key__unenc__uint8_array = await ASYM_DECRYPT(this.props.account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array)


        const get_protected_data_res = await auth_axios.post(`/api/items/${item_id}/protected-data/fetch`, {
          user_id: this.props.user_id
        });
    
        // Fetching data was unsuccessful
        if (!get_protected_data_res.data.success) {
          console.error('get protected data failed');
          this.setState({
            awaiting_server_response: false,
            error_message: 'get protected data failed',
            progress_message: ''
          });
          return;
        } 

        this.setState({
          progress_message: 'Securely decrypting data ...'
        })

        const protected_data__enc_ik__base64 = get_protected_data_res.data.protected_data;
        const protected_data__enc_ik__uint8_array = base64_to_uint8_array(protected_data__enc_ik__base64)

        const protected_data__unenc__uint8_array = await SYM_DECRYPT(item_key__unenc__uint8_array, protected_data__enc_ik__uint8_array);

        const protected_data__unenc__string = uint8_array_to_string(protected_data__unenc__uint8_array);

        const protected_data__unenc = JSON.parse(protected_data__unenc__string);

        this.setState({
          decrypted_data: protected_data__unenc
        });

        // [field_name, field_name_custom (only if field_name is 'custom'), field_value, protected_status]

        let fields_holder = [];
        // let field_name_options = [...this.state.field_name_options];

        const config_id = this.props.item.metadata.config_id || ''

        let field_name_options = this.get_config_field_name_options(config_id);

        let notes = '';

        for await (const field of field_status) {

          const [field_name_raw, protected_status] = field;
          const is_protected = (protected_status === 'protected')

          // let field_name = field_name_raw;
          // let field_name_custom = '';

          const field_value = protected_data__unenc[field_name_raw];

          if (field_name_raw.includes('custom:')) {
            // field_name = 'custom'
            const field_name_custom = field_name_raw.slice(7);

            field_name_options.push({
              label: `${field_name_custom}`, 
              value: `${field_name_raw}`
            });
          }

          else if (field_name_raw === 'notes') {
            notes = field_value;
            continue; // Don't add notes to the fields_holder
          }

          fields_holder.push([field_name_raw, field_value, is_protected, 'pre'])
        }

        
        let owner_holder = '';
        let read_write_list_holder = [];
        let read_only_list_holder = [];
        let one_time_list_holder = [];

        for await (const user_id of Object.keys(permissions)) {
          if (permissions[user_id] === 'owner') {
            owner_holder = user_id;
          }
          if (permissions[user_id] === 'read_write') {
            read_write_list_holder.push(user_id)
          }
          else if (permissions[user_id] === 'read_only') {
            read_only_list_holder.push(user_id)
          }
          else if (permissions[user_id] === 'one_time') {
            one_time_list_holder.push(user_id)
          }
        }

        

        this.setState({
          awaiting_server_response: false,
          progress_message: '',
          
          item_name_holder: item_name,
          item_type_holder: item_type,
          config_holder: config_id,
          fields_holder: fields_holder,
          notes_holder: notes,

          field_name_options: field_name_options,

          item_visibility_holder: visibility ? visibility : 'public', // TODO don't need to do this fail checking

          original_files_metadata: original_files_metadata,

          permissions_section_open: false,

          is_current_owner: (this.props.user_id === owner_holder),
          owner_holder: owner_holder,
          read_write_list_holder: read_write_list_holder,
          read_only_list_holder: read_only_list_holder,
          one_time_list_holder: one_time_list_holder,
        })


        // Wait until React Modal loads
        await sleep(1.0);

        // If modal is still open and the item_type is file, attach drag listeners
        if (this.props.is_open && item_type === 'file') {
          this.drag_counter = 0;
          let try_counter = 0;

          let edit_item_modal;

          do {
            edit_item_modal = document.getElementsByClassName('ReactModal__Overlay')[0];  
            try_counter++;
          } while(!edit_item_modal && try_counter < 100);

          edit_item_modal.addEventListener('dragenter', this.handle_file_drag_enter);
          edit_item_modal.addEventListener('dragleave', this.handle_file_drag_leave);
          edit_item_modal.addEventListener('dragover', this.handle_file_drag_over);
          edit_item_modal.addEventListener('drop', this.handle_file_drop);
        }


      }
    }
    catch (error) {
      console.log(error)
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  prepend_file_dragging_overlay = () => {
    // First check if file_dragging_overlay already exists
    const file_dragging_overlay = document.getElementById('edit-item-modal__file-dragging-overlay');

    // Add if it doesn't exist already
    if (!file_dragging_overlay) {
      let try_counter = 0;
      let edit_item_modal;
      do {
        edit_item_modal = document.getElementsByClassName('ReactModal__Overlay')[0];
        try_counter++;
      } while(!edit_item_modal && try_counter < 100);
  
      // Prepend file_dragging_overlay
      const file_dragging_overlay = document.createElement('div');
      file_dragging_overlay.id = 'edit-item-modal__file-dragging-overlay';

      const description_text = document.createElement('div');
      description_text.className = 'edit-item-modal__file-dragging-description';
      description_text.innerHTML = 'Update file'

      file_dragging_overlay.prepend(description_text);
      edit_item_modal.prepend(file_dragging_overlay);
    }
  }

  remove_file_dragging_overlay = () => {
    // Remove file_dragging_overlay
    const file_dragging_overlay = document.getElementById('edit-item-modal__file-dragging-overlay');

    if (file_dragging_overlay) {
      file_dragging_overlay.remove();
    }
  }

  handle_file_drag_enter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.drag_counter++;

    if (e.dataTransfer.items && e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].kind === 'file') { // only allow one file upload file for now // > 0) { 
      this.setState({
        file_dragging: true,
      })
    }

    this.prepend_file_dragging_overlay();
  }

  handle_file_drag_leave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.drag_counter--;
    if (this.drag_counter > 0) return;

    this.setState({
      file_dragging: false,
    });

    this.remove_file_dragging_overlay();
  }

  handle_file_drag_over = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handle_file_drop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      file_dragging: false,

      // item_name_holder: '',
      // fields_holder: [],
      config_holder: '',
      // notes_holder: '',
      // files_holder: []

      new_files_holder: e.dataTransfer.files,
      files_changed: true,
      // item_name_holder: e.dataTransfer.files[0].name,
      // item_type_holder: 'file',
    });

    this.remove_file_dragging_overlay();
  }

  clear_holders = () => {
    this.setState({
      awaiting_server_response: true,
      awaiting_password_generation: false,
      error_message: '',
      progress_message: '',

      item_name_holder: '',
      item_type_holder: 'account',
      config_holder: '',
      fields_holder: [], // [['custom:Phone number', 6281002930, false, false], ...] field_name, field_value, protected, pre/generated/copied
      notes_holder: '',

      file_dragging: false,

      original_files_metadata: [], // [{file_id: 'pF12345', name: 'Test File', mimetype}, ...]
      new_files_holder: [], // [<File>, ...]
      files_changed: false,

      field_name_options: [],

      item_visibility_holder: 'public',

      permissions_section_open: false,

      is_current_owner: false,
      owner_holder: '',

      read_write_list_holder: [],
      read_only_list_holder: [],
      one_time_list_holder: [],
      
      config_options: [],
      config_login_credentials_maps: {},
      config_display_name_maps: {},

      decrypted_data: {},
    })
  }; 

  add_field = () => {
    let fields_holder = [...this.state.fields_holder];
    fields_holder.push(['', '', '', 'pre']);

    this.setState({
      fields_holder: fields_holder
    })
  }

  delete_field = () => {
    let fields_holder = [...this.state.fields_holder];
    fields_holder.pop();

    this.setState({
      fields_holder: fields_holder
    })
  }

  handle_item_name_change = (e) => {
    this.setState({ item_name_holder: e.target.value, error_message: '' });
  }

  handle_item_type_change = (e) => {
    this.setState({ 
      item_type_holder: e.value, 
      error_message: '' 
    });

    // if (e.value !== 'account') {
    //   this.setState({
    //     config_holder: ''
    //   })
    // }

    switch (e.value) {
      case 'account': {
        this.setState({
          // item_name_holder: '',
          fields_holder: [
            ['email_address', '', false, 'pre'],
            ['password', '', true, 'pre']
          ],
          config_holder: '',
          // notes_holder: '',
          new_files_holder: [],
          files_changed: true
        });

        // Always break
        break;
      }
      case 'file': {
        this.setState({
          // item_name_holder: '',
          fields_holder: [],
          config_holder: '',
          // notes_holder: '',
          // new_files_holder: []
        });

        // Always break
        break;
      }
      case 'custom': {
        this.setState({
          // item_name_holder: '',
          fields_holder: [
            ['', '', false, 'pre'],
          ],
          config_holder: '',
          // notes_holder: '',
          new_files_holder: [],
          files_changed: true
        });

        // Always break
        break;
      }
      default: {
        // Always break
        break;
      }
    }
  }

  handle_notes_change = (e) => {
    this.setState({ notes_holder: e.target.value, error_message: '' });
  }

  handle_item_visibility_change = (e) => {
    this.setState({ 
      item_visibility_holder: e.value, 
      error_message: '' 
    });
  }

  on_permissions_section_open = () => {
    this.setState({ permissions_section_open: true });
  }

  on_permissions_section_close = () => {
    this.setState({ permissions_section_open: false });
  }

  handle_owner_change = (e) => {
    this.setState({ 
      owner_holder: e.value, 
      error_message: '' 
    });
  }

  handle_read_write_list_change = (e) => {
    const read_write_list_holder = e.map((user) => user.value);
    this.setState({ read_write_list_holder: read_write_list_holder, error_message: '' });
  }

  handle_read_only_list_change = (e) => {
    const read_only_list_holder = e.map((user) => user.value);
    this.setState({ read_only_list_holder: read_only_list_holder, error_message: '' });
  }

  handle_one_time_list_change = (e) => {
    const one_time_list_holder = e.map((user) => user.value);
    this.setState({ one_time_list_holder: one_time_list_holder, error_message: '' });
  }

  handle_config_change = (e) => {

    let value = '';
    if (e) {
      value = e.value
    }

    if (value) { // && (this.state.fields_holder.length === 0 || this.state.fields_holder.length === this.state.config_login_credentials_maps[value].length)) {

      let fields_holder = [];

      for (const field_name of this.state.config_login_credentials_maps[value]) {
        let initial_value = '';
        for (const current_field_holder of this.state.fields_holder) {
          if (current_field_holder[0] === field_name) {
            initial_value = current_field_holder[1];
          }
        }

        if (field_name === 'password') {
          fields_holder.push([field_name, initial_value, true, 'pre'])
        }
        else {
          fields_holder.push([field_name, initial_value, false, 'pre'])
        }
      }

      this.setState({ 
        fields_holder: fields_holder
      });
    }

    const item_name = value ? this.state.config_display_name_maps[value] : ''

    // Do this for all
    this.setState({ 
      item_name_holder: item_name,
      config_holder: value, 
      error_message: '' 
    });


    // Change up field_options depending on the config
    const new_field_name_options = this.get_config_field_name_options(value);
    this.setState({ 
      field_name_options: new_field_name_options
    });

  }

  get_config_field_name_options = (config_id) => {
    // Change up field_options depending on the config
    switch (config_id) {

      case 'pC000000000025': { // godaddy
        return default_field_name_options.concat([
          {label: 'Customer number', value: 'godaddy:customer_number'}
        ])
      }

      case 'pC000000000050': { // amplitude
        return default_field_name_options.concat([
          {label: 'Org URL', value: 'amplitude:org_url'}
        ])
      }

      case 'pC000000000054': { // awsiam
        return default_field_name_options.concat([
          {label: 'AWS account ID', value: 'awsiam:account_id'}
        ])
      }

      case 'pC000000000077': { // metabase
        return default_field_name_options.concat([
          {label: 'Org URL', value: 'metabase:org_url'}
        ])
      }

      case 'pC000000000094': { // slack
        return default_field_name_options.concat([
          {label: 'Org URL', value: 'slack:org_url'}
        ])
      }

      // ... just set it back to default
      default: {
        return default_field_name_options
      }
    }
  }

  // [field_name, field_name_custom (only if field_name is 'custom'), field_value, protected_status]
  handle_fields_change = (value, index, type) => {

    let which;
    switch (type){
      case 'field_name':
        which = 0;
        break;
      // case 'field_name_custom':
      //   which = 1;
      //   break;
      case 'field_value':
        which = 1;
        break;
      case 'protected_status':
        which = 2;
        break;
    }

    let fields_holder = [...this.state.fields_holder];
    let field_holder = [...fields_holder[index]];
  
    // If field_name, check if the field_name exists in the current options, if not add a new option
    if ((which === 0) && !(this.state.field_name_options.map((field_name_option) => field_name_option.value).includes(value))) {
      let new_field_name_options = [...this.state.field_name_options];
      new_field_name_options.push({
        label: `${value}`, 
        value: `custom:${value}`
      });
      this.setState({
        field_name_options: new_field_name_options
      })

      value = `custom:${value}`;
    }

    field_holder[which] = value;

    // If password, update which=3 to 'pre' if value changed or type changed
    if (field_holder[0] === 'password') {
      if (which === 0 || which === 1) {
        field_holder[3] = 'pre'
      }
    }

    // Set to max-security by default if the field name is password
    if (which === 0) {
      if (value === 'password') {
        field_holder[2] = true
      } else {
        field_holder[2] = false
      }
    }

    fields_holder[index] = field_holder;

    this.setState({ 
      fields_holder: fields_holder
    });
  }


  generate_random_password = (index) => {

    // const allowed_chars = [
    //   'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '~'
    // ]

    // const random_password__base64 = uint8_array_to_base64(CSPRNG(16));

    // const random_password = random_password__base64.filter((char) => {
    //   allowed_chars.includes(char);
    // })

    this.setState({
      awaiting_password_generation: true
    })

    const allowed_chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const PASSWORD_LENGTH = 12;
    let random_password = "";
    for (let i = 0; i <= PASSWORD_LENGTH; i++) {
      const random_number = Math.floor(Math.random() * allowed_chars.length);
      random_password += allowed_chars.substring(random_number, random_number+1);
    }

    let fields_holder = [...this.state.fields_holder];
    let field_holder = [...fields_holder[index]];

    field_holder[1] = random_password;
    field_holder[3] = 'generated';

    fields_holder[index] = field_holder;
    
    this.setState({ 
      fields_holder: fields_holder,
      awaiting_password_generation: false
    });
  }

  copy_to_clipboard = async (index) => {

    let fields_holder = [...this.state.fields_holder];
    let field_holder = [...fields_holder[index]];

    const password = field_holder[1];

    // Copy to clipboard
    await navigator.clipboard.writeText(password);

    // Toggle clipboard copied to true
    field_holder[3] = 'copied';

    fields_holder[index] = field_holder;
    
    this.setState({ 
      fields_holder: fields_holder
    }); 
  }

  handle_files_change = (e) => {

    const files = e.target.files;

    // If cancelled, don't do anything
    if (!files[0]) {
      return;
    }

    this.setState({ 
      // item_name_holder: files[0].name,
      files_changed: true,
      new_files_holder: [
        // ...this.state.files_holder, // for multiple select scenarios
        // ...files, // for multiple select scenarios
        files[0]
      ]
    });
  }

  save_item = async () => {

    //
    this.setState({
      error_message: '',
      awaiting_server_response: true,
      progress_message: 'Pre-processing data ...'
    });

    const item_id = this.props.item.item_id;

    const item_key__enc_apubk__base64 = this.props.item_keys[item_id];
    
    const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);

    const item_key__unenc__uint8_array = await ASYM_DECRYPT(this.props.account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array)


    const selected_team_id = this.props.selected_team_id;

    const current_user_id = this.props.user_id;

    const visibility = this.state.item_visibility_holder;

    const owner_user_id = this.state.owner_holder;
    const read_write_user_ids = this.state.read_write_list_holder;
    const read_only_user_ids = this.state.read_only_list_holder;
    const one_time_user_ids = this.state.one_time_list_holder;

    const shared_user_ids = read_write_user_ids.concat(read_only_user_ids.concat(one_time_user_ids))

    const access_user_ids = shared_user_ids.concat([owner_user_id]);
    
    const item_name = this.state.item_name_holder;
    const item_type = this.state.item_type_holder;
    const fields = this.state.fields_holder;

    const files_changed = this.state.files_changed;
    const original_files_metadata = this.state.original_files_metadata;
    const new_files = this.state.new_files_holder;

    const config_id = this.state.config_holder;

    // Make sure item_name is set
    if (!item_name) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Item name is required.',
        progress_message: ''
      })
      return;
    }

    // Make sure item_type is set
    if (!item_type) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Item type is required.',
        progress_message: ''
      })
      return;
    }

    // // Make sure there is some data (field) that was set
    // if (fields.length === 0) {
    //   this.setState({
    //     awaiting_server_response: false,
    //     error_message: 'Field data is required.',
      // progress_message: ''
    //   })
    //   return;
    // }

    // Make sure every field is unique + check if any of the fields have the same name
    let fields_list = [];

    let empty_field_name_found = false;
    let empty_field_value_found = false;
    let invalid_field_name_found = false;

    for (const field of fields) {
      let field_name = field[0];
      // if (field[0] === 'custom') {
      //   field_name += `:${field[1]}`; 

      //   if (!field[1]) {
      //     empty_field_name_found = true
      //   }
      // }

      if (!field_name) {
        empty_field_name_found = true;
      }

      console.log('field_name', field_name)

      if ((field_name === 'notes') || (field_name === 'file')) {
        invalid_field_name_found = true;
      }

      fields_list.push(field_name)

      if (!field[1]) {
        empty_field_value_found = true;
      }
    }

    if (invalid_field_name_found) {
      this.setState({
        awaiting_server_response: false,
        error_message: `There cannot be a field name called 'notes' or 'file'.`,
        progress_message: ''
      })
      return;
    }

    let duplicate_field_name_found = (new Set(fields_list)).size !== fields_list.length;

    if (duplicate_field_name_found) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Please make sure every field name is unique.',
        progress_message: ''
      })
      return;
    }

    if (empty_field_value_found || empty_field_name_found) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Please make sure none of the fields is empty.',
        progress_message: ''
      })
      return;
    }

    // Make sure visibility is set (visibility should never be not set)
    if (!visibility) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Discoverability must be set.',
        progress_message: ''
      })
      return;
    }

    // Make sure the owner holder is not empty
    if (!this.state.owner_holder) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Item owner must be set.',
        progress_message: ''
      })
      return;
    }

    // Make sure there are no duplicates in access_user_ids
    if (new Set(access_user_ids).size !== access_user_ids.length) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'There cannot be duplicate users in the sharing settings.',
        progress_message: ''
      })
      return;
    }

    





    // Initialize data to be saved
    let all_fields = {}; // to be encrypted with item key
    let unprotected_fields = {}; // to be encrypted with server public key
    let field_status = []; // TO SAVE

    for await (const field of fields) {

      let field_name = field[0];
      // if (field[0] === 'custom') {
      //   field_name += `:${field[1]}`;
      // }

      let field_value = field[1];

      all_fields[field_name] = field_value;
      unprotected_fields[field_name] = field_value;

      const is_protected = field[2];
      if (is_protected) {
        unprotected_fields[field_name] = '' // if the field is protected, then 
      }

      field_status.push([field_name, is_protected ? 'protected' : 'unprotected'])
    }

    // Add notes
    all_fields['notes'] = this.state.notes_holder;
    unprotected_fields['notes'] = this.state.notes_holder;
    field_status.push(['notes', 'unprotected']);


    /**************************************
    * 1. Preprocess all_fields and unprotected_fields
    ***************************************/

    const all_fields__unenc__string = JSON.stringify(all_fields);
    const all_fields__unenc__uint8_array = string_to_uint8_array(all_fields__unenc__string);

    const unprotected_fields__unenc__string = JSON.stringify(unprotected_fields);
    const unprotected_fields__unenc__uint8_array = string_to_uint8_array(unprotected_fields__unenc__string);



    /**************************************
    * 1.5. If there are any files, upload them to S3 and get back list of eTags
    ***************************************/

    let files_metadata;

    if (files_changed) {

      this.setState({
        progress_message: 'Encrypting and uploading replaced file ...'
      });

      files_metadata = [];

      // Delete old files from S3
      for (const file_metadata of original_files_metadata) {
  
        const file_id = file_metadata.file_id;

        const delete_file_res = await auth_axios.delete(`/api/files/${selected_team_id}/${file_id}`); 

        console.log('delete_file_res.data.success', delete_file_res.data.success)
      }

      // Add new files to S3
      for (const file of new_files) {
        const form_data = new FormData();
        form_data.append('file', file)
  
        const form_data_res = await auth_axios.post(`/api/files/${selected_team_id}`, form_data); 
  
        console.log('form_data_res.data', form_data_res.data);
  
        const file_metadata = form_data_res.data.file_metadata;
  
        files_metadata.push(file_metadata);
      }

      // DO PROGRESS BAR HERE
    }
    else {
      files_metadata = original_files_metadata;
    }


    
    /**************************************
    * 2. Encrypt all_fields using item_key
    ***************************************/

    this.setState({
      progress_message: 'Encrypting data ...'
    });
    
    const all_fields__enc_ik__uint8_array = await SYM_ENCRYPT(item_key__unenc__uint8_array, all_fields__unenc__uint8_array);
    const all_fields__enc_ik__base64 = uint8_array_to_base64(all_fields__enc_ik__uint8_array); // TO SAVE


    /**************************************
    * 3. Encrypt unprotected_fields using server_public_key
    ***************************************/

    const server_public_key__unenc__uint8_array = this.props.server_public_key__unenc__uint8_array;

    const unprotected_fields__enc_spubk__uint8_array = await ASYM_ENCRYPT(server_public_key__unenc__uint8_array, unprotected_fields__unenc__uint8_array);
    const unprotected_fields__enc_spubk__base64 = uint8_array_to_base64(unprotected_fields__enc_spubk__uint8_array); // TO SAVE


    /**************************************
    * 4. Update item entry
    ***************************************/

    this.setState({
      progress_message: 'Securely saving item ...'
    });
    
    let permissions = {};

    permissions[owner_user_id] = 'owner';

    for await (const read_write_user_id of read_write_user_ids) {
      permissions[read_write_user_id] = 'read_write'
    }

    for await (const read_only_user_id of read_only_user_ids) {
      permissions[read_only_user_id] = 'read_only'
    }

    for await (const one_time_user_id of one_time_user_ids) {
      permissions[one_time_user_id] = 'one_time'
    }
        
    const update_item_res = await auth_axios.put(`/api/items/${item_id}`, {
      current_user_id: this.props.user_id,
      item_name: item_name,
      item_type: item_type,
      visibility: visibility,
      permissions: permissions,
      field_status: field_status,
      protected_data: all_fields__enc_ik__base64,
      unprotected_data: unprotected_fields__enc_spubk__base64,
      files_metadata: files_metadata,
      config_id: config_id
    });

    if (!update_item_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with update item',
        progress_message: ''
      });
    }


    /**************************************
    * 5. Figure out who to remove access from and grant access to
    ***************************************/


    // TODO - this is a crude implementation for now
    const original_access_list = Object.keys(this.props.item.permissions);
    const new_access_list = Object.keys(permissions);

    let delete_from_user_ids = [];
    let add_to_user_ids = [];

    for await (const original_access_user_id of original_access_list) {
      if (!new_access_list.includes(original_access_user_id)) {
        delete_from_user_ids.push(original_access_user_id);
      }
    }

    for await (const new_access_user_id of new_access_list) {
      if (!original_access_list.includes(new_access_user_id)) {
        add_to_user_ids.push(new_access_user_id);
      }
    }

    /**************************************
    * 6. Delete item keys from users to delete from
    ***************************************/

    for await (const delete_from_user_id of delete_from_user_ids) {

      const delete_access_res = await auth_axios.delete(`/api/items/${item_id}/access/${delete_from_user_id}`);
  
      if (!delete_access_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with delete access',
          progress_message: ''
        });
      }
    }


    /**************************************
    * 7. Add item_key to users to add to
    ***************************************/

    for await (const add_to_user_id of add_to_user_ids) {

      // First, fetch and encrypt using account public key
      const get_account_public_key_res = await axios.get(`/api/users/${add_to_user_id}/account-public-key`);
  
      if (!get_account_public_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get account public key',
          progress_message: ''
        });
        continue;
      }

      const shared_user_account_public_key__unenc__base64 = get_account_public_key_res.data.account_public_key__unenc;
      const shared_user_account_public_key__unenc__uint8_array = base64_to_uint8_array(shared_user_account_public_key__unenc__base64);

      const item_key__enc_shared_user_apubk__uint8_array = await ASYM_ENCRYPT(shared_user_account_public_key__unenc__uint8_array, item_key__unenc__uint8_array);
      const item_key__enc_shared_user_apubk__base64 = uint8_array_to_base64(item_key__enc_shared_user_apubk__uint8_array); // TO SAVE




      // Second, fetch and encrypt using device public keys

      const get_device_public_keys_res = await axios.get(`/api/users/${add_to_user_id}/device-public-keys`);
  
      if (!get_device_public_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get device public keys',
          progress_message: ''
        });
        continue;
      }

      const shared_user_device_public_keys = get_device_public_keys_res.data.device_public_keys

      let item_keys__enc_dpubk = {}; // TO PARSE AND SAVE

      for (const shared_user_device_id in shared_user_device_public_keys) { // TODO okay that this does not have await?
        const shared_user_device_public_key__unenc__base64 = shared_user_device_public_keys[shared_user_device_id];

        const shared_user_device_public_key__unenc__uint8_array = base64_to_uint8_array(shared_user_device_public_key__unenc__base64);

        const item_key__enc_shared_user_dpubk__uint8_array = await ASYM_ENCRYPT(shared_user_device_public_key__unenc__uint8_array, item_key__unenc__uint8_array);

        const item_key__enc_shared_user_dpubk__base64 = uint8_array_to_base64(item_key__enc_shared_user_dpubk__uint8_array); // TO SAVE

        item_keys__enc_dpubk[shared_user_device_id] = item_key__enc_shared_user_dpubk__base64;
      }

      const insert_item_key_to_user_res = await auth_axios.put(`/api/users/${add_to_user_id}/item-key`, {
        item_id: item_id,
        item_key__enc_apubk: item_key__enc_shared_user_apubk__base64,
        item_keys__enc_dpubk: item_keys__enc_dpubk
      });
  
      if (!insert_item_key_to_user_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with insert item key to user',
          progress_message: ''
        });
        continue;
      }
    }




    /**************************************
    * 8. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false,
      progress_message: ''
    });


    /**************************************
    * 11. Trigger dashboard items pull and close modal
    ***************************************/

    await this.props.on_edit_item_modal_close(true);
    await this.props.update_dashboard_items();

    this.clear_holders(); 


    /**************************************
    * 9. Slack notification request
    ***************************************/

    const display_name = this.props.user_id_display_name_maps[current_user_id];

    const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

    

    // Send message to item adder (this user)

    const message = `You modified ${item_name}.`;
    await send_slack_notification(url, selected_team_id, current_user_id, message, {

    });


    // Send message to add_to_user_ids 

    const message2 = `SLACK_USER_ID (${display_name}) shared ${item_name} with you.`;

    for (const add_to_user_id of add_to_user_ids) {
      await send_slack_notification(url, selected_team_id, add_to_user_id, message2, {
        TO_FORMAT_USER_ID: current_user_id
      });
    }


    // Send message to delete_from_user_ids 

    const message3 = `SLACK_USER_ID (${display_name}) removed your access to ${item_name}.`;

    for (const delete_from_user_id of delete_from_user_ids) {
      await send_slack_notification(url, selected_team_id, delete_from_user_id, message3, {
        TO_FORMAT_USER_ID: current_user_id
      });
    }


    /**************************************
    * 10. Mixpanel
    ***************************************/

    mixpanel.track('web_edit_item_modal_submitted', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });

  
    
    /**************************************
    * 11. Trigger items pull and close modal
    ***************************************/

    // // Redirect and reload
    // await this.props.history.push(`/dashboard`);
    // window.location.reload(false);


  }


  render() {

    const owner_user_id = this.state.owner_holder;
    const read_write_user_ids = this.state.read_write_list_holder;
    const read_only_user_ids = this.state.read_only_list_holder;
    const one_time_user_ids = this.state.one_time_list_holder;

    const shared_user_ids = read_write_user_ids.concat(read_only_user_ids.concat(one_time_user_ids))

    const access_user_ids = shared_user_ids.concat([owner_user_id]);

    const available_user_options = this.props.selected_team_user_ids.filter((user_id) => ! access_user_ids.includes(user_id)).map((user_id) => 
      ({
        label: this.props.user_id_display_name_maps[user_id],
        value: user_id
      })
    );
    const all_user_options = this.props.selected_team_user_ids.map((user_id) => 
      ({
        label: this.props.user_id_display_name_maps[user_id],
        value: user_id
      })
    );

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    const get_secondary_field = (item_type) => {
      switch (item_type) {
        case 'account': {
          return <>
            <div
              className='edit-item-modal__select-account-text'
            >
              Select account
              <div className='edit-item-modal__info-container'>
                <InfoIcon 
                  className='edit-item-modal__info-icon'
                />
                <div className='edit-item-modal__info-description select-account'>
                  Recommended (if available): amongst the dozens of accounts we support, select the appropriate account type for this item. At PlusIdentity, we make note of which credentials you need to access each account type, and will later be supporting convenient features such as auto-login.
                </div>
              </div>
            </div>
            <Select
              options={this.state.config_options}
              value={this.state.config_options.filter((config_option) => {
                return config_option.value === this.state.config_holder
              })}
              isSearchable
              onChange={this.handle_config_change}
              isDisabled={this.state.awaiting_server_response}
              isClearable={true}
              className='edit-item-modal__search-select'
              classNamePrefix='edit-item-modal__search-select-child'
            />
          </>;
        }
        case 'file': {
          return <>
            <div
              className='edit-item-modal__select-file-text'
            >
              Select file
              <div className='edit-item-modal__info-container'>
                <InfoIcon 
                  className='edit-item-modal__info-icon'
                />
                <div className='edit-item-modal__info-description select-file'>
                  The file size cannot exceed 100MB.
                </div>
              </div>
            </div>

            <input type='file' id='edit-item-modal__select-file-input' onChange={this.handle_files_change} /> {/* multiple */}
            <label 
              htmlFor='edit-item-modal__select-file-input'
              className={`edit-item-modal__select-file-input ${this.state.files_changed ? (this.state.new_files_holder.length > 0 ? 'modified' : '') : (this.state.original_files_metadata.length > 0 ? 'selected' : '')}`}
            >
              {this.state.files_changed ? (this.state.new_files_holder.length > 0 ? this.state.new_files_holder[0].name : 'Upload') : (this.state.original_files_metadata.length > 0 ? this.state.original_files_metadata[0].file_name : 'Upload')}
            </label>

            <div
              className='edit-item-modal__select-file-text'
            >
              or drag in
            </div>
          </>;
        }
        default: {
          return <></>;
        }
      }
    }

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            this.clear_holders();
            await this.props.on_edit_item_modal_close(false);
          }
        }}
        style={{
          content: {
            height: this.state.awaiting_server_response ? 200 : (screen_height > 680 ? 640 : 450),
            width: this.state.awaiting_server_response ? 200 : (screen_width > 690 ? 650 : 300)
          }
        }}
      >
        <div className="edit-item-modal__container">
          {
            this.state.awaiting_server_response
            ? <div className="edit-item-modal__loading">
                <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
                <div className="edit-item-modal__progress_message">
                  <span>{this.state.progress_message}</span>
                </div>
              </div>
            : <>
                <div className="edit-item-modal__title">
                  EDIT ITEM
                </div>
                <div className="edit-item-modal__item-type">
                  <span>Type*</span>
                  <Select
                    options={[
                      {label: 'Account', value: 'account'},
                      {label: 'File', value: 'file'},
                      {label: 'Custom item', value: 'custom'}
                    ]}
                    value={[
                      {label: 'Account', value: 'account'},
                      {label: 'File', value: 'file'},
                      {label: 'Custom item', value: 'custom'}
                    ].filter((item_type_option) => {
                      return item_type_option.value === this.state.item_type_holder
                    })}
                    isSearchable
                    onChange={this.handle_item_type_change}
                    isDisabled={this.state.awaiting_server_response}
                    className='edit-item-modal__search-select'
                    classNamePrefix='edit-item-modal__search-select-child'
                  />
                  {get_secondary_field(this.state.item_type_holder)}
                  {/* {
                    (this.state.item_type_holder === 'account')
                    ? <>
                        <div
                          className='edit-item-modal__select-account-text'
                        >
                          Select account
                          <div className='edit-item-modal__info-container'>
                            <InfoIcon 
                              className='edit-item-modal__info-icon'
                            />
                            <div className='edit-item-modal__info-description select-account'>
                              Recommended (if available): amongst the dozens of accounts we support, select the appropriate account type for this item. At PlusIdentity, we make note of which credentials you need to access each account type, and will later be supporting convenient features such as auto-login.
                            </div>
                          </div>
                        </div>
                        <Select
                          options={this.state.config_options}
                          value={this.state.config_options.filter((config_option) => {
                            return config_option.value === this.state.config_holder
                          })}
                          isSearchable
                          onChange={this.handle_config_change}
                          isDisabled={this.state.awaiting_server_response}
                          isClearable={true}
                          className='edit-item-modal__search-select'
                          classNamePrefix='edit-item-modal__search-select-child'
                        />
                      </>
                    : <></>
                  } */}
                </div>
                <div className="edit-item-modal__item-name">
                  <span>Name*</span>
                  <input 
                    type='text'
                    value={ this.state.item_name_holder }
                    onChange={ this.handle_item_name_change }
                    disabled={this.state.awaiting_server_response}
                    placeholder='What do you want to call this item?'
                    className='edit-item-modal__item-name-input'
                    autoComplete="new-password"
                  />
                </div>
                <div className="edit-item-modal__fields">
                  <div className="edit-item-modal__fields-meta">
                    <span>Fields*</span>
                    <button 
                      onClick={this.add_field} 
                      disabled={this.state.awaiting_server_response}
                      className='edit-item-modal__fields-meta-add-button'
                    >
                      +
                    </button>
                    <button 
                      onClick={this.delete_field}
                      disabled={this.state.awaiting_server_response}
                      className='edit-item-modal__fields-meta-delete-button'
                    >
                      -
                    </button>
                  </div>
                  <div className="edit-item-modal__fields-list">
                    <div className={`edit-item-modal__fields-header ${this.state.fields_holder.length === 0 ? 'hidden' : ''}`}>
                      <div className="edit-item-modal__fields-header-first">
                        Field name*
                      </div>
                      <div className="edit-item-modal__fields-header-second">
                        Value*
                      </div>
                      <div className="edit-item-modal__fields-header-third">
                        Max-security
                        <div className='edit-item-modal__info-container'>
                          <InfoIcon 
                            className='edit-item-modal__info-icon'
                          />
                          <div className='edit-item-modal__info-description encrypt'>
                            By choosing max-security, you make that field available (client-side decryptable) only to those who have the appropriate cryptographic access to this item (set in the Sharing Settings of an item). Any sensitive information such as a password should use max-security (client-side encryption).<br/>Note that every field of data stored on PlusIdentity's servers is always encrypted securely, even if max-security is not chosen.
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.fields_holder.map((field, i) => 
                      <div key={i} className='edit-item-modal__fields-field'>
                        <CreatableSelect
                          options={this.state.field_name_options} 
                          value={this.state.field_name_options.filter((field_name_option) => {
                            return field_name_option.value === field[0]
                          })}
                          isSearchable
                          onChange={ (e) => this.handle_fields_change(e.value, i, 'field_name') }
                          isDisabled={this.state.awaiting_server_response}
                          maxMenuHeight={150}
                          className='edit-item-modal__fields-field-first edit-item-modal__search-select'
                          classNamePrefix='edit-item-modal__search-select-child'
                        />
                        <input
                          type={ (field[1] && field[2]) ? 'password' : 'text' }
                          value={ field[1] }
                          onChange={ (e) => this.handle_fields_change(e.target.value, i, 'field_value') }
                          disabled={this.state.awaiting_server_response}
                          className={`edit-item-modal__fields-field-second ${(field[1] !== this.state.decrypted_data[field[0]]) ? 'modified'  : ''}`}
                          autoComplete="new-password"
                        />
                        <input
                          type='checkbox'
                          checked={ field[2] }
                          value={ field[2] }
                          onChange={ (e) => this.handle_fields_change(e.target.checked, i, 'protected_status') }
                          disabled={this.state.awaiting_server_response}
                          className='edit-item-modal__fields-field-third'
                        />
                        {
                          field[0] === 'password'
                          ? this.state.awaiting_password_generation
                            ? <ReactLoading
                                type='cylon'
                                color='#000000'
                                className='loading__animation'
                              />
                            : field[3] === 'pre'
                              ? <button
                                  onClick={async () => this.generate_random_password(i)}
                                  className='edit-item-modal__fields-field-fourth'
                                >
                                  Generate random
                                </button>
                              : field[3] === 'generated'
                              ? <button
                                  onClick={async () => this.copy_to_clipboard(i)}
                                  className='edit-item-modal__fields-field-fourth'
                                >
                                  Copy to clipboard
                                </button>
                              : <CheckLogo 
                                  className='edit-item-modal__fields-field-fourth copied'
                                />
                          : <></>
                        }
                      </div>
                    )}
                    <div className="edit-item-modal__fields-notes">
                      <div className="edit-item-modal__fields-notes-field-name">
                        Additional notes
                      </div>
                      <textarea 
                        type='text'
                        value={ this.state.notes_holder }
                        onChange={ this.handle_notes_change }
                        disabled={this.state.awaiting_server_response}
                        placeholder='Additional notes'
                        className="edit-item-modal__fields-notes-textarea"
                      />
                    </div>
                  </div>
                </div>
                <div className="edit-item-modal__visibility">
                  <div className="edit-item-modal__visibility-title">Visibility Settings</div>
                  <div
                    className='edit-item-modal__visibility-visibility'
                  >
                    <div
                      className='edit-item-modal__visibility-visibility-description'
                    >
                      Discoverability*
                      <div className='edit-item-modal__info-container'>
                        <InfoIcon 
                          className='edit-item-modal__info-icon'
                        />
                        <div className='edit-item-modal__info-description visibility'>
                          Discoverable: make this item discoverable if you want anyone on the team to be able to see that this item exists and request access to it.<br/><br/>Hidden: make this item hidden if you want only those with access be able to see that this item exists.
                        </div>
                      </div>
                    </div>
                    <Select
                      options={[
                        {label: 'Discoverable', value: 'public'},
                        {label: 'Hidden', value: 'private'}
                      ]}
                      value={[
                        {label: 'Discoverable', value: 'public'},
                        {label: 'Hidden', value: 'private'}
                      ].filter((item_visibility_option) => {
                        return item_visibility_option.value === this.state.item_visibility_holder
                      })}
                      isSearchable
                      onChange={this.handle_item_visibility_change}
                      isDisabled={this.state.awaiting_server_response}
                      className='edit-item-modal__search-select edit-item-modal__visibility-visibility-select'
                      classNamePrefix='edit-item-modal__search-select-child'
                    />
                  </div>
                </div>
                <div className="edit-item-modal__permissions">
                  <Collapsible 
                    trigger={
                      <div className="edit-item-modal__permissions-title">
                        <div 
                          className="edit-item-modal__permissions-section-toggle-icon-container"
                        >
                          {
                            this.state.permissions_section_open
                            ? <FiltersOpenIcon
                                className='edit-item-modal__permissions-section-toggle-icon'
                              />
                            : <FiltersClosedIcon
                                className='edit-item-modal__permissions-section-toggle-icon'
                              />
                          }
                        </div>
                        <span>Sharing Settings</span>
                      </div>
                    }
                    open={this.state.permissions_section_open}
                    onOpening={this.on_permissions_section_open}
                    onClosing={this.on_permissions_section_close}
                    transitionTime={200}
                    className='dashboard__left-bar-collapsible'
                    openedClassName='dashboard__left-bar-collapsible'
                    triggerClassName='dashboard__left-bar-collapsible-trigger'
                    triggerOpenedClassName='dashboard__left-bar-collapsible-trigger'
                    contentOuterClassName='dashboard__left-bar-collapsible-outer'
                    contentInnerClassName="dashboard__left-bar-collapsible-inner"
                  >
                    {
                      (this.state.is_current_owner)
                      ? <div
                          className='edit-item-modal__permissions-full'
                        >
                          <div
                            className='edit-item-modal__permissions-full-description'
                          >
                            Owner*
                            <div className='edit-item-modal__info-container'>
                              <InfoIcon 
                                className='edit-item-modal__info-icon'
                              />
                              <div className='edit-item-modal__info-description owner'>
                                Required: item owner can read, edit, share, transfer ownership of, and delete the item. There can only be one owner per item and an item must always have an owner (by default set to item creator).
                              </div>
                            </div>
                          </div>
                          <Select
                            options={available_user_options}
                            value={all_user_options.filter((user_option) => {
                              return user_option.value === this.state.owner_holder
                            })}
                            isSearchable
                            onChange={this.handle_owner_change}
                            isDisabled={this.state.awaiting_server_response}
                            maxMenuHeight={150}
                            className='edit-item-modal__search-select edit-item-modal__permissions-full-select'
                            classNamePrefix='edit-item-modal__search-select-child'
                          />
                        </div>
                      : <div
                          className='edit-item-modal__permissions-owner'
                        >
                          <div className="edit-item-modal__permissions-owner-description">
                            Owner*
                            <div className='edit-item-modal__info-container'>
                              <InfoIcon 
                                className='edit-item-modal__info-icon'
                              />
                              <div className='edit-item-modal__info-description owner'>
                                Required: item owner can read, edit, share, transfer ownership of, and delete the item. There can only be one owner per item and an item must always have an owner (by default set to item creator).
                              </div>
                            </div>
                          </div>
                          <div className="edit-item-modal__permissions-owner-select">
                            {this.props.user_id_display_name_maps[this.state.owner_holder]}
                          </div>
                        </div>
                    }
                    <div
                      className='edit-item-modal__permissions-full'
                    >
                      <div
                        className='edit-item-modal__permissions-full-description'
                      >
                        Edit-access
                        <div className='edit-item-modal__info-container'>
                          <InfoIcon 
                            className='edit-item-modal__info-icon'
                          />
                          <div className='edit-item-modal__info-description edit-access'>
                            Users with edit-access permission can view and edit all the fields of the item (including max-security fields), plus the sharing settings of the item.
                          </div>
                        </div>
                      </div>
                      <Select
                        options={available_user_options} 
                        value={all_user_options.filter((user_option) => {
                          return this.state.read_write_list_holder.includes(user_option.value)
                        })}
                        isSearchable
                        isMulti
                        onChange={this.handle_read_write_list_change}
                        isDisabled={this.state.awaiting_server_response}
                        isClearable={true}
                        maxMenuHeight={150}
                        className='edit-item-modal__search-select edit-item-modal__permissions-full-select'
                        classNamePrefix='edit-item-modal__search-select-child'
                      />
                    </div>
                    <div
                      className='edit-item-modal__permissions-full'
                    >
                      <div
                        className='edit-item-modal__permissions-full-description'
                      >
                        Read-only
                        <div className='edit-item-modal__info-container'>
                          <InfoIcon 
                            className='edit-item-modal__info-icon'
                          />
                          <div className='edit-item-modal__info-description read-only'>
                            Users with read-only permission can read all the fields of the item (including max-security fields), plus the sharing settings of the item.
                          </div>
                        </div>
                      </div>
                      <Select
                        options={available_user_options} 
                        value={all_user_options.filter((user_option) => {
                          return this.state.read_only_list_holder.includes(user_option.value)
                        })}
                        isSearchable
                        isMulti
                        onChange={this.handle_read_only_list_change}
                        isDisabled={this.state.awaiting_server_response}
                        isClearable={true}
                        maxMenuHeight={150}
                        className='edit-item-modal__search-select edit-item-modal__permissions-full-select'
                        classNamePrefix='edit-item-modal__search-select-child'
                      />
                    </div>
                    <div
                      className='edit-item-modal__permissions-full'
                    >
                      <div
                        className='edit-item-modal__permissions-full-description'
                      >
                        One-time read-only
                        <div className='edit-item-modal__info-container'>
                          <InfoIcon 
                            className='edit-item-modal__info-icon'
                          />
                          <div className='edit-item-modal__info-description one-time'>
                            Users with one-time read-only permission can read one max-security field of the item in plaintext just once. Upon reading, their access to this item is automatically revoked.
                          </div>
                        </div>
                      </div>
                      <Select
                        options={available_user_options} 
                        value={all_user_options.filter((user_option) => {
                          return this.state.one_time_list_holder.includes(user_option.value)
                        })}
                        isSearchable
                        isMulti
                        onChange={this.handle_one_time_list_change}
                        isDisabled={this.state.awaiting_server_response}
                        isClearable={true}
                        menuPlacement='top'
                        maxMenuHeight={150}
                        className='edit-item-modal__search-select edit-item-modal__permissions-full-select'
                        classNamePrefix='edit-item-modal__search-select-child'
                      />
                    </div>
                  </Collapsible>
                </div>
                <div className="edit-item-modal__action-buttons">
                  {
                    this.state.awaiting_server_response
                    ? <ReactLoading
                        type='spokes'
                        color='#9696ad'
                        height={20}
                        width={20}
                      />
                    : <>
                        <input
                          className='edit-item-modal__submit-button'
                          type='submit'
                          value={ 'Save' }
                          onClick={ this.save_item }
                        />
                        <a
                          className='edit-item-modal__cancel'
                          onClick={async () => {
                            this.clear_holders();
                            await this.props.on_edit_item_modal_close(false);
                          }}
                        >
                          Cancel
                        </a>
                      </>
                  }
                </div>
                <div className="edit-item-modal__error_message">
                  <span>{this.state.error_message}</span>
                </div>
              </>
          }
        </div>
      </ReactModal>
    );
  }
}

export default EditItemModal;
