import React from 'react';
import './InitiateAdminRecovery.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_DECRYPT, ASYM_ENCRYPT, ASYM_DECRYPT, CSPRNG, GEN_KEY_PAIR, PBKDF2, HKDF, XOR } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string, uint8_array_to_base64, string_to_uint8_array } from '../../../crypto/util';

import { get as dbGet } from 'idb-keyval';


import { send_slack_notification } from '../../../slack/util';

import { auth_axios, sleep } from '../../../auth/util';

import { set as dbSet, del as dbDel } from 'idb-keyval';


import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';

import { ReactComponent as InfoIcon } from './media/svg/initiate-admin-recovery__info-icon.svg';
import { ReactComponent as PlusLock } from '../../../logos/plusidentity-lock.svg';




// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




class InitiateAdminRecovery extends React.Component {
  
  constructor(props) {
    super(props);

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const url_params = new URLSearchParams(`?${query__string}`);

    const type = url_params.get('type');

    // URL Params if type === admin
    const registration_id = url_params.get('registration_id');
    const email_address = url_params.get('email_address');
    const admin_user_id = url_params.get('admin_user_id');
    const requestor_user_id = url_params.get('requestor_user_id');
    const team_id = url_params.get('team_id');

    let url_valid = false;
    if (type === 'admin') {
      url_valid = (!((!registration_id) || (!email_address)) || (!admin_user_id)  || (!requestor_user_id)  || (!team_id));
    } else if (type === '___') {
      // url_valid = (!((!___) || (!____)) || (!___));
    } else {
      url_valid = false;
    }

    this.state = {
      awaiting_server_response: true,

      fatal_error_occurred: false,

      status_message: 'Validating the link and your credentials . . .',
      warning_message: '',
      error_message: '',
      
      email_registration_id: registration_id,
      email_address: email_address,
      admin_user_id: admin_user_id,
      requestor_user_id: requestor_user_id,
      team_id: team_id,

      url_valid: url_valid,
      validation_success: false,

      // cache_intact: false,
      continue_clicked: false,
      terminated: false,

      recovery_metadata: {},
      device_id: '',

      team_unlock_key__unenc__uint8_array: undefined,
      team_private_key__unenc__uint8_array: undefined,
      to_recover_item_ids: [],
      team_item_keys: {},

      requestor_account_public_key__unenc__uint8_array: undefined,
      requestor_device_public_keys: {},
      requestor_is_admin: undefined,

      preflight_registration_id: '',
    }
  }

  componentDidMount = async () => {
    try {
      /**************************************
      * 0. URL valid check
      ***************************************/

      if (!this.state.url_valid) {
        alert('Invalid URL');
        await this.props.history.push(`/dashboard`);
        return;
      }

      /**************************************
      * 1. Validate registration_id
      ***************************************/

      const validate_registration_res = await axios.post(`/api/registrations/${this.state.email_registration_id}/validate`, {
        email_address: this.state.email_address,
        type: 'initiate-admin-recovery'
      });

      if (!validate_registration_res.data.success) {
        alert('Link is invalid - registration id is not valid');
        await this.props.history.push(`/dashboard`);
        return;
      }

      /**************************************
      * 2. Fetch user id via email address and see if it matches the user_id from the URL param and also the currently logged in user id
      ***************************************/

      const get_user_id_res = await axios.post('/api/util/email-address/user-id/fetch', {
        email_address: this.state.email_address
      });

      if (!get_user_id_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get user_id command'
        });
        return;
      }

      const fetched_user_id = get_user_id_res.data.user_id;

      if (!((fetched_user_id === this.state.admin_user_id) && (fetched_user_id === this.props.user_id))) {
        alert('Incorrect user - you do not have access to take this action');
        await this.props.history.push(`/dashboard`);
        return;
      }

      /**************************************
      * 3. Decrypt team_private_key
      ***************************************/

      const team_unlock_key_res = await auth_axios.get(`/api/users/${this.state.admin_user_id}/accounts/${this.state.team_id}/team-unlock-key`);

      const team_private_key_res = await auth_axios.get(`/api/teams/${this.state.team_id}/team-private-key`);

      if (!team_private_key_res.data.success || !team_unlock_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get team private / unlock key command'
        });
        return;
      }

      const account_private_key__unenc__uint8_array = this.props.keychain.account_private_key__unenc__uint8_array;

      const team_unlock_key__enc_apubk__base64 = team_unlock_key_res.data.team_unlock_key__enc_apubk;

      const team_unlock_key__enc_apubk__uint8_array = base64_to_uint8_array(team_unlock_key__enc_apubk__base64);

      const team_private_key__enc_tuk__base64 = team_private_key_res.data.team_private_key__enc_tuk

      const team_private_key__enc_tuk__uint8_array = base64_to_uint8_array(team_private_key__enc_tuk__base64);

      const team_unlock_key__unenc__uint8_array = await ASYM_DECRYPT(account_private_key__unenc__uint8_array, team_unlock_key__enc_apubk__uint8_array);

      const team_private_key__unenc__uint8_array = await SYM_DECRYPT(team_unlock_key__unenc__uint8_array, team_private_key__enc_tuk__uint8_array);

      /**************************************
      * 4. Figure out list of item ids to recover 
      ***************************************/

      const get_access_items_res = await auth_axios.get(`/api/users/${this.state.requestor_user_id}/accounts/${this.state.team_id}/access-items`);

      if (!get_access_items_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get access items command'
        });
        return;
      }

      const to_recover_item_ids = get_access_items_res.data.access_items;

      /**************************************
      * 5. Get item keys (enc_tpubk)
      ***************************************/

      const get_item_keys_res = await auth_axios.get(`/api/teams/${this.state.team_id}/item-keys`);

      if (!get_item_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get item keys command'
        });
        return;
      }

      const team_item_keys = get_item_keys_res.data.item_keys;

      /**************************************
      * 6. Check that every to_recover_item_id is accounted for in the team_item_keys (should be so, since admin-initiated account recovery is disabled until item_keys is synced up with item_ids (master))
      ***************************************/

      for (const to_recover_item_id of to_recover_item_ids) {
        if (!(to_recover_item_id in team_item_keys)) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Admin-initiated account recovery is not available'
          });
          return;
        }
      }

      /**************************************
      * 7. Fetch requestor account public key
      ***************************************/

      const get_requestor_account_public_key_res = await axios.get(`/api/users/${this.state.requestor_user_id}/account-public-key`);

      if (!get_requestor_account_public_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get requestor account public key command'
        });
        return;
      }

      const requestor_account_public_key__unenc__base64 = get_requestor_account_public_key_res.data.account_public_key__unenc;

      const requestor_account_public_key__unenc__uint8_array = base64_to_uint8_array(requestor_account_public_key__unenc__base64)

      /**************************************
      * 8. Fetch requestor device public keys
      ***************************************/

      // Fetch device keychains from server
      const get_requestor_device_public_keys_res = await axios.get(`/api/users/${this.state.requestor_user_id}/device-public-keys`);

      if (!get_requestor_device_public_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get device public keys'
        });
      }

      const requestor_device_public_keys = get_requestor_device_public_keys_res.data.device_public_keys

      /**************************************
      * 9. Check whether requestor is admin or not
      ***************************************/

      // Check if this person is admin and has the privileges to see
      const check_if_admin_res = await auth_axios.post(`/api/teams/${this.state.team_id}/admins/validate`, {
        user_id: this.state.requestor_user_id
      });

      if (!check_if_admin_res) {
        console.error('checking if admin failed')
        return;
      }

      const requestor_is_admin = check_if_admin_res.data.privileged




      /**************************************
      * 10. Set state
      ***************************************/

      this.setState({
        awaiting_server_response: false,
        validation_success: true,
        status_message: 'Validation success!',

        team_unlock_key__unenc__uint8_array: team_unlock_key__unenc__uint8_array,
        team_private_key__unenc__uint8_array: team_private_key__unenc__uint8_array,
        to_recover_item_ids: to_recover_item_ids,
        team_item_keys: team_item_keys,

        requestor_account_public_key__unenc__uint8_array: requestor_account_public_key__unenc__uint8_array,
        requestor_device_public_keys: requestor_device_public_keys,
        requestor_is_admin: requestor_is_admin
      });



      /**************************************
      * 11. Mixpanel
      ***************************************/

      mixpanel.track('web_admin_account_recovery_visited', {  
        distinct_id: this.state.admin_user_id,
        team_id: this.state.team_id,
        is_dev: this.props.mode_info.is_dev
      });




    }
    catch (error) {
      console.error(error)
      this.setState({
        error_message: 'fatal error',
        fatal_error_occurred: true
      });
    }
  }

  on_continue = async () => {
    this.setState({
      awaiting_server_response: true,
      status_message: '',
      error_message: ''
    });

    const create_preflight_registration_res = await axios.post(`/api/registrations/recovery-preflight/create`, {
      email_address: this.state.email_address,
      preflight_prefix: 'initiate-admin-recovery'
    });

    if (!create_preflight_registration_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with preflight registration'
      });
      return;
    }

    const preflight_registration_id = create_preflight_registration_res.data.registration_id;

    this.setState({
      awaiting_server_response: false,
      continue_clicked: true,
      preflight_registration_id: preflight_registration_id,
    });
  }

  on_initiate_admin_recovery = async () => {
    try {
      this.setState({
        awaiting_server_response: true,
        status_message: 'Securely executing account recovery',
      });

      /**************************************
      * 0. Validate registration_id
      ***************************************/
      const validate_registration_res = await axios.post(`/api/registrations/${this.state.preflight_registration_id}/validate`, {
        email_address: this.state.email_address,
        type: 'initiate-admin-recovery-preflight'
      });

      if (!validate_registration_res.data.success) {
        alert('Link is invalid - registration id is not valid');
        await this.props.history.push(`/dashboard`);
        return;
      }

      /**************************************
      * 1. Fetch ingredients from this.state
      ***************************************/

      const team_unlock_key__unenc__uint8_array = this.state.team_unlock_key__unenc__uint8_array;
      const team_private_key__unenc__uint8_array = this.state.team_private_key__unenc__uint8_array;
      const to_recover_item_ids = this.state.to_recover_item_ids;
      const team_item_keys = this.state.team_item_keys;

      const requestor_account_public_key__unenc__uint8_array = this.state.requestor_account_public_key__unenc__uint8_array;
      const requestor_device_public_keys = this.state.requestor_device_public_keys
      const requestor_is_admin = this.state.requestor_is_admin;

      /**************************************
      * 5. Get to work (upsert item by item to both item_keys and device_keychains)
      ***************************************/

      for (const to_recover_item_id of to_recover_item_ids) {

        const item_key__enc_tpubk__base64 = team_item_keys[to_recover_item_id];

        const item_key__enc_tpubk__uint8_array = base64_to_uint8_array(item_key__enc_tpubk__base64);

        const item_key__unenc__uint8_array = await ASYM_DECRYPT(team_private_key__unenc__uint8_array, item_key__enc_tpubk__uint8_array)

        const item_key__enc_apubk__uint8_array = await ASYM_ENCRYPT(requestor_account_public_key__unenc__uint8_array, item_key__unenc__uint8_array);

        const item_key__enc_apubk__base64 = uint8_array_to_base64(item_key__enc_apubk__uint8_array);

        let item_keys__enc_dpubk = {};
        for (const requestor_device_id in requestor_device_public_keys) {
          const requestor_device_public_key__unenc__base64 = requestor_device_public_keys[requestor_device_id];
          
          const requestor_device_public_key__unenc__uint8_array = base64_to_uint8_array(requestor_device_public_key__unenc__base64);
  
          const item_key__enc_requestor_user_dpubk__uint8_array = await ASYM_ENCRYPT(requestor_device_public_key__unenc__uint8_array, item_key__unenc__uint8_array);

          const item_key__enc_requestor_user_dpubk__base64 = uint8_array_to_base64(item_key__enc_requestor_user_dpubk__uint8_array);

          item_keys__enc_dpubk[requestor_device_id] = item_key__enc_requestor_user_dpubk__base64;
        }

        const insert_item_key_to_user_res = await auth_axios.put(`/api/users/${this.state.requestor_user_id}/item-key`, {
          item_id: to_recover_item_id,
          item_key__enc_apubk: item_key__enc_apubk__base64,
          item_keys__enc_dpubk: item_keys__enc_dpubk
        });
    
        if (!insert_item_key_to_user_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with insert item key to user',
            terminated: true
          });
        }
      }


      /**************************************
      * 6. Handle if admin scenario
      ***************************************/

      if (requestor_is_admin) {

        // Derive team unlock key enc apubk
        const team_unlock_key__enc_requestor_apubk__uint8_array = await ASYM_ENCRYPT(requestor_account_public_key__unenc__uint8_array, team_unlock_key__unenc__uint8_array);

        const team_unlock_key__enc_requestor_apubk__base64 = uint8_array_to_base64(team_unlock_key__enc_requestor_apubk__uint8_array)

        const put_team_unlock_key_res = await auth_axios.put(`/api/users/${this.state.requestor_user_id}/accounts/${this.state.team_id}/team-unlock-key`, {
          team_unlock_key__enc_apubk: team_unlock_key__enc_requestor_apubk__base64
        });

        if (!put_team_unlock_key_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with put team unlock key command',
            terminated: true
          });
          return;
        }
      }


      /**************************************
      * 7. Initiate admin recovery post actions
      ***************************************/

      const post_recovery_actions_res = await auth_axios.post(`/api/users/${this.state.requestor_user_id}/initiate-admin-recovery-post-actions`, {
        email_address: this.state.email_address,
        admin_user_id: this.state.admin_user_id,
        registration_id: this.state.preflight_registration_id,  
        team_id: this.state.team_id,
      });

      if (!post_recovery_actions_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with recovery post actions command',
          terminated: true
        });
        return;
      } 






      /**************************************
      * 8. Delete registration 
      ***************************************/ 

      const delete_registration_res = await axios.delete(`/api/registrations/${this.state.preflight_registration_id}`);

      if (!delete_registration_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with delete registration command',
          terminated: true
        });
        return;
      } 


      /**************************************
      * 9. Mixpanel
      ***************************************/

      mixpanel.track('web_admin_account_recovery_executed', {  
        distinct_id: this.state.admin_user_id,
        team_id: this.state.team_id,
        is_dev: this.props.mode_info.is_dev
      });



      /**************************************
      * 10. Success messages
      ***************************************/

      this.setState({
        awaiting_server_response: false,
        status_message: 'Account was successfully recovered!',
        terminated: true
      });
      // // Reload
      // await this.props.history.push('/dashboard');
      // window.location.reload(false);
    }
    catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="initiate-admin-recovery__container">
        <div className="initiate-admin-recovery__top">
          <span className="initiate-admin-recovery__title">I N I T I A T E &nbsp;&nbsp;&nbsp;A C C O U N T &nbsp;&nbsp;&nbsp;R E C O V E R Y</span>
          <div className="initiate-admin-recovery__plus-lock"><PlusLock/></div>
        </div>
        <div className="initiate-admin-recovery__bottom">
           {/* Checking status, then show continue button with maybe warning message as appropriate */}
          <div className='initiate-admin-recovery__description-text'>
            Initiate account recovery for the following user:
          </div>
          <div
            className='initiate-admin-recovery__bottom-input-box pre-continue'
          >
            <input 
              className="initiate-admin-recovery__bottom-input-field disabled"
              value={ this.props.user_id_display_name_maps[this.state.requestor_user_id] }
              type="text" 
              disabled
            />
          </div>
          <div
            className='initiate-admin-recovery__bottom-send-link-action'
          >
            {
              (this.state.awaiting_server_response || !this.state.validation_success)
              ? <div 
                  className='initiate-admin-recovery__bottom-loading-container'
                >
                  <ReactLoading
                    type='spokes'
                    color='#9696ad'
                    height={20}
                    width={20}
                  />
                </div>
              : this.state.continue_clicked
                ? this.state.terminated
                  ? <input
                      className='initiate-admin-recovery__bottom-continue-button disabled'
                      type="submit"
                      value="Initiate account recovery"
                    />
                  : <input
                      className='initiate-admin-recovery__bottom-continue-button'
                      type="submit"
                      value="Initiate account recovery"
                      onClick={ this.on_initiate_admin_recovery }
                    />
                : <input
                    className='initiate-admin-recovery__bottom-continue-button'
                    type="submit"
                    value="Continue"
                    onClick={ this.on_continue }
                  />
            }
          </div>
          <span className="initiate-admin-recovery__status-message">{ this.state.status_message }</span>
          <span className="initiate-admin-recovery__warning-message">{ this.state.warning_message }</span>
          <span className="initiate-admin-recovery__error-message">{ this.state.error_message }</span>
        </div>
      </div>
    );
  }
}

export default InitiateAdminRecovery;
