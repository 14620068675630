import React from 'react';
import './ConnectExtension.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_ENCRYPT, ASYM_ENCRYPT, CSPRNG, GEN_KEY_PAIR } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string, uint8_array_to_base64 } from '../../../crypto/util';

import { send_slack_notification } from '../../../slack/util';

import { auth_axios, sleep } from '../../../auth/util';

import { set as dbSet, del as dbDel } from 'idb-keyval';


import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';




// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




class ConnectExtension extends React.Component {
  
  constructor(props) {
    super(props);

    // For safety
    window.localStorage.removeItem('resolve_registration_id');
    window.localStorage.removeItem('web_connect_registration_id');

    // const query__base64 = props.location.search.split('?')[1] || '';

    // const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    // const url_params = new URLSearchParams(`?${query__string}`);

    // const registration_id = url_params.get('registration_id');
    // const email_address = url_params.get('email_address');

    const url_valid = true; // (!((!registration_id) || (!email_address))); //  || (!existing_email_address) || (!new_email_address) || (!slack_team_id) || (!slack_user_id) || (!is_installer)));

    this.state = {
      awaiting_server_response: true,

      fatal_error_occurred: false,

      error_message: '',

      account_private_key__unenc__uint8_array: props.keychain.account_private_key__unenc__uint8_array,

      account_public_key__unenc__uint8_array: props.keychain.account_public_key__unenc__uint8_array,

      server_public_key__unenc__uint8_array: props.keychain.server_public_key__unenc__uint8_array,

      team_public_keys: props.keychain.team_public_keys,
      
      // registration_id: registration_id,
      // email_address: email_address,

      url_valid: url_valid,
    }
  }

  componentDidMount = async () => {
    try {
      /**************************************
      * 0. Fetch the registration id using primary_email_address and user_id
      ***************************************/

      
      
      const fetch_registration_res = await axios.post(`/api/util/extension/registration-id/fetch`, {
        email_address: this.props.primary_email_address,
        user_id: this.props.user_id,
        type: 'connect-extension-preflight'
      });

      if (!fetch_registration_res.data.success) {
        alert('Error - fetch_registration_res failed');
        await this.props.history.push(`/dashboard`);
        return;
      }

      const web_connect_registration_id = fetch_registration_res.data.registration_id;


      /**************************************
      * 2. Put registration_id in localStorage for content.js to pick up (extension's content.js will delete it later, so no need to handle deletion)
      ***************************************/


      window.localStorage.setItem('web_connect_registration_id', web_connect_registration_id);










      // /**************************************
      // * 1. Check email addresses and also validate registration
      // ***************************************/

      // // if (this.props.primary_email_address !== this.state.email_address) {
      // //   alert('Link is invalid - wrong user / primary email address');
      // //   await this.props.history.push(`/dashboard`);
      // // }

      // const validate_registration_res = await axios.post(`/api/registrations/${this.state.registration_id}/validate`, {
      //   email_address: this.props.primary_email_address,
      //   type: 'connect-extension-preflight'
      // });

      // if (!validate_registration_res.data.success) {
      //   alert('Link is invalid - registration id is not valid');
      //   await this.props.history.push(`/dashboard`);
      //   return;
      // }



      // /**************************************
      // * 1. Create another registration
      // ***************************************/
      

      // // Create registration id
      // const create_registration_res = await axios.post(`/api/util/extension/web-extension-connect-rotate`, {
      //   email_address: this.state.email_address,
      // });

      // if (!create_registration_res.data.success) {
      //   return;
      // }


      /**************************************
      * 3. Delete current preflight registration
      ***************************************/

      const delete_registration_res = await axios.delete(`/api/registrations/${web_connect_registration_id}`);

      if (!delete_registration_res.data.success) {
        alert('delete_registration_res failed');
        await this.props.history.push(`/dashboard`);
        return;
      } 


      /**************************************
      * 4. Final actions
      ***************************************/

      // Create registration id
      const create_registration_res = await auth_axios.post(`/api/util/extension/web-extension-connect-resolve`, {
        email_address: this.props.primary_email_address,
      });

      if (!create_registration_res.data.success) {
        return;
      }

      const resolve_registration_id = create_registration_res.data.registration_id;


      /**************************************
      * 5. Resolve flag set for content.js to pick up on (again, to be deleted by content.js)
      ***************************************/

      window.localStorage.setItem('resolve_registration_id', resolve_registration_id);



      /**************************************
      * 5. REMOVE
      ***************************************/

      await sleep(1);
      
      window.localStorage.removeItem('web_connect_registration_id');
      window.localStorage.removeItem('resolve_registration_id');

      /**************************************
      * 6. Final actions
      ***************************************/

      this.setState({
        awaiting_server_response: false
      }); 

    
    }
    catch (error) {
      console.error(error)
      this.setState({
        error_message: 'fatal error',
        fatal_error_occurred: true
      });
    }
  }

  render() {
    return (
      <div className='connect-extension__container'>
        {
          this.state.url_valid
          ? this.state.awaiting_server_response
            ? <div className="connect-extension__awaiting_response">
                <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              </div>
            : <div className="connect-extension__main">
                <CheckLogo 
                  className='connect-extension__copied-check'
                />
                <div className="connect-extension__text">
                  The browser extension was successfully paired with your web app session. Open the extension to finish logging in.
                </div>
                
              </div>
          : <div className='connect-extension__error-message'>Invalid URL</div>
        }
      </div>
    );
  }
}

export default ConnectExtension;
