import React from 'react';
import './RevokeAdminModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';

import { get as dbGet, set as dbSet } from 'idb-keyval';
import { CSPRNG, PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT, ASYM_DECRYPT } from '../../../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../../../crypto/util';

import {auth_axios} from '../../../../../auth/util';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


class RevokeAdminModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      request_sent: false,

      status_message: '',
      error_message: ''
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({        
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  reset_modal = () => {
    
  }


  on_revoke_admin = async () => {

    try {
      //
      this.setState({
        error_message: '',
        awaiting_server_response: true
      });

      /**************************************
      * 1. Pre-processing
      ***************************************/

      const to_revoke_admin_user_id = this.props.to_revoke_admin_user_id;

    

      /**************************************
      * 2. Make the remove admin API call
      ***************************************/

      const remove_admin_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/remove`, {
        to_revoke_admin_user_id: to_revoke_admin_user_id,
      });

      if (!remove_admin_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with remove admin'
        });
        return;
      }

      /**************************************
      * 6. Stop loading 
      ***************************************/

      this.setState({
        awaiting_server_response: false,
        request_sent: true,
        status_message: `Successfully removed ${this.props.user_id_display_name_maps[to_revoke_admin_user_id]} from admin.`,
      });

      /**************************************
      * 7. Mixpanel tracking
      ***************************************/

      mixpanel.track('web_revoke_admin_modal_submitted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });

      /**************************************
      * 9. Reload 
      ***************************************/
      window.location.reload(false);
    }




    catch (error) {
      console.log(error)
      this.setState({
        awaiting_server_response: false,
        error_message: 'Error occurred while making admin'
      });
    }
  }



  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_revoke_admin_modal_close();
            this.reset_modal();
          }
        }}
        style={{
          content: {
            height: 220,
            width: 340
          }
        }}
      >
        <div className="revoke-admin-modal__container">
          <div
            className='revoke-admin-modal__text' 
          >
            Confirm to revoke admin privilege from {this.props.user_id_display_name_maps[this.props.to_revoke_admin_user_id]}.
          </div>
          
          <div className="revoke-admin-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    (!this.state.request_sent)
                    ? <input
                        type='submit'
                        className={`revoke-admin-modal__update-button`}
                        value={ 'Revoke admin privilege' }
                        onClick={ this.on_revoke_admin }
                      />
                    : <input
                        type='submit'
                        className={`revoke-admin-modal__update-button disabled`}
                        value={ 'Revoke admin privilege' }
                      />
                  }
                  <a
                    className='revoke-admin-modal__cancel'
                    onClick={() => {
                      this.props.on_revoke_admin_modal_close();
                      this.reset_modal();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          {
            this.state.request_sent
            ? <span className='revoke-admin-modal__status-message'>{this.state.status_message}</span>
            : <></>
          }
          <span className='revoke-admin-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default RevokeAdminModal;
