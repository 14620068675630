import React from 'react';
import './Signup.css';

import axios from 'axios';
import ReactLoading from 'react-loading';


import { CSPRNG, PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, ASYM_ENCRYPT } from '../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { auth_axios } from '../../../auth/util';

import { set as dbSet, del as dbDel } from 'idb-keyval';

import { ReactComponent as AddToSlackBtn } from './media/svg/signup__add-to-slack.svg';
import { ReactComponent as InfoIcon } from './media/svg/signup__info-icon.svg';

import { ReactComponent as PlusLock } from '../../../logos/plusidentity-lock.svg';


import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


class Signup extends React.Component {

  constructor(props) {
    super(props);

    // Check if url is valid

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const query_params = query__string.split(/email_address=|&slack_team_id=|&slack_user_id=|&display_name=/).slice(1);

    const [query_email, query_slack_team_id, query_slack_user_id, query_display_name] = (query_params.length === 4) ? query_params : ['', '', '', ''];

    const [email_valid, email_address__string] = this.validate_email_address(query_email);

    const [slack_team_id_valid, slack_team_id__string] = this.validate_slack_team_id(query_slack_team_id);

    const [slack_user_id_valid, slack_user_id__string] = this.validate_slack_user_id(query_slack_user_id);

    this.state = {
      team_info_loading: true,
      team_name: '',
      team_name_holder: '',
      team_exists: false,
      display_name_holder: query_display_name,
      first_user: false,
      url_valid: (email_valid && slack_team_id_valid && slack_user_id_valid),
      email_address: email_address__string,
      slack_team_id: slack_team_id__string,
      slack_user_id: slack_user_id__string,
      master_password_holder: '',
      master_password_confirm_holder: '',
      master_password_valid: false,
      user_agreed: false,
      error_message: '',
      status_message: '',
      // email_address_holder: '',
      awaiting_server_response: false,
    }
  }

  validate_slack_team_id = (query_slack_team_id) => {
    return [true, query_slack_team_id];
  }

  validate_slack_user_id = (query_slack_user_id) => {
    return [true, query_slack_user_id];
  }

  validate_team_name = (team_name) => {
    // let contains_space = ((team_name.match(/\ /g) || []).length == 1);
    // let contains_upper = (/[A-Z]/.test(team_name));

    let valid = /^[a-z]+(-[a-z]+)*$/.test(team_name);
    return valid;
  }

  validate_email_address = (query_email) => {
    // NOT USING NOW
    // const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    if (!query_email) {
      return [false, ''];
    }

    let contains_one_at = (((query_email).match(/@/g) || []).length == 1);
    let one_dot_right = ((((query_email).split('@')[1] || '').match(/\./g) || []).length > 0);
  
    // TODO do more checking than just seeing if @ is contained...
    return [(contains_one_at && one_dot_right), query_email];
  }

  componentDidMount = async () => {
    // If not /signup (with no params), then fetch team info
    if (this.props.location.search.split('?').length !== 1) {
      this.setState({
        team_info_loading: true,
      });

      const slack_team_id_check_result = await axios.get(`/api/util/slack-teams/${this.state.slack_team_id}/team-name`);

      if (!slack_team_id_check_result.data.success) {
        // TO CHANGE SIGNIFICANTLY WHEN NON-SLACK INSTALLATION IS SUPPORTED
        this.setState({
          team_exists: false,
          first_user: true
        });
        // alert('Error: your team does not exist');
        await this.props.history.push(`/signup`);
        window.location.reload(false);
        return;
      }


      // Check if a user entry already exists with this email address
      const email_address_user_id_res = await axios.post(`/api/util/email-address/user-id/fetch`, {
        email_address: this.state.email_address
      });

      if (email_address_user_id_res.data.success && email_address_user_id_res.data.user_id) {
        alert(`Error: a user already exists with ${this.state.email_address}`);
        await this.props.history.push(`/login`);
      }

      
      this.setState({
        team_name: slack_team_id_check_result.data.team_name,
        team_exists: true,
        team_info_loading: false
      });
    }

    mixpanel.track('web_signup_visited', {
      is_dev: this.props.mode_info.is_dev
    });
  }

  // NOT USED RIGHT NOW
  handle_email_address_change = (e) => {
    this.setState({ email_address_holder: e.target.value });
  }

  handle_display_name_change = (e) => {
    this.setState({ display_name_holder: e.target.value });
  }

  handle_team_name_change = (e) => {
    this.setState({ team_name_holder: e.target.value });
  }

  handle_master_password_change = (e) => {
    const master_password_holder = e.target.value;
    const master_password_valid = ((master_password_holder !== '') && (master_password_holder === this.state.master_password_confirm_holder));

    this.setState({ 
      master_password_holder: master_password_holder,
      master_password_valid: master_password_valid
    });

  }

  handle_master_password_confirm_change = (e) => {
    const master_password_confirm_holder = e.target.value;
    const master_password_valid = ((master_password_confirm_holder !== '') && (master_password_confirm_holder === this.state.master_password_holder));
    
    this.setState({ 
      master_password_confirm_holder: master_password_confirm_holder,
      master_password_valid: master_password_valid
    });

  }

  handle_user_agreed = () => {
    this.setState(({
      user_agreed: !this.state.user_agreed
    }))
  }

  on_add_to_slack_click = () => {
    mixpanel.track('web_signup_add_to_slack_clicked', {
      is_dev: this.props.mode_info.is_dev
    });
  }

 
  on_submit = async () => {
    // Master password did not match and should be typed again
    if ((!this.state.master_password_valid) || (this.state.master_password_holder !== this.state.master_password_confirm_holder) || (this.state.master_password_holder === '')) {
      this.setState({
        master_password_holder: '',
        master_password_confirm_holder: '',
        error_message: 'Weird error, please contact channy@plusidentity.com'
      });
      return;
    }

    // THE ENTERED MASTER PASSWORD IS VALID!

    this.setState({
      awaiting_server_response: true,
      status_message: 'Securely creating your account . . .'
    });
    
    /**************************************
    * 1. Encryption
    ***************************************/

    // Encrypt 
    const master_password__unenc__string = this.state.master_password_holder;

    // Derive account_secret_key
    const master_password__unenc__uint8_array = string_to_uint8_array(master_password__unenc__string);

    const account_salt_1__unenc__uint8_array = CSPRNG(32);

    const account_secret_key__unenc__uint8_array = await PBKDF2(master_password__unenc__uint8_array, account_salt_1__unenc__uint8_array);

    // Derive account_unlock_key
    const account_salt_21__unenc__uint8_array = string_to_uint8_array(this.state.email_address);
    const account_salt_22__unenc__uint8_array = string_to_uint8_array(this.state.email_address.split('@')[0]);

    const intermediate_key__unenc__uint8_array = await HKDF(account_secret_key__unenc__uint8_array, account_salt_21__unenc__uint8_array, account_salt_22__unenc__uint8_array);

    const account_unlock_key__unenc__uint8_array = XOR(intermediate_key__unenc__uint8_array, master_password__unenc__uint8_array);

    // Generate account_private & account_public key pair
    const [account_private_key__unenc__uint8_array, account_public_key__unenc__uint8_array] = await GEN_KEY_PAIR();

    // Encrypt account_private_key with account_unlock_key
    const account_private_key__enc_auk__uint8_array = await SYM_ENCRYPT(account_unlock_key__unenc__uint8_array, account_private_key__unenc__uint8_array);
    
    


    /**************************************
    * 2. If installer, create team
    ***************************************/

    // Now check if this user is the installer or not
    const check_res = await axios.post(`/api/util/slack-teams/${this.state.slack_team_id}/installer/validate`, {
      slack_user_id: this.state.slack_user_id
    });

    let is_installer;
    if (!check_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Check if user is installer went wrong'
      });
      return;
    }
    is_installer = check_res.data.is_installer

    // This is the team installer
    let team_unlock_key__enc_apubk__base64, team_public_key__unenc__base64; // TODO logic flow for non-installer admin user


    if (is_installer) {
      // Create team asym and unlock keys 
      const team_unlock_key__unenc__uint8_array = CSPRNG(32);
      const team_unlock_key__enc_apubk__uint8_array = await ASYM_ENCRYPT(account_public_key__unenc__uint8_array, team_unlock_key__unenc__uint8_array);

      const [team_private_key__unenc__uint8_array, team_public_key__unenc__uint8_array] = await GEN_KEY_PAIR();

      const team_private_key__enc_tuk__uint8_array = await SYM_ENCRYPT(team_unlock_key__unenc__uint8_array, team_private_key__unenc__uint8_array);

      // Convert and save keys to client & server 
      team_unlock_key__enc_apubk__base64 = uint8_array_to_base64(team_unlock_key__enc_apubk__uint8_array); // Send to server persistence
      const team_private_key__enc_tuk__base64 = uint8_array_to_base64(team_private_key__enc_tuk__uint8_array) // Send to server persistence
      team_public_key__unenc__base64 = uint8_array_to_base64(team_public_key__unenc__uint8_array); // Send to client & server persistence

      // Trigger team creation (plus accompanying slack_workspace update)
      const create_team_res = await axios.post('/api/teams/create', {
        slack_team_id: this.state.slack_team_id,
        team_name: this.state.team_name,
        team_private_key__enc_tuk: team_private_key__enc_tuk__base64,
        team_public_key__unenc: team_public_key__unenc__base64,
        email_address: this.state.email_address
      });

      if (!create_team_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Create new team went wrong'
        });
        return;
      }

    }







    /**************************************
    * 3. Create user
    ***************************************/

    // SEND TO CLIENT & SERVER

    // Get server_public_key from server
    const get_res = await axios.get('/api/keys/server-public-key');
    if (!get_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Server public key get went wrong'
      });
      return;
    }

    const server_public_key__unenc__base64 = get_res.data.server_public_key__unenc; // Send to client persistence

    const server_public_key__unenc__uint8_array = base64_to_uint8_array(server_public_key__unenc__base64); 
    const account_salt_1__enc_spubk__uint8_array  = await ASYM_ENCRYPT(server_public_key__unenc__uint8_array, account_salt_1__unenc__uint8_array)

    const account_salt_1__enc_spubk__base64 = uint8_array_to_base64(account_salt_1__enc_spubk__uint8_array); // Send to server persistence

    const account_secret_key__unenc__base64 = uint8_array_to_base64(account_secret_key__unenc__uint8_array); // Send to client persistence

    const account_private_key__enc_auk__base64 = uint8_array_to_base64(account_private_key__enc_auk__uint8_array); // Send to client & server persistence

    const account_public_key__unenc__base64 = uint8_array_to_base64(account_public_key__unenc__uint8_array); // Send to client & server persistence

    

    // const email_address__unenc__uint8_array = string_to_uint8_array(this.state.email_address);
    // const email_address__enc_spubk__uint8_array = await ASYM_ENCRYPT(server_public_key__unenc__uint8_array, email_address__unenc__uint8_array)
    // const email_address__enc_spubk__base64 = uint8_array_to_base64(email_address__enc_spubk__uint8_array); // Send to client persistence

    // CLIENT PERSISTENCE

    // Delete first for safety
    await dbDel('server_public_key__unenc');
    await dbDel('account_secret_key__unenc');
    await dbDel('account_private_key__enc_auk');
    await dbDel('account_public_key__unenc');

    // Set
    await dbSet('server_public_key__unenc', server_public_key__unenc__base64);
    await dbSet('account_secret_key__unenc', account_secret_key__unenc__base64);
    await dbSet('account_private_key__enc_auk', account_private_key__enc_auk__base64);
    await dbSet('account_public_key__unenc', account_public_key__unenc__base64);


    // Get team_id from server using slack_team_id
    const team_id_res = await axios.get(`/api/util/slack-teams/${this.state.slack_team_id}/team-id`);
    if (!team_id_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Server team_id get went wrong'
      });
      return;
    }
    const team_id = team_id_res.data.team_id;


    // If not installer, then we need to fetch the team_public_key
    if (!is_installer) {
      const get_team_public_key_res = await axios.get(`/api/teams/${team_id}/team-public-key`);

      if (!get_team_public_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Get team public key went wrong'
        });
        return;
      }

      team_public_key__unenc__base64 = get_team_public_key_res.data.team_public_key__unenc;
    }

    // Delete first for safety
    await dbDel('team_public_keys');

    // Set
    await dbSet('team_public_keys', {[team_id]: team_public_key__unenc__base64});
    





    const display_name = this.state.display_name_holder;

    // SERVER PERSISTENCE
    // Now trigger account creation (plus accompanying team & slack_workspace update)
    const create_user_res = await axios.post('/api/users/create', {
      slack_team_id: this.state.slack_team_id,
      slack_user_id: this.state.slack_user_id,
      email_address: this.state.email_address,
      display_name: display_name,
      team_id: team_id,
      account_private_key__enc_auk: account_private_key__enc_auk__base64,
      account_public_key__unenc: account_public_key__unenc__base64,
      account_salt_1__enc_spubk: account_salt_1__enc_spubk__base64,
      team_unlock_key__enc_apubk: team_unlock_key__enc_apubk__base64,
      is_installer: is_installer,
    });

    if (!create_user_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with create user command'
      });
      return;
    }

    

    // // Mixpanel - create new user

    // mixpanel.people.set(user_id, {
    //   $name: display_name,
    //   $created: (new Date()).toISOString(),
    //   $email: this.state.email_address,
    //   team_id: team_id,
    //   is_dev: this.props.mode_info.is_dev
    // });



    
    /**************************************
    * 4. Create new session
    ***************************************/

    // Create session details

    const user_id = create_user_res.data.user_id;
    const device_id = create_user_res.data.device_id;

    const create_session_res = await axios.post('/api/sessions/create', {
      user_id: user_id,
      device_id: device_id,
      type: 'web'
    });

    if (!create_session_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with create session command'
      });
      return;
    } 

    // Save session id and key in client persistence
    const session_token__unenc__base64 = create_session_res.data.session_token__unenc;
    const session_key__unenc__base64 = create_session_res.data.session_key__unenc;

    const session_key__unenc__uint8_array = base64_to_uint8_array(session_key__unenc__base64);
    const account_private_key__enc_sk__uint8_array = await SYM_ENCRYPT(session_key__unenc__uint8_array, account_private_key__unenc__uint8_array);

    const account_private_key__enc_sk__base64 = uint8_array_to_base64(account_private_key__enc_sk__uint8_array);


    // Delete first for safety
    await dbDel('session_token__unenc');
    await dbDel('account_private_key__enc_sk');

    // Set
    await dbSet('session_token__unenc', session_token__unenc__base64);
    await dbSet('account_private_key__enc_sk', account_private_key__enc_sk__base64);



    /**************************************
    * 5. Register new device
    ***************************************/

    const register_device_res = await axios.post(`/api/devices/${device_id}`, {
      user_id: user_id    
    });

    if (!register_device_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with register device command'
      });
      return;
    } 



    /**************************************
    * 6. Generate device private public key pair
    ***************************************/

    // Generate device key pair
          
    const [device_private_key__unenc__uint8_array, device_public_key__unenc__uint8_array] = await GEN_KEY_PAIR();


    const device_private_key__unenc__base64 = uint8_array_to_base64(device_private_key__unenc__uint8_array); // Send to client persistence
    const device_public_key__unenc__base64 = uint8_array_to_base64(device_public_key__unenc__uint8_array); // Send to client & server persistence


    // Delete first for safety
    await dbDel('device_private_key__unenc');
    await dbDel('device_public_key__unenc');

    // Set
    await dbSet('device_private_key__unenc', device_private_key__unenc__base64);
    await dbSet('device_public_key__unenc', device_public_key__unenc__base64);

    // Create device_keychain 
    const device_keychain = {
      device_public_key__unenc: device_public_key__unenc__base64,
      item_keys: {}, // since user was just created, no item is present
      // registered_at: new Date()
    }

    // Send to client
    const insert_device_keychain_res = await axios.put(`/api/users/${user_id}/device-keychain`, {
      device_id: device_id,  
      device_keychain: device_keychain
    });

    if (!insert_device_keychain_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with insert device keychain'
      });
      return;
    }


    /**************************************
    * 7. Trigger post-setup Slack server actions
    ***************************************/
    try {
      // Send POST request to slack.plusidentity.com server to indicate successful 

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const params = new URLSearchParams()

      params.append('slack_team_id', this.state.slack_team_id)
      params.append('slack_user_id', this.state.slack_user_id)
      params.append('is_installer', is_installer)
      params.append('email_address', this.state.email_address)

      const post_trigger_res = await axios.post(`${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/setup-complete`, params, config);

      // TODO Because of CORS, don't do error checking for now...
      // if (!post_trigger_res.data.success) {
      //   this.setState({
      //     awaiting_server_response: false,
      //     error_message: 'Something went wrong with post slack server trigger'
      //   });
      //   return;
      // }

    }
    catch {
      console.error('CORS configurations not yet set up properly')
    }


    /**************************************
    * 8. Alert deep link back to Slack NOTE: Currently, this disappears upon refresh (no. 10)
    ***************************************/

    // Slack app plusidentity-dev : A02AZNB3KUM
    // Slack app plusidentity : A02Q9A55M46

    // Now alert deep link back to the messages tab of the app
    let meta = document.createElement('meta');
    meta.httpEquiv = 'refresh';
    meta.content = this.props.mode_info.is_dev ? `0; URL=slack://app?team=${this.state.slack_team_id}&id=A02AZNB3KUM&tab=messages` : `0; URL=slack://app?team=${this.state.slack_team_id}&id=A02Q9A55M46&tab=messages`
    document.head.appendChild(meta);



    /**************************************
    * 9. Mixpanel tracking code
    ***************************************/

    if (is_installer) {
      mixpanel.track('web_installer_signup_submitted', {
        distinct_id: user_id,
        team_id: team_id,
        is_dev: this.props.mode_info.is_dev
      });
    }

    else {
      mixpanel.track('web_signup_submitted', {
        distinct_id: user_id,
        team_id: team_id,
        is_dev: this.props.mode_info.is_dev
      });
    }



    /**************************************
    * 10. Trigger Installer Zap for Salesforce record creation
    ***************************************/

    try {
      let url;
      if (is_installer) {
        url = 'https://hooks.zapier.com/hooks/catch/10692832/b97sppm'; // Installer Zapier hook
      } else {
        url = 'https://hooks.zapier.com/hooks/catch/10692832/b5ugl0e'; // User Zapier hook
      }

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    
      const params = new URLSearchParams()
    
      params.append('team_name', this.state.team_name)
      params.append('display_name', display_name)
      params.append(`${is_installer ? 'installer_email_address' : 'user_email_address'}`, this.state.email_address);
    
      const zapier_res = await axios.post(url, params, config);
    }
  
    catch (error) {
      console.log(error);
    }

    /**************************************
    * 11. Redirect to dashboard immediately!
    ***************************************/

    // Redirect and reload
    //await this.props.history.push(`/dashboard`);
    window.location.reload(false);



  







    
  }

  // NOT USED RIGHT NOW
  on_continue = async () => {

    const email_address__base64 = uint8_array_to_base64(string_to_uint8_array(this.state.email_address_holder));
    await this.props.history.push(`/signup?${email_address__base64}`);

    const query__base64 = this.props.location.search.split('?')[1] || '';
    const [url_valid, email_address__string] = this.validate_email_address(query__base64);

    if (url_valid) {
      // Check with server whether the email exists already or not

      // TOGGLE DEV
      // const email_check_result = await axios.post('/api/auth/email-check', {
      //   email_address: email_address__string
      // });
      const email_check_result = {data: {status: 'EMAIL_DNE'}}
      // TOGGLE END

    
      // Proceed with sign up
      if (email_check_result.data.status === 'EMAIL_DNE') {
        this.setState({
          url_valid: url_valid,
          email_address: email_address__string,
          error_message: ''
        });
      }

      // Email exists, redirect to login
      else if (email_check_result.data.status === 'EMAIL_EXISTS') {
        await this.props.history.push(`/login?${email_address__base64}`);
      }

      // Server error occurred, reset and reload signup
      else if (email_check_result.data.status === 'SERVER_ERROR') {
        await this.props.history.push(`/signup`);
        this.setState({
          url_valid: url_valid,
          email_address: '',
          error_message: 'Server error ocurred (email check)... Please try again later'
        });
      }

      else {
        // some other scenario
      }
    }
    // Email is not valid
    else {
      this.setState({
        url_valid: url_valid,
        email_address: '',
        error_message: 'Please check your email address'
      });
    }
  }

  // NOT USED RIGHT NOW
  set_team_name = async () => {

    const typed_team_name = this.state.team_name_holder;
    const [team_name_valid, team_name__string] = this.validate_team_name(typed_team_name);

    if (team_name_valid) {
      // Check with server whether the email exists already or not

      // TOGGLE DEV
      // const team_name_check_result = await axios.post('/api/auth/team-name-check', {
      //   team_name: team_name__string
      // });
      const team_name_check_result = {status: 'TEAM_NAME_DNE'} 
      // TOGGLE END

      // Proceed with sign up
      if (team_name_check_result.data.status === 'TEAM_NAME_DNE') {
        this.setState({
          team_name: team_name__string,
          team_exists: true,
          error_message: ''
        });
      }

      // Team name exists, have them pick a different team name
      else if (team_name_check_result.data.status === 'TEAM_NAME_EXISTS') {
        this.setState({
          error_message: 'Please select a different team name'
        });
      }

      // Server error occurred, reset and reload signup
      else if (team_name_check_result.data.status === 'SERVER_ERROR') {
        await this.props.history.push(`/signup`);
        this.setState({
          team_name: '',
          error_message: 'Server error ocurred (team name check)... Please try again later'
        });
      }

      else {
        // some other scenario
      }
    }
    // Team name is not valid
    else {
      this.setState({
        team_name: '',
        error_message: 'Please make sure every letter is lowercase and contains no empty space'
      });
    }
  }

  // NOT USED RIGHT NOW
  on_back = async () => {
    this.setState({
      team_exists: false
    });

    // BEFORE CODE
    // await this.props.history.push(`/signup`);
    // this.setState({
    //   url_valid: false,
    //   email_address: '',
    //   error_message: ''
    // });
  }

  render() {
    return (
      <div className="signup__container">
        <div className="signup__top">
          <span className="signup__title">S I G N &nbsp;&nbsp;&nbsp; U P</span>
          <div className="signup__plus-lock"><PlusLock/></div>
        </div>
        <div className="signup__bottom">
          {/* {
            this.state.first_user && this.state.team_exists
            ? <>
                <span className="signup__back-button" onClick={ this.on_back }>{'< '}Back</span>
              </>
            : <></>
          } */}
          {
            this.state.url_valid
            ? this.state.team_info_loading 
              ? <div className='signup__bottom-loading-container'>
                  <ReactLoading
                    type='spokes'
                    color='#9696ad'
                    height={20}
                    width={20}
                  />
                </div>
              : <div className='signup__bottom-signup-container'>
                  <div className='signup__bottom-entry'>
                    <div className="signup__data-type">Team</div>
                    <div
                      className='signup__bottom-input-box'
                    >
                      <input 
                        className="signup__bottom-input-field disabled"
                        type="text"
                        value={ this.state.team_name }
                        disabled
                      />
                    </div>
                  </div> 
                  <div className="signup__bottom-entry">
                    <div className="signup__data-type">Email</div>
                    <div
                      className='signup__bottom-input-box'
                    >
                      <input 
                        className="signup__bottom-input-field disabled"
                        type="text"
                        value={ this.state.email_address }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="signup__bottom-entry">
                    <div className="signup__data-type">Set your display name</div>
                    <div
                      className='signup__bottom-input-box'
                    >
                      <input 
                        className="signup__bottom-input-field"
                        type="text"
                        value={ this.state.display_name_holder }
                        onChange={ this.handle_display_name_change }
                        autoComplete="new-password"
                      />
                    </div>
                  </div>


                  


                  <div className="signup__bottom-entry">
                    <div className="signup__data-type">
                      Set your master password
                      <div className='signup__info-container'>
                        <InfoIcon 
                          className='signup__info-icon'
                        />
                        <div className='signup__info-description'>
                          Do not lose your master password. PlusIdentity never has access to your master password or any of its derivatives, so if you lose your master password, we may not be able to recover your account.
                        </div>
                      </div>
                    </div>

                    <div
                      className='signup__bottom-input-box password'
                    >
                      <input 
                        className="signup__bottom-input-field"
                        type={this.state.master_password_holder ? "password" : "text"}
                        disabled={this.state.awaiting_server_response}
                        placeholder="Master password"
                        value={ this.state.master_password_holder }
                        onChange={ this.handle_master_password_change }
                        autoComplete="new-password"
                      />
                    </div>
                    <div
                      className='signup__bottom-input-box confirm'
                    >
                      <input 
                        className="signup__bottom-input-field"
                        type={this.state.master_password_confirm_holder ? "password" : "text"} 
                        disabled={this.state.awaiting_server_response}
                        placeholder="Confirm master password"
                        value={ this.state.master_password_confirm_holder }
                        onChange={ this.handle_master_password_confirm_change }
                        autoComplete="new-password"
                      />
                    </div>
                  </div>


                  
                  <div className="signup__bottom-send-link-action">
                    {
                      this.state.awaiting_server_response
                      ? <ReactLoading
                          type='spokes'
                          color='#9696ad'
                          height={20}
                          width={20}
                        />
                      : this.state.master_password_valid
                        ? <input
                            className='signup__bottom-send-link-button '
                            type="submit"
                            value={ this.first_user ? "Create team & account" : "Create account"}
                            onClick={ this.on_submit }
                          />
                        : <input
                            className='signup__bottom-send-link-button disabled'
                            type="submit"
                            value={ this.first_user ? "Create team & account" : "Create account"}
                          />
                    }
                  </div>
                  <div className="signup__status-message">{ this.state.status_message }</div>
                </div>
            : <> 
                {this.state.user_agreed
                  ? <a 
                      href="https://slack.com/oauth/v2/authorize?scope=chat%3Awrite%2Ccommands%2Cusers%3Aread%2Cusers%3Aread.email&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnN0YWxsT3B0aW9ucyI6eyJzY29wZXMiOlsiY2hhdDp3cml0ZSIsImNvbW1hbmRzIiwidXNlcnM6cmVhZCIsInVzZXJzOnJlYWQuZW1haWwiXX0sIm5vdyI6IjIwMjItMDItMjVUMDQ6MzQ6MTAuMDQ4WiIsImlhdCI6MTY0NTc2MzY1MH0.0xLH5NO_boEmzTWynYHSTQPF9j_adOC-rYb2iyWvMQ8&client_id=1513829906626.2825345191142"
                      target="_blank"
                      onClick={this.on_add_to_slack_click}
                    >
                      <AddToSlackBtn className="signup__install-button" />
                    </a>
                  : <a>
                      <AddToSlackBtn className="signup__install-button disabled" />
                    </a>
                }
                <div
                  className='signup__user-agreed'
                  onClick={this.handle_user_agreed}
                >
                  <input
                    type='checkbox'
                    checked={this.state.user_agreed}
                    value={this.state.user_agreed}
                    onChange={this.handle_user_agreed}
                    disabled={this.state.awaiting_server_response}
                    className='signup__user-agreed-checkbox'
                  />
                  <div
                    className='signup__user-agreed-text'
                  >
                    I am installing as a Primary Owner / Workspace Admin user on my Slack workspace, and I agree to PlusIdentity's&nbsp;<a href='https://www.plusidentity.com/terms' target='_blank'>Terms of Service</a>&nbsp;and&nbsp;<a href='https://www.plusidentity.com/privacy' target='_blank'>Privacy Policy</a>.
                  </div>
                </div> 
              </>
          }
          <span className="signup__error-message">{ this.state.error_message }</span>
        </div>
      </div>
    );
  }
}

export default Signup;
