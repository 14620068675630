import './App.css';
import React from 'react';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import axios from 'axios';


import { get as dbGet, set as dbSet, del as dbDel } from 'idb-keyval';
import { base64_to_uint8_array, uint8_array_to_base64 } from './crypto/util';


// NAVBAR
import Navbar from './components/Navbar';

// FOOTER
import Footer from './components/Footer';


// PAGES
// From left nav

// From right nav
import Login from './components/pages/LoginPage/Login';
import Signup from './components/pages/SignupPage/Signup';
import RegisterDevice from './components/pages/RegisterDevicePage/RegisterDevice';

import ForgotPassword from './components/pages/ForgotPasswordPage/ForgotPassword';
import RecoverAccount from './components/pages/RecoverAccountPage/RecoverAccount';

import LinkView from './components/pages/LinkViewPage/LinkView';

import ConnectAccount from './components/pages/ConnectAccountPage/ConnectAccount';
import ConnectTeam from './components/pages/ConnectTeamPage/ConnectTeam';

import ConnectExtension from './components/pages/ConnectExtensionPage/ConnectExtension';
import ExtensionAutologin from './components/pages/ExtensionAutologinPage/ExtensionAutologin';

import Dashboard from './components/pages/DashboardPage/Dashboard';
import Settings from './components/pages/DashboardPage/SettingsPage/Settings'

import Clipboard from './components/pages/ClipboardPage/Clipboard';
import AddEmailAddress from './components/pages/AddEmailAddressPage/AddEmailAddress'


// Loading screen
import Loading from './components/animations/Loading';
import VaultOpen from './components/animations/VaultOpen';


// Admin only
import InitiateAdminRecovery from './components/pages/InitiateAdminRecoveryPage/InitiateAdminRecovery';



// Authentication functions
import { enforce_https, on_load, auth_axios } from './auth/util';
import ProtectedRoute from './auth/ProtectedRoute';
import UnprotectedRoute from './auth/UnprotectedRoute';



// Talk to us
import TalkToUsModal from './components/etc/TalkToUsModal';




// Mixpanel 
import mixpanel from 'mixpanel-browser';
import { ASYM_DECRYPT, ASYM_ENCRYPT } from './crypto/crypto';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 






/*****************************************
* PRODUCTION / DEVELOPMENT TOGGLE
*****************************************/
const IS_DEV = false;
const SLACK_DEV_URL = 'https://2aa8-115-136-123-51.ngrok.io'
// const SLACK_DEV_URL = 'https://slack-dev.plusidentity.com'
const MODE_INFO = {is_dev: IS_DEV, slack_dev_url: SLACK_DEV_URL}
/*****************************************
* END OF TOGGLE
*****************************************/











class App extends React.Component {

  constructor(props) {
    super(props);

    // Enforce HTTPS
    // * This is already enforced through network configuration and server settings (more reliable), but is enforced again here client side nonetheless for extra protection
    // TODO: figure out whether it'd be better to just not render
    if (!IS_DEV) enforce_https();

    this.state = {
      awaiting_server_response: true,
      loading: true,
      session_status: '',
      keychain: {},
      user_id: '',
      device_id: '',
      selected_team_id: '',

      // account_private_key__unenc__uint8_array: '',
      // account_public_key__unenc__uint8_array: '',
      // server_public_key__unenc__uint8_array: '',
      // team_public_keys: [],

      email_addresses: [],
      team_ids: [],
      team_statuses: {},
      item_keys: {},

      team_id_team_name_maps: {},
      user_id_display_name_maps: {},

      selected_team_user_ids: [],
      selected_team_item_ids: [],
      selected_team_admin_user_ids: [],

      talk_to_us_modal_open: false,

      screen_width: window.innerWidth,
      screen_height: window.innerHeight,
    }
  }

  componentDidMount = async () => {
    // Screen size listener
    window.addEventListener('resize', this.update_window_dimensions);

    // If this is a share link open, don't do the stuff below
    const current_url = window.location.href;
    const share_link_regex = `\^${IS_DEV ? 'http:\\/\\/localhost:3000' : 'https:\\/\\/app\\.plusidentity\\.com'}\\/l\\/\.\*\$`;
    const share_link_pattern = new RegExp(share_link_regex);
    if (share_link_pattern.test(current_url)) {
      console.log('This is a share link!');
      this.setState({
        awaiting_server_response: false,
      });
      return;
    }

    // Upon initial contact
    let [session_status, keychain, user_id, device_id] = await on_load();
    
    let cached_team_data_exists;

    if (session_status === 'SESSION_VALID') {
      const get_user_data_res = await auth_axios.get(`/api/users/${user_id}/data`);

      if (!get_user_data_res) {
        // TODO display error message
        return;
      }
  
      const email_addresses = get_user_data_res.data.email_addresses;
      const display_name = get_user_data_res.data.display_name;
      const preference_settings = get_user_data_res.data.preference_settings;
      const item_keys_device_keychains_synced = get_user_data_res.data.item_keys_device_keychains_synced;
      
      let team_ids = get_user_data_res.data.team_ids;
      let team_statuses = get_user_data_res.data.team_statuses;
      let item_keys = get_user_data_res.data.item_keys;

      let team_id_team_name_maps, selected_team_id, selected_team_user_ids, selected_team_item_ids, user_id_display_name_maps, selected_team_admin_user_ids

      if (team_ids.length >= 1) {
        // Get team_name data for each team
        const sync_teams_res = await auth_axios.post('/api/util/sync-teams/fetch', {
          team_ids: team_ids,
          team_public_keys: keychain.team_public_keys
        });
    
        if (!sync_teams_res) {
          // TODO display error message
          return;
        }
    
        team_id_team_name_maps = sync_teams_res.data.team_id_team_name_maps;
        const team_public_keys_synced = sync_teams_res.data.team_public_keys_synced;
        const team_item_keys_synced = sync_teams_res.data.team_item_keys_synced;

        // Automatically pick the team from cache (or the first team if no cache)
        const session_selected_team_id = await dbGet('session_selected_team_id');
        if (session_selected_team_id && team_ids.includes(session_selected_team_id)) {
          selected_team_id = session_selected_team_id;
        } else {
          selected_team_id = team_ids[0];
          await dbSet('session_selected_team_id', selected_team_id);
        }
        
        let get_team_data_res;

        const cached_data = await dbGet('cached_data');

        if (cached_data && cached_data[selected_team_id] && cached_data[selected_team_id].team_data) {
          cached_team_data_exists = true;

          get_team_data_res = {};
          get_team_data_res.data = cached_data[selected_team_id].team_data;
        }
        else {
          cached_team_data_exists = false;

          get_team_data_res = await auth_axios.post(`/api/teams/${selected_team_id}/data/fetch`, {
            user_id: user_id
          });

          if (!get_team_data_res.data.success) {
            // TODO display error message
            return;
          } 

          // Set cached data
          let new_cached_data = {
            ...cached_data,
            [selected_team_id]: {
              ...(cached_data ? cached_data[selected_team_id] : {}),
              team_data: get_team_data_res.data
            }
          };

          await dbSet('cached_data', new_cached_data);
        }
    
        selected_team_user_ids = get_team_data_res.data.user_ids;
        selected_team_item_ids = get_team_data_res.data.item_ids;
        selected_team_admin_user_ids = get_team_data_res.data.admin_user_ids

        user_id_display_name_maps = get_team_data_res.data.user_id_display_name_maps; 

        // **************
        // CHECK THE FOLLOWING for solo_admin account recovery case in which the admin's device did not have cryptographic access to an item and so:
        // 1. team_public_key might have changed (need to refresh current session so that current session's keychain has the correct team_public_keys)
        // 2. team entry's item_keys may be missing some keys to items (from the item_ids list)
        // CHECK FOR WEIRD CASE IN WHICH 
        // 3. user entry's item_keys (master) is not synced with device_keychains
        // **************

        if (!team_public_keys_synced) {
          console.log('!team_public_keys_synced')

          const get_team_public_keys_res = await axios.get(`/api/users/${user_id}/team-public-keys`);

          if (!get_team_public_keys_res.data.success) {
            // return;
          }

          const team_public_keys = get_team_public_keys_res.data.team_public_keys;

          // Delete first for safety
          await dbDel('team_public_keys');
          // Set
          await dbSet('team_public_keys', team_public_keys);

          // Refresh the page so that keychain is updated
          window.location.reload(false);
        }

        if (!team_item_keys_synced) {
          console.log('!team_item_keys_synced')

          const get_team_public_keys_res = await axios.get(`/api/users/${user_id}/team-public-keys`);

          if (!get_team_public_keys_res.data.success) {
            // return;
          }

          const team_public_keys = get_team_public_keys_res.data.team_public_keys;

          for (const team_id of team_ids) {
            // ***
            // Check for missing item_keys 
            // ***
            const get_item_keys_populate_order_res = await auth_axios.get(`/api/teams/${team_id}/item-keys/populate-order`);
  
            if (!get_item_keys_populate_order_res.data.success) {
              // TODO display error message
              continue;
            }
  
            const to_populate_item_ids = get_item_keys_populate_order_res.data.to_populate_item_ids

            const team_public_key__unenc__base64 = team_public_keys[team_id];
  
            // There are missing items (admin recovery not available)
            if (to_populate_item_ids.length > 0) {
              let encrypt_item_keys = {};
              for (const to_populate_item_id of to_populate_item_ids) {
                if (to_populate_item_id in item_keys) {
  
                  const item_key__enc_apubk__base64 = item_keys[to_populate_item_id];
                  const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);
  
                  const item_key__unenc__uint8_array = await ASYM_DECRYPT(keychain.account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array)
  
                  const team_public_key__unenc__uint8_array = base64_to_uint8_array(team_public_key__unenc__base64)
  
                  const item_key__enc_tpubk__uint8_array = await ASYM_ENCRYPT(team_public_key__unenc__uint8_array, item_key__unenc__uint8_array);
  
                  const item_key__enc_tpubk__base64 = uint8_array_to_base64(item_key__enc_tpubk__uint8_array)
  
                  encrypt_item_keys[to_populate_item_id] = item_key__enc_tpubk__base64;
                }
              }
  
              if (Object.keys(encrypt_item_keys).length > 0) {
                const item_keys_populate_res = await auth_axios.post(`/api/teams/${team_id}/item-keys/populate`, {
                  encrypt_item_keys: encrypt_item_keys
                });
  
                if (!item_keys_populate_res.data.success) {
                  // TODO display error message
                  continue;
                }
              }
            }
          }
        }

        if (!item_keys_device_keychains_synced) {
          console.log('!item_keys_device_keychains_synced');

          // Fetch device keychains from server
          const get_device_public_keys_res = await axios.get(`/api/users/${user_id}/device-public-keys`);

          if (!get_device_public_keys_res.data.success) {
            this.setState({
              awaiting_server_response: false,
              error_message: 'Something went wrong with get device public keys'
            });
          }

          const device_public_keys = get_device_public_keys_res.data.device_public_keys

          for (const device_id in device_public_keys) {
            const device_public_key__unenc__base64 = device_public_keys[device_id];

            const device_public_key__unenc__uint8_array = base64_to_uint8_array(device_public_key__unenc__base64);

            let item_keys__enc_dpubk = {}

            for (const item_id in item_keys) {
              const item_key__enc_apubk__base64 = item_keys[item_id];
              const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);
      
              const item_key__unenc__uint8_array = await ASYM_DECRYPT(keychain.account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array);
      
              const item_key__enc_dpubk__uint8_array = await ASYM_ENCRYPT(device_public_key__unenc__uint8_array, item_key__unenc__uint8_array);
      
              const item_key__enc_dpubk__base64 = uint8_array_to_base64(item_key__enc_dpubk__uint8_array)
      
              item_keys__enc_dpubk[item_id] = item_key__enc_dpubk__base64;
            }
      
            // Create device_keychain
            const device_keychain = {
              device_public_key__unenc: device_public_key__unenc__base64,
              item_keys: item_keys__enc_dpubk,
              // registered_at: new Date()
            }
      
            // Send to client
            const insert_device_keychain_res = await axios.put(`/api/users/${user_id}/device-keychain`, {
              device_id: device_id,  
              device_keychain: device_keychain
            });
      
            if (!insert_device_keychain_res.data.success) {
              this.setState({
                awaiting_server_response: false,
                error_message: 'Something went wrong with insert device keychain'
              });
              continue;
            }
          }
        }
      }




      // Create an no team simulation team
      else if (team_ids.length < 1) {

        team_ids = ['pT_NO_TEAM'];
        team_statuses = {
          'pT_NO_TEAM': 'active' // TODO not sure if this is correct
        }
        selected_team_id = 'pT_NO_TEAM';

        selected_team_user_ids = [user_id];
        selected_team_item_ids = [];
        selected_team_admin_user_ids = [];

        team_id_team_name_maps = {
          'pT_NO_TEAM': '-'
        }

        user_id_display_name_maps = {
          [user_id]: display_name
        }
      }
    
      this.setState({
        email_addresses: email_addresses,
        preference_settings: preference_settings,
        team_ids: team_ids,
        team_statuses: team_statuses,
        item_keys: item_keys,
        selected_team_id: selected_team_id,
        selected_team_user_ids: selected_team_user_ids,
        selected_team_item_ids: selected_team_item_ids,
        selected_team_admin_user_ids: selected_team_admin_user_ids,
        team_id_team_name_maps: team_id_team_name_maps,
        user_id_display_name_maps: user_id_display_name_maps,
      });

    }

    // TODO what is this case?

    this.setState({
      awaiting_server_response: false,
      session_status: session_status,
      keychain: keychain,
      user_id: user_id,
      device_id: device_id
    });


    // If cached team data was used for this mount, update the dashboard
    if (cached_team_data_exists) {
      await this.update_dashboard_items_app();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update_window_dimensions)
  }

  update_window_dimensions = () => {
    this.setState({ 
      screen_width: window.innerWidth,
      screen_height: window.innerHeight
    });
  }

  select_team = async (team_id) => {
    
    const get_team_data_res = await auth_axios.post(`/api/teams/${team_id}/data/fetch`, {
      user_id: this.state.user_id
    });

    if (!get_team_data_res.data.success) {
      // TODO display error message
      if (get_team_data_res.data.destroy_session) {
        console.log('destroy session')
      }
      return;
    } 

    const selected_team_user_ids = get_team_data_res.data.user_ids;
    const selected_team_item_ids = get_team_data_res.data.item_ids;
    const user_id_display_name_maps = get_team_data_res.data.user_id_display_name_maps;  

    this.setState({
      selected_team_id: team_id,
      selected_team_user_ids: selected_team_user_ids,
      selected_team_item_ids: selected_team_item_ids,
      user_id_display_name_maps: user_id_display_name_maps
    });

    await dbSet('session_selected_team_id', team_id);
  }

  update_dashboard_items_app = async () => {
    console.log('update_dashboard_items_app')
    const get_user_data_res = await auth_axios.get(`/api/users/${this.state.user_id}/data`);

    const email_addresses = get_user_data_res.data.email_addresses;
    // const display_name = get_user_data_res.data.display_name;
    const preference_settings = get_user_data_res.data.preference_settings;
    // const item_keys_device_keychains_synced = get_user_data_res.data.item_keys_device_keychains_synced;
    const team_ids = get_user_data_res.data.team_ids;
    const team_statuses = get_user_data_res.data.team_statuses;
    const item_keys = get_user_data_res.data.item_keys;

    const get_team_data_res = await auth_axios.post(`/api/teams/${this.state.selected_team_id}/data/fetch`, {
      user_id: this.state.user_id
    });

    if (!get_team_data_res.data.success) {
      // TODO display error message
      if (get_team_data_res.data.destroy_session) {
        console.log('destroy session')
      }
      return;
    } 

    const selected_team_user_ids = get_team_data_res.data.user_ids;
    const selected_team_item_ids = get_team_data_res.data.item_ids;
    const selected_team_admin_user_ids = get_team_data_res.data.admin_user_ids
    const user_id_display_name_maps = get_team_data_res.data.user_id_display_name_maps; 

    this.setState({
      // get user res
      email_addresses: email_addresses,
      preference_settings: preference_settings,
      team_ids: team_ids,
      team_statuses: team_statuses,
      item_keys: item_keys,
      // get team res
      selected_team_user_ids: selected_team_user_ids,
      selected_team_item_ids: selected_team_item_ids,
      selected_team_admin_user_ids: selected_team_admin_user_ids,
      user_id_display_name_maps: user_id_display_name_maps,
    });

    // Update cached_data
    const cached_data = await dbGet('cached_data');
    let new_cached_data = {
      ...cached_data,
      [this.state.selected_team_id]: {
        ...(cached_data ? cached_data[this.state.selected_team_id] : {}),
        team_data: get_team_data_res.data
      }
    };
    await dbSet('cached_data', new_cached_data);
  }

  on_talk_to_us_modal_open = () => {
    this.setState({
      talk_to_us_modal_open: true
    });

    // Mixpanel web_talk_to_us_modal_opened
    mixpanel.track('web_talk_to_us_modal_opened', {
      distinct_id: this.state.user_id,
      team_id: this.state.selected_team_id,
      is_dev: MODE_INFO.is_dev
    });
  }

  on_talk_to_us_modal_close = () => {
    this.setState({
      talk_to_us_modal_open: false
    });
  }

  set_loading = () => {
    this.setState({
      loading: false
    })
  }

  render() { 
    return this.state.awaiting_server_response 
      ? <Loading/>
      : <>
          {
            ((this.state.loading) && (this.state.session_status === 'SESSION_VALID'))
            ? <VaultOpen
                set_loading={this.set_loading}
              />
            : <></>
          }
          <Router>
            <Navbar 
              session_status={this.state.session_status}  
              user_id={this.state.user_id} 
              selected_team_id={this.state.selected_team_id}
              team_ids={this.state.team_ids}
              team_id_team_name_maps={this.state.team_id_team_name_maps}
              user_id_display_name_maps={this.state.user_id_display_name_maps}
              select_team={this.select_team}

              screen_width={this.state.screen_width}
              screen_height={this.state.screen_height}

              mode_info={MODE_INFO}
            />
            <Switch>

              <Route exact path='/'>
                <Redirect to='/login' />
              </Route>

              <UnprotectedRoute 
                path='/login' 
                exact 
                component={Login}
                session_status={this.state.session_status} 

                device_id={this.state.device_id}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <UnprotectedRoute 
                path='/signup' 
                exact 
                component={Signup} 
                session_status={this.state.session_status} 

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}
      
                mode_info={MODE_INFO}
              />

              <UnprotectedRoute 
                path='/register-device' 
                exact 
                component={RegisterDevice} 
                session_status={this.state.session_status}
                
                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <UnprotectedRoute 
                path='/forgot-password' 
                exact 
                component={ForgotPassword} 
                session_status={this.state.session_status} 

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <UnprotectedRoute 
                path='/recover-account' 
                exact 
                component={RecoverAccount} 
                session_status={this.state.session_status} 

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <Route 
                path='/l/:link_id' 
                exact
              >
                <LinkView
                  session_status={this.state.session_status} 

                  device_id={this.state.device_id}
  
                  screen_width={this.state.screen_width}
                  screen_height={this.state.screen_height}
  
                  mode_info={MODE_INFO}
                />
              </Route>

              <ProtectedRoute 
                path='/connect-account'  
                exact
                component={ConnectAccount} 
                session_status={this.state.session_status} 

                keychain={this.state.keychain} 
                
                user_id={this.state.user_id}
                user_id_display_name_maps={this.state.user_id_display_name_maps}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <ProtectedRoute 
                path='/connect-team'  
                exact
                component={ConnectTeam} 
                session_status={this.state.session_status} 

                keychain={this.state.keychain} 
                
                user_id={this.state.user_id}
                user_id_display_name_maps={this.state.user_id_display_name_maps}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <ProtectedRoute 
                path='/connect-extension'  
                exact
                component={ConnectExtension} 
                session_status={this.state.session_status} 

                keychain={this.state.keychain} 
                
                user_id={this.state.user_id}
                user_id_display_name_maps={this.state.user_id_display_name_maps}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                primary_email_address={this.state.email_addresses[0]}

                mode_info={MODE_INFO}
              />

              <ProtectedRoute 
                path='/extension-autologin'  
                exact
                component={ExtensionAutologin} 
                session_status={this.state.session_status} 

                keychain={this.state.keychain} 

                user_id={this.state.user_id}
                
                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <ProtectedRoute 
                path='/initiate-admin-recovery'  
                exact
                component={InitiateAdminRecovery} 
                session_status={this.state.session_status} 

                keychain={this.state.keychain} 
                
                user_id={this.state.user_id}
                user_id_display_name_maps={this.state.user_id_display_name_maps}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <ProtectedRoute 
                path='/dashboard'  
                exact
                component={Dashboard} 
                session_status={this.state.session_status} 

                keychain={this.state.keychain} 
                
                user_id={this.state.user_id}

                selected_team_id={this.state.selected_team_id}
                selected_team_status={this.state.team_statuses[this.state.selected_team_id]}
                selected_team_name={this.state.team_id_team_name_maps[this.state.selected_team_id]}

                team_id_team_name_maps={this.state.team_id_team_name_maps}
                selected_team_user_ids={this.state.selected_team_user_ids}
                user_id_display_name_maps={this.state.user_id_display_name_maps}
                selected_team_item_ids={this.state.selected_team_item_ids}
                item_keys={this.state.item_keys}

                on_talk_to_us_modal_open={this.on_talk_to_us_modal_open}
                on_talk_to_us_modal_close={this.on_talk_to_us_modal_close}

                update_dashboard_items_app={this.update_dashboard_items_app}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />

              <ProtectedRoute 
                path='/dashboard/settings' 
                // <- no exact since there is switch
                component={Settings} 
                session_status={this.state.session_status} 

                keychain={this.state.keychain} 
                
                user_id={this.state.user_id}
                user_id_display_name_maps={this.state.user_id_display_name_maps}

                email_addresses={this.state.email_addresses}
                preference_settings={this.state.preference_settings}

                selected_team_id={this.state.selected_team_id}
                selected_team_status={this.state.team_statuses[this.state.selected_team_id]}
                selected_team_name={this.state.team_id_team_name_maps[this.state.selected_team_id]}
                selected_team_user_ids={this.state.selected_team_user_ids}
                selected_team_admin_user_ids={this.state.selected_team_admin_user_ids}

                item_keys={this.state.item_keys}

                on_talk_to_us_modal_open={this.on_talk_to_us_modal_open}
                on_talk_to_us_modal_close={this.on_talk_to_us_modal_close}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}
                
                mode_info={MODE_INFO}
              />

              <ProtectedRoute 
                path='/clipboard' 
                exact 
                component={Clipboard} 
                session_status={this.state.session_status} 

                keychain={this.state.keychain} 
                
                user_id={this.state.user_id}
                selected_team_id={this.state.selected_team_id}
                item_keys={this.state.item_keys}

                update_dashboard_items_app={this.update_dashboard_items_app}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}
                
                mode_info={MODE_INFO}
              />

              <ProtectedRoute 
                path='/add-email-address'  
                exact
                component={AddEmailAddress} 
                session_status={this.state.session_status} 
                
                user_id={this.state.user_id}
                selected_team_id={this.state.selected_team_id}

                screen_width={this.state.screen_width}
                screen_height={this.state.screen_height}

                mode_info={MODE_INFO}
              />


            </Switch>
            {/* <Footer /> */}
          </Router>

          <TalkToUsModal
            is_open={this.state.talk_to_us_modal_open}
            on_talk_to_us_modal_close={this.on_talk_to_us_modal_close}

            user_id={this.state.user_id}
            selected_team_id={this.state.selected_team_id}

            screen_width={this.state.screen_width}
            screen_height={this.state.screen_height}

            mode_info={MODE_INFO}
          />

        </>
  }
}

export default App;
