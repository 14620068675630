import React from 'react';
import './Dashboard.css';

import axios from 'axios';
import ReactLoading from 'react-loading';
import { FaTimes } from 'react-icons/fa';
import Collapsible from 'react-collapsible';

import { get as dbGet, set as dbSet, del as dbDel } from 'idb-keyval';

import { base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { auth_axios } from '../../../auth/util';

import PreAddItemModal from './PreAddItemModal';

import AddItemModal from './AddItemModal';
import DeleteItemModal from './DeleteItemModal';
import EditItemModal from './EditItemModal';

import SharingLinksModal from './SharingLinksModal';
import ViewHistoryModal from './ViewHistoryModal';

import DashboardLoadingModal from './DashboardLoadingModal';

import AcceptAccessModal from './AcceptAccessModal';
import DeclineAccessModal from './DeclineAccessModal';
import RequestAccessModal from './RequestAccessModal';
import CancelRequestModal from './CancelRequestModal';

import FileViewerModal from './FileViewerModal'

import Field from './Field';



import { ReactComponent as SquareLogoWhite } from '../../../logos/logo-square-white.svg';
import { ReactComponent as SquareLogoBlack } from '../../../logos/logo-square-black.svg';

import { ReactComponent as TalkToUsLogo } from './media/svg/talk-to-us.svg';

import { ReactComponent as TypeIconDefault } from './media/svg/type-icon-default.svg';
import { ReactComponent as PermissionIconDefault } from './media/svg/permission-icon-default.svg';
import { ReactComponent as OwnerIconDefault } from './media/svg/owner-icon-default.svg';
import { ReactComponent as TagIconDefault } from './media/svg/tag-icon-default.svg';

import { ReactComponent as TypeIconNondefault } from './media/svg/type-icon-nondefault.svg';
import { ReactComponent as PermissionIconNondefault } from './media/svg/permission-icon-nondefault.svg';
import { ReactComponent as OwnerIconNondefault } from './media/svg/owner-icon-nondefault.svg';
import { ReactComponent as TagIconNondefault } from './media/svg/tag-icon-nondefault.svg';

import { ReactComponent as AccountIconConfig } from './media/svg/account-icon-config-white.svg';
import { ReactComponent as AccountIconNonconfig } from './media/svg/account-icon-nonconfig-white.svg';
import { ReactComponent as FileIcon } from './media/svg/file-icon-white.svg'; 
import { ReactComponent as CustomIcon } from './media/svg/custom-icon-white.svg'; 

import { ReactComponent as PreviewIcon } from './media/svg/preview-icon.svg'; 
import { ReactComponent as DownloadIcon } from './media/svg/download-icon.svg'; 

import { ReactComponent as PublicIconBlack } from './media/svg/public-icon-black.svg'; 
import { ReactComponent as PublicIconWhite } from './media/svg/public-icon-white.svg'; 
import { ReactComponent as PrivateIconBlack } from './media/svg/private-icon-black.svg'; 
import { ReactComponent as PrivateIconWhite } from './media/svg/private-icon-white.svg'; 


import { ReactComponent as FiltersOpenIcon } from './media/svg/filters-open-icon.svg';
import { ReactComponent as FiltersClosedIcon } from './media/svg/filters-closed-icon.svg';

import { ReactComponent as CheckboxIconUnselected } from './media/svg/checkbox-icon-unselected.svg';
import { ReactComponent as CheckboxIconSelected } from './media/svg/checkbox-icon-selected.svg';

import { ReactComponent as ExpandIconWhite } from './media/svg/expand-icon-white.svg';
import { ReactComponent as CollapseIconWhite } from './media/svg/collapse-icon-white.svg';
import { ReactComponent as ExpandIconGray } from './media/svg/expand-icon-gray.svg';
import { ReactComponent as CollapseIconGray } from './media/svg/collapse-icon-gray.svg';

import { ReactComponent as ExpandIconMinWidthWhite } from './media/svg/expand-icon-min-width-white.svg';

// Mixpanel 
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




const compare_items_alphabet = (a, b) => {
  const name_a = a.item_name.toUpperCase(); // ignore upper and lowercase
  const name_b = b.item_name.toUpperCase(); // ignore upper and lowercase
  if (name_a < name_b) {
    return -1;
  }
  if (name_a > name_b) {
    return 1;
  }
  return 0;
}

const compare_items_permission = (before_item, after_item) => {
  const order_map = {
    'owner': 1,
    'read_write': 2,
    'read_only': 3,
    'one_time': 4,
    '': 5
  }

  const before_item_order = order_map[before_item.permission] || 5;
  const after_item_order = order_map[after_item.permission] || 5;

  return before_item_order - after_item_order;
}



const beautify_field_name = (field_name) => {
  const mapping = {
    'email_address': 'Email address',
    'password': 'Password',
    'username': 'Username',
    'phone_number': 'Phone number',
    'notes': 'Additional notes',

    // config specific fields
    'amplitude:org_url': 'Org URL',
    'awsiam:account_id': 'AWS account ID',
    'godaddy:customer_number': 'Customer number',
    'metabase:org_url': 'Org URL',
    'slack:org_url': 'Org URL',
  }

  if (field_name.startsWith('custom:')) {
    return field_name.slice(7);
  }

  else if (mapping[field_name]) {
    return mapping[field_name];
  }

  else {
    return field_name;
  }
}


const beautify_permission = (permission) => {
  const mapping = {
    'owner': 'Item owner',
    'read_write': 'Edit-access',
    'read_only': 'Read-only',
    'one_time': 'One-time read-only',
  }

  if (mapping[permission]) {
    return mapping[permission];
  }

  else {
    return permission;
  }
}




class Dashboard extends React.Component {
  
  constructor(props) {
    super(props);


    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const url_params = new URLSearchParams(`?${query__string}`);

    const add_item_modal_open = (url_params.get('modal_type') === 'add_item'); // false if no valid param
    const edit_item_modal_open = (url_params.get('modal_type') === 'edit_item'); // false if no valid param

    const accept_access_modal_open = (url_params.get('modal_type') === 'accept_access'); // false if no valid param

    let selected_item_id = '';
    let access_request_user_id = '';
    let edit_secret = false;
    let selected_secret_id = '';
    if (edit_item_modal_open || accept_access_modal_open) {
      selected_item_id = url_params.get('item_id'); // undefined if no valid param

      if (edit_item_modal_open) {
        edit_secret = (url_params.get('edit_secret') === 'true');

        if (edit_secret) {
          selected_item_id = '';
          selected_secret_id = url_params.get('item_id');
        }
      }

      if (accept_access_modal_open) {
        access_request_user_id = url_params.get('access_request_user_id'); // undefined if no valid param
      }
    }


    // Build up owner_filters_holder
    let owner_filters_holder = {}
    for (const selected_team_user_id of props.selected_team_user_ids) {
      owner_filters_holder[selected_team_user_id] = true;
    }


    this.state = {
      awaiting_server_response: true,
      no_team: false,

      fatal_error_occurred: false,

      error_message: '',

      account_private_key__unenc__uint8_array: props.keychain.account_private_key__unenc__uint8_array,

      account_public_key__unenc__uint8_array: props.keychain.account_public_key__unenc__uint8_array,

      server_public_key__unenc__uint8_array: props.keychain.server_public_key__unenc__uint8_array,

      team_public_keys: props.keychain.team_public_keys,


      ordered_unprotected_items_info: [],
      filtered_ordered_unprotected_items_info: [],

      unprotected_items_info: {},


      pre_add_item_modal_open: add_item_modal_open,

      add_item_modal_open: false,
      add_item_modal_prefill_type: '', // account / file / custom
      add_item_modal_prefill_config: '',
      add_item_modal_prefill_files: [], 

      delete_item_modal_open: false,
      edit_item_modal_open: edit_item_modal_open,

      edit_secret: edit_secret,
      selected_secret_id: selected_secret_id,
      fetched_secret_data: undefined,

      sharing_links_modal_open: false,
      view_history_modal_open: false, 

      access_request_user_id: access_request_user_id, // TODO
      accept_access_modal_open: accept_access_modal_open, // TODO
      decline_access_modal_open: false, // TODO
      request_access_modal_open: false,
      cancel_request_modal_open: false,

      file_viewer_modal_open: false, // true, // false
      loading_modal_open: false,

      // preview_file_metadata: { // {},
      //   file_id: 'pF292892619607',
      //   name: 'test.txt',
      //   mimetype: 'text/plain', // 'image/png' // 'application/pdf' // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      // },

      preview_file_metadata: {},

      selected_item_id: selected_item_id,

      dashboard_pull_upon_item_change: false,

      file_dragging: false,

      add_button_logo_is_white: true,
      left_bar_toggle_is_gray: false,
      public_icon_is_black: true,
      private_icon_is_black: true,

      left_bar_open: false,

      type_filters_open: true,
      permission_filters_open: false,
      owner_filters_open: false,
      tag_filters_open: false,

      all_filters_are_default: true,
      type_filters_are_default: true,
      permission_filters_are_default: true,
      owner_filters_are_default: true,
      tag_filters_are_default: true,
      visibility_filters_are_default: true,
      
      search_holder: '',
      keywords_holder: [],

      type_filters_holder: {
        account: true,
        file: true,
        custom: true,
      },
      permission_filters_holder: {
        owner: true,
        read_write: true,
        read_only: true,
        one_time: true,
        not_shared: true
      },
      owner_filters_holder: owner_filters_holder,
      tag_filters_holder: {}, // To be populated during componentDidMount

      visibility_filters_holder: {
        public: true,
        private: true
      },

      loading_modal_progress_message: ''
    }
  }
  

  componentDidMount = async () => {
    try {
      // Deal with no team situation
      if (this.props.selected_team_id === 'pT_NO_TEAM') {
        this.setState({
          awaiting_server_response: false,
          no_team: true
        })
        return;
      }

      let get_unprotected_items_info_res, cached_unordered_unprotected_items_info_exists;

      const cached_data = await dbGet('cached_data');

      if (cached_data && cached_data[this.props.selected_team_id] && cached_data[this.props.selected_team_id].unprotected_items) {
        cached_unordered_unprotected_items_info_exists = true;

        get_unprotected_items_info_res = {};
        get_unprotected_items_info_res.data = cached_data[this.props.selected_team_id].unprotected_items;
      }
      else {
        cached_unordered_unprotected_items_info_exists = false;

        get_unprotected_items_info_res = await auth_axios.post('/api/items/unprotected-items/fetch', {
          item_ids: this.props.selected_team_item_ids,
          user_id: this.props.user_id
        });

        // Fetching data was unsuccessful
        if (!get_unprotected_items_info_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'unprotected-items-fetch failed'
          });
          return;
        }

        // Set cached data
        let new_cached_data = {
          ...cached_data,
          [this.props.selected_team_id]: {
            ...(cached_data ? cached_data[this.props.selected_team_id] : {}),
            unprotected_items: get_unprotected_items_info_res.data
          }
        };

        await dbSet('cached_data', new_cached_data);
      }

      

      // Fetching data was successful

      // Now sort such that accounts that the user owns comes first, followed by accounts that the user has full access to        
      let ordered_unprotected_items_info = get_unprotected_items_info_res.data.unordered_unprotected_items_info

      // Sort the array in place
      ordered_unprotected_items_info.sort(compare_items_alphabet);// sort by name alphabetical
      ordered_unprotected_items_info.sort(compare_items_permission); // sort by permission

      let unprotected_items_info = {}
      for await (const item of ordered_unprotected_items_info) {
        unprotected_items_info[item.item_id] = item;
      }

      this.setState({
        ordered_unprotected_items_info: ordered_unprotected_items_info,
        unprotected_items_info: unprotected_items_info,

        awaiting_server_response: false
      }, () => {
        const filtered_ordered_unprotected_items_info = this.filter_items();

        this.setState({
          filtered_ordered_unprotected_items_info: filtered_ordered_unprotected_items_info,
        })
      });
      
     




      // If this is a secret editing link, individually pull secret
      if (this.state.edit_secret) {
        // Fetch the secret individually
        const get_secret_res = await auth_axios.post(`/api/items/${this.state.selected_secret_id}/secret/fetch`, {
          user_id: this.props.user_id
        });

        // Fetching data was unsuccessful
        if (!get_secret_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'get-secret failed'
          });
          
          alert('You do not have the permission to save this secret permanently')
          return;
        }

        const fetched_secret_data = get_secret_res.data.secret_data;

        // Set selected_item_id back to nothing
        this.setState({
          fetched_secret_data: fetched_secret_data
        })
      }



      // Listeners for drag and drop
      this.drag_counter = 0;
      let dashboard_main = document.getElementById('dashboard__main');

      if (dashboard_main) { // in case dashboard_main was not found
        dashboard_main.addEventListener('dragenter', this.handle_file_drag_enter);
        dashboard_main.addEventListener('dragleave', this.handle_file_drag_leave);
        dashboard_main.addEventListener('dragover', this.handle_file_drag_over);
        dashboard_main.addEventListener('drop', this.handle_file_drop);
      }

      mixpanel.track('web_dashboard_visited', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });


      // If cached team data was used for this mount, update the dashboard
      if (cached_unordered_unprotected_items_info_exists) {
        await this.update_dashboard_items();
      }
    }
    catch (error) {
      console.log(error)
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    try {
      // Whenever the team changes
      if (this.props.selected_team_id !== prevProps.selected_team_id) {
        console.log('UPDATING')

        // Awaiting
        this.setState({
          awaiting_server_response: true
        });

        // Fetch item info for selected_team_item_ids 
        const get_unprotected_items_info_res = await auth_axios.post('/api/items/unprotected-items/fetch', {
          item_ids: this.props.selected_team_item_ids,
          user_id: this.props.user_id
        });
    
        // Fetching data was unsuccessful
        if (!get_unprotected_items_info_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'unprotected-items-fetch failed'
          });
          return;
        } 

        // Now sort such that accounts that the user owns comes first, followed by accounts that the user has full access to        
        let ordered_unprotected_items_info = get_unprotected_items_info_res.data.unordered_unprotected_items_info

        // Sort the array in place
        ordered_unprotected_items_info.sort(compare_items_alphabet);// sort by name alphabetical
        ordered_unprotected_items_info.sort(compare_items_permission); // sort by permission

        let unprotected_items_info = {}
        for await (const item of ordered_unprotected_items_info) {
          unprotected_items_info[item.item_id] = item;
        }

        // Build up owner_filters_holder
        let owner_filters_holder = {}
        for (const selected_team_user_id of this.props.selected_team_user_ids) {
          owner_filters_holder[selected_team_user_id] = true;
        }

        this.setState({
          ordered_unprotected_items_info: ordered_unprotected_items_info,
          unprotected_items_info: unprotected_items_info,

          selected_item_id: '',

          // Reset search & filters related states
          
          left_bar_open: false,

          type_filters_open: true,
          permission_filters_open: false,
          owner_filters_open: false,
          tag_filters_open: false,

          all_filters_are_default: true,
          type_filters_are_default: true,
          permission_filters_are_default: true,
          owner_filters_are_default: true,
          tag_filters_are_default: true,
          visibility_filters_are_default: true,

          search_holder: '',
          keywords_holder: [],

          type_filters_holder: {
            account: true,
            file: true,
            custom: true,
          },
          permission_filters_holder: {
            owner: true, 
            read_write: true, 
            read_only: true, 
            one_time: true, 
            not_shared: true
          },
          owner_filters_holder: owner_filters_holder,
          tag_filters_holder: {}, // TODO
          visibility_filters_holder: {
            public: true,
            private: true
          },

          loading_modal_progress_message: '',

          awaiting_server_response: false
        }, () => {
          const filtered_ordered_unprotected_items_info = this.filter_items();

          this.setState({
            filtered_ordered_unprotected_items_info: filtered_ordered_unprotected_items_info,
          })
        });


        // Update cached_data
        const cached_data = await dbGet('cached_data');
        let new_cached_data = {
          ...cached_data,
          [this.props.selected_team_id]: {
            ...(cached_data ? cached_data[this.props.selected_team_id] : {}),
            unprotected_items: get_unprotected_items_info_res.data
          }
        };
        await dbSet('cached_data', new_cached_data);
        
      }
    }
    catch (error) {
      console.log(error);
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  update_dashboard_items = async () => {

    // Update item_keys and team_item_ids
    await this.props.update_dashboard_items_app();

    // Fetch item info for selected_team_item_ids 
    const get_unprotected_items_info_res = await auth_axios.post('/api/items/unprotected-items/fetch', {
      item_ids: this.props.selected_team_item_ids,
      user_id: this.props.user_id
    });

    // Fetching data was unsuccessful
    if (!get_unprotected_items_info_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'unprotected-items-fetch failed'
      });
      return;
    } 

    // Now sort such that accounts that the user owns comes first, followed by accounts that the user has full access to        
    let ordered_unprotected_items_info = get_unprotected_items_info_res.data.unordered_unprotected_items_info

    // Sort the array in place
    ordered_unprotected_items_info.sort(compare_items_alphabet);// sort by name alphabetical
    ordered_unprotected_items_info.sort(compare_items_permission); // sort by permission

    let unprotected_items_info = {}
    for await (const item of ordered_unprotected_items_info) {
      unprotected_items_info[item.item_id] = item;
    }

    this.setState({
      ordered_unprotected_items_info: ordered_unprotected_items_info,
      unprotected_items_info: unprotected_items_info,

      awaiting_server_response: false
    }, () => {
      const filtered_ordered_unprotected_items_info = this.filter_items();

      this.setState({
        filtered_ordered_unprotected_items_info: filtered_ordered_unprotected_items_info,
      })
    });

    // Update cached_data
    const cached_data = await dbGet('cached_data');
    let new_cached_data = {
      ...cached_data,
      [this.props.selected_team_id]: {
        ...(cached_data ? cached_data[this.props.selected_team_id] : {}),
        unprotected_items: get_unprotected_items_info_res.data
      }
    };
    await dbSet('cached_data', new_cached_data);
  }

  handle_file_drag_enter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.drag_counter++;

    if (e.dataTransfer.items && e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].kind === 'file') { // only allow one file upload file for now // > 0) { 
      this.setState({
        file_dragging: true,
      })
    }
  }

  handle_file_drag_leave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.drag_counter--;
    if (this.drag_counter > 0) return;

    this.setState({
      file_dragging: false,
    })
  }

  handle_file_drag_over = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handle_file_drop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      file_dragging: false,
      add_item_modal_open: true,
      add_item_modal_prefill_type: 'file',
      add_item_modal_prefill_files: e.dataTransfer.files,
    })
  }






  handle_add_item_modal_prefill_type_change = (add_item_modal_prefill_type) => {
    this.setState({
      add_item_modal_prefill_type: add_item_modal_prefill_type
    });
  }

  handle_add_item_modal_prefill_config_change = (add_item_modal_prefill_config) => {
    this.setState({
      add_item_modal_prefill_config: add_item_modal_prefill_config
    });
  }

  handle_add_item_modal_prefill_files_change = (add_item_modal_prefill_files) => {
    this.setState({
      add_item_modal_prefill_files: add_item_modal_prefill_files
    });
  }




  on_pre_add_item_modal_open = () => {
    this.setState({
      pre_add_item_modal_open: true
    });

    // Mixpanel web_pre_add_item_modal_opened
    mixpanel.track('web_pre_add_item_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_pre_add_item_modal_close = async () => {
    this.setState({
      pre_add_item_modal_open: false
    });

    await this.props.history.push(`/dashboard`);
  }



  on_add_item_modal_open = () => {
    this.setState({
      add_item_modal_open: true
    });

    // Mixpanel web_add_item_modal_opened
    mixpanel.track('web_add_item_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_add_item_modal_close = async () => {
    this.setState({
      add_item_modal_open: false
    });

    await this.props.history.push(`/dashboard`);
  }

  on_delete_item_modal_open = () => {
    this.setState({
      delete_item_modal_open: true
    });

    // Mixpanel web_delete_item_modal_opened
    mixpanel.track('web_delete_item_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_delete_item_modal_close = async (post_action) => {
    if (post_action) {
      this.setState({
        delete_item_modal_open: false,
        selected_item_id: ''
      });
    }
    else {
      this.setState({
        delete_item_modal_open: false
      });
    }
    
    await this.props.history.push(`/dashboard`);
  }

  on_edit_item_modal_open = () => {
    this.setState({
      edit_item_modal_open: true
    });

    // Mixpanel web_edit_item_modal_opened
    mixpanel.track('web_edit_item_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_edit_item_modal_close = async (post_action) => {
    if (post_action) {
      this.setState({
        edit_item_modal_open: false,
        edit_secret: false,
        selected_secret_id: '',
        fetched_secret_data: undefined,
        selected_item_id: '',
      });
    }
    else {
      this.setState({
        edit_item_modal_open: false,
        edit_secret: false,
        selected_secret_id: '',
        fetched_secret_data: undefined
      });
    }
    
    await this.props.history.push(`/dashboard`);
  }

  on_sharing_links_modal_open = () => {
    this.setState({
      sharing_links_modal_open: true
    });

    // Mixpanel web_sharing_links_modal_opened
    mixpanel.track('web_sharing_links_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_sharing_links_modal_close = async () => { // (post_action) => {
    // if (post_action) {
    //   this.setState({
    //     sharing_links_modal_open: false,
    //     selected_item_id: ''
    //   });
    // }
    // else {
    this.setState({
      sharing_links_modal_open: false
    });
    // }
    
    await this.props.history.push(`/dashboard`);
  }

  // on_loading_modal_open = () => {
  //   this.setState({
  //     loading_modal_open: true
  //   });
  // }

  // on_loading_modal_close = () => {
  //   this.setState({
  //     loading_modal_open: false
  //   });
  // }

  on_view_history_modal_open = () => {
    this.setState({
      view_history_modal_open: true
    });

    // Mixpanel web_view_history_modal_opened
    mixpanel.track('web_view_history_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_view_history_modal_close = async () => { // (post_action) => {
    // if (post_action) {
    //   this.setState({
    //     sharing_links_modal_open: false,
    //     selected_item_id: ''
    //   });
    // }
    // else {
    this.setState({
      view_history_modal_open: false
    });
    // }
    
    await this.props.history.push(`/dashboard`);
  }

  on_accept_access_modal_open = (access_request_user_id) => {
    this.setState({
      accept_access_modal_open: true,
      access_request_user_id: access_request_user_id
    });

    // Mixpanel web_accept_access_modal_opened
    mixpanel.track('web_accept_access_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_accept_access_modal_close = async (post_action) => {
    if (post_action) {
      this.setState({
        accept_access_modal_open: false,
        access_request_user_id: '',
        selected_item_id: ''
      });
    }
    else {
      this.setState({
        accept_access_modal_open: false,
        access_request_user_id: '',
      });
    }

    await this.props.history.push(`/dashboard`);
  }

  on_decline_access_modal_open = (access_request_user_id) => {
    this.setState({
      decline_access_modal_open: true,
      access_request_user_id: access_request_user_id
    });

    // Mixpanel web_decline_access_modal_opened
    mixpanel.track('web_decline_access_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_decline_access_modal_close = async (post_action) => {
    if (post_action) {
      this.setState({
        decline_access_modal_open: false,
        access_request_user_id: '',
        selected_item_id: ''
      });
    }
    else {
      this.setState({
        decline_access_modal_open: false,
        access_request_user_id: '',
      });
    }

    await this.props.history.push(`/dashboard`);
  }

  on_request_access_modal_open = () => {
    this.setState({
      request_access_modal_open: true,
    });

    // Mixpanel web_request_access_modal_opened
    mixpanel.track('web_request_access_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_request_access_modal_close = async (post_action) => {
    if (post_action) {
      this.setState({
        request_access_modal_open: false,
        selected_item_id: ''
      });
    }
    else {
      this.setState({
        request_access_modal_open: false,
      });
    }

    await this.props.history.push(`/dashboard`);
  }

  on_cancel_request_modal_open = () => {
    this.setState({
      cancel_request_modal_open: true,
    });

    // Mixpanel web_cancel_request_modal_opened
    mixpanel.track('web_cancel_request_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_cancel_request_modal_close = async (post_action) => {
    if (post_action) {
      this.setState({
        cancel_request_modal_open: false,
        selected_item_id: ''
      });
    }
    else {
      this.setState({
        cancel_request_modal_open: false,
      });
    }

    await this.props.history.push(`/dashboard`);
  }

  on_file_viewer_modal_open = () => {
    this.setState({
      file_viewer_modal_open: true,
    });

    // // Mixpanel web_file_viewer_modal_opened
    // mixpanel.track('web_file_viewer_modal_opened', {
    //   distinct_id: this.props.user_id,
    //   team_id: this.props.selected_team_id,
    //   is_dev: this.props.mode_info.is_dev
    // });
  }

  on_file_viewer_modal_close = async (post_action) => {
    if (post_action) {
      this.setState({
        file_viewer_modal_open: false,
        preview_file_metadata: {},
        selected_item_id: ''
      });
    }
    else {
      this.setState({
        file_viewer_modal_open: false,
        preview_file_metadata: {},
      });
    }

    await this.props.history.push(`/dashboard`);
  } 

  handle_item_click = async (e) => {
    const clicked_item_id = e.target.getAttribute("data-item_id");

    if (clicked_item_id === this.state.selected_item_id) {
      this.setState({
        selected_item_id: '',
      });
    }
    else {
      this.setState({
        selected_item_id: clicked_item_id,
      });
    }


    // dashboard_pull_upon_item_change
    if (this.state.dashboard_pull_upon_item_change) {
      this.setState({
        dashboard_pull_upon_item_change: false,
      }, () => {
        this.update_dashboard_items()
      });
    }

    
  };

  handle_dashboard_pull_upon_item_change = (is_pull) => {
    this.setState({
      dashboard_pull_upon_item_change: is_pull,
    });
  }

  clear_item_selection = () => {
    this.setState({
      selected_item_id: '',
      // preview_file_metadata: {}
    })
  }

  clear_dashboard_prefills = () => {
    this.setState({
      add_item_modal_prefill_type: '',
      add_item_modal_prefill_config: '',
      add_item_modal_prefill_files: [],
    })
  }

  filter_items = () => {

    const search_filter = this.state.keywords_holder;

    const type_filters_holder = this.state.type_filters_holder;
    const permission_filters_holder = this.state.permission_filters_holder;
    const owner_filters_holder = this.state.owner_filters_holder;
    const tag_filters_holder = this.state.tag_filters_holder;

    const visibility_filters_holder = this.state.visibility_filters_holder;



    let filtered_ordered_unprotected_items_info = [...this.state.ordered_unprotected_items_info];



    /**************************************
    * 1. Search words (space-separated)
    ***************************************/

    if (search_filter.length > 0) {
      for (const keyword of search_filter) {

        // Error resiliency
        // console.log('keyword', keyword);
        if (!keyword) {
          console.log('Error with search bar: keyword', keyword)
          continue;
        }

        const keyword_lower = keyword.toLowerCase();
        
        filtered_ordered_unprotected_items_info = filtered_ordered_unprotected_items_info.filter((item) => {
          
          // a. Go through metafields
          const meta_fields_to_consider = ['item_name', 'owner_display_name'];

          item = {
            ...item,
            owner_display_name: this.props.user_id_display_name_maps[item.owner_user_id]
          }
    
          for (const meta_field_to_consider of meta_fields_to_consider) {

            // Error resiliency
            // console.log('item[meta_field_to_consider]', item[meta_field_to_consider]);
            if (!item[meta_field_to_consider]) {
              console.log('Error with search bar: item[meta_field_to_consider]', item[meta_field_to_consider])
              continue;
            }

            const meta_field_value_lower = item[meta_field_to_consider].toLowerCase();
    
            if (meta_field_value_lower.includes(keyword_lower)) {
              return true;
            }
          }

          // b. Go thorugh unprotected data
    
          for (const display_data_item of item.display_data__unenc) {
            const [field_name, field_value] = display_data_item;
    
            // Error resiliency
            if (!field_value) {
              if (field_name === 'notes') {
                continue;
              }
              // console.log('display_data_item', display_data_item)
              console.log('Error with search bar: field_value', field_value)
              continue;
            }

            const field_value_lower = field_value.toLowerCase();
    
            if (field_value_lower.includes(keyword_lower)) {
              return true;
            }
          }

          // c. Go through files_metadata and look at file_name
          for (const file_metadata of item.files_metadata) {
            const file_name = file_metadata.file_name;
    
            // Error resiliency
            if (!file_name) {
              console.log('Error with search bar: file_name', file_name)
              continue;
            }

            const file_name_lower = file_name.toLowerCase();
    
            if (file_name_lower.includes(keyword_lower)) {
              return true;
            }
          }
          
    
          return false;
        })
      }
    }

    /**************************************
    * 2. Type filters
    ***************************************/

    let selected_type_filters = [];
    for (const type_type in type_filters_holder) {
      if (type_filters_holder[type_type]) {
        selected_type_filters.push(type_type)
      }
    }
    
    filtered_ordered_unprotected_items_info = filtered_ordered_unprotected_items_info.filter((item) => {
      for (const selected_type_filter of selected_type_filters) {
        if (selected_type_filter === item.item_type) {
          return true;
        }
      }
      return false;
    });

    /**************************************
    * 3. Permission filters
    ***************************************/

    let selected_permission_filters = [];
    for (const permission_type in permission_filters_holder) {
      if (permission_filters_holder[permission_type]) {
        selected_permission_filters.push(permission_type)
      }
    }
    
    filtered_ordered_unprotected_items_info = filtered_ordered_unprotected_items_info.filter((item) => {
      for (const selected_permission_filter of selected_permission_filters) {
        if (selected_permission_filter === item.permission) {
          return true;
        } 
        else if (selected_permission_filter === 'not_shared') {
          if ((item.permission !== 'owner') && (item.permission !== 'read_write') && (item.permission !== 'read_only') && (item.permission !== 'one_time')) { // here, list all permission that would not be considered not_shared
            return true;
          }
        }
      }
      return false;
    });

    /**************************************
    * 4. Owner filters
    ***************************************/

    let selected_owner_filters = [];
    for (const owner_user_id in owner_filters_holder) {
      if (owner_filters_holder[owner_user_id]) {
        selected_owner_filters.push(owner_user_id)
      }
    }
    
    filtered_ordered_unprotected_items_info = filtered_ordered_unprotected_items_info.filter((item) => {
      for (const selected_owner_filter of selected_owner_filters) {
        if (selected_owner_filter === item.owner_user_id) {
          return true;
        }
      }
      return false;
    });

    /**************************************
    * 5. Tag filters
    ***************************************/


    /**************************************
    * 6. Visibility filters
    ***************************************/

    filtered_ordered_unprotected_items_info = filtered_ordered_unprotected_items_info.filter((item) => {
      if (visibility_filters_holder[item.visibility]) {
        return true;
      } else {
        return false;
      }
    })

    return filtered_ordered_unprotected_items_info
  }

  handle_left_bar_open = () => {
    this.setState({
      left_bar_open: !this.state.left_bar_open,
      error_message: '' 
    });
  }

  handle_closed_filter_type_click = (filter_type) => {
    this.setState({
      [`${filter_type}_filters_open`]: true,
      left_bar_open: true
    });
  }

  handle_search_change = async (e) => {
    
    const search = e.target.value;
    let keywords = search.trim().split(' ');

    if ((keywords.length === 1) && (keywords[0] === '')) {
      keywords = [];
    }

    this.setState({ 
      search_holder: search, 
      keywords_holder: keywords,
      error_message: '' 
    }, () => {
      const filtered_ordered_unprotected_items_info = this.filter_items();
      this.setState({ 
        filtered_ordered_unprotected_items_info: filtered_ordered_unprotected_items_info,
      });
    });
  }

  handle_filters_one_action = (filter_type, filter) => {
    const new_filters_holder = {
      ...this.state[`${filter_type}_filters_holder`],
      [filter]: !this.state[`${filter_type}_filters_holder`][filter]
    }

    this.setState({
      [`${filter_type}_filters_holder`]: new_filters_holder,
      error_message: '' 
    }, () => {
      const filtered_ordered_unprotected_items_info = this.filter_items();

      this.setState({
        filtered_ordered_unprotected_items_info: filtered_ordered_unprotected_items_info,
      });

      this.update_filters_are_default();
    });
  }

  handle_filters_all_action = (filter_type, action) => {
    let toggle;
    if (action === 'select') {
      toggle = true;
    } else if (action === 'unselect') {
      toggle = false;
    }

    let new_filters_holder = {}
    for (const filter in this.state[`${filter_type}_filters_holder`]) {
      new_filters_holder[filter] = toggle;
    }

    this.setState({
      [`${filter_type}_filters_holder`]: new_filters_holder,
      error_message: '' 
    }, () => {
      const filtered_ordered_unprotected_items_info = this.filter_items();

      this.setState({
        filtered_ordered_unprotected_items_info: filtered_ordered_unprotected_items_info,
      });

      this.update_filters_are_default();
    });
  }

  handle_visibility_toggle = async (selected_visibility) => {

    let new_visibility_filters_holder = {
      ...this.state.visibility_filters_holder,
      [selected_visibility]: !this.state.visibility_filters_holder[selected_visibility]
    }

    // CHECK FOR ALL TOGGLED OFF, IF SO, DON'T UPDATE
    let visibility_filters_valid = false;
    for (const visibility_option in new_visibility_filters_holder) {
      if (new_visibility_filters_holder[visibility_option]) {
        visibility_filters_valid = true;
      }
    }

    if (visibility_filters_valid) {
      this.setState({
        visibility_filters_holder: new_visibility_filters_holder,
        error_message: '' 
      }, () => {
        const filtered_ordered_unprotected_items_info = this.filter_items();

        this.setState({
          filtered_ordered_unprotected_items_info: filtered_ordered_unprotected_items_info,
        });

        this.update_filters_are_default();
      });
    }
  }

  update_filters_are_default = () => {
    const types = ['type', 'permission', 'owner', 'tag', 'visibility'];

    let all_filters_are_default = true;

    for (const type of types) {
      let filters_are_default = true;

      for (const filter in this.state[`${type}_filters_holder`]) {
        if (!this.state[`${type}_filters_holder`][filter]) {
          filters_are_default = false;
          all_filters_are_default = false;
          break; // TODO check
        }
      }

      this.setState({
        [`${type}_filters_are_default`]: filters_are_default,
      });
    }

    this.setState({
      all_filters_are_default: all_filters_are_default,
    });
  }

  reset_filters_to_default = () => {

    // Build up owner_filters_holder
    let owner_filters_holder = {}
    for (const selected_team_user_id of this.props.selected_team_user_ids) {
      owner_filters_holder[selected_team_user_id] = true;
    }

    this.setState({            
      all_filters_are_default: true,
      type_filters_are_default: true,
      permission_filters_are_default: true,
      owner_filters_are_default: true,
      tag_filters_are_default: true,
      visibility_filters_are_default: true,

      type_filters_holder: {
        account: true,
        file: true,
        custom: true,
      },
      permission_filters_holder: {
        owner: true, 
        read_write: true, 
        read_only: true, 
        one_time: true, 
        not_shared: true
      },
      owner_filters_holder: owner_filters_holder,
      tag_filters_holder: {}, // TODO
      visibility_filters_holder: {
        public: true,
        private: true
      }
    }, () => {
      const filtered_ordered_unprotected_items_info = this.filter_items();

      this.setState({
        filtered_ordered_unprotected_items_info: filtered_ordered_unprotected_items_info,
      });
    })
  }

  on_open_type_filters = () => {
    this.setState({
      type_filters_open: true
    })
  }

  on_close_type_filters = () => {
    this.setState({
      type_filters_open: false
    })
  }

  on_open_permission_filters = () => {
    this.setState({
      permission_filters_open: true
    })
  }

  on_close_permission_filters = () => {
    this.setState({
      permission_filters_open: false
    })
  }

  on_open_owner_filters = () => {
    this.setState({
      owner_filters_open: true
    })
  }

  on_close_owner_filters = () => {
    this.setState({
      owner_filters_open: false
    })
  }

  on_open_tag_filters = () => {
    this.setState({
      tag_filters_open: true
    })
  }

  on_close_tag_filters = () => {
    this.setState({
      tag_filters_open: false
    })
  }

  set_visibility_icon_to_black = (visibility) => {
    this.setState({
      [`${visibility}_icon_is_black`]: true
    })
  }

  set_visibility_icon_to_white = (visibility) => {
    this.setState({
      [`${visibility}_icon_is_black`]: false
    })
  }

  set_add_button_logo_to_black = () => {
    this.setState({
      add_button_logo_is_white: false
    })
  }

  set_add_button_logo_to_white = () => {
    this.setState({
      add_button_logo_is_white: true
    })
  }

  set_left_bar_toggle_to_white = () => {
    this.setState({
      left_bar_toggle_is_gray: false
    })
  }

  set_left_bar_toggle_to_gray = () => {
    this.setState({
      left_bar_toggle_is_gray: true
    })
  }



  preview_file = async (file_metadata) => {

    const file_id = file_metadata.file_id;
    const file_name = file_metadata.file_name;

    // let convert_to_pdf;

    // switch (file_metadata.mimetype) {

    //   // Convert to PDF for office docs
    //   case 'application/msword':
    //   case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    //   case 'application/vnd.ms-powerpoint':
    //   case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    //   case 'application/vnd.ms-excel':
    //   case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    //     convert_to_pdf = true;
    //     break;
    //   }

    //   // For all other types, don't convert
    //   default: {
    //     convert_to_pdf = false;
    //     break;
    //   }
    // }

    this.setState({
      file_viewer_modal_open: true,
    })

    const file_preview_res = await auth_axios.post(`/api/files/${this.props.selected_team_id}/${file_id}/preview`, {
      user_id: this.props.user_id,
      item_id: this.state.selected_item_id,
    }); 

    const success = file_preview_res.data.success;

    this.setState({
      preview_file_metadata: file_metadata
    })
  }


  download_file = async (file_metadata) => {

    const file_id = file_metadata.file_id;
    const file_name = file_metadata.file_name;

    this.setState({
      loading_modal_open: true,
      loading_modal_progress_message: 'Downloading file ...'
    })

    const get_file_res = await auth_axios.post(`/api/files/${this.props.selected_team_id}/${file_id}/fetch`, {
      user_id: this.props.user_id,
      item_id: this.state.selected_item_id
    }); 

    const file__unenc__base64 = get_file_res.data.file__unenc;

    const file__unenc__uint8_array = base64_to_uint8_array(file__unenc__base64);

    // Invisible link for the download
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const blob = new Blob([file__unenc__uint8_array], {type: 'application/octet-stream'});
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = file_name;
    a.click();
    window.URL.revokeObjectURL(url);

    this.setState({
      loading_modal_open: false,
      loading_modal_progress_message: ''
    })
  }



  render() {

    let selected_item;

    if (this.state.selected_item_id) {
      selected_item = this.state.unprotected_items_info[this.state.selected_item_id];
    }

    const type_mappings = {
      'account': 'Account',
      'file': 'File',
      'custom': 'Custom item'
    }
    
    const permission_mappings = {
      'owner': 'My items',
      'read_write': 'Items I have edit access to',
      'read_only': 'Items I have read-only access to',
      'one_time': 'Items I have one-time read-only access to',
      'not_shared': `Other items on ${this.props.selected_team_name}`
    }

    const tag_mappings = {

    }

    const filter_mappings = {
      'type': type_mappings,
      'permission': permission_mappings,
      'owner': this.props.user_id_display_name_maps,
      'tag': tag_mappings
    }

    const filter_type_mappings = {
      'type': 'Type',
      'permission': 'Permission',
      'owner': 'Owner',
      'tag': 'Tag'
    }

    const filter_type_icon_mappings = {
      'type': {
        'default': <></>,
        'nondefault': <></>
      },
      'type': {
        'default': <TypeIconDefault 
          className='dashboard__left-bar-filters-icon'
          key='dashboard__left-bar-filters-icon-type' 
        />,
        'nondefault': <TypeIconNondefault 
          className='dashboard__left-bar-filters-icon'
          key='dashboard__left-bar-filters-icon-type' 
        />,
      },
      'permission': {
        'default': <PermissionIconDefault 
          className='dashboard__left-bar-filters-icon'
          key='dashboard__left-bar-filters-icon-permission' 
        />,
        'nondefault': <PermissionIconNondefault 
          className='dashboard__left-bar-filters-icon'
          key='dashboard__left-bar-filters-icon-permission' 
        />,
      },
      'owner': {
        'default': <OwnerIconDefault 
          className='dashboard__left-bar-filters-icon'
          key='dashboard__left-bar-filters-icon-owner' 
        />,
        'nondefault': <OwnerIconNondefault 
          className='dashboard__left-bar-filters-icon'
          key='dashboard__left-bar-filters-icon-owner' 
        />,
      },
      'tag': {
        'default': <TagIconDefault 
          className='dashboard__left-bar-filters-icon'
          key='dashboard__left-bar-filters-icon-tag' 
        />,
        'nondefault': <TagIconNondefault 
          className='dashboard__left-bar-filters-icon'
          key='dashboard__left-bar-filters-icon-tag' 
        />,
      },
    }




    const item_icon = (item, clickable) => {
      if (item.item_type === 'account') {
        if (item.metadata.square_logo_url) {
          return (
            <div
              className='dashboard__left-side-item-logo-with-config-container'
              data-item_id={item.item_id}
              onClick={clickable ? this.handle_item_click : undefined}
            >
              <div className="dashboard__left-side-item-config-logo-wrapper">
                <img 
                  className='dashboard__left-side-item-config-logo'
                  src={(() => {
                    let logo_url;
                    if (item.metadata.square_logo_url) {
                      logo_url = item.metadata.square_logo_url;
                    } else {
                      logo_url = 'https://plusidentity-config-assets.s3.us-west-1.amazonaws.com/logos/DEFAULT-square.png';
                    }
                    return logo_url
                  })()}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src='https://plusidentity-config-assets.s3.us-west-1.amazonaws.com/logos/DEFAULT-square.png';}} // Show default if there is error
                  data-item_id={item.item_id}
                  onClick={clickable ? this.handle_item_click : undefined}
                />
              </div>
              {/* <img 
                src={AccountIconConfig} 
                alt='' 
                className='dashboard__left-side-item-logo'
                key={`dashboard__left-side-item-icon-${item.item_id}`}
                data-item_id={item.item_id}
                onClick={clickable ? this.handle_item_click : undefined}
                pointerEvents='none'
              /> */}
              <AccountIconConfig
                className='dashboard__left-side-item-logo'
                key={`dashboard__left-side-item-icon-${item.item_id}`}
                data-item_id={item.item_id}
                onClick={clickable ? this.handle_item_click : undefined}
                pointerEvents='none'
              />
            </div>
          )
        } else {
          return (
            <AccountIconNonconfig
              className='dashboard__left-side-item-logo'
              key={`dashboard__left-side-item-icon-${item.item_id}`}
              data-item_id={item.item_id}
              onClick={clickable ? this.handle_item_click : undefined}
              pointerEvents='none'
            />
          )
        }
      } 

      else if (item.item_type === 'file') {
        return (
          <FileIcon 
            className='dashboard__left-side-item-logo'
            key={`dashboard__left-side-item-icon-${item.item_id}`}
            data-item_id={item.item_id}
            onClick={clickable ? this.handle_item_click : undefined}
            pointerEvents='none'
          />
        )
      }

      else if (item.item_type === 'custom') {
        return (
          <CustomIcon 
            className='dashboard__left-side-item-logo'
            key={`dashboard__left-side-item-icon-${item.item_id}`}
            data-item_id={item.item_id}
            onClick={clickable ? this.handle_item_click : undefined}
            pointerEvents='none'
          />
        )
      }
    }

    const get_class_name = (section) => {

      const width = this.props.screen_width;
      const selected_item_id = this.state.selected_item_id;
      const left_bar_open = this.state.left_bar_open

      let class_name = '';

      switch (section) {
        case 'bar': {
          if (left_bar_open) {
            class_name += 'open ';
          }
          if (width < 550) {
            if (left_bar_open) {
              class_name += 'full-width'
            }
            else {
              class_name += 'mobile-hidden'
            }
          }
          break;
        }
        case 'left': {
          if ((width < 750 && selected_item_id) || (width < 550 && left_bar_open)) {
            class_name = 'mobile-hidden'
          }

          break;
        }
        case 'right': {
          if ((width < 750 && !selected_item_id) || (width < 550 && left_bar_open)) {
            class_name = 'mobile-hidden'
          }
          break;
        }
        default: {
          break;
        }
      }

      return class_name;
    }




    return (
      <div className='dashboard__container'>
        {
          this.state.awaiting_server_response
          ? <div className="dashboard__loading">
              <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            </div>
          : this.state.fatal_error_occurred
            ? <>
                Server error occurred, sorry about that! Please refresh the page later.
              </>
            : <>
                {
                  (this.props.screen_width > 550)
                  ? <button 
                      className='dashboard__talk-to-us-button'
                      onClick={ this.props.on_talk_to_us_modal_open }
                      disabled={ this.state.awaiting_server_response }
                    >
                      <div
                        className='dashboard__talk-to-us-button-text'
                      >
                        Talk to us
                      </div>
                      <TalkToUsLogo 
                        className='dashboard__talk-to-us-button-logo'
                      />
                    </button>
                  : <></>
                }
                {
                  (this.props.selected_team_status === 'active')
                  ? <div id='dashboard__main'>
                      <div className={`dashboard__file-dragging-overlay ${this.state.file_dragging ? '' : 'hidden'}`}>
                        <div className='dashboard__file-dragging-description'>
                          Upload file
                        </div>
                      </div>

                      <div className={`dashboard__left-bar ${this.state.left_bar_open ? 'open' : ''} ${get_class_name('bar')}`}>
                        <div className="dashboard__left-bar-contents">
                          <button 
                            className={`dashboard__left-bar-add-button ${this.state.left_bar_open ? 'large' : ''}`}
                            onClick={ this.on_pre_add_item_modal_open }
                            onMouseEnter={ this.set_add_button_logo_to_black }
                            onMouseLeave={ this.set_add_button_logo_to_white }
                            disabled={ this.state.awaiting_server_response || this.state.no_team }
                          >
                            {
                              this.state.add_button_logo_is_white
                              ? <SquareLogoWhite className={`dashboard__left-bar-add-button-icon ${this.state.left_bar_open ? 'large' : ''}`} />
                              : <SquareLogoBlack className={`dashboard__left-bar-add-button-icon ${this.state.left_bar_open ? 'large' : ''}`} />
                            }
                            {`Add ${this.state.left_bar_open ? 'item' : ''}`}
                          </button>

                          <div className="dashboard__left-bar-filters-container">
                            {['type', 'permission', 'owner', 'tag'].map((filter_type) => 
                              <div 
                                className={`dashboard__left-bar-filter-container ${this.state.left_bar_open ? '' : 'left-bar-closed'}`}
                                key={`dashboard__left-bar-filter-container-${filter_type}`}
                              >
                                <div 
                                  className="dashboard__left-bar-separator-horizontal-container"
                                  key={`dashboard__left-bar-separator-horizontal-container-${filter_type}`}
                                >
                                  <div 
                                    className="dashboard__left-bar-separator-horizontal"
                                    key={`dashboard__left-bar-separator-horizontal-${filter_type}`}
                                  ></div>
                                </div>
                                {/* {
                                  this.state.left_bar_open
                                  ? this.state[`${filter_type}_filters_are_default`]
                                    ? <div
                                        className='dashboard__left-bar-filters-all-action'
                                        onClick={() => this.handle_filters_all_action(filter_type, 'unselect')}
                                        key={`dashboard__left-bar-filters-all-action-${filter_type}`}
                                      >
                                        Unselect all
                                      </div>
                                    : <div
                                        className='dashboard__left-bar-filters-all-action'
                                        onClick={() => this.handle_filters_all_action(filter_type, 'select')}
                                        key={`dashboard__left-bar-filters-all-action-${filter_type}`}
                                      >
                                        Select all
                                      </div>
                                  : <></>
                                } */}
                                {
                                  this.state.left_bar_open
                                  ? this.state[`${filter_type}_filters_are_default`]
                                    ? <div
                                        className='dashboard__left-bar-filters-all-action'
                                        onClick={() => this.handle_filters_all_action(filter_type, 'unselect')}
                                        key={`dashboard__left-bar-filters-all-action-${filter_type}`}
                                      >
                                        <CheckboxIconSelected 
                                          className='dashboard__left-bar-filters-all-action-checkbox'
                                          key={`dashboard__left-bar-filters-all-action-checkbox-${filter_type}`}
                                        />
                                        <div
                                          className='dashboard__left-bar-filters-all-action-text'
                                          key={`dashboard__left-bar-filters-all-action-text-${filter_type}`}
                                        >
                                          Select all
                                        </div>
                                      </div>
                                    : <div
                                        className='dashboard__left-bar-filters-all-action'
                                        onClick={() => this.handle_filters_all_action(filter_type, 'select')}
                                        key={`dashboard__left-bar-filters-all-action-${filter_type}`}
                                      >
                                        <CheckboxIconUnselected 
                                          className='dashboard__left-bar-filters-all-action-checkbox'
                                          key={`dashboard__left-bar-filters-all-action-checkbox-${filter_type}`}
                                        />
                                        <div
                                          className='dashboard__left-bar-filters-all-action-text'
                                          key={`dashboard__left-bar-filters-all-action-text-${filter_type}`}
                                        >
                                          Select all
                                        </div>
                                      </div>
                                  : <></>
                                }
                                {
                                  this.state.left_bar_open
                                  ? <Collapsible 
                                      trigger={
                                        <>
                                          <div 
                                            className="dashboard__left-bar-filters-toggle-icon-container"
                                            key={`dashboard__left-bar-filters-toggle-icon-container-${filter_type}`}
                                          >
                                            {
                                              this.state[`${filter_type}_filters_open`]
                                              ? <FiltersOpenIcon
                                                  className='dashboard__left-bar-filters-toggle-icon'
                                                  key={`dashboard__left-bar-filters-toggle-icon-${filter_type}`} 
                                                />
                                              : <FiltersClosedIcon
                                                  className='dashboard__left-bar-filters-toggle-icon'
                                                  key={`dashboard__left-bar-filters-toggle-icon-${filter_type}`} 
                                                />
                                            }
                                          </div>
                                          <div
                                            className='dashboard__left-bar-filters-icon-container'
                                            key={`dashboard__left-bar-filters-icon-container-${filter_type}`} 
                                          >
                                            {
                                              this.state[`${filter_type}_filters_are_default` ]
                                              ? filter_type_icon_mappings[filter_type]['default']
                                              : filter_type_icon_mappings[filter_type]['nondefault']
                                            }
                                          </div>
                                          <div
                                            className='dashboard__left-bar-filters-text-container'
                                            key={`dashboard__left-bar-filters-text-container-${filter_type}`} 
                                          >
                                            {filter_type_mappings[filter_type]}
                                          </div>  
                                        </>
                                      }
                                      open={this.state[`${filter_type}_filters_open`]}
                                      onOpening={this[`on_open_${filter_type}_filters`]}
                                      onClosing={this[`on_close_${filter_type}_filters`]}
                                      transitionTime={200}
                                      key={`dashboard__left-bar-collapsible-${filter_type}`} 
                                      className='dashboard__left-bar-collapsible'
                                      openedClassName='dashboard__left-bar-collapsible'
                                      triggerClassName='dashboard__left-bar-collapsible-trigger'
                                      triggerOpenedClassName='dashboard__left-bar-collapsible-trigger'
                                      contentOuterClassName='dashboard__left-bar-collapsible-outer'
                                      contentInnerClassName="dashboard__left-bar-collapsible-inner"
                                    >
                                      {
                                        Object.keys(this.state[`${filter_type}_filters_holder`]).map((filter) => 
                                        (!((!this.state.visibility_filters_holder['public']) && (filter_type === 'permission') && (filter === 'not_shared')))
                                        ? <div 
                                            className="dashboard__left-bar-filter-item"
                                            key={`dashboard__left-bar-filter-item-${filter_type}-${filter}`} 
                                            onClick={ () => this.handle_filters_one_action(filter_type, filter) }
                                            disabled={ this.state.awaiting_server_response || this.state.no_team }
                                          >
                                            {
                                              this.state[`${filter_type}_filters_holder`][filter]
                                              ? <div 
                                                  className="dashboard__left-bar-filter-item-checkbox-container"
                                                  key={`dashboard__left-bar-filter-item-checkbox-container-${filter_type}-${filter}`}
                                                >
                                                  <CheckboxIconSelected 
                                                    key={`dashboard__left-bar-filter-item-checkbox-${filter_type}-${filter}`}
                                                  />
                                                </div>
                                              : <div 
                                                  className="dashboard__left-bar-filter-item-checkbox-container"
                                                  key={`dashboard__left-bar-filter-item-checkbox-container-${filter_type}-${filter}`}
                                                >
                                                  <CheckboxIconUnselected 
                                                    key={`dashboard__left-bar-filter-item-checkbox-${filter_type}-${filter}`}
                                                  />
                                                </div>
                                            }
                                            <button 
                                              className='dashboard__left-bar-filter-item-text'
                                              key={`dashboard__left-bar-filter-item-text-${filter_type}-${filter}`}
                                            >
                                              {filter_mappings[filter_type][filter]}
                                            </button>
                                          </div>
                                        : <></>
                                          
                                        )
                                      }
                                    </Collapsible>
                                  : <div
                                      className='dashboard__left-bar-filters-icon-container left-bar-closed'
                                      key={`dashboard__left-bar-filters-icon-container left-bar-closed-${filter_type}`}
                                      onClick={() => this.handle_closed_filter_type_click(filter_type)}
                                    >
                                      {
                                        this.state[`${filter_type}_filters_are_default`] 
                                        ? filter_type_icon_mappings[filter_type]['default']
                                        : filter_type_icon_mappings[filter_type]['nondefault']
                                      }
                                    </div>
                                }
                              </div>
                            )}
                            
                          </div>

                        </div>

                        


                        <div
                          className='dashboard__left-bar-toggle-container'
                        >
                          <div
                            className='dashboard__left-bar-toggle'
                            onClick={ this.handle_left_bar_open }
                            onMouseEnter={ this.set_left_bar_toggle_to_gray }
                            onMouseLeave={ this.set_left_bar_toggle_to_white }
                          >
                            {
                              this.state.left_bar_open
                              ? <>
                                  {this.state.left_bar_toggle_is_gray
                                  ? <CollapseIconGray />
                                  : <CollapseIconWhite />}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Collapse
                                </>
                              : this.state.left_bar_toggle_is_gray
                                ? <ExpandIconGray />
                                : <ExpandIconWhite />
                            }
                            
                          </div>
                          {
                            this.state.left_bar_open 
                            ? this.state.all_filters_are_default
                              ? <button
                                  className='dashboard__left-bar-reset-button inactive' 
                                >
                                  Reset all filters
                                </button>
                              : <button
                                  className='dashboard__left-bar-reset-button' 
                                  onClick={this.reset_filters_to_default}
                                >
                                  Reset all filters
                                </button>
                            : <></>
                          }
                        </div>
                      </div>

                      {
                        (!this.state.left_bar_open) && (this.props.screen_width < 550)
                        ? <div
                            className='dashboard__left-bar-min-width'
                          >
                            <div
                              className='dashboard__left-bar-min-width-expand-button'
                              onClick={ this.handle_left_bar_open }
                            >
                              <ExpandIconMinWidthWhite />
                            </div>
                          </div>
                        : <></>
                      }

                      <div className="dashboard__separator-vertical"></div>

                      <div className={`dashboard__left-side ${get_class_name('left')}`}>
                        {
                          ((this.state.keywords_holder.length > 0) && (!this.state.all_filters_are_default)) ? <div className="dashboard__left-side-search-reset-filters-button-container">
                              <button
                                className='dashboard__left-side-search-reset-filters-button' 
                                onClick={this.reset_filters_to_default}
                              >
                                Search globally (reset filters)
                              </button>
                            </div>
                          : <></>
                        }

                        

                        <div className="dashboard__left-side-search">
                          <input
                            className='dashboard__left-side-search-actions-searchbar'
                            placeholder='Search'
                            type='text'
                            value={ this.state.search_holder }
                            onChange={ this.handle_search_change }
                            disabled={ this.state.awaiting_server_response || this.state.no_team }
                          />
                        </div>

                        <div className="dashboard__left-side-visibility-buttons">
                          <button
                            className={`dashboard__left-side-visibility-public ${(this.state.visibility_filters_holder.public) ? 'selected' : ''}`}
                            onClick={ () => {this.handle_visibility_toggle('public')} }
                            onMouseEnter={() => this.set_visibility_icon_to_black('public')}
                            onMouseLeave={() => this.set_visibility_icon_to_white('public')}
                          >
                            {
                              (this.state.visibility_filters_holder.public || this.state.public_icon_is_black)
                              ? <PublicIconBlack
                                  className='dashboard__left-side-visibility-public-icon'
                                />
                              : <PublicIconWhite
                                  className='dashboard__left-side-visibility-public-icon'
                                />
                            }
                            {`Discoverable`}
                          </button>
                          <button 
                            className={`dashboard__left-side-visibility-private ${(this.state.visibility_filters_holder.private) ? 'selected' : ''}`}
                            onClick={ () => {this.handle_visibility_toggle('private')} }
                            onMouseEnter={() => this.set_visibility_icon_to_black('private')}
                            onMouseLeave={() => this.set_visibility_icon_to_white('private')}
                          >
                            {
                              (this.state.visibility_filters_holder.private || this.state.private_icon_is_black)
                              ? <PrivateIconBlack
                                  className='dashboard__left-side-visibility-private-icon'
                                />
                              : <PrivateIconWhite
                                  className='dashboard__left-side-visibility-private-icon'
                                />
                            }
                            {`Hidden`}
                          </button>
                        </div>
                        
                        <div className="dashboard__left-side-item-list">
                          {this.state.filtered_ordered_unprotected_items_info.map((item) => 
                            <div 
                              key={item.item_id}
                              data-item_id={item.item_id}
                              className={`dashboard__left-side-item ${this.state.selected_item_id === item.item_id ? 'selected' : ''}`}
                              onClick={this.handle_item_click}
                            >
                              <div 
                                className={`dashboard__left-side-item-permissionbar ${(() => {
                                  if (item.permission === 'owner') {
                                    return 'owner'
                                  } else if (item.permission === 'read_write') {
                                    return 'read-write'
                                  } else if (item.permission === 'read_only') {
                                    return 'read-only'
                                  } else if (item.permission === 'one_time') {
                                    return 'one-time'
                                  } else {
                                    return 'not-shared'
                                  }
                                })()}`}
                                data-item_id={item.item_id}
                                onClick={this.handle_item_click}
                              >
                              </div>
                              
                              <div
                                className="dashboard__left-side-item-logo-wrapper"
                                data-item_id={item.item_id}
                                onClick={this.handle_item_click}
                              >
                                { item_icon(item, true) }
                              </div>

                              <div 
                                className="dashboard__left-side-item-name"
                                data-item_id={item.item_id}
                                onClick={this.handle_item_click}
                              >
                                {item.item_name}
                              </div>
                              <div 
                                className="dashboard__left-side-item-field-list"
                                data-item_id={item.item_id}
                                onClick={this.handle_item_click}
                              >
                                {item.display_data__unenc.map((field) => 
                                  (!((field[0] === 'notes') && (field[1] === ''))) // Don't show if the field is empty notes
                                  ? <div 
                                      key={item.item_id + field[0]}
                                      className="dashboard__left-side-item-field"
                                      data-item_id={item.item_id}
                                      onClick={this.handle_item_click}
                                    >
                                      <span 
                                        className="dashboard__left-side-item-field-name"
                                        data-item_id={item.item_id}
                                        onClick={this.handle_item_click}
                                      >
                                        {beautify_field_name(field[0])}
                                      </span>
                                      <span 
                                        className="dashboard__left-side-item-field-value"
                                        data-item_id={item.item_id}
                                        onClick={this.handle_item_click}
                                      >
                                        {field[1]}
                                      </span>
                                    </div>
                                  : <React.Fragment key={item.item_id + field[0]}></React.Fragment> // To suppress must have unique key error
                                )}
                              </div>

                            </div>
                          )}
                        </div>
                      </div>

                      <div className="dashboard__separator-vertical"></div>

                      <div className={`dashboard__right-side ${get_class_name('right')}`}>
                        {
                          this.state.selected_item_id
                          ? <>

                              {/* TOP LINE */}

                              <div className="dashboard__right-side-topline">
                                <FaTimes 
                                  className='dashboard__right-side-topline-close'
                                  onClick={this.clear_item_selection}
                                />
                              </div>

                              {/* ITEM */}

                              <div className="dashboard__right-side-item">
                                <div className="dashboard__right-side-item-title">
                                  <div 
                                    className='dashboard__right-side-item-title-logo-wrapper'
                                  >
                                    { item_icon(selected_item, false) }
                                  </div>
                                  <div
                                    className='dashboard__right-side-item-title-text'
                                  >
                                    {(() => {
                                      if (!selected_item.metadata.config_display_name) {
                                        return selected_item.item_name
                                      } else {
                                        return selected_item.item_name
                                      }
                                    })()}
                                  </div>
                                </div>

                                {
                                  selected_item.display_data__unenc.filter((field) => (field[1])).length > 0 // there is at least one non-empty field
                                  ? <>
                                      <div 
                                        className='dashboard__right-side-separator-horizontal'
                                      ></div>

                                      <div className="dashboard__right-side-item-info-field-list">
                                        {selected_item.display_data__unenc.map((field) => 
                                          (!((field[0] === 'notes') && (field[1] === '')))
                                          ? <div
                                              key={`${this.state.selected_item_id}-${field[0]}`}
                                              className="dashboard__right-side-item-info-field"
                                            >
                                              <span className="dashboard__right-side-item-info-field-name">{beautify_field_name(field[0])}</span>
                                              <div className="dashboard__right-side-item-info-field-value">
                                                <Field 
                                                  key={`${this.state.selected_item_id}-${field[0]}`}
                                                  field_name={field[0]}
                                                  field_value={field[1]}
                                                  is_protected={field[1] === '********'} // This needs to be changed later
                                                  account_private_key__unenc__uint8_array={this.state.account_private_key__unenc__uint8_array}
                                                  item_keys={this.props.item_keys}
                                                  selected_item_id={this.state.selected_item_id}
                                                  selected_item_name={selected_item.item_name}
                                                  permissions={selected_item.permissions}
                                                  permission={selected_item.permissions[this.props.user_id]}
                                                  user_id={this.props.user_id}
                                                  selected_team_id={this.props.selected_team_id}

                                                  handle_dashboard_pull_upon_item_change={this.handle_dashboard_pull_upon_item_change}
                                                  update_dashboard_items={this.update_dashboard_items}

                                                  mode_info={this.props.mode_info}
                                                />
                                              </div>
                    
                                            </div>
                                          : <React.Fragment key={`${this.state.selected_item_id}-${field[0]}`}></React.Fragment> // To suppress must have unique key error
                                        )}
                                      </div>

                                    </>
                                  : <></>
                                }

                                {
                                  selected_item.files_metadata.length > 0
                                  ? <>
                                      <div 
                                        className='dashboard__right-side-separator-horizontal'
                                      ></div>

                                      <div className="dashboard__right-side-item-files-list">
                                        {selected_item.files_metadata.map((file_metadata) => 
                                          <div
                                            key={`${this.state.selected_item_id}-${file_metadata.file_id}`}
                                            className="dashboard__right-side-item-file"
                                          >
                                            <div className="dashboard__right-side-item-file-name">
                                              {file_metadata.file_name}
                                            </div>
                                            <div className="dashboard__right-side-item-file-actions">
                                              {
                                                (selected_item.permissions[this.props.user_id] === 'owner' || selected_item.permissions[this.props.user_id] === 'read_write' || selected_item.permissions[this.props.user_id] === 'read_only' || selected_item.permissions[this.props.user_id] === 'one_time')
                                                ? <>
                                                    <div
                                                      className='dashboard__right-side-item-file-actions-preview'
                                                    >
                                                      <PreviewIcon
                                                        className='dashboard__right-side-item-file-actions-preview-icon'
                                                        onClick={async () => await this.preview_file(file_metadata)}
                                                      />
                                                      <div 
                                                        className='dashboard__right-side-item-file-actions-preview-description'
                                                      >
                                                        Preview file
                                                      </div>
                                                    </div>
                                                    <div
                                                      className='dashboard__right-side-item-file-actions-download'
                                                    >
                                                      <DownloadIcon 
                                                        className='dashboard__right-side-item-file-actions-download-icon'
                                                        onClick={async () => await this.download_file(file_metadata)}
                                                      />
                                                      <div 
                                                        className='dashboard__right-side-item-file-actions-download-description'
                                                      >
                                                        Download file
                                                      </div>
                                                    </div>
                                                  </>
                                                : <></>
                                              }
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  : <></>
                                }

                              </div>

                              {/* ACCESS INFO */}

                              {
                                (selected_item.permissions[this.props.user_id] === 'owner' || selected_item.permissions[this.props.user_id] === 'read_write' || selected_item.permissions[this.props.user_id] === 'read_only' || selected_item.permissions[this.props.user_id] === 'one_time')
                                ? <>
                                    <div 
                                      className='dashboard__right-side-separator-horizontal'
                                    ></div>
                                    <div className="dashboard__right-side-access">
                                      <div className="dashboard__right-side-access-info-user-list">
                                        {Object.keys(selected_item.permissions).map((user_id) => 
                                          <div  
                                            key={user_id}
                                            className="dashboard__right-side-access-info-user"
                                          >
                                            <div className="dashboard__right-side-access-info-user-name">
                                              {this.props.user_id_display_name_maps[user_id]}
                                            </div>
                                            <div className="dashboard__right-side-access-info-user-permission">
                                              {beautify_permission(this.state.unprotected_items_info[this.state.selected_item_id].permissions[user_id])}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      {
                                        (selected_item.permissions[this.props.user_id] === 'owner' && selected_item.access_requests.length > 0)
                                        ? <>
                                            <div className="dashboard__right-side-access-requests-title">
                                              <span className="dashboard__right-side-access-requests-title-text">Access Requests ({selected_item.access_requests.length})</span>
                                            </div>
                                            <div className="dashboard__right-side-access-requests-list">
                                              {selected_item.access_requests.map((user_id) => 
                                                <div  
                                                  key={user_id}
                                                  className="dashboard__right-side-access-request"
                                                >
                                                  <span className="dashboard__right-side-access-request-user">
                                                    {this.props.user_id_display_name_maps[user_id]}
                                                  </span>
                                                  <button
                                                    className='dashboard__right-side-access-request-accept-button' 
                                                    onClick={() => {
                                                      this.on_accept_access_modal_open(user_id)
                                                    }}
                                                  >
                                                    Accept
                                                  </button>
                                                  <button
                                                    className='dashboard__right-side-access-request-decline-button' 
                                                    onClick={() => {
                                                      this.on_decline_access_modal_open(user_id)
                                                    }}
                                                  >
                                                    Decline
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        : <></>
                                      }
                                      
                                    </div>
                                  </>
                                : <div className="dashboard__right-side-access">
                                    <div className="dashboard__right-side-access-title">
                                  
                                    </div>
                                    <div className="dashboard__right-side-access-info-user-list">
                                      
                                    </div>
                                  </div>
                              }
                              
                              {/* ACTIONS */}

                              <div className="dashboard__right-side-actions">
                                {(() => {
                                  if (selected_item.permissions[this.props.user_id] === 'owner' || selected_item.permissions[this.props.user_id] === 'read_write' || selected_item.permissions[this.props.user_id] === 'read_only' || selected_item.permissions[this.props.user_id] === 'one_time') {
                                    return <>
                                      <div 
                                        className='dashboard__right-side-separator-horizontal'
                                      ></div>
                                      <div className="dashboard__right-side-actions-buttons">
                                        <button
                                          className='dashboard__right-side-actions-edit' 
                                          onClick={ this.on_view_history_modal_open }
                                        >
                                          View history
                                        </button>
                                        {
                                          (selected_item.permissions[this.props.user_id] === 'owner' || selected_item.permissions[this.props.user_id] === 'read_write') 
                                          ? <>
                                              <button
                                                className='dashboard__right-side-actions-edit' 
                                                onClick={ this.on_sharing_links_modal_open }
                                              >
                                                Sharing links
                                              </button>
                                              <button
                                                className='dashboard__right-side-actions-edit' 
                                                onClick={ this.on_edit_item_modal_open }
                                              >
                                                Edit item
                                              </button>
                                              {
                                                (selected_item.permissions[this.props.user_id] === 'owner')
                                                ? <button 
                                                    className='dashboard__right-side-actions-delete'
                                                    onClick={ this.on_delete_item_modal_open }
                                                  >
                                                    Delete item
                                                  </button>
                                                : <></>
                                              }
                                            </>
                                          : <></>
                                        }
                                      </div>
                                    </>
                                  }
                                  else if (!selected_item.permissions[this.props.user_id]) {
                                    if (selected_item.access_requests.includes(this.props.user_id)) {
                                      return <>
                                        <div 
                                          className='dashboard__right-side-separator-horizontal'
                                        ></div>
                                        <div className="dashboard__right-side-actions-buttons">
                                          <button
                                            className='dashboard__right-side-actions-request'
                                            onClick={this.on_cancel_request_modal_open}
                                          >
                                            Cancel request
                                          </button>
                                        </div>
                                        
                                      </>
                                    } else {
                                      return <>
                                        <div 
                                          className='dashboard__right-side-separator-horizontal'
                                        ></div>
                                        <div className="dashboard__right-side-actions-buttons">
                                          <button
                                            className='dashboard__right-side-actions-request'
                                            onClick={this.on_request_access_modal_open}
                                          >
                                            Request access
                                          </button>
                                        </div>
                                        
                                      </>
                                    }
                                  }
                                })()}
                              </div>
                            </>
                          : <></>
                        }
                      </div>
                    </div>
                  : <div className='dashboard__warning-container'>
                      <div className='dashboard__warning-text'>
                        {
                          (() => {
                            if (this.props.selected_team_status === 'inactive') {
                              return 'Your account is not active'
                            } else if (this.props.selected_team_status === 'locked') {
                              return `Your account on ${this.props.selected_team_name} is locked. Visit the Account page in Settings to request a team admin for the recovery of your account.`
                            } else if (this.props.selected_team_status === 'team_locked') {
                              return `The ${this.props.selected_team_name} team is locked active`
                            }
                          })()
                        }
                      </div>
                      <div className='dashboard__warning-actions'>
                        <button
                          className='dashboard__warning-actions-button'
                          onClick={async () => {await this.props.history.push(`/dashboard/settings/account`);}}
                        >
                          Account
                        </button>
                      </div>
                    </div> 
                }
              </> // 
        }
        <PreAddItemModal 
          is_open={this.state.pre_add_item_modal_open}

          on_pre_add_item_modal_close={this.on_pre_add_item_modal_close}
          on_add_item_modal_open={this.on_add_item_modal_open}

          handle_add_item_modal_prefill_type_change={this.handle_add_item_modal_prefill_type_change}
          handle_add_item_modal_prefill_config_change={this.handle_add_item_modal_prefill_config_change}
          handle_add_item_modal_prefill_files_change={this.handle_add_item_modal_prefill_files_change}

          selected_team_user_ids={this.props.selected_team_user_ids} 
          user_id_display_name_maps={this.props.user_id_display_name_maps} 
          user_id={this.props.user_id}
          selected_team_id={this.props.selected_team_id}
          server_public_key__unenc__uint8_array={this.state.server_public_key__unenc__uint8_array}
          // account_public_key__unenc__uint8_array={this.state.account_public_key__unenc__uint8_array} // Not used because we fetch apubk for all users to be shared with including owner anyways
          team_public_keys={this.state.team_public_keys}

          selected_visibility={this.state.visibility_filters_holder.public ? 'public' : 'private'}

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <AddItemModal 
          is_open={this.state.add_item_modal_open}
          on_add_item_modal_close={this.on_add_item_modal_close}
          clear_dashboard_prefills={this.clear_dashboard_prefills}

          prefill_type={this.state.add_item_modal_prefill_type}
          prefill_config={this.state.add_item_modal_prefill_config}
          prefill_files={this.state.add_item_modal_prefill_files}

          selected_team_user_ids={this.props.selected_team_user_ids} 
          user_id_display_name_maps={this.props.user_id_display_name_maps} 
          user_id={this.props.user_id}
          selected_team_id={this.props.selected_team_id}
          server_public_key__unenc__uint8_array={this.state.server_public_key__unenc__uint8_array}
          // account_public_key__unenc__uint8_array={this.state.account_public_key__unenc__uint8_array} // Not used because we fetch apubk for all users to be shared with including owner anyways
          team_public_keys={this.state.team_public_keys}

          selected_visibility={this.state.visibility_filters_holder.public ? 'public' : 'private'}

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <DeleteItemModal
          is_open={this.state.delete_item_modal_open}
          on_delete_item_modal_close={this.on_delete_item_modal_close}

          user_id_display_name_maps={this.props.user_id_display_name_maps} 
          selected_item={selected_item}
          selected_team_id={this.props.selected_team_id}
          user_id={this.props.user_id}

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <EditItemModal 
          is_open={this.state.edit_item_modal_open}
          on_edit_item_modal_close={this.on_edit_item_modal_close}

          edit_secret={this.state.edit_secret}

          item={selected_item || this.state.fetched_secret_data}
          item_keys={this.props.item_keys}
          account_private_key__unenc__uint8_array={this.state.account_private_key__unenc__uint8_array}

          selected_team_user_ids={this.props.selected_team_user_ids} 
          user_id_display_name_maps={this.props.user_id_display_name_maps} 
          user_id={this.props.user_id}
          selected_team_id={this.props.selected_team_id}
          server_public_key__unenc__uint8_array={this.state.server_public_key__unenc__uint8_array}
          // account_public_key__unenc__uint8_array={this.state.account_public_key__unenc__uint8_array} // Not used because we fetch apubk for all users to be shared with including owner anyways
          team_public_keys={this.state.team_public_keys}

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}
    
          mode_info={this.props.mode_info}
        />


        <SharingLinksModal
          is_open={this.state.sharing_links_modal_open}
          on_sharing_links_modal_close={this.on_sharing_links_modal_close}

          user_id_display_name_maps={this.props.user_id_display_name_maps} 
          selected_item={selected_item}
          selected_team_id={this.props.selected_team_id}
          user_id={this.props.user_id}

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <ViewHistoryModal
          is_open={this.state.view_history_modal_open}
          on_view_history_modal_close={this.on_view_history_modal_close}

          user_id_display_name_maps={this.props.user_id_display_name_maps} 
          selected_item={selected_item}
          selected_team_id={this.props.selected_team_id}
          user_id={this.props.user_id}

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />


        <AcceptAccessModal
          is_open={this.state.accept_access_modal_open}
          on_accept_access_modal_close={this.on_accept_access_modal_close}

          item_keys={this.props.item_keys}
          account_private_key__unenc__uint8_array={this.state.account_private_key__unenc__uint8_array}

          selected_item={selected_item}
          selected_team_id={this.props.selected_team_id}
          user_id={this.props.user_id}

          user_id_display_name_maps={this.props.user_id_display_name_maps} 
          access_request_user_id={this.state.access_request_user_id}

          dashboard_awaiting_server_response={this.state.awaiting_server_response}

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <DeclineAccessModal
          is_open={this.state.decline_access_modal_open}
          on_decline_access_modal_close={this.on_decline_access_modal_close}

          selected_item={selected_item}
          selected_team_id={this.props.selected_team_id}
          user_id={this.props.user_id}

          user_id_display_name_maps={this.props.user_id_display_name_maps} 
          access_request_user_id={this.state.access_request_user_id}

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <RequestAccessModal
          is_open={this.state.request_access_modal_open}
          on_request_access_modal_close={this.on_request_access_modal_close}

          selected_item={selected_item}
          selected_team_id={this.props.selected_team_id}
          user_id={this.props.user_id}

          user_id_display_name_maps={this.props.user_id_display_name_maps} 

          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
        <CancelRequestModal
          is_open={this.state.cancel_request_modal_open}
          on_cancel_request_modal_close={this.on_cancel_request_modal_close}

          selected_item={selected_item}
          selected_team_id={this.props.selected_team_id}
          user_id={this.props.user_id}

          user_id_display_name_maps={this.props.user_id_display_name_maps}
          
          update_dashboard_items={this.update_dashboard_items}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <FileViewerModal
          is_open={this.state.file_viewer_modal_open}
          on_file_viewer_modal_close={this.on_file_viewer_modal_close}

          preview_file_metadata={this.state.preview_file_metadata}
          selected_team_id={this.props.selected_team_id}
          user_id={this.props.user_id}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <DashboardLoadingModal
          is_open={this.state.loading_modal_open}
          // on_file_viewer_modal_close={this.on_loading_modal_close}

          progress_message={this.state.loading_modal_progress_message}

          mode_info={this.props.mode_info}
        />
      </div>
    );
  }
}

export default Dashboard;
