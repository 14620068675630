import React from 'react';
import './SharingLinksModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';


import { send_slack_notification } from '../../../slack/util';

import { auth_axios, sleep } from '../../../auth/util';

import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';



import CreateLinkSubmodal from './LinkSubmodals/CreateLinkSubmodal';
import UpdateLinkSubmodal from './LinkSubmodals/UpdateLinkSubmodal';
import RemoveLinkSubmodal from './LinkSubmodals/RemoveLinkSubmodal';




// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


class SharingLinksModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      links: [],

      actions_clicked_link_index: undefined,
      copied_link_index: undefined,

      to_update_link: {},
      to_remove_link: {},

      create_link_submodal_open: false,
      update_link_submodal_open: false,
      remove_link_submodal_open: false,

      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.is_open !== this.props.is_open) {
      console.log('this.props.selected_item', this.props.selected_item)
      
      const fetch_links_res = await auth_axios.post('/api/links/links-data/fetch', {
        link_ids: this.props.selected_item.link_ids,
      });

      const links = fetch_links_res.data.links;

      console.log('fetch_links_res.data', fetch_links_res.data)

      this.setState({
        links: links
      });
    }
  }

  update_dashboard_items_links = async () => {
    await this.props.update_dashboard_items();

    console.log('this.props.selected_item', this.props.selected_item)
      
    const fetch_links_res = await auth_axios.post('/api/links/links-data/fetch', {
      link_ids: this.props.selected_item.link_ids,
    });

    const links = fetch_links_res.data.links;

    console.log('fetch_links_res.data', fetch_links_res.data)

    this.setState({
      links: links
    });
  }

  link_copy_to_clipboard = async (link, index) => {
    



    await navigator.clipboard.writeText(link);

    this.setState({
      link_copied: true,
      copied_link_index: index
    })



    await sleep(1);

    this.setState({
      link_copied: false,
      copied_link_index: undefined
    })

  }


  on_link_actions_click = (index) => {
    this.setState({
      actions_clicked_link_index: index
    });
  }

  on_link_actions_leave = () => {
    this.setState({
      actions_clicked_link_index: undefined
    });
  }

  on_create_link_submodal_open = () => {
    this.setState({
      create_link_submodal_open: true
    });

    // Mixpanel web_create_link_submodal_opened
    mixpanel.track('web_create_link_submodal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_create_link_submodal_close = () => {
    this.setState({
      create_link_submodal_open: false
    });
  }

  on_update_link_submodal_open = (link) => {
    this.setState({
      update_link_submodal_open: true,
      to_update_link: link
    });

    // Mixpanel web_update_link_submodal_opened
    mixpanel.track('web_update_link_submodal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_update_link_submodal_close = () => {
    this.setState({
      update_link_submodal_open: false,
      to_update_link: {}
    });
  }

  on_remove_link_submodal_open = (link) => {
    this.setState({
      remove_link_submodal_open: true,
      to_remove_link: link
    });

    // Mixpanel web_remove_link_submodal_opened
    mixpanel.track('web_remove_link_submodal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_remove_link_submodal_close = () => {
    this.setState({
      remove_link_submodal_open: false,
      to_remove_link: {}
    });
  }





  render() {
    let item_name;

    if (this.props.selected_item) {
      item_name = this.props.selected_item.item_name;
    }

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            await this.props.on_sharing_links_modal_close(false);
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 600 : 450,
            width: screen_width > 690 ? 800 : 300
          }
        }}
      >
        <div className='sharing-links-modal__container'>
          <div className='sharing-links-modal__top-line'>
            <div className='sharing-links-modal__title'>
              SHARING LINKS ({item_name})
            </div>
            <button
              className='sharing-links-modal__create-button' 
              onClick={ this.on_create_link_submodal_open }
            >
              Create
            </button>
          </div>

          <div className='sharing-links-modal__content'>
            <div className='sharing-links-modal__content-header'>
              <div className='sharing-links-modal__content-header-time'>
                Expire at
              </div>
              <div className='sharing-links-modal__content-header-name'>
                Name
              </div>
              <div className='sharing-links-modal__content-header-url'>
                URL
              </div>
              <div className='sharing-links-modal__content-header-access'>
                Access count
              </div>
              <div className='sharing-links-modal__content-header-actions'>
                More
              </div>
            </div>
            <div className='sharing-links-modal__content-links'>
            {this.state.links.map((link, index) => 
              <div 
                key={link.link_id}
                data-item_id={link.link_id}
                className='sharing-links-modal__content-link'
              >

                <div
                  className='sharing-links-modal__content-link-time'
                >
                  { ((new Date(link.link_metadata.link_expire_at)).getTime() === 0) ? 'No expiration date' : `${new Date(link.link_metadata.link_expire_at).toLocaleString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})` }
                </div>

                <div
                  className='sharing-links-modal__content-link-name'
                >
                  { link.link_name }
                </div>

                <div
                  className='sharing-links-modal__content-link-url'
                >
                  {
                    index === this.state.copied_link_index
                    ? <div 
                        className='sharing-links-modal__content-link-url-check-container'
                      >
                        <CheckLogo 
                          className='sharing-links-modal__content-link-url-check'
                        />
                      </div>
                    : <div 
                        className='sharing-links-modal__content-link-url-text'
                        onClick={async () => await this.link_copy_to_clipboard(`${this.props.mode_info.is_dev ? 'http://localhost:3000' : 'https://app.plusidentity.com'}/l/${link.link_id}`, index)}
                      >
                        { `${this.props.mode_info.is_dev ? 'http://localhost:3000' : 'https://app.plusidentity.com'}/l/${link.link_id}` }
                        <div
                          className='sharing-links-modal__content-link-hover-text'
                        >
                          Click text to copy
                        </div>
                      </div>
                  }
                  
                </div>

                <div
                  className='sharing-links-modal__content-link-access'
                >
                  { link.link_metadata.current_access_count }
                </div>

                <div
                  className='sharing-links-modal__content-link-actions'
                  onClick={() => this.on_link_actions_click(index)}
                  onMouseLeave={this.on_link_actions_leave}
                >
                  ...
                  <div 
                    className='sharing-links-modal__content-link-actions-menu-container'
                  >
                    <div
                      className={`sharing-links-modal__content-link-actions-menu ${index === this.state.actions_clicked_link_index ? 'shown' : ''}`}
                    > 
                      <div 
                        className='sharing-links-modal__content-link-actions-menu-button'
                        onClick={() => this.on_update_link_submodal_open(link)}
                      >
                        Update link
                      </div>
                      <div 
                        className='sharing-links-modal__content-link-actions-menu-button'
                        onClick={() => this.on_remove_link_submodal_open(link)}
                      >
                        Remove link
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            )}  
            </div>
          </div>

          <div className='sharing-links-modal__bottom-line'>
            <button
              className='sharing-links-modal__close'
              onClick={async () => {
                await this.props.on_sharing_links_modal_close();
              }}
            >
              Close
            </button>
          </div>
          
          <span>{this.state.error_message}</span>
        </div>



        <CreateLinkSubmodal
          is_open={this.state.create_link_submodal_open}
          on_create_link_submodal_close={this.on_create_link_submodal_close}

          // user_id_display_name_maps={this.props.user_id_display_name_maps} 
          selected_item={this.props.selected_item}
          // selected_team_id={this.props.selected_team_id}
          // user_id={this.props.user_id}

          update_dashboard_items_links={this.update_dashboard_items_links}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <UpdateLinkSubmodal
          is_open={this.state.update_link_submodal_open}
          on_update_link_submodal_close={this.on_update_link_submodal_close}

          // user_id_display_name_maps={this.props.user_id_display_name_maps} 
          selected_item={this.props.selected_item}
          // selected_team_id={this.props.selected_team_id}
          // user_id={this.props.user_id}
          to_update_link={this.state.to_update_link}

          update_dashboard_items_links={this.update_dashboard_items_links}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <RemoveLinkSubmodal
          is_open={this.state.remove_link_submodal_open}
          on_remove_link_submodal_close={this.on_remove_link_submodal_close}

          // user_id_display_name_maps={this.props.user_id_display_name_maps} 
          selected_item={this.props.selected_item}
          // selected_team_id={this.props.selected_team_id}
          // user_id={this.props.user_id}
          to_remove_link={this.state.to_remove_link}

          update_dashboard_items_links={this.update_dashboard_items_links}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />



      </ReactModal>
    );
  }
}

export default SharingLinksModal;
