import React from 'react';
import './DeclineAccessModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

import { auth_axios } from '../../../auth/util';

import { send_slack_notification } from '../../../slack/util';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


class DeclineAccessModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }


  decline_access = async () => {

    //
    this.setState({
      error_message: '',
      awaiting_server_response: true
    });


    /**************************************
    * 1. Call the decline access API call
    ***************************************/

    const decline_access_res = await auth_axios.post(`/api/items/${this.props.selected_item.item_id}/access/request/decline`, {
      user_id: this.props.user_id,
      access_request_user_id: this.props.access_request_user_id
    });

    if (!decline_access_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with decline access'
      });
      return;
    }

    /**************************************
    * 2. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false
    });


    




    /**************************************
    * 4. Slack notification request
    ***************************************/



    const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

    const team_id = this.props.selected_team_id;

    const item_name = this.props.selected_item.item_name

    const user_id = this.props.user_id;
    const display_name = this.props.user_id_display_name_maps[user_id]

    const requestor_user_id = this.props.access_request_user_id
    const requestor_display_name = this.props.user_id_display_name_maps[requestor_user_id]



    // Message to decliner (this user)

    const message = `You declined access request to ${item_name} from SLACK_USER_ID (${requestor_display_name}).`;
    await send_slack_notification(url, team_id, user_id, message, {
      TO_FORMAT_USER_ID: requestor_user_id
    });


    // Message to requestor

    const message2 = `SLACK_USER_ID (${display_name}) declined your access request for ${item_name}.`;

    await send_slack_notification(url, team_id, requestor_user_id, message2, {
      TO_FORMAT_USER_ID: user_id, 
    });



    /**************************************
    * 3. Trigger dashboard items pull and close modal
    ***************************************/

    await this.props.on_decline_access_modal_close(true);
    await this.props.update_dashboard_items();


    /**************************************
    * 5. Mixpanel
    ***************************************/

    mixpanel.track('web_decline_access_modal_submitted', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });

    

  
    /**************************************
    * 5. Refresh page to see changes on dashboard immediately!
    ***************************************/

    // Redirect and reload
    //await this.props.history.push(`/dashboard`);
    // window.location.reload(false);
  }


  render() {

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;


    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            await this.props.on_decline_access_modal_close(false);
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 180 : 180,
            width: screen_width > 690 ? 360 : 300
          }
        }}
      >
        <div className="decline-access-modal__container">
          <div
            className='decline-access-modal__text' 
          >
            Are you sure you want to decline access from <span className='decline-access-modal__text-bold'>{this.props.user_id_display_name_maps[this.props.access_request_user_id]}</span>?
          </div>
          {
            this.state.awaiting_server_response
            ? <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            : <>
                
                <input
                  type='submit'
                  className='decline-access-modal__delete-button'
                  value={ 'Decline' }
                  onClick={ this.decline_access }
                />
                <a
                  className='decline-access-modal__cancel'
                  onClick={async () => {
                    await this.props.on_decline_access_modal_close(false);
                  }}
                >
                  Cancel
                </a>
              </>
          }
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default DeclineAccessModal;
