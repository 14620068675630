import React from 'react';
import './ManageUsers.css';


import axios from 'axios';
import ReactLoading from 'react-loading';

import MakeAdminModal from './MakeAdminModal';
import RevokeAdminModal from './RevokeAdminModal';
import RemoveAccountModal from './RemoveAccountModal';


import { auth_axios } from '../../../../../auth/util';


// Mixpanel
import mixpanel from 'mixpanel-browser';

const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



const beautify_admin_type = (type) => {
  const mapping = {
    'owner': 'Team owner',
    'admin': 'Team admin',
    'member': 'Team member',
  }

  if (mapping[type]) {
    return mapping[type];
  }

  else {
    return type;
  }
}


const beautify_team_status = (team_status) => {
  const mapping = {
    'active': 'Active',
    'inactive': 'Inactive',
    'locked': 'Locked',
    'team_locked': 'Team Locked'
  }

  if (mapping[team_status]) {
    return mapping[team_status];
  }

  else {
    return team_status;
  }
}




class ManageUsers extends React.Component {
  
  constructor(props) {
    super(props);


    let user_id_actions_opened_maps = {}
    for (const user_id of props.selected_team_user_ids) {
      user_id_actions_opened_maps[user_id] = false
    }


    this.state = {
      awaiting_server_response: true,

      user_search_holder: '',
      user_keywords_holder: [],

      filtered_user_ids: props.selected_team_user_ids,

      user_id_team_status_maps: {},
      user_id_email_address_maps: {},
      user_id_user_type_maps: {},

      user_id_actions_opened_maps: user_id_actions_opened_maps,

      to_revoke_admin_user_id: '',
      to_remove_account_user_id: '',
      to_make_admin_user_id: '',

      make_admin_modal_open: false,
      revoke_admin_modal_open: false,
      remove_account_modal_open: false,

      error_message: '',
    }
  }
  
  componentDidMount = async () => {
    try {
      // Protect against url paste access
      if (!this.props.is_admin) {
        // 
        alert('You do not have permission to view this page');
        await this.props.history.push('/dashboard/settings');
        return;
      }

      // Get team info
      const team_info_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/team-info/fetch`, {
        user_id: this.props.user_id,
      });

      if (!team_info_res.data.success) {
        console.error('get account info failed')
        return;
      }

      const user_id_team_status_maps = team_info_res.data.user_id_team_status_maps;
      const user_id_email_address_maps = team_info_res.data.user_id_email_address_maps;
      const user_id_user_type_maps = team_info_res.data.user_id_user_type_maps;

      
      this.setState({
        awaiting_server_response: false,

        user_id_team_status_maps: user_id_team_status_maps,
        user_id_email_address_maps: user_id_email_address_maps,
        user_id_user_type_maps: user_id_user_type_maps
      });

      // Mixpanel web_manage_users_visited
      mixpanel.track('web_manage_users_visited', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });
      
    }
    catch {
      // 
      alert('Something went wrong that needs investigating...');
      await this.props.history.push('/dashboard/settings');
    }
  }

  handle_user_search_change = (e) => {
    const search = e.target.value;
    let keywords = search.trim().split(' ');

    if ((keywords.length === 1) && (keywords[0] === '')) {
      keywords = [];
    }

    this.setState({ 
      user_search_holder: search, 
      user_keywords_holder: keywords,
      error_message: '' 
    }, () => {
      const filtered_user_ids = this.filter_user_ids();
      this.setState({ 
        filtered_user_ids: filtered_user_ids,
      });
    });
  }

  filter_user_ids = () => {

    let filtered_user_ids = [];

    // Iterate across every user in the team
    for (const user_id of this.props.selected_team_user_ids) {

      // Check for each keyword
      for (const user_keyword of this.state.user_keywords_holder) {

        if (!user_keyword) { // Error resiliency
          console.log('Error with search bar: user_keyword', user_keyword)
          continue;
        }
        let user_keyword_lowercase = user_keyword.toLowerCase();

        // Check name
        if (!this.props.user_id_display_name_maps[user_id]) { // Error resiliency
          console.log('Error with search bar: this.props.user_id_display_name_maps[user_id]', this.props.user_id_display_name_maps[user_id])
          continue;
        }
        if (this.props.user_id_display_name_maps[user_id].toLowerCase().includes(user_keyword_lowercase)) {
          filtered_user_ids.push(user_id);
          continue;
        }

        // Check email address
        if (!this.state.user_id_email_address_maps[user_id]) { // Error resiliency
          console.log('Error with search bar: this.state.user_id_email_address_maps[user_id]', this.state.user_id_email_address_maps[user_id])
          continue;
        }
        if (this.state.user_id_email_address_maps[user_id].toLowerCase().includes(user_keyword_lowercase)) {
          filtered_user_ids.push(user_id);
          continue;
        }

        // Check user type
        if (!beautify_admin_type(this.state.user_id_user_type_maps[user_id])) { // Error resiliency
          console.log('Error with search bar: beautify_admin_type(this.state.user_id_user_type_maps[user_id])', beautify_admin_type(this.state.user_id_user_type_maps[user_id]))
          continue;
        }
        if (beautify_admin_type(this.state.user_id_user_type_maps[user_id]).toLowerCase().includes(user_keyword_lowercase)) {
          filtered_user_ids.push(user_id);
          continue;
        }

        // Check user status
        if (!beautify_team_status(this.state.user_id_team_status_maps[user_id])) { // Error resiliency
          console.log('Error with search bar: beautify_team_status(this.state.user_id_team_status_maps[user_id])', beautify_team_status(this.state.user_id_team_status_maps[user_id]))
          continue;
        }
        if (beautify_team_status(this.state.user_id_team_status_maps[user_id]).toLowerCase().includes(user_keyword_lowercase)) {
          filtered_user_ids.push(user_id);
          continue;
        }
      }
    }

    // If the search / keywords is empty
    if (this.state.user_keywords_holder.length === 0) {
      filtered_user_ids = this.props.selected_team_user_ids
    }

    filtered_user_ids = [...new Set(filtered_user_ids)];

    return filtered_user_ids;
  }






  on_user_actions_click = (user_id) => {
    this.setState({
      user_id_actions_opened_maps: {
        ...this.state.user_id_actions_opened_maps,
        [user_id]: true
      }
    });
  }

  on_user_actions_leave = () => {

    let user_id_actions_opened_maps = {}
    for (const user_id of this.props.selected_team_user_ids) {
      user_id_actions_opened_maps[user_id] = false
    }

    this.setState({
      user_id_actions_opened_maps: user_id_actions_opened_maps
    });
  }

  on_make_admin_modal_open = (user_id) => {
    this.setState({
      make_admin_modal_open: true,
      to_make_admin_user_id: user_id
    });

    // Mixpanel web_make_admin_modal_opened
    mixpanel.track('web_make_admin_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_make_admin_modal_close = () => {
    this.setState({
      make_admin_modal_open: false,
      to_make_admin_user_id: ''
    });
  }

  on_revoke_admin_modal_open = (user_id) => {
    this.setState({
      revoke_admin_modal_open: true,
      to_revoke_admin_user_id: user_id
    });

    // Mixpanel web_revoke_admin_modal_opened
    mixpanel.track('web_revoke_admin_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_revoke_admin_modal_close = () => {
    this.setState({
      revoke_admin_modal_open: false,
      to_revoke_admin_user_id: ''
    });
  }

  on_remove_account_modal_open = (user_id) => {
    this.setState({
      remove_account_modal_open: true,
      to_remove_account_user_id: user_id
    });

    // Mixpanel web_remove_account_modal_opened
    mixpanel.track('web_remove_account_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_remove_account_modal_close = () => {
    this.setState({
      remove_account_modal_open: false,
      to_remove_account_user_id: ''
    });
  }
   

  
  render() {
    return (
      <div className='manage-users__container'>
        {
          this.state.awaiting_server_response
          ? <div className="manage-users__loading-container">
              <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            </div>
          : <div className='manage-users__contents'>
              <div className='manage-users__section'>
                <div className='manage-users__section-left'>
                  Users
                </div>


                <div className='manage-users__section-right'>



                  <div className='manage-users__section-right-action'>

                    <div className="manage-users__search">
                      <input
                        className='manage-users__search-actions-searchbar'
                        placeholder='Search users'
                        type='text'
                        value={ this.state.user_search_holder }
                        onChange={ this.handle_user_search_change }
                        disabled={ this.state.awaiting_server_response || this.state.no_team }
                      />
                    </div>

                    <button 
                      className="manage-users__invite-users-button"
                      // onClick={ this.____ }
                      disabled
                    >
                      + Invite users
                    </button>
                    
                  </div>
                  <div className='manage-users__section-right-list'>

                    <div className='manage-users__section-right-list-header'>
                      <div className="manage-users__section-right-list-header-profile">
                        User
                      </div>
                      <div className="manage-users__section-right-list-header-type">
                        Type
                      </div>
                      <div className="manage-users__section-right-list-header-status">
                        Status
                      </div>
                      <div className="manage-users__section-right-list-header-actions">
                        
                      </div>
                    </div>

                    <div className='manage-users__section-right-list-users'>
                      {this.state.filtered_user_ids.map((user_id, index) => 
                        <div 
                          key={user_id}
                          className='manage-users__section-right-list-user'
                        >

                          <div
                            className='manage-users__section-right-list-user-profile'
                          >
                            <div
                              className='manage-users__section-right-list-user-display-name'
                            >
                              { this.props.user_id_display_name_maps[user_id] }
                            </div>

                            <div
                              className='manage-users__section-right-list-user-email-address'
                            >
                              { this.state.user_id_email_address_maps[user_id] }
                            </div>
                          </div>
                          

                          <div
                            className='manage-users__section-right-list-user-type'
                          >
                            { beautify_admin_type(this.state.user_id_user_type_maps[user_id]) }
                          </div>

                          <div
                            className='manage-users__section-right-list-user-status'
                          >
                            { beautify_team_status(this.state.user_id_team_status_maps[user_id]) }
                          </div>

                          <div
                            className='manage-users__section-right-list-user-actions'
                            onClick={() => this.on_user_actions_click(user_id)}
                            onMouseLeave={this.on_user_actions_leave}
                          >
                            ...
                            <div 
                              className="manage-users__section-right-list-user-actions-menu-container"
                            >
                              <div
                                className={`manage-users__section-right-list-user-actions-menu ${this.state.user_id_actions_opened_maps[user_id] ? 'shown' : ''}`}
                              > 
                                {
                                  (this.state.user_id_user_type_maps[user_id] === 'owner')
                                  ? <div 
                                      className="manage-users__section-right-list-user-actions-menu-button"
                                    >
                                      (You can't take any actions on the team owner)
                                    </div>
                                  : <></>
                                }
                                {
                                  (this.state.user_id_user_type_maps[user_id] === 'admin')
                                  ? <div 
                                      className="manage-users__section-right-list-user-actions-menu-button"
                                      onClick={() => this.on_revoke_admin_modal_open(user_id)}
                                    >
                                      Revoke admin status
                                    </div>
                                  : (this.state.user_id_user_type_maps[user_id] === 'member')
                                    ? <div 
                                        className="manage-users__section-right-list-user-actions-menu-button"
                                        onClick={() => this.on_make_admin_modal_open(user_id)}
                                      >
                                        Make user a team admin
                                      </div>
                                    : <></>
                                }
                                {
                                  ((user_id !== this.props.user_id) && (this.state.user_id_user_type_maps[user_id] !== 'owner'))
                                  ? <div 
                                      className="manage-users__section-right-list-user-actions-menu-button"
                                      onClick={() => this.on_remove_account_modal_open(user_id)}
                                    >
                                      Remove user from team
                                    </div>
                                  : <></>
                                }
                              </div>
                            </div>
                          </div>


                          
                        </div>
                      )}
                    </div>
                  </div>



                </div>
              </div>
              
              <div className="manage-users__separator-horizontal"></div>

              

              <div className='manage-users__section'>
                <div className='manage-users__section-left'>
                  Groups
                </div>
                <div className='manage-users__section-right'>
                  <div className='manage-users__section-right-action'>
                    <div className="manage-users__search">
                      <input
                        className='manage-users__search-actions-searchbar'
                        placeholder='Search groups'
                        type='text'
                        disabled
                        // value={ this.state.search_holder }
                        // onChange={ this.handle_search_change }
                        // disabled={ this.state.awaiting_server_response || this.state.no_team }
                      />
                    </div>

                    <button 
                      className="manage-users__create-group-button"
                      onClick={ this.on_add_email_address_modal_open }
                      disabled
                    >
                      + Create a group
                    </button>
                          
                  </div>
                  <div className='manage-users__section-right-list'>
            
                    
                  </div>
                </div>

              </div>
              
            </div>
        }

        

        <MakeAdminModal
          is_open={this.state.make_admin_modal_open}
          on_make_admin_modal_close={this.on_make_admin_modal_close}

          keychain={this.props.keychain} 

          selected_team_id={this.props.selected_team_id}
          
          user_id={this.props.user_id}
          to_make_admin_user_id={this.state.to_make_admin_user_id}

          user_id_display_name_maps={this.props.user_id_display_name_maps}
          
          is_admin={this.props.is_admin}

          mode_info={this.props.mode_info}
        />


        <RevokeAdminModal
          is_open={this.state.revoke_admin_modal_open}
          on_revoke_admin_modal_close={this.on_revoke_admin_modal_close}

          keychain={this.props.keychain} 

          selected_team_id={this.props.selected_team_id}
          
          user_id={this.props.user_id}
          to_revoke_admin_user_id={this.state.to_revoke_admin_user_id}

          user_id_display_name_maps={this.props.user_id_display_name_maps}
          
          is_admin={this.props.is_admin}

          mode_info={this.props.mode_info}
        />

        <RemoveAccountModal
          is_open={this.state.remove_account_modal_open}
          on_remove_account_modal_close={this.on_remove_account_modal_close}

          keychain={this.props.keychain} 

          selected_team_id={this.props.selected_team_id}
          selected_team_name={this.props.selected_team_name}
          
          user_id={this.props.user_id}
          to_remove_account_user_id={this.state.to_remove_account_user_id}
          to_remove_account_user_type={this.state.user_id_user_type_maps[this.state.to_remove_account_user_id]}

          user_id_display_name_maps={this.props.user_id_display_name_maps}
          
          is_admin={this.props.is_admin}

          mode_info={this.props.mode_info}
        />




      </div>
    );
  }
}

export default ManageUsers;
