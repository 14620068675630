import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Button } from './etc/Button';
import './Navbar.css';
import { IconContext } from 'react-icons/lib';
import { ReactComponent as SquareLogo } from '../logos/logo-square-white.svg';
import axios from 'axios';
import { auth_axios } from '../auth/util';

import { get as dbGet, del as dbDel } from 'idb-keyval';

import { ReactComponent as OpenIcon } from './pages/DashboardPage/media/svg/filters-open-icon.svg';
import { ReactComponent as ClosedIcon } from './pages/DashboardPage/media/svg/filters-closed-icon.svg';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



function Navbar({session_status, user_id, selected_team_id, team_ids, team_id_team_name_maps, user_id_display_name_maps, select_team, screen_width, screen_height, mode_info}) {

  const [NavbarOpen, setNavbarOpen] = useState(false);
  const [top_right_open, set_top_right_open] = useState(false);
  const [team_selection_open, set_team_selection_open] = useState(false);

  const valid_session = (session_status === 'SESSION_VALID')
  const is_signup = (useLocation().pathname === '/signup');

  const handleNavbarOpen = () => setNavbarOpen(!NavbarOpen);
  const handle_top_right_open = () => set_top_right_open(!top_right_open);
  const handle_team_selection_open = () => set_team_selection_open(!team_selection_open);
  
  const close_top_right = () => set_top_right_open(false);
  const close_team_selection = () => set_team_selection_open(false);

  const closeAll = () => {
    setNavbarOpen(false);
    set_top_right_open(false);
    set_team_selection_open(false);
  };


  const logout = async () => {
    // Trigger logout 
    // let session_token__unenc__base64 = await dbGet('session_token__unenc');

    // POST request to server to delete session id
    const logout_res = await auth_axios.post('/api/sessions/logout', {
      // session_token__unenc: session_token__unenc__base64,
    });

    if (!logout_res.data.success) {
      console.error('logout server side error occurred');
    }
    else {
      console.log('logout successful on the server side');
    }

    // Delete account_private_key__enc_sk but keep session_token__unenc to check for short term logout vs long term logout
    // await dbDel('session_token__unenc');
    await dbDel('account_private_key__enc_sk');
    console.log('deleted account_private_key__enc_sk on the client side');

    await dbDel('cached_data'); // Delete local cache

    // Mixpanel web_logout_clicked
    mixpanel.track('web_logout_clicked', {
      distinct_id: user_id,
      is_dev: mode_info.is_dev
    });


    // Redirect to login page
    // history.push(`/login`);
    window.location.reload(false);
  }






  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className={`navbar ${valid_session ? 'valid' : ''}`}>
          <div className='navbar-container'>
            <div id="left-menu">
              <Link to='/' className="navbar-logo" onClick={closeAll}>
                <SquareLogo className="navbar-icon" />
                plusidentity
              </Link>
              <ul className={`${NavbarOpen ? 'nav-menu active' : 'nav-menu'} ${valid_session ? '' : 'unprotected'}`}>
                {
                  valid_session
                  ? <li
                      className={team_selection_open ? "nav-item nav-products nav-products-expanded" : "nav-item nav-products"}
                      onMouseLeave={
                        NavbarOpen 
                        ? ""
                        : () => close_team_selection()
                      }
                      onClick={() => handle_team_selection_open()}
                    >
                      <div className="nav-products-links team">
                      {team_id_team_name_maps[selected_team_id]}
                      {
                        screen_width < 960
                        ? team_selection_open
                          ? <OpenIcon 
                              className='selection-open-close-icon'
                            />
                          : <ClosedIcon 
                              className='selection-open-close-icon'
                            />
                        : <></>
                      }
                      </div>
                      <div className="nav-products-space"></div>
                      {team_selection_open
                        ? <div className="nav-products-dropdown visible">
                            {team_ids.map((team_id) => 
                              <div 
                                key={team_id}  
                                className="nav-products-dropdown-item"
                                onClick={async () => await select_team(team_id)}
                              >
                                {team_id_team_name_maps[team_id]}
                              </div>
                            )}
                          </div>
                        : <div className='hidden'></div>
                      }
                    </li>
                  : <></>
                }
              </ul>
            </div>
            <div id="right-menu">
              <ul className={`${NavbarOpen ? 'nav-menu active' : 'nav-menu'} ${valid_session ? '' : 'unprotected'}`}>
                {
                  valid_session
                  ? 
                    <li
                      className={top_right_open ? "nav-item nav-products nav-products-expanded" : "nav-item nav-products"}
                      onMouseEnter={
                        NavbarOpen 
                        ? ""
                        : () => handle_top_right_open()
                      }
                      onMouseLeave={
                        NavbarOpen 
                        ? ""
                        : () => close_top_right()
                      }
                      onClick={() => handle_top_right_open()}
                    >
                      <div className="nav-products-links">
                        {
                          user_id_display_name_maps[user_id]
                        }
                        {
                          screen_width < 960
                          ? top_right_open
                            ? <OpenIcon 
                                className='selection-open-close-icon'
                              />
                            : <ClosedIcon 
                                className='selection-open-close-icon'
                              />
                          : <></>
                        }
                      </div>
                      <div className="nav-products-space"></div>
                      {top_right_open
                        ? <div className="nav-products-dropdown visible">
                            <Link to='/dashboard/settings' className="nav-products-dropdown-item" onClick={closeAll}>
                              Settings
                            </Link>
                            <a className="nav-products-dropdown-item" onClick={()=> {logout(); closeAll();}}>
                              Log Out
                            </a>
                          </div>
                        : <div className='hidden'></div>
                      }
                    </li>
                  : is_signup 
                    ? <li className="nav-btn">
                        <Link to='/login' className="btn-link" onClick={closeAll}>
                          <Button buttonStyle='btn--outline'>Log In</Button>
                        </Link>
                      </li>
                    : <li className="nav-btn">
                        <Link to='/signup' className="btn-link" onClick={closeAll}>
                          <Button buttonStyle='btn--outline'>SIGN UP</Button>
                        </Link>
                      </li> 
                }
              </ul>
            </div>
            
            <div className="menu-icon" onClick={() => {handleNavbarOpen()}}>
              {NavbarOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
          {
            valid_session // && !NavbarOpen
            ? <div className='navbar-separator-horizontal'></div>
            : <></>
          }
        </div>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
