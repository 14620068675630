import React from 'react';
import './UpdateLinkSubmodal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';
import Collapsible from 'react-collapsible';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';


import { send_slack_notification } from '../../../../slack/util';

import { auth_axios } from '../../../../auth/util';

import { ReactComponent as InfoIcon } from '../media/svg/info-icon.svg';

import { ReactComponent as FiltersOpenIcon } from '../media/svg/filters-open-icon.svg';
import { ReactComponent as FiltersClosedIcon } from '../media/svg/filters-closed-icon.svg';



// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


const expire_at_interval_mappings = {
  'no_expiration': 'No expiration date',
  '3600000': '1 hour',
  '7200000': '2 hours',
  '10800000': '3 hours',
  '14400000': '4 hours',
  '18000000': '5 hours',
  '21600000': '6 hours',
  '43200000': '12 hours',
  '86400000': '1 day',
  '172800000': '2 days',
  '259200000': '3 days',
  '345600000': '4 days',
  '432000000': '5 days',
  '518400000': '6 days',
  '604800000': '7 days',
}

const max_access_count_mappings = {
  '0': 'Unlimited',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '10': '10'
}

class UpdateLinkSubmodal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {

      link_name_holder: '',

      set_new_expiration_holder: false, // false by default
      expire_at_interval_holder: 'no_expiration', // No expiration by default

      max_access_count_holder: 0, // 0 (unlimited) by default
      require_email_holder: true, // require email by default
      require_email_verification_holder: false, // not require email auth by default
      allow_downloads_holder: true, // allow downloads by default
      passcode_holder: '', // no passcode by default
      watermark_holder: true, // watermark on by default
      only_file_holder: true, // only_file on by default

      advanced_section_open: false,
            
      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      });
    }
    catch (error) {
      console.log(error)
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.is_open !== this.props.is_open) {

      if (this.props.is_open) {
        const link = this.props.to_update_link;

        console.log('link', link)
  
        const link_name = link.link_name;
  
        const max_access_count = link.link_metadata.max_access_count.toString();
        const require_email = link.link_metadata.require_email;
        const require_email_verification = link.link_metadata.require_email_verification;
        const allow_downloads = link.link_metadata.allow_downloads;
        const passcode = link.link_metadata.passcode;
        const watermark = link.link_metadata.watermark;
        const only_file = link.link_metadata.only_file;
        
        this.setState({
  
          link_name_holder: link_name,
  
          max_access_count_holder: max_access_count,
          require_email_holder: require_email,
          require_email_verification_holder: require_email_verification,
          allow_downloads_holder: allow_downloads,
          passcode_holder: passcode,
          watermark_holder: watermark,
          only_file_holder: only_file
        });
      }
    }
  }

  clear_holders = () => {

    this.setState({
      link_name_holder: '',

      set_new_expiration_holder: false, // false by default
      expire_at_interval_holder: 'no_expiration', // No expiration by default

      max_access_count_holder: 0, // 0 (unlimited) by default
      require_email_holder: true, // require email by default
      require_email_verification_holder: false, // not require email auth by default
      allow_downloads_holder: true, // allow downloads by default
      passcode_holder: '', // no passcode by default
      watermark_holder: true, // watermark on by default
      only_file_holder: true, // only_file on by default

      advanced_section_open: false,
            
      awaiting_server_response: false,
    })
  }; 


  handle_link_name_change = (e) => {
    this.setState({ 
      link_name_holder: e.target.value, 
      error_message: '' 
    });
  }

  handle_set_new_expiration_change = (e) => {
    this.setState({ 
      set_new_expiration_holder: e.target.checked, 
      error_message: '' 
    });
  }

  handle_expire_at_interval_change = (e) => {
    const new_expire_at_interval = e.value;

    this.setState({ 
      expire_at_interval_holder: new_expire_at_interval,
      error_message: ''
    });
  }

  handle_max_access_count_change = (e) => {
    const new_max_access_count = e.value;

    this.setState({ 
      max_access_count_holder: new_max_access_count,
      error_message: ''
    });
  }

  handle_allow_downloads_change = (e) => {
    this.setState({ 
      allow_downloads_holder: e.target.checked, 
      error_message: '' 
    });
  }

  handle_require_email_change = (e) => {
    this.setState({ 
      require_email_holder: e.target.checked,
      require_email_verification_holder: false, 
      error_message: '' 
    });
  }

  handle_require_email_verification_change = (e) => {
    this.setState({ 
      require_email_verification_holder: e.target.checked, 
      error_message: '' 
    });
  }

  handle_passcode_change = (e) => {
    this.setState({ 
      passcode_holder: e.target.value, 
      error_message: '' 
    });
  }

  handle_watermark_change = (e) => {
    this.setState({ 
      watermark_holder: e.target.checked, 
      error_message: '' 
    });
  }

  handle_only_file_change = (e) => {
    this.setState({ 
      only_file_holder: e.target.checked, 
      error_message: '' 
    });
  }


  on_advanced_section_open = () => {
    this.setState({ advanced_section_open: true });
  }

  on_advanced_section_close = () => {
    this.setState({ advanced_section_open: false });
  }

  update_link = async () => {

    this.setState({
      error_message: '',
      awaiting_server_response: true
    });

    const link_name = this.state.link_name_holder;

    const set_new_expiration = this.state.set_new_expiration_holder;
    const expire_at_interval = this.state.expire_at_interval_holder;

    const max_access_count = this.state.max_access_count_holder;
    const require_email = this.state.require_email_holder;
    const require_email_verification = this.state.require_email_verification_holder;
    const allow_downloads = this.state.allow_downloads_holder;
    const passcode = this.state.passcode_holder;
    const watermark = this.state.watermark_holder;
    const only_file = this.state.only_file_holder;

    // Make sure link is set
    if (!link_name) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Link name is required.'
      })
      return;
    }

    // Sanity check - if require_email_verification is true, require_email cannot be false
    if (require_email_verification && !require_email) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'If require_email_verification is true, require_email cannot be false.'
      })
      return;
    } 

    /**************************************
    * 3. Update link entry
    ***************************************/

    const update_link_res = await auth_axios.put(`/api/links/${this.props.to_update_link.link_id}`, {
      link_name: link_name,

      set_new_expiration: set_new_expiration,
      expire_at_interval: expire_at_interval,

      max_access_count: max_access_count,
      require_email: require_email,
      require_email_verification: require_email_verification,
      allow_downloads: allow_downloads,
      passcode: passcode,
      watermark: watermark,
      only_file: only_file
    });

    if (!update_link_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with update link'
      });
    }

    /**************************************
    * 4. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false
    });

    /**************************************
    * 5. Close modal and trigger dashboard items pull and links update
    ***************************************/

    await this.props.on_update_link_submodal_close();
    this.clear_holders();

    await this.props.update_dashboard_items_links();
  };

  


  render() {
    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    const expire_at_interval_options = Object.keys(expire_at_interval_mappings).map((interval) =>
      ({
        label: expire_at_interval_mappings[interval],
        value: interval
      })
    )

    const max_access_count_options = Object.keys(max_access_count_mappings).map((count) =>
      ({
        label: max_access_count_mappings[count],
        value: count
      })
    )

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            this.clear_holders();
            await this.props.on_update_link_submodal_close();
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 630 : 420,
            width: screen_width > 690 ? 440 : 300
          }
        }}
      >
        <div className='update-link-submodal__container'>
          <div className='update-link-submodal__top-line'>
            <div className='update-link-submodal__title'>
              UPDATE LINK
            </div>
          </div>

          <div className='update-link-submodal__fields'>

            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-name'>
                Name*
              </div>
              <input 
                type='text'
                value={ this.state.link_name_holder }
                onChange={ this.handle_link_name_change }
                disabled={this.state.awaiting_server_response}
                placeholder='Give a name to this link (only visible to you)'
                className='update-link-submodal__field-query text-input'
                autoComplete='new-password'
              />
            </div>

            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-current-expiration-text'>
                { this.props.to_update_link.link_metadata 
                  ? ((new Date(this.props.to_update_link.link_metadata.link_expire_at)).getTime() === 0)
                    ? 'No expiration date'
                    : `This link is set to expire at ${new Date(this.props.to_update_link.link_metadata.link_expire_at).toLocaleString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})` 
                  : '[link not yet set]'
                }
              </div>
            </div>

            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-name'>
                Set new expiration*
                <div className='update-link-submodal__info-container'>
                  <InfoIcon 
                    className='update-link-submodal__info-icon'
                  />
                  <div className='update-link-submodal__info-description'>
                    Check this if you wish to set a new time of expiration.
                  </div>
                </div>
              </div>
              <input
                type='checkbox'
                checked={ this.state.set_new_expiration_holder }
                value={this.state.set_new_expiration_holder}
                onChange={this.handle_set_new_expiration_change}
                disabled={this.state.awaiting_server_response}
                className='update-link-submodal__field-query checkbox'
              />
            </div>

            {
              this.state.set_new_expiration_holder
              ? <div className='update-link-submodal__field'>
                  <div className='update-link-submodal__field-name'>
                    Link expires after*
                    <div className='update-link-submodal__info-container'>
                      <InfoIcon 
                        className='update-link-submodal__info-icon'
                      />
                      <div className='update-link-submodal__info-description'>
                        {'This newly set time of expiration would be relative to the current time (and not relative to the currently set time of expiration). The link will be automatically deleted upon expiration.'}
                      </div>
                    </div>
                  </div>
                  <Select
                    options={expire_at_interval_options} 
                    value={expire_at_interval_options.filter((expire_at_interval_option) => {
                      return expire_at_interval_option.value === this.state.expire_at_interval_holder
                    })}
                    isSearchable
                    onChange={ this.handle_expire_at_interval_change }
                    isDisabled={this.state.awaiting_server_response}
                    maxMenuHeight={100}
                    className='update-link-submodal__field-query update-link-submodal__search-select'
                    classNamePrefix='update-link-submodal__search-select-child'
                  />
                </div>
              : <></>
            }

            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-name'>
                Max access count*
                <div className='update-link-submodal__info-container'>
                  <InfoIcon 
                    className='update-link-submodal__info-icon'
                  />
                  <div className='update-link-submodal__info-description'>
                    The link will be automatically deleted upon reaching the maxiumum access count.
                  </div>
                </div>
              </div>
              <Select
                options={max_access_count_options} 
                value={max_access_count_options.filter((max_access_count_option) => {
                  return max_access_count_option.value === this.state.max_access_count_holder
                })}
                isSearchable
                onChange={ this.handle_max_access_count_change }
                isDisabled={this.state.awaiting_server_response}
                maxMenuHeight={100}
                className='update-link-submodal__field-query update-link-submodal__search-select'
                classNamePrefix='update-link-submodal__search-select-child'
              />
            </div>

            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-name'>
                Allow downloads*
                <div className='update-link-submodal__info-container'>
                  <InfoIcon 
                    className='update-link-submodal__info-icon'
                  />
                  <div className='update-link-submodal__info-description'>
                    If disabled, viewers can only preview the file through PlusIdentity and are unable to download. PlusIdentity's browser preview feature currently supports the following file formats: .pdf, .png, .jpeg/jpg, .gif, .svg, .mp4, .webm, .mp3, .wav, .txt, .csv
                  </div>
                </div>
              </div>
              <input
                type='checkbox'
                checked={ this.state.allow_downloads_holder }
                value={this.state.allow_downloads_holder}
                onChange={this.handle_allow_downloads_change}
                disabled={this.state.awaiting_server_response}
                className='update-link-submodal__field-query checkbox'
              />
            </div>
            
            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-name'>
                Require email to view*
                <div className='update-link-submodal__info-container'>
                  <InfoIcon 
                    className='update-link-submodal__info-icon'
                  />
                  <div className='update-link-submodal__info-description'>
                    This will require the viewer to input their email address in order to view the file.
                  </div>
                </div>
              </div>
              <input
                type='checkbox'
                checked={ this.state.require_email_holder }
                value={this.state.require_email_holder}
                onChange={this.handle_require_email_change}
                disabled={this.state.awaiting_server_response}
                className='update-link-submodal__field-query checkbox'
              />
            </div>

            {
              this.state.require_email_holder
              ? <div className='update-link-submodal__field'>
                  <div className='update-link-submodal__field-name'>
                    Require viewers to verify email ownership*
                    <div className='update-link-submodal__info-container'>
                      <InfoIcon 
                        className='update-link-submodal__info-icon'
                      />
                      <div className='update-link-submodal__info-description'>
                        This will require the viewer to enter in the secret code that is sent to their inbox, in order to view the file.
                      </div>
                    </div>
                  </div>
                  <input
                    type='checkbox'
                    checked={ this.state.require_email_verification_holder }
                    value={this.state.require_email_verification_holder}
                    onChange={this.handle_require_email_verification_change}
                    disabled={this.state.awaiting_server_response}
                    className='update-link-submodal__field-query checkbox'
                  />
                </div>
              : <></>
            }

            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-name'>
                Passcode
                <div className='update-link-submodal__info-container'>
                  <InfoIcon 
                    className='update-link-submodal__info-icon'
                  />
                  <div className='update-link-submodal__info-description'>
                    You may optionally set a passcode that the viewer must enter to view the file. You must provide the passcode directly to the viewer.
                  </div>
                </div>
              </div>
              <input 
                type='text'
                value={ this.state.passcode_holder }
                onChange={ this.handle_passcode_change }
                disabled={this.state.awaiting_server_response}
                placeholder='Set a passcode (optional)'
                className='update-link-submodal__field-query text-input passcode'
                autoComplete='new-password'
              />
            </div>

            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-name'>
                Watermark*
                <div className='update-link-submodal__info-container'>
                  <InfoIcon 
                    className='update-link-submodal__info-icon'
                  />
                  <div className='update-link-submodal__info-description'>
                    If checked, every .pdf file that the viewer views or downloads will have a watermark that indicates the email address and time of viewing/download.
                  </div>
                </div>
              </div>
              <input
                type='checkbox'
                checked={ this.state.watermark_holder }
                value={this.state.watermark_holder}
                onChange={this.handle_watermark_change}
                disabled={this.state.awaiting_server_response}
                className='update-link-submodal__field-query checkbox'
              />
            </div>

            <div className='update-link-submodal__field'>
              <div className='update-link-submodal__field-name'>
                Only share file data*
                <div className='update-link-submodal__info-container'>
                  <InfoIcon 
                    className='update-link-submodal__info-icon'
                  />
                  <div className='update-link-submodal__info-description'>
                    If checked, non-file data such as field values and notes will not be shared with the viewer. Please note that there is no way to share max-security data over sharing links.
                  </div>
                </div>
              </div>
              <input
                type='checkbox'
                checked={ this.state.only_file_holder }
                value={this.state.only_file_holder}
                onChange={this.handle_only_file_change}
                disabled={this.state.awaiting_server_response}
                className='update-link-submodal__field-query checkbox'
              />
            </div>
          </div>

          <div className='update-link-submodal__advanced'>
            <Collapsible 
              trigger={
                <div className='update-link-submodal__advanced-title'>
                  <div 
                    className='update-link-submodal__advanced-section-toggle-icon-container'
                  >
                    {
                      this.state.advanced_section_open
                      ? <FiltersOpenIcon
                          className='update-link-submodal__advanced-section-toggle-icon'
                        />
                      : <FiltersClosedIcon
                          className='update-link-submodal__advanced-section-toggle-icon'
                        />
                    }
                  </div>
                  <span>Advanced</span>
                </div>
              }
              open={this.state.advanced_section_open}
              onOpening={this.on_advanced_section_open}
              onClosing={this.on_advanced_section_close}
              transitionTime={200}
              className='dashboard__left-bar-collapsible'
              openedClassName='dashboard__left-bar-collapsible'
              triggerClassName='dashboard__left-bar-collapsible-trigger'
              triggerOpenedClassName='dashboard__left-bar-collapsible-trigger'
              contentOuterClassName='dashboard__left-bar-collapsible-outer'
              contentInnerClassName="dashboard__left-bar-collapsible-inner"
            >
              <div className='update-link-submodal__advanced-fields'>
                <div className='update-link-submodal__advanced-field'>
                  <div className='update-link-submodal__advanced-field-name'>
                    Enable email address allow-list*
                    <div className='update-link-submodal__info-container'>
                      <InfoIcon 
                        className='update-link-submodal__info-icon'
                      />
                      <div className='update-link-submodal__info-description'>
                        This feature is currently unavailable
                      </div>
                    </div>
                  </div>
                  <input
                    type='checkbox'
                    // checked={ this.state.___ }
                    // value={this.state.___}
                    // onChange={this.___}
                    disabled={true} // this.state.awaiting_server_response
                    className='update-link-submodal__advanced-field-query checkbox'
                  />
                </div>
                <div className='update-link-submodal__advanced-field'>
                  <div className='update-link-submodal__advanced-field-name'>
                    Enable email address block-list*
                    <div className='update-link-submodal__info-container'>
                      <InfoIcon 
                        className='update-link-submodal__info-icon'
                      />
                      <div className='update-link-submodal__info-description'>
                        This feature is currently unavailable
                      </div>
                    </div>
                  </div>
                  <input
                    type='checkbox'
                    // checked={ this.state.___ }
                    // value={this.state.___}
                    // onChange={this.___}
                    disabled={true} // this.state.awaiting_server_response
                    className='update-link-submodal__advanced-field-query checkbox'
                  />
                </div>
                <div className='update-link-submodal__advanced-field'>
                  <div className='update-link-submodal__advanced-field-name'>
                    Require NDA before viewing*
                    <div className='update-link-submodal__info-container'>
                      <InfoIcon 
                        className='update-link-submodal__info-icon'
                      />
                      <div className='update-link-submodal__info-description'>
                        This feature is currently unavailable
                      </div>
                    </div>
                  </div>
                  <input
                    type='checkbox'
                    // checked={ this.state.___ }
                    // value={this.state.___}
                    // onChange={this.___}
                    disabled={true} // this.state.awaiting_server_response
                    className='update-link-submodal__advanced-field-query checkbox'
                  />
                </div>
              </div>
            </Collapsible>
          </div>

          <div className='update-link-submodal__bottom-line'>
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  <input
                    className='update-link-submodal__update-button'
                    type='submit'
                    value={ 'Save' }
                    onClick={ this.update_link }
                  />
                  <button
                    className='update-link-submodal__close'
                    onClick={async () => {
                      await this.props.on_update_link_submodal_close();
                    }}
                  >
                    Close
                  </button>
                </>
            }
          </div>

          <div className="update-link-submodal__error_message">
            <span>{this.state.error_message}</span>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default UpdateLinkSubmodal;
