import React from 'react';
import './DeleteTeamModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { auth_axios } from '../../../../../auth/util';

import { FaTimes } from 'react-icons/fa';

import { set as dbSet, get as dbGet } from 'idb-keyval';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


class DeleteTeamModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      confirm_team_name_holder: '',
      team_name_confirmed: false

    }
  }

  componentDidMount = async () => {

    if (!this.props.is_admin) {
      alert('You do not have access to take this action');
      await this.props.history.push(`/dashboard`);
    }


    try {
      this.setState({
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }


  handle_confirm_team_name_change = (e) => {
    const confirm_text = e.target.value;

    this.setState({ 
      confirm_team_name_holder: confirm_text,  
      team_name_confirmed: (confirm_text === `Delete ${this.props.selected_team_name}`)
    });
  }

  clear_confirm_team_name = () => {
    this.setState({
      confirm_team_name_holder: '',
      team_name_confirmed: false
    })
  }


  delete_team = async () => {

    //
    this.setState({
      error_message: '',
      awaiting_server_response: true
    });


    /**************************************
    * 1. Call the comprehensive delete team API call
    ***************************************/

    const delete_team_res = await auth_axios.put(`/api/teams/${this.props.selected_team_id}/remove`, {
      user_id: this.props.user_id
    });

    if (!delete_team_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with delete team'
      });
      return;
    }

    /**************************************
    * 2. Delete the team public key from the client-side team_public_keys 
    ***************************************/

    const original_team_public_keys = await dbGet('team_public_keys');
    let new_team_public_keys = {...original_team_public_keys}
    delete new_team_public_keys[this.props.selected_team_id];

    // set
    await dbSet('team_public_keys', new_team_public_keys)


    /**************************************
    * 3. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false
    });



    /**************************************
    * 4. Mixpanel tracking
    ***************************************/

    mixpanel.track('web_delete_team_modal_submitted', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });

  
    /**************************************
    * 5. Refresh page (to get logged out)
    ***************************************/

    // Reload
    window.location.reload(false);
  }

  

  render() {

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_delete_team_modal_close();
            this.clear_confirm_team_name();
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 240 : 340,
            width: screen_width > 690 ? 400 : 300
          }
        }}
      >
        <div className="delete-team-modal__container">
          <div
            className='delete-team-modal__text' 
          >
            Deleting the team will result in a secure removal of all of your team's data and is irreversible. To proceed, please type <span className='delete-team-modal__text-bold'>Delete {this.props.selected_team_name}</span> below to confirm.
          </div>
          <input
            className='delete-team-modal__confirm-team-name-input'
            placeholder={`Delete ${this.props.selected_team_name}`}
            type='text'
            value={ this.state.confirm_team_name_holder }
            onChange={ this.handle_confirm_team_name_change }
            disabled={ this.state.awaiting_server_response }
          />
          <div className="delete-team-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    this.state.team_name_confirmed
                    ? <input
                        type='submit'
                        className={`delete-team-modal__delete-button`}
                        value={ 'Delete' }
                        onClick={ this.delete_team }
                      />
                    : <input
                        type='submit'
                        className={`delete-team-modal__delete-button disabled`}
                        value={ 'Delete' }
                      />
                  }
                  <a
                    className='delete-team-modal__cancel'
                    onClick={() => {
                      this.props.on_delete_team_modal_close();
                      this.clear_confirm_team_name();
                    }}
                  >
                    Cancel
                  </a>
                </>
            }
          </div>
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default DeleteTeamModal;
