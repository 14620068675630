import React from 'react';
import './UpdatePrimaryEmailModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';

import { get as dbGet, set as dbSet } from 'idb-keyval';
import { CSPRNG, PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT } from '../../../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../../../crypto/util';

import {auth_axios} from '../../../../../auth/util';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


class UpdatePrimaryEmailModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      new_primary_email_holder: props.primary_email,
      master_password_holder: '',
      input_valid: false,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({        
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.primary_email !== prevProps.primary_email) {
      this.setState({        
        new_primary_email_holder: this.props.primary_email,
      })
    }
  }

  reset_modal = () => {
    this.setState({
      new_primary_email_holder: this.props.primary_email,
      master_password_holder: '',
      error_message: '',
    })
  }

  handle_new_primary_email_change = (e) => {
    const new_primary_email = e.value;

    this.setState({ 
      new_primary_email_holder: new_primary_email, 
      input_valid: (new_primary_email !== this.props.primary_email) && (this.state.master_password_holder !== ''),
    });
  }

  handle_master_password_change = (e) => {
    const new_master_password = e.target.value;

    this.setState({ 
      master_password_holder: new_master_password, 
      input_valid: (this.state.new_primary_email_holder !== this.props.primary_email) && (new_master_password !== ''),
    });
  }


  update_primary_email = async () => {

    try {
      //
      this.setState({
        error_message: '',
        awaiting_server_response: true
      });

      /**************************************
      * 1. Pre-processing
      ***************************************/

      // Master password
      const master_password__unenc__string = this.state.master_password_holder;
      const master_password__unenc__uint8_array = string_to_uint8_array(master_password__unenc__string);

      // Get the secret key from IndexedDb
      const account_secret_key__unenc__base64 = await dbGet('account_secret_key__unenc');
      const account_secret_key__unenc__uint8_array = base64_to_uint8_array(account_secret_key__unenc__base64);

      /**************************************
      * 2. Check if the master password is correct, by unlocking the account private key from IndexedDb 
      ***************************************/

      // Derive account_unlock_key
      const original_account_salt_21__unenc__uint8_array = string_to_uint8_array(this.props.primary_email);
      const original_account_salt_22__unenc__uint8_array = string_to_uint8_array(this.props.primary_email.split('@')[0]);

      const original_intermediate_key__unenc__uint8_array = await HKDF(account_secret_key__unenc__uint8_array, original_account_salt_21__unenc__uint8_array, original_account_salt_22__unenc__uint8_array);

      const original_account_unlock_key__unenc__uint8_array = XOR(original_intermediate_key__unenc__uint8_array, master_password__unenc__uint8_array);

      const account_private_key__enc_original_auk__base64 = await dbGet('account_private_key__enc_auk');

      const account_private_key__enc_original_auk__uint8_array = base64_to_uint8_array(account_private_key__enc_original_auk__base64)

      const account_private_key__unenc__uint8_array = await SYM_DECRYPT(original_account_unlock_key__unenc__uint8_array, account_private_key__enc_original_auk__uint8_array);


      /**************************************
      * 3. If master password is correct, then proceed with encrypting the account_private_key afresh with the new primary email 
      ***************************************/

      // Derive account_unlock_key
      const new_account_salt_21__unenc__uint8_array = string_to_uint8_array(this.state.new_primary_email_holder);
      const new_account_salt_22__unenc__uint8_array = string_to_uint8_array(this.state.new_primary_email_holder.split('@')[0]);

      const new_intermediate_key__unenc__uint8_array = await HKDF(account_secret_key__unenc__uint8_array, new_account_salt_21__unenc__uint8_array, new_account_salt_22__unenc__uint8_array);

      const new_account_unlock_key__unenc__uint8_array = XOR(new_intermediate_key__unenc__uint8_array, master_password__unenc__uint8_array);

      // Encrypt account_private_key with the new account_unlock_key
      const account_private_key__enc_new_auk__uint8_array = await SYM_ENCRYPT(new_account_unlock_key__unenc__uint8_array, account_private_key__unenc__uint8_array);

      const account_private_key__enc_new_auk__base64 = uint8_array_to_base64(account_private_key__enc_new_auk__uint8_array)

      /**************************************
      * 4. Update IndexedDb
      ***************************************/

      await dbSet('account_private_key__enc_auk', account_private_key__enc_new_auk__base64);

      /**************************************
      * 5. Call the update primary email comprehensive API call
      ***************************************/

      const update_primary_email_res = await auth_axios.put(`/api/users/${this.props.user_id}/primary-email`, {
        new_primary_email: this.state.new_primary_email_holder,
        account_private_key__enc_auk: account_private_key__enc_new_auk__base64
      });

      if (!update_primary_email_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with update primary email'
        });
        return;
      }

      /**************************************
      * 6. Stop loading 
      ***************************************/

      this.setState({
        awaiting_server_response: false
      });



      /**************************************
      * 7. Mixpanel tracking
      ***************************************/

      mixpanel.track('web_update_primary_email_modal_submitted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });


      /**************************************
      * 8. Refresh page to see changes on dashboard immediately!
      ***************************************/

      // Reload
      window.location.reload(false);
    }




    catch (error) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Wrong master password'
      });
    }


    
  }



  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_update_primary_email_modal_close();
            this.reset_modal();
          }
        }}
        style={{
          content: {
            height: 360,
            width: 340
          }
        }}
      >
        <div className="update-primary-email-modal__container">
          <div
            className='update-primary-email-modal__text' 
          >
            Select the email address to set as primary. Primary email address is used to log into PlusIdentity, and all major communications are directed there.
          </div>
          <Select
            options={this.props.email_addresses.map((email_address) => ({
              label: email_address,
              value: email_address
            }))} 
            value={{
              label: this.state.new_primary_email_holder,
              value: this.state.new_primary_email_holder
            }}
            isSearchable
            onChange={ this.handle_new_primary_email_change }
            isDisabled={this.state.awaiting_server_response}
            className='update-primary-email__fields-field-first update-primary-email__search-select'
            classNamePrefix='update-primary-email__search-select-child'
          />
          <div
            className='update-primary-email-modal__text' 
          >
            Enter master password to confirm
          </div>
          <input
            className='update-primary-email-modal__master-password-input'
            placeholder={'Master password'}
            type={this.state.master_password_holder ? "password" : "text"}
            value={ this.state.master_password_holder }
            onChange={ this.handle_master_password_change }
            disabled={ this.state.awaiting_server_response }
            autoComplete="new-password"
          />
          <div className="update-primary-email-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    this.state.input_valid
                    ? <input
                        type='submit'
                        className={`update-primary-email-modal__update-button`}
                        value={ 'Update primary email' }
                        onClick={ this.update_primary_email }
                      />
                    : <input
                        type='submit'
                        className={`update-primary-email-modal__update-button disabled`}
                        value={ 'Update primary email' }
                      />
                  }
                  <a
                    className='update-primary-email-modal__cancel'
                    onClick={() => {
                      this.props.on_update_primary_email_modal_close();
                      this.reset_modal();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          <span className='update-primary-email-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default UpdatePrimaryEmailModal;
