import React from 'react';
import './RequestRecoveryModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';

import { get as dbGet, set as dbSet } from 'idb-keyval';
import { CSPRNG, PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT } from '../../../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../../../crypto/util';

import {auth_axios} from '../../../../../auth/util';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


class RequestRecoveryModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      selected_admin_user_id_holder: '',
      request_sent: false,

      status_message: '',
      error_message: ''
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({        
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  // componentDidUpdate = (prevProps) => {
  //   if (this.props.primary_email !== prevProps.primary_email) {
  //     this.setState({        
  //       selected_admin_user_id_holder: this.props.primary_email,
  //     })
  //   }
  // }

  reset_modal = () => {
    this.setState({
      selected_admin_user_id_holder: '',
      error_message: '',
    })
  }

  handle_admin_user_id_change = (e) => {
    this.setState({ 
      selected_admin_user_id_holder: e.value, 
    });
  }

  on_submit = async () => {

    try {
      //
      this.setState({
        error_message: '',
        awaiting_server_response: true
      });

      /**************************************
      * 1. Pre-processing
      ***************************************/

      const selected_admin_user_id = this.state.selected_admin_user_id_holder;

      /**************************************
      * 2. Call the request admin recovery API call
      ***************************************/

      const request_admin_recovery_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/request-admin-recovery`, {
        requestor_user_id: this.props.user_id,
        admin_user_id: selected_admin_user_id
      });

      if (!request_admin_recovery_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with request admin recovery'
        });
        return;
      }
      

      /**************************************
      * 3. Stop loading 
      ***************************************/

      this.setState({
        awaiting_server_response: false,
        request_sent: true,
        status_message: `Request has been sent to ${this.props.user_id_display_name_maps[selected_admin_user_id]}.`,
      });

      /**************************************
      * 4. Mixpanel tracking
      ***************************************/

      mixpanel.track('web_request_recovery_modal_submitted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });
    }




    catch (error) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Error occurred while requesting account recovery'
      });
    }
  }



  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_request_recovery_modal_close();
            this.reset_modal();
          }
        }}
        style={{
          content: {
            height: 260,
            width: 340
          }
        }}
      >
        <div className="request-recovery-modal__container">
          <div
            className='request-recovery-modal__text' 
          >
            Select team admin you would like to send the recovery request to.
          </div>
          <Select
            options={this.props.selected_team_admin_user_ids.filter((admin_user_id) => admin_user_id !== this.props.user_id).map((filtered_admin_user_id) => ({
              label: this.props.user_id_display_name_maps[filtered_admin_user_id],
              value: filtered_admin_user_id
            }))} 
            value={{
              label: this.props.user_id_display_name_maps[this.state.selected_admin_user_id_holder],
              value: this.state.selected_admin_user_id_holder
            }}
            isSearchable
            onChange={ this.handle_admin_user_id_change }
            isDisabled={this.state.awaiting_server_response || this.state.request_sent}
            className='request-recovery-modal__fields-field-first request-recovery-modal__search-select'
            classNamePrefix='request-recovery-modal__search-select-child'
          />
          <div className="request-recovery-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    (this.state.selected_admin_user_id_holder && (!this.state.request_sent))
                    ? <input
                        type='submit'
                        className={`request-recovery-modal__update-button`}
                        value={ 'Send request' }
                        onClick={ this.on_submit }
                      />
                    : <input
                        type='submit'
                        className={`request-recovery-modal__update-button disabled`}
                        value={ 'Send request' }
                      />
                  }
                  <a
                    className='request-recovery-modal__cancel'
                    onClick={() => {
                      this.props.on_request_recovery_modal_close();
                      this.reset_modal();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          {
            this.state.request_sent
            ? <span className='add-email-address-modal__status-message'>{this.state.status_message}</span>
            : <></>
          }
          <span className='request-recovery-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default RequestRecoveryModal;
