import React from 'react';
import './TalkToUsModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { auth_axios } from '../../auth/util';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


class TalkToUsModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      message_holder: '',
      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  handle_message_change = (e) => {
    this.setState({ 
      message_holder: e.target.value,  
    });
  }

  connect_with_us = async () => {

    this.setState({
      awaiting_server_response: true,
    });

    const message = this.state.message_holder.replace(/\r\n|\r|\n/g,"<br />")

    const talk_to_us_res = await auth_axios.post('/api/util/talk-to-us', {
      team_id: this.props.selected_team_id,
      user_id: this.props.user_id,
      message: message
    });

    if (!talk_to_us_res.data.success) {
      this.setState({
        awaiting_server_response: false,
      });
      return;
    } 

    if (talk_to_us_res.data.success) {
      this.setState({
        message_holder: '',
        awaiting_server_response: false,
      });

      this.props.on_talk_to_us_modal_close();


      
      // Mixpanel web_talk_to_us_modal_submitted
      mixpanel.track('web_talk_to_us_modal_submitted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });
    }
  }

  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_talk_to_us_modal_close();
          }
        }}
        style={{
          content: {
            height: 300,
            width: 500
          }
        }}
      >
        <div className="talk-to-us-modal__container">
          <div
            className='talk-to-us-modal__text' 
          >
            Please let us know anything that's on your mind, and we'll connect you right away with <span className='talk-to-us-modal__text-bold'>Channy</span> from Team PlusIdentity. We promise to get back to you within 12 hours.
          </div>
          {
            this.state.awaiting_server_response
            ? <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            : <>
                <textarea
                  className='talk-to-us-modal__contact-text-input'
                  placeholder="Tell us what's on your mind"
                  type='text'
                  value={ this.state.message_holder }
                  onChange={ this.handle_message_change }
                  disabled={ this.state.awaiting_server_response }
                />
                <input
                  type='submit'
                  className='talk-to-us-modal__delete-button'
                  value={ 'Connect' }
                  onClick={ this.connect_with_us }
                />
                <a
                  className='talk-to-us-modal__cancel'
                  onClick={() => {
                    this.props.on_talk_to_us_modal_close();
                  }}
                >
                  Cancel
                </a>
              </>
          }
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default TalkToUsModal;
