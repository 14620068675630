import React from 'react';
import ReactLoading from 'react-loading';

import { ReactComponent as PlusLock } from '../../logos/plusidentity-lock.svg';

import { ReactComponent as SquareLogo } from '../../logos/logo-square-white.svg';

import './Loading.css';


class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    return (
      <>
        <div className="navbar">
          <div className="navbar-container container">
            <div id="left-menu">
              <div className="navbar-logo">
                <SquareLogo className="navbar-icon" />
                plusidentity
              </div>
            </div>
          </div>
        </div>
        <div className='loading__container'>
          <div className="loading__top">
            {/* <span className="loading__title">T I T L E</span> */}
            <div className="loading__plus-lock">
              <PlusLock
                className="loading__plus-lock-svg"
              />
            </div>
          </div>
          <div className="loading__bottom">
    
          </div>
        </div>
      </>
    )
  }

}



export default Loading;




