import React from 'react';
import './RegisterDevice.css';

import axios from 'axios';
import ReactLoading from 'react-loading';


import { PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT, ASYM_DECRYPT } from '../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { auth_axios, sleep } from '../../../auth/util'

import { set as dbSet, del as dbDel } from 'idb-keyval';


import { ReactComponent as PlusLock } from '../../../logos/plusidentity-lock.svg';
import { ReactComponent as ContinueButton } from './media/svg/continue-button.svg';


import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


class RegisterDevice extends React.Component {

  constructor(props) {
    super(props);

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const query_params = query__string.split(/email_address=|&registration_id=/).slice(1);

    let email_valid, email_address__string, registration_id_valid, registration_id__string;
    let awaiting_server_response = false;

    // This is redirect from login
    if (query_params.length === 1) {
      [email_valid, email_address__string] = this.validate_email_address(query_params[0]);

      registration_id_valid = false
      registration_id__string = '';
    }

    // This is valid magic link
    else if (query_params.length === 2) {
      [email_valid, email_address__string] = this.validate_email_address(query_params[0]);

      [registration_id_valid, registration_id__string] = this.validate_registration_id(query_params[1]);
    }

    // Invalid link altogether, send back to login
    else {
      awaiting_server_response = true
    }


    this.state = {
      master_password_holder: '',
      email_valid: email_valid,
      registration_id_valid: email_valid && registration_id_valid,

      email_address: email_valid ? email_address__string : '',
      registration_id: registration_id_valid ? registration_id__string : '',

      magic_link_sent: false,
      error_message: '',
      awaiting_server_response: awaiting_server_response
    }
  }

  validate_email_address = (query_email_address) => {
    // const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    if (!query_email_address) {
      return [false, ''];
    }

    let contains_one_at = (((query_email_address).match(/@/g) || []).length === 1);
    let one_dot_right = ((((query_email_address).split('@')[1] || '').match(/\./g) || []).length > 0);
  
    // TODO do more checking than just seeing if @ is contained...
    return [(contains_one_at && one_dot_right), query_email_address];
  }

  validate_registration_id = (query_registration_id) => {  
    // TODO
    return [true, query_registration_id];
  }

  componentDidMount = async () => {
    // If both email and registration_id are invalid, then redirect to login
    if (!this.state.email_valid && !this.state.registration_id_valid) {
      await this.props.history.push(`/login`);
    }

    // If both email and registration_id are valid, then verify validity of the magic link
    else if (this.state.email_valid && this.state.registration_id_valid) {

      // Disable submit button until verified
      this.setState({
        awaiting_server_response: true
      });

      // Send
      const validate_registration_res = await axios.post(`/api/registrations/${this.state.registration_id}/validate`, {
        email_address: this.state.email_address,
        type: 'register-device'
      });

      // Parse server response on registration validation
      if (validate_registration_res) {
        if (validate_registration_res.data.success) {
          this.setState({
            awaiting_server_response: false
          });
        } 
        // Server sent back failure response
        else {
          alert('Device registration link is invalid - registration id is not valid');
          await this.props.history.push(`/login`);
        }
      }

      // Server did not send back anything
      else {
        alert('Device registration link is invalid - server did not send back anything');
        await this.props.history.push(`/login`);
      }
      
      mixpanel.track('web_register_device_visited', {        
        is_dev: this.props.mode_info.is_dev
      });
    }
  }

  handle_master_password_change = (e) => {
    this.setState({ master_password_holder: e.target.value, error_message: '' });
  }

  handleSubmitKeypress = async (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === 13 && (!this.state.awaiting_server_response)) {
      await this.on_submit();
    }
  };
 
  on_submit = async () => {

    const registration_id = this.state.registration_id;

    try {

      this.setState({
        awaiting_server_response: true
      });

      const email_address = this.state.email_address;

      ///////////////////////////////////
      // Fetch user_id from server
      ///////////////////////////////////

      const get_user_id_res = await axios.post('/api/util/email-address/user-id/fetch', {
        email_address: email_address
      });

      if (!get_user_id_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get user_id command'
        });
        return;
      }

      const user_id = get_user_id_res.data.user_id;



      
      /**************************************
      * 1. Encryption
      ***************************************/

      // Configure entered master password 
      const master_password__unenc__string = this.state.master_password_holder;
      const master_password__unenc__uint8_array = string_to_uint8_array(master_password__unenc__string)

      ///////////////////////////////////
      // Fetch account salt 1 from server
      ///////////////////////////////////

      const get_account_salt_1_res = await axios.post(`/api/users/${user_id}/account-salt-1/fetch`, {
        registration_id: registration_id
      });

      if (!get_account_salt_1_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get account salt 1'
        });
        return;
      }

      const account_salt_1__unenc__base64 = get_account_salt_1_res.data.account_salt_1__unenc;

      const account_salt_1__unenc__uint8_array = base64_to_uint8_array(account_salt_1__unenc__base64);


      ///////////////////////////////////
      // Derive account secret key
      ///////////////////////////////////

      const account_secret_key__unenc__uint8_array = await PBKDF2(master_password__unenc__uint8_array, account_salt_1__unenc__uint8_array);

      const account_secret_key__unenc__base64 = uint8_array_to_base64(account_secret_key__unenc__uint8_array);

      ///////////////////////////////////
      // Derive account_unlock_key
      ///////////////////////////////////

      const account_salt_21__unenc__uint8_array = string_to_uint8_array(email_address);
      const account_salt_22__unenc__uint8_array = string_to_uint8_array(email_address.split('@')[0]);

      const intermediate_key__unenc__uint8_array = await HKDF(account_secret_key__unenc__uint8_array, account_salt_21__unenc__uint8_array, account_salt_22__unenc__uint8_array);

      const account_unlock_key__unenc__uint8_array = XOR(intermediate_key__unenc__uint8_array, master_password__unenc__uint8_array);



      ///////////////////////////////////
      // Fetch account_private_key from server then derive
      ///////////////////////////////////

      const get_account_private_key_res = await axios.post(`/api/users/${user_id}/account-private-key/fetch`, {
        registration_id: registration_id
      });

      if (!get_account_private_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get account private key'
        });
        return;
      }

      const account_private_key__enc_auk__base64 = get_account_private_key_res.data.account_private_key__enc_auk;

      const account_private_key__enc_auk__uint8_array = base64_to_uint8_array(account_private_key__enc_auk__base64);

      const account_private_key__unenc__uint8_array = await SYM_DECRYPT(account_unlock_key__unenc__uint8_array, account_private_key__enc_auk__uint8_array);



      
      ///////////////////////////////////
      // Fetch account_public_key from server
      ///////////////////////////////////

      const get_account_public_key_res = await axios.get(`/api/users/${user_id}/account-public-key`);

      if (!get_account_public_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get account public key'
        });
        return;
      }

      const account_public_key__unenc__base64 = get_account_public_key_res.data.account_public_key__unenc;

      const account_public_key__unenc__uint8_array = base64_to_uint8_array(account_public_key__unenc__base64)



      ///////////////////////////////////
      // Fetch server public key from server
      ///////////////////////////////////

      // Get server_public_key from server
      const get_server_public_key_res = await axios.get('/api/keys/server-public-key');
      if (!get_server_public_key_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Server public key get went wrong'
        });
        return;
      }

      const server_public_key__unenc__base64 = get_server_public_key_res.data.server_public_key__unenc;

      // CLIENT PERSISTENCE

      // Delete first for safety
      await dbDel('server_public_key__unenc');
      await dbDel('account_secret_key__unenc');
      await dbDel('account_private_key__enc_auk');
      await dbDel('account_public_key__unenc');

      // Set
      await dbSet('server_public_key__unenc', server_public_key__unenc__base64);
      await dbSet('account_secret_key__unenc', account_secret_key__unenc__base64);
      await dbSet('account_private_key__enc_auk', account_private_key__enc_auk__base64);
      await dbSet('account_public_key__unenc', account_public_key__unenc__base64);
    



      ///////////////////////////////////
      // Fetch team public keys from server
      ///////////////////////////////////

      const get_team_public_keys_res = await axios.get(`/api/users/${user_id}/team-public-keys`);
      if (!get_team_public_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Get team public keys went wrong'
        });
        return;
      }

      const team_public_keys = get_team_public_keys_res.data.team_public_keys;


      // Delete first for safety
      await dbDel('team_public_keys');

      // Set
      await dbSet('team_public_keys', team_public_keys);
      





      /**************************************
      * 2. Generate device private public key pair
      ***************************************/

      // Generate device key pair
            
      const [device_private_key__unenc__uint8_array, device_public_key__unenc__uint8_array] = await GEN_KEY_PAIR();


      const device_private_key__unenc__base64 = uint8_array_to_base64(device_private_key__unenc__uint8_array); // Send to client persistence
      const device_public_key__unenc__base64 = uint8_array_to_base64(device_public_key__unenc__uint8_array); // Send to client & server persistence


      // Delete first for safety
      await dbDel('device_private_key__unenc');
      await dbDel('device_public_key__unenc');

      // Set
      await dbSet('device_private_key__unenc', device_private_key__unenc__base64);
      await dbSet('device_public_key__unenc', device_public_key__unenc__base64);


      
      
      /**************************************
      * 3. Register this device
      ***************************************/

      ///////////////////////////////////
      // Generate new device_id from server
      ///////////////////////////////////

      const gen_device_id_res = await axios.get('/api/devices/id/new');

      if (!gen_device_id_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get new device id'
        });
        return;
      }

      const device_id = gen_device_id_res.data.device_id;

      ///////////////////////////////////
      // Register this device_id on the server
      ///////////////////////////////////

      const register_device_res = await axios.post(`/api/devices/${device_id}`, {
        user_id: user_id    
      });

      if (!register_device_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with register device command'
        });
        return;
      } 

      /**************************************
      * 4. Update device_keychains
      ***************************************/

      ///////////////////////////////////
      // Fetch item_keys and derive device_keys by encrypting with account_recovery_key
      ///////////////////////////////////

      const get_item_keys_res = await axios.get(`/api/users/${user_id}/item-keys`);

      if (!get_item_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Get item keys failed'
        });
        return;
      } 

      const item_keys = get_item_keys_res.data.item_keys;

      let item_keys__enc_dpubk = {}

      for (const item_id in item_keys) {
        const item_key__enc_apubk__base64 = item_keys[item_id];
        const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);

        const item_key__unenc__uint8_array = await ASYM_DECRYPT(account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array);

        const item_key__enc_dpubk__uint8_array = await ASYM_ENCRYPT(device_public_key__unenc__uint8_array, item_key__unenc__uint8_array);

        const item_key__enc_dpubk__base64 = uint8_array_to_base64(item_key__enc_dpubk__uint8_array)

        item_keys__enc_dpubk[item_id] = item_key__enc_dpubk__base64;
      }

      ///////////////////////////////////
      // Update device_keys for the user with this 
      ///////////////////////////////////

      // Create device_keychain
      const device_keychain = {
        device_public_key__unenc: device_public_key__unenc__base64,
        item_keys: item_keys__enc_dpubk,
        // registered_at: new Date()
      }

      // Send to client
      const insert_device_keychain_res = await axios.put(`/api/users/${user_id}/device-keychain`, {
        device_id: device_id,  
        device_keychain: device_keychain
      });

      if (!insert_device_keychain_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with insert device keychain'
        });
        return;
      }




      /**************************************
      * 5. Create new session
      ***************************************/

      const create_session_res = await axios.post('/api/sessions/create', {
        user_id: user_id,
        device_id: device_id,
        type: 'web'
      });

      if (!create_session_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with create session command'
        });
        return;
      } 

      
      // Save session id and account private key enc_sk in client persistence
      const session_token__unenc__base64 = create_session_res.data.session_token__unenc;
      const session_key__unenc__base64 = create_session_res.data.session_key__unenc;

      const session_key__unenc__uint8_array = base64_to_uint8_array(session_key__unenc__base64);
      const account_private_key__enc_sk__uint8_array = await SYM_ENCRYPT(session_key__unenc__uint8_array, account_private_key__unenc__uint8_array);

      const account_private_key__enc_sk__base64 = uint8_array_to_base64(account_private_key__enc_sk__uint8_array);




      // Delete first for safety
      await dbDel('session_token__unenc');
      await dbDel('account_private_key__enc_sk');

      await dbSet('session_token__unenc', session_token__unenc__base64);
      await dbSet('account_private_key__enc_sk', account_private_key__enc_sk__base64);


      /**************************************
      * 6. Delete registration 
      ***************************************/ 

      const delete_registration_res = await axios.delete(`/api/registrations/${registration_id}`);

      if (!delete_registration_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with delete registration command'
        });
        return;
      } 





      /**************************************
      * 7. Mixpanel
      ***************************************/

      mixpanel.track('web_register_device_submitted', {  
        distinct_id: user_id,
        is_dev: this.props.mode_info.is_dev
      });
      
       




      

      /**************************************
      * 8. Redirect to dashboard
      ***************************************/

      // Redirect and reload
      // await this.props.history.push(`/dashboard`);
      window.location.reload(false);
    }
    



    catch {
      // If error, delete the registration entry
      // await axios.post('/api/registration/delete-registration', {
      //   registration_id: registration_id,
      // });

      this.setState({
        awaiting_server_response: false,
        error_message: 'Wrong master password'
      });
    }

  }

  // Email address is entered in
  on_send_registration_link = async () => {

    this.setState({
      awaiting_server_response: true,
    });

    // First check if the email exists or not

    const email_check_res = await axios.post('/api/util/email-address/exists', {
      email_address: this.state.email_address
    });

    // If email does not exist, then do nothing
    if (!email_check_res.data || !email_check_res.data.email_exists) {
      await sleep(2);

      this.setState({
        magic_link_sent: true,
        awaiting_server_response: false,
      });
      return;
    }


    // Trigger magic link send
    const send_magic_link_res = await axios.post('/api/util/email-address/send-register-device-magic-link', {
      email_address: this.state.email_address
    });

    if (send_magic_link_res) {

      if (send_magic_link_res.data.success) {
        this.setState({
          magic_link_sent: true,
          awaiting_server_response: false,
          // error_message: 'Magic link sent! Please check your email.'
        });

        // Mixpanel
        mixpanel.track('web_register_device_registration_link_sent', {  
          is_dev: this.props.mode_info.is_dev
        });

      }

      else {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with the server... sorry about that!'
        });
      }
    }

    // Nothing came back from the server...
    else {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went terribly wrong with the server... sorry about that!'
      });
    }
  }

  on_forgot_password = async () => {
    const email_address = this.state.email_address;

    ///////////////////////////////////
    // Fetch user_id from server
    ///////////////////////////////////

    const get_user_id_res = await axios.post('/api/util/email-address/user-id/fetch', {
      email_address: email_address
    });

    if (!get_user_id_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with get user_id command'
      });
      return;
    }

    const user_id = get_user_id_res.data.user_id;

    const param__base64 = uint8_array_to_base64(string_to_uint8_array('email_address=' + email_address + '&user_id=' + user_id));
    await this.props.history.push(`/forgot-password?${param__base64}`);
  }

  render() {
    return (
      <div className="register-device__container">
        <div className="register-device__top">
          <span className="register-device__title">R E G I S T E R &nbsp;&nbsp;&nbsp; D E V I C E</span>
          <div className="register-device__plus-lock"><PlusLock/></div>
        </div>
        <div className="register-device__bottom">
          {
            this.state.registration_id_valid
              ? <> {/* Email set */}
                  
                  <div
                    className='register-device__bottom-input-box email'
                  >
                    <input 
                      className="register-device__bottom-input-field disabled"
                      type="text"
                      value={ this.state.email_address }
                      disabled
                    />
                  </div>

                  <div
                    className='register-device__bottom-input-box password'
                  >
                    <input 
                      className="register-device__bottom-input-field"
                      type={this.state.master_password_holder ? "password" : "text"}
                      placeholder="Master password"
                      autoFocus
                      onKeyPress={ this.handleSubmitKeypress }
                      value={ this.state.master_password_holder }
                      onChange={ this.handle_master_password_change }
                      disabled={ this.state.awaiting_server_response }
                      autoComplete="new-password"
                    />
                    <div
                      className='register-device__bottom-input-continue-button'
                    >
                      {
                        this.state.awaiting_server_response
                        ? <ReactLoading
                            type='spokes'
                            color='#9696ad'
                            height={20}
                            width={20}
                          />
                        : <div 
                            onClick={ this.on_submit }
                          >
                            <ContinueButton />
                          </div>
                      }
                    </div>
                  </div>

                  <div
                    className='register-device__forgot-password'
                    onClick={this.on_forgot_password}
                  >
                    Forgot master password?
                  </div>
                </>
              : <> {/* Pre */}
                  <div
                    className='register-device__bottom-input-box'
                  >
                    <input 
                      className="register-device__bottom-input-field disabled"
                      value={ this.state.email_address }
                      type="text" 
                      disabled
                    />
                  </div>
                  <div
                    className='register-device__bottom-send-link-action'
                  >
                    {
                      this.state.awaiting_server_response
                      ? <ReactLoading
                          type='spokes'
                          color='#9696ad'
                          height={20}
                          width={20}
                        />
                      : this.state.magic_link_sent
                        ? <span 
                            className='register-device__bottom-send-link-ack'
                          >
                            {`If account exists, a magic link was sent to ${this.state.email_address}.`}
                          </span>
                        : <input
                            className='register-device__bottom-send-link-button'
                            type="submit"
                            value="Send magic link"
                            onClick={ this.on_send_registration_link }
                          />
                    }
                  </div>
                </>
          }
          <span className="register-device__error-message">{ this.state.error_message }</span>
        </div>
      </div>
    );
  }
}

export default RegisterDevice;
