import React from 'react';
import './AddEmailAddress.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_ENCRYPT, ASYM_ENCRYPT, CSPRNG, GEN_KEY_PAIR } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string, uint8_array_to_base64 } from '../../../crypto/util';

import { send_slack_notification } from '../../../slack/util';

import { auth_axios } from '../../../auth/util';

import { set as dbSet, del as dbDel } from 'idb-keyval';


import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';




// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




class AddEmailAddress extends React.Component {
  
  constructor(props) {
    super(props);

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const url_params = new URLSearchParams(`?${query__string}`);

    const registration_id = url_params.get('registration_id');
    const new_email_address = url_params.get('email_address');
    const connect_user_id = url_params.get('user_id');

    const url_valid = (!((!registration_id) || (!new_email_address) || (!connect_user_id)));

    this.state = {
      awaiting_server_response: true,

      fatal_error_occurred: false,

      error_message: '',

      registration_id: registration_id,
      new_email_address: new_email_address,
      connect_user_id: connect_user_id,

      url_valid: url_valid,
    }
  }

  componentDidMount = async () => {
    try {

      /**************************************
      * 0. Check if correct user & validate registration
      ***************************************/

      if (this.state.connect_user_id !== this.props.user_id) {
        alert('Incorrect user - you do not have access to take this action');
        await this.props.history.push(`/dashboard`);
        return;
      }

      const validate_registration_res = await axios.post(`/api/registrations/${this.state.registration_id}/validate`, {
        email_address: this.state.new_email_address,
        type: 'add-email-address'
      });

      if (!validate_registration_res.data.success) {
        alert('Link is invalid - registration id is not valid');
        await this.props.history.push(`/dashboard`);
        return;
      }

      /**************************************
      * 1. Connect new email to the user entry
      ***************************************/

      const add_email_address_res = await auth_axios.post(`/api/users/${this.props.user_id}/add-email-address`, {
        registration_id: this.state.registration_id,
        email_address: this.state.new_email_address,
      });

      if (!add_email_address_res.data.success) {
        alert('Link is invalid - registration id is not valid');
        await this.props.history.push(`/dashboard`);
        return;
      }




      /**************************************
      * 2. Trigger post- connect account Slack server actions
      ***************************************/
      try {
        // Send POST request to slack.plusidentity.com server to indicate successful 

        const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

        const message = `:tada: You've successfully added *${this.state.new_email_address}* to your account!`

        await send_slack_notification(url, this.props.selected_team_id, this.props.user_id, message, {});
      }
      catch {
        console.error('CORS configurations not yet set up properly')
      }



      /**************************************
      * 4. Final actions
      ***************************************/

      this.setState({
        awaiting_server_response: false
      }); 


      /**************************************
      * 5. Mixpanel tracking code
      ***************************************/

      mixpanel.track('web_add_email_address_succeeded', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });
    
    
    }
    catch (error) {
      console.error(error)
      this.setState({
        error_message: 'fatal error',
        fatal_error_occurred: true
      });
    }
  }

  render() {
    return (
      <div className='add-email-address__container'>
        {
          this.state.url_valid
          ? this.state.awaiting_server_response
            ? <div className="add-email-address__awaiting_response">
                <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              </div>
            : <div className="add-email-address__main">
                <CheckLogo 
                  className='add-email-address__copied-check'
                />
                <div className="add-email-address__text">
                  {this.state.new_email_address} was successfully added to your account
                </div>
                
              </div>
          : <div className='add-email-address__error-message'>Invalid URL</div>
        }
      </div>
    );
  }
}

export default AddEmailAddress;
