import React from 'react';
import './Billing.css';


import axios from 'axios';
import ReactLoading from 'react-loading';

import { auth_axios } from '../../../../../auth/util';

import { Elements } from '@stripe/react-stripe-js';
// import {ElementsConsumer, PaymentElement} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

import SetupForm from './SetupForm';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




/*****************************************
* PRODUCTION / DEVELOPMENT TOGGLE
*****************************************/
const IS_DEV = false;
/*****************************************
* END OF TOGGLE
*****************************************/


const STRIPE_TEST_PUBLISHABLE_KEY = 'pk_test_51K2mH1JBd8M7u9KfZoRebYvWZ2gffhBBCmpK3qVu3Ss0kXT7x9xLiOrA5j2QkmlaY2RvEcbKUnElBwmzUnn79XRe001gmLKBgP';
const STRIPE_LIVE_PUBLISHABLE_KEY = 'pk_live_51K2mH1JBd8M7u9KfuwH0od3TkhtjnXELm7rKu1EHaQvnSPeUAg6CwhZYt03RbsbWlpL6k0XHJ5u86kQjB0u5D0U800iwvHfIyr';



const stripe_promise = loadStripe(IS_DEV ? STRIPE_TEST_PUBLISHABLE_KEY : STRIPE_LIVE_PUBLISHABLE_KEY);

const card_brand_map = {
  'visa': 'Visa',
  'mastercard': 'Mastercard',
  'amex': 'American Express',
  'cartes_bancaires': 'Cartes Bancaires',
  'diners_club': 'Diners Club',
  'discover': 'Discover',
  'jcb': 'JCB',
  'unionpay': 'UnionPay'
}

const format_month = (month_int) => {
  const month_str = month_int.toString()
  if (month_str.length === 2) {
    return month_str;
  }
  else if (month_str.length === 1) {
    return ('0' + month_str);
  }
}


const format_year = (year_int) => {
  const year_str = year_int.toString()
  if (year_str.length === 2) {
    return year_str;
  }
  else if (year_str.length === 4) {
    return year_str.slice(2);
  }
}

const beautify_tier = (tier) => {
  const mapping = {
    'plusidentity_business_free': 'Business (Free)',
    'plusidentity_business_paid': 'Business (Paid)',
    'plusidentity_advanced_paid': 'Advanced (Paid)',
  }

  if (mapping[tier]) {
    return mapping[tier];
  }

  else {
    return tier;
  }
}


const beautify_status = (status) => {
  const mapping = {
    'active': 'Active',
    'inactive': 'Inactive',
  }

  if (mapping[status]) {
    return mapping[status];
  }

  else {
    return status;
  }
}





const timestamp_to_date = (timestamp) => {
  const month_mapping = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
  }

  const day_mapping = {
    1: '1st',
    2: '2nd',
    3: '3rd',
    4: '4th',
    5: '5th',
    6: '6th',
    7: '7th',
    8: '8th',
    9: '9th',
    10: '10th',
    11: '11th',
    12: '12th',
    13: '13th',
    14: '14th',
    15: '15th',
    16: '16th',
    17: '17th',
    18: '18th',
    19: '19th',
    20: '20th',
    21: '21st',
    22: '22nd',
    23: '23rd',
    24: '24th',
    25: '25th',
    26: '26th',
    27: '27th',
    28: '28th',
    29: '29th',
    30: '30th',
    31: '31st'
  }

  const date = new Date(timestamp * 1000); // convert to milliseconds

  const month = month_mapping[date.getMonth()];
  const day = day_mapping[date.getDate()];
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`
}



const beautify_amount_due = (amount_due_cents) => {
  let amount_due = amount_due_cents.toString();

  if (amount_due.length === 2) {
    amount_due = '0' + amount_due;
  } else if (amount_due.length === 1) {
    amount_due = '00' + amount_due;
  }

  amount_due = '$' + amount_due.slice(0, -2) + '.' + amount_due.slice(-2);

  return amount_due;
}



class Billing extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
      fatal_error_occurred: false,

      business_tier_pivot: 0,
      tier: '',
      status: '',
      subscription_found: false,
      current_period_end: '',
      next_invoice_amount_due: '',

      item_keys: [],

      team_id_team_name_maps: {},

      error_message: '',

      client_secret: '',

      current_mode: 'view', // one of 'view', 'update' ...

      payment_method_info: {},

      payment_method_exists: false // This to be updated soon
    }
  }
  
  componentDidMount = async () => {
    try {
      // Protect against url paste access
      if (!this.props.is_admin) {
        // 
        alert('You do not have permission to view this page');
        await this.props.history.push('/dashboard/settings');
        return;
      }

      // If privileged, then show current payment method info and subscription details
      const get_billing_res = await auth_axios.get(`/api/teams/${this.props.selected_team_id}/billing`);

      if (!get_billing_res.data.success) {
        // 
        alert('Something went wrong with get tier details');
        await this.props.history.push('/dashboard/settings');
        return;
      } 

      const business_tier_pivot = get_billing_res.data.business_tier_pivot;
      const tier = get_billing_res.data.tier;
      const status = get_billing_res.data.status;
      const current_period_end_timestamp = get_billing_res.data.current_period_end;
      const next_invoice_amount_due_cents = get_billing_res.data.next_invoice_amount_due;

      let current_period_end, next_invoice_amount_due;

      const subscription_found = (!(current_period_end_timestamp === ''))

      if (subscription_found) {
        current_period_end = timestamp_to_date(current_period_end_timestamp);
        next_invoice_amount_due = beautify_amount_due(next_invoice_amount_due_cents);
      }

      let payment_method_info, payment_method_exists;
      if (get_billing_res.data.payment_method_exists) {
        payment_method_info = get_billing_res.data.payment_method_info;
        payment_method_exists = true;
      }
      else {
        payment_method_info = {}
        payment_method_exists = false;
      }

      this.setState({
        awaiting_server_response: false,
        business_tier_pivot: business_tier_pivot,
        tier: tier,
        status: status,
        subscription_found: subscription_found,
        current_period_end: current_period_end,
        next_invoice_amount_due: next_invoice_amount_due,
        payment_method_info: payment_method_info,
        payment_method_exists: payment_method_exists
      });

      // Mixpanel web_billing_visited
      mixpanel.track('web_billing_visited', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });
    }
    catch {
      // 
      alert('Something went wrong that needs investigating...');
      await this.props.history.push('/dashboard/settings');
    }
  }

  update_payment_method = async () => {
    try {
      this.setState({
        awaiting_server_response: true
      });

      // When trying to add a payment method, first create setup intent
      const create_setup_intent_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/billing/setup-intent`, {});

      if (!create_setup_intent_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Create setup intent went wrong'
        });
        return;
      }

      const client_secret = create_setup_intent_res.data.client_secret;

      this.setState({
        client_secret: client_secret,
        current_mode: 'update',
        awaiting_server_response: false
      });
    }
    catch (error) {

      // Trigger subscription deletion on the server side

      console.log(error)
    }
  }

  cancel_update_payment = async () => {
    this.setState({
      current_mode: 'view'
    })
  }





  // 
  cancel_subscription = async () => {

  }

  update_subscription = async () => {

  }


  on_add_modal_open = async () => {

  }


  onSubmit = async (e) => {
    e.preventDefault()
  }

  
  render() {
    
    const current_page = () => {
      let options = {
        clientSecret: this.state.client_secret,
        appearance: {
          theme: 'stripe',
          labels: 'floating',
          variables: {
            colorBackground: '#1A1924',
            colorText: '#fff',
            colorDanger: '#F87575',
          }
        },
      }

      if (this.state.current_mode === 'view') {
        return (
          <>
            {
              this.state.payment_method_exists
              ? <>
                  <div className='billing__section'>
                    <div className='billing__section-left'>
                      Plan info
                    </div>
                    <div className='billing__section-right'>
                      <div className="billing__section-right-field">
                        <div className='billing__section-right-field-name'>
                          Free up to
                        </div>
                        <div className='billing__section-right-field-value'>
                          {this.state.business_tier_pivot} users
                        </div>  
                        <div className="billing__section-right-action-container">
                          {/* Nothing */}
                        </div>
                      </div>
                      <div className="billing__section-right-field">
                        <div className='billing__section-right-field-name'>
                          Tier
                        </div>
                        <div className='billing__section-right-field-value'>
                          {beautify_tier(this.state.tier)}
                        </div>  
                        <div className="billing__section-right-action-container">
                          {/* Nothing */}
                        </div>
                      </div>
                      <div className="billing__section-right-field">
                        <div className='billing__section-right-field-name'>
                          Status
                        </div>
                        <div className='billing__section-right-field-value'>
                          {beautify_status(this.state.status)}
                        </div>  
                        <div className="billing__section-right-action-container">
                          {/* Nothing */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="billing__separator-horizontal"></div>

                  <div className='billing__section'>
                    <div className='billing__section-left'>
                      Payment info
                    </div>
                    <div className='billing__section-right'>
                      <div className="billing__section-right-field">
                        <div className='billing__section-right-field-name'>
                          {card_brand_map[this.state.payment_method_info.brand]} ending in
                        </div>
                        <div className='billing__section-right-field-value'>
                          {this.state.payment_method_info.last4}
                        </div>  
                        <div className="billing__section-right-action-container">
                          <button
                            className="billing__card-update-button" 
                            onClick={this.update_payment_method}
                          >
                            Update payment method
                          </button>
                        </div>
                      </div>
                      <div className="billing__section-right-field">
                        <div className='billing__section-right-field-name'>
                          Expiration date
                        </div>
                        <div className='billing__section-right-field-value'>
                          {format_month(this.state.payment_method_info.exp_month)}/{format_year(this.state.payment_method_info.exp_year)}
                        </div>  
                        <div className="billing__section-right-action-container">
                          {/* Nothing */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.subscription_found
                    ? <>
                        <div className="billing__separator-horizontal"></div>
                        <div className='billing__section'>
                          <div className='billing__section-left'>
                            Billing info
                          </div>
                          <div className='billing__section-right'>
                            <div className="billing__section-right-field">
                              <div className='billing__section-right-field-name'>
                                Next invoice billing
                              </div>
                              <div className='billing__section-right-field-value'>
                                {this.state.current_period_end}
                              </div>  
                              <div className="billing__section-right-action-container">
                                {/* Nothing */}
                              </div>
                            </div>
                            <div className="billing__section-right-field">
                              <div className='billing__section-right-field-name'>
                                Amount due on next invoice (projected)
                                <div
                                  className='billing__section-proration-text'
                                >
                                  The projected billing amount due upon next invoice is subject to change upon users being added to or removed from your team. The prorated amount is calculated based on the number of users in the team upon invoice, plus any prorated adjustments that need to be made regarding the change in number of users in the team since the previous invoice.
                                </div>
                              </div>
                              <div className='billing__section-right-field-value'>
                                {this.state.next_invoice_amount_due}
                              </div>  
                              <div className="billing__section-right-action-container">
                                {/* Nothing */}
                              </div> 
                            </div>
                          </div>
                        </div>
                      </>
                    : <></>
                  }
                </>
              : <>
                  <div className='billing__section'>
                    <div className='billing__section-left'>
                      Plan info
                    </div>
                    <div className='billing__section-right'>
                      <div className="billing__section-right-field">
                        <div className='billing__section-right-field-name'>
                          Free up to
                        </div>
                        <div className='billing__section-right-field-value'>
                          {this.state.business_tier_pivot} users
                        </div>  
                        <div className="billing__section-right-action-container">
                          {/* Nothing */}
                        </div>
                      </div>
                      <div className="billing__section-right-field">
                        <div className='billing__section-right-field-name'>
                          Tier
                        </div>
                        <div className='billing__section-right-field-value'>
                          {beautify_tier(this.state.tier)}
                        </div>  
                        <div className="billing__section-right-action-container">
                          {/* Nothing */}
                        </div>
                      </div>
                      <div className="billing__section-right-field">
                        <div className='billing__section-right-field-name'>
                          Status
                        </div>
                        <div className='billing__section-right-field-value'>
                          {beautify_status(this.state.status)}
                        </div>  
                        <div className="billing__section-right-action-container">
                          {/* Nothing */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="billing__separator-horizontal"></div>

                  <div className='billing__section'>
                    <div className='billing__section-left'>
                      Payment info
                    </div>
                    <div className='billing__section-right'>
                      <div className="billing__section-right-field">
                        <div className="billing__section-right-description">
                          No payment method exists.
                        </div>
                        <div className="billing__section-right-action-container">
                          <button
                            className="billing__card-update-button" 
                            onClick={this.update_payment_method}
                          >
                            Add payment method
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }
          </>
        )
      }
      else if (this.state.current_mode === 'update') {
        return(
          <>
            <Elements 
              stripe={stripe_promise} 
              options={options}
            >
              <SetupForm 
                cancel_update_payment={this.cancel_update_payment}
                is_dev={IS_DEV}
              />
            </Elements>
          </>
        )
      }
    }



    return (
      <div className='billing__container'>
        {
          this.state.awaiting_server_response
          ? <div className="billing__loading-container">
              <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            </div>
          : <div className='billing__contents'>
              
              {current_page()}
              
              <div className='billing__bottom'>
                <span className="billing__error-message">{ this.state.error_message }</span>
              </div>
            </div>
        }
      </div>
    );
  }
}

export default Billing;
