import React from 'react';
import ReactLoading from 'react-loading';

import { ReactComponent as PlusLock } from '../../logos/plusidentity-lock.svg';

import { ReactComponent as SquareLogo } from '../../logos/logo-square-white.svg';

import { sleep } from '../../auth/util';

import './VaultOpen.css';


class VaultOpen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount = async () => {
    // Unlocking animation
    await sleep(2);

    this.props.set_loading();
  }

  render() {
    return (
      <div className="vault-open">
        <div className="navbar">
          <div className="navbar-container container">
            <div id="left-menu">
              <div className="navbar-logo">
                <SquareLogo className="navbar-icon" />
                plusidentity
              </div>
            </div>
          </div>
        </div>
        <div className='vault-open__container'>
          <div className="vault-open__top">
            {/* <span className="vault-open__title">T I T L E</span> */}
            <div className="vault-open__plus-lock">
              <PlusLock
                className="vault-open__plus-lock-svg"
              />
            </div>
          </div>
          <div className="vault-open__bottom">
    
          </div>
        </div>
      </div>
    )
  }

}



export default VaultOpen;




