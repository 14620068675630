import React from 'react';
import './ExportItemsModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { auth_axios } from '../../../../../auth/util';

import { SYM_DECRYPT, ASYM_DECRYPT } from '../../../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string } from '../../../../../crypto/util';



// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


class ExportItemsModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      new_email_address_holder: '',
      input_valid: false,

      is_submitted: false,
      status_message: '',
      error_message: ''
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  reset_modal = () => {
    this.setState({
      new_email_address_holder: '',
      input_valid: false,

      is_submitted: false,
      status_message: '',
      error_message: ''
    })
  }



  export_items = async () => {

    //
    this.setState({
      error_message: '',
      status_message: '',
      awaiting_server_response: true
    });



    /**************************************
    * 1. Download all items
    ***************************************/


    // /:user_id/items/protected-data/fetch

    const all_items_protected_data_res = await auth_axios.post(`/api/users/${this.props.user_id}/items/protected-data/fetch`, {
      item_ids: Object.keys(this.props.item_keys),
    });

    if (!all_items_protected_data_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with decrypt & download'
      });
      return;
    }

    /**************************************
    * 2. Build up the items data
    ***************************************/

    let data = []

    // Preprocess account private key
    const account_private_key__unenc__uint8_array = this.props.account_private_key__unenc__uint8_array

    for (const item of all_items_protected_data_res.data.items) {

      console.log("item", item)

      if (item.protected_data === "secret") {
        
        data.push({
          item_name: item.item_name,
          item_type: item.item_type,
          ...item.unprotected_data
        })
      }

      else {
        // Unlock item key
        const item_key__enc_apubk__base64 = this.props.item_keys[item.item_id];

        const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);

        const item_key__unenc__uint8_array = await ASYM_DECRYPT(account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array)

        const protected_data__enc_ik__base64 = item.protected_data;

        const protected_data__enc_ik__uint8_array = base64_to_uint8_array(protected_data__enc_ik__base64);

        const protected_data__unenc__uint8_array = await SYM_DECRYPT(item_key__unenc__uint8_array, protected_data__enc_ik__uint8_array);

        const protected_data__unenc__string = uint8_array_to_string(protected_data__unenc__uint8_array);

        const decrypted_data = JSON.parse(protected_data__unenc__string);

        data.push({
          item_name: item.item_name,
          item_type: item.item_type,
          ...decrypted_data
        })
      }

    }

    

    /**************************************
    * 2. Export and download data into csv file
    ***************************************/

    // Convert data to CSV format without headers
    const convertToCSV = (data) => {
      return data.map(row =>
        Object.values(row).map(value => JSON.stringify(value || '')).join(',') // Escape and join values
      ).join('\n'); // Combine all rows
    };

    const csvData = convertToCSV(data);

    // Create a Blob and trigger download
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'items.csv'); // File name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up


    /**************************************
    * 3. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false,
      is_submitted: true,
      status_message: 'Download complete.',
    });



    /**************************************
    * 4. Mixpanel tracking
    ***************************************/

    // mixpanel.track('web_add_email_address_modal_is_submitted', {
    //   distinct_id: this.props.user_id,
    //   team_id: this.props.selected_team_id,
    //   is_dev: this.props.mode_info.is_dev
    // });

  
    // /**************************************
    // * 5. Refresh page to see changes on dashboard immediately!
    // ***************************************/

    // // Reload
    // window.location.reload(false);
  }



  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_export_items_modal_close();
            this.reset_modal();
          }
        }}
        style={{
          content: {
            height: 180,
            width: 320
          }
        }}
      >
        <div className="export-items-modal__container">
          <div
            className='export-items-modal__text' 
          >
            Decrypt & download all items.
          </div>
          <div className="export-items-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    !this.state.is_submitted
                    ? <input
                        type='submit'
                        className={`export-items-modal__update-button`}
                        value={ 'Decrypt & download all items' }
                        onClick={ this.export_items }
                      />
                    : <input
                        type='submit'
                        className={`export-items-modal__update-button disabled`}
                        value={ 'Decrypt & download all items' }
                      />
                  }
                  <a
                    className='export-items-modal__cancel'
                    onClick={() => {
                      this.props.on_export_items_modal_close();
                      this.reset_modal();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          {
            this.state.is_submitted
            ? <span className='export-items-modal__status-message'>{this.state.status_message}</span>
            : <></>
          }
          <span className='export-items-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default ExportItemsModal;
