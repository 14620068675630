import React from 'react';
import './RecoverAccount.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_ENCRYPT, ASYM_ENCRYPT, ASYM_DECRYPT, CSPRNG, GEN_KEY_PAIR, PBKDF2, HKDF, XOR } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string, uint8_array_to_base64, string_to_uint8_array } from '../../../crypto/util';

import { get as dbGet } from 'idb-keyval';


import { send_slack_notification } from '../../../slack/util';

import { auth_axios, sleep } from '../../../auth/util';

import { set as dbSet, del as dbDel } from 'idb-keyval';


import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';

import { ReactComponent as InfoIcon } from './media/svg/recover-account__info-icon.svg';
import { ReactComponent as PlusLock } from '../../../logos/plusidentity-lock.svg';




// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




class RecoverAccount extends React.Component {
  
  constructor(props) {
    super(props);

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const url_params = new URLSearchParams(`?${query__string}`);

    const type = url_params.get('type');

    // URL Params if type === device
    const registration_id = url_params.get('registration_id');
    const email_address = url_params.get('email_address');
    const user_id = url_params.get('user_id');

    // URL Params if type === admin
    // TODO

    let url_valid = false;

    if (type === 'device') {
      url_valid = (!((!registration_id) || (!email_address)) || (!user_id));
    } else if (type === 'admin') {
      // url_valid = (!((!___) || (!____)) || (!___));
    } else {
      url_valid = false;
    }

    this.state = {
      awaiting_server_response: true,

      fatal_error_occurred: false,

      status_message: 'Checking whether account recovery is possible . . .',
      warning_message: '',
      error_message: '',
      
      email_registration_id: registration_id,
      user_id: user_id,
      email_address: email_address,

      url_valid: url_valid,

      // cache_intact: false,
      continue_clicked: false,
      recovery_metadata: {},
      device_id: '',

      solo_admin_team_ids: [],
      non_solo_admin_team_ids: [],
      execute_recovery_team_ids: [],
      request_admin_team_ids: [],

      solo_admin_team_names: '',
      non_solo_admin_team_names: '',
      execute_recovery_team_names: '',
      request_admin_team_names: '',

      execute_recovery: false,
      request_admin: false,

      preflight_registration_id: '',

      new_master_password_holder: '',
      new_master_password_confirm_holder: '',
      
      new_master_password_valid: false,
    }
  }

  componentDidMount = async () => {
    try {
      /**************************************
      * 0. URL valid check
      ***************************************/

      if (!this.state.url_valid) {
        alert('Invalid URL');
        await this.props.history.push(`/login`);
        return;
      }

      /**************************************
      * 1. Validate registration_id
      ***************************************/

      const validate_registration_res = await axios.post(`/api/registrations/${this.state.email_registration_id}/validate`, {
        email_address: this.state.email_address,
        type: 'recover-account-device'
      });

      if (!validate_registration_res.data.success) {
        alert('Link is invalid - registration id is not valid');
        await this.props.history.push(`/login`);
        return;
      }

      /**************************************
      * 2. Fetch user id via email address and see if it matches the user_id from the URL param
      ***************************************/

      const get_user_id_res = await axios.post('/api/util/email-address/user-id/fetch', {
        email_address: this.state.email_address
      });

      if (!get_user_id_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get user_id command'
        });
        return;
      }

      const fetched_user_id = get_user_id_res.data.user_id;

      if (fetched_user_id !== this.state.user_id) {
        alert('Incorrect user - you do not have access to take this action');
        await this.props.history.push(`/login`);
        return;
      }

      /**************************************
      * 3. Fetch the device public keys and see if it matches the current 
      ***************************************/

      const device_private_key__unenc__base64 = await dbGet('device_private_key__unenc');
      const device_public_key__unenc__base64 = await dbGet('device_public_key__unenc');

      // Fetch device public keys from server and see if any of them matches, then save the device_id in the state if it does
      const get_device_public_keys_res = await axios.get(`/api/users/${this.state.user_id}/device-public-keys`);

      if (!get_device_public_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get device public keys'
        });
      }

      const device_public_keys = get_device_public_keys_res.data.device_public_keys;

      let device_id = '';
      for (const fetched_device_id in device_public_keys) {
        if (device_public_keys[fetched_device_id] === device_public_key__unenc__base64) {
          device_id = fetched_device_id;
        }
      }

      /**************************************
      * 4. Fetch recovery metadata from the server
      ***************************************/

      const recovery_metadata_res = await axios.get(`/api/users/${this.state.user_id}/recovery-metadata`);

      if (!recovery_metadata_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with get recovery metadata'
        });
        return;
      }

      const recovery_metadata = recovery_metadata_res.data.recovery_metadata;




      /**************************************
      * 5. Pre-process solo and non-solo admin team_ids
      ***************************************/

      const cache_intact = (!((!device_private_key__unenc__base64) || (!device_public_key__unenc__base64) || (device_id === '')));

      let solo_admin_team_ids = [];
      let non_solo_admin_team_ids = [];
      let execute_recovery_team_ids = [];
      let request_admin_team_ids = [];


      let solo_admin_team_names = '';
      let non_solo_admin_team_names = '';
      let execute_recovery_team_names = '';
      let request_admin_team_names = '';

      for (const team_id in recovery_metadata) {
        if (recovery_metadata[team_id].user_type === 'non_solo_admin') { // cache intact or not, always must request admin
          non_solo_admin_team_ids.push(team_id);
          request_admin_team_ids.push(team_id);

          non_solo_admin_team_names += `${recovery_metadata[team_id].team_name}, `;
          request_admin_team_names += `${recovery_metadata[team_id].team_name}, `;
        } 
        else if (recovery_metadata[team_id].user_type === 'solo_admin') { // only here if cache is intact
          solo_admin_team_ids.push(team_id);
          execute_recovery_team_ids.push(team_id);

          solo_admin_team_names += `${recovery_metadata[team_id].team_name}, `;
          execute_recovery_team_names += `${recovery_metadata[team_id].team_name}, `;
        } 
        else if (recovery_metadata[team_id].user_type === 'user') { // execute recovery if cache intact, request admin if not
          if (cache_intact) {
            execute_recovery_team_ids.push(team_id);
            execute_recovery_team_names += `${recovery_metadata[team_id].team_name}, `;
          }
          else {
            request_admin_team_ids.push(team_id);
            request_admin_team_names += `${recovery_metadata[team_id].team_name}, `;
          }
        } 
      }

      if (solo_admin_team_names) {
        solo_admin_team_names = solo_admin_team_names.slice(0, -2);
      }
      if (non_solo_admin_team_names) {
        non_solo_admin_team_names = non_solo_admin_team_names.slice(0, -2);
      }
      if (execute_recovery_team_names) {
        execute_recovery_team_names = execute_recovery_team_names.slice(0, -2);
      }
      if (request_admin_team_names) {
        request_admin_team_names = request_admin_team_names.slice(0, -2);
      }





      // **********
      // **********
      // **********
      // **********
      // **********
      // RUN THROUGH VARIOUS SCENARIOS
      // **********
      // **********
      // **********
      // **********
      // **********
      // **********



      // **********
      // CACHE-NOT-INTACT scenario AND solo-admin of at least one of the teams (color- coded RED) 
      // **********
      if ((!cache_intact) && (solo_admin_team_ids.length > 0)) {
        alert('We cannot verify your credentials on this browser, and you are a solo-admin in at least one of the teams you belong to. Try again from a different browser where you have previously logged into PlusIdentity.');
        await this.props.history.push(`/login`);
        return;
      }




      // QUICK ASIDE:
      // Universal set state for non-failure scenarios
      this.setState({
        awaiting_server_response: false,
        device_private_key__unenc: cache_intact ? device_private_key__unenc__base64 : '',
        device_public_key__unenc: cache_intact ? device_public_key__unenc__base64 : '',
        device_id: cache_intact ? device_id : '',
        recovery_metadata: recovery_metadata,

        solo_admin_team_ids: solo_admin_team_ids,
        non_solo_admin_team_ids: non_solo_admin_team_ids,
        execute_recovery_team_ids: execute_recovery_team_ids,
        request_admin_team_ids: request_admin_team_ids,

        solo_admin_team_names: solo_admin_team_names,
        non_solo_admin_team_names: non_solo_admin_team_names,
        execute_recovery_team_names: execute_recovery_team_names,
        request_admin_team_names: request_admin_team_names,
      });


      // **********
      // CACHE-INTACT scenario AND user_type on every team is either user or solo-admin (color-coded BLUE)
      // execute_recovery : TRUE
      // request_admin : FALSE
      // **********
      if ((cache_intact) && (non_solo_admin_team_ids.length === 0)) {
        this.setState({
          status_message: `You're in luck! Account recovery is available.`,
          execute_recovery: true,
          request_admin: false,
        });
      }


      // **********
      // CACHE-INTACT & non-solo admin for every team OR CACHE-NOT-INTACT and user or non-solo admin for every team (color-coded GREEN)
      // execute_recovery : FALSE
      // request_admin : TRUE
      // **********
      else if (((cache_intact) && (Object.keys(recovery_metadata).length === non_solo_admin_team_ids.length)) || ((!cache_intact) && (solo_admin_team_ids.length === 0))) {
        this.setState({
          status_message: `Account recovery is available through admin user(s) of the team(s) you belong to.`,
          execute_recovery: false,
          request_admin: true,
        });
      }

      // **********
      // CACHE-INTACT scenario AND contains at least one team where non-solo admin but also other team where user or solo-admin (color-coded PURPLE)
      // execute_recovery : TRUE
      // request_admin : TRUE
      // **********
      else if ((cache_intact) && (Object.keys(recovery_metadata).length > non_solo_admin_team_ids.length) && (non_solo_admin_team_ids.length > 0)) {
        this.setState({
          status_message: `Account recovery is available partially through your device and partially through admin user(s) of the team(s) you belong to.`,
          execute_recovery: true,
          request_admin: true,
        });
      }

      // **********
      // **********
      // **********
      // CODE SHOULD NOT REACH HERE, BUT A CATCH-ALL JUST IN CASE
      // **********
      else {
        alert('Account recovery not available. Try again from a different browser where you have previously logged into PlusIdentity.');
        await this.props.history.push(`/login`);
        return;
      }
      




      /**************************************
      * Mixpanel
      ***************************************/

      mixpanel.track('web_account_recovery_visited', {  
        distinct_id: this.state.user_id,
        is_dev: this.props.mode_info.is_dev
      });




    }


    
    catch (error) {
      console.error(error)
      this.setState({
        error_message: 'fatal error',
        fatal_error_occurred: true
      });
    }
  }

  on_continue = async () => {
    this.setState({
      awaiting_server_response: true,
      status_message: ''
    });

    const create_preflight_registration_res = await axios.post(`/api/registrations/recovery-preflight/create`, {
      email_address: this.state.email_address,
      preflight_prefix: 'recover-account-device'
    });

    if (!create_preflight_registration_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with preflight registration'
      });
      return;
    }

    const preflight_registration_id = create_preflight_registration_res.data.registration_id;

    this.setState({
      awaiting_server_response: false,
      continue_clicked: true,
      preflight_registration_id: preflight_registration_id,
    });
  }

  handle_new_master_password_change = (e) => {
    const new_master_password_holder = e.target.value;
    const new_master_password_valid = ((new_master_password_holder !== '') && (new_master_password_holder === this.state.new_master_password_confirm_holder));

    this.setState({ 
      new_master_password_holder: new_master_password_holder,
      new_master_password_valid: new_master_password_valid
    });
  }

  handle_new_master_password_confirm_change = (e) => {
    const new_master_password_confirm_holder = e.target.value;
    const new_master_password_valid = ((new_master_password_confirm_holder !== '') && (new_master_password_confirm_holder === this.state.new_master_password_holder));
    
    this.setState({ 
      new_master_password_confirm_holder: new_master_password_confirm_holder,
      new_master_password_valid: new_master_password_valid
    });
  }

  on_execute_recovery = async () => {
    try {
      // Master password did not match and should be typed again
      if ((!this.state.new_master_password_valid) || (this.state.new_master_password_holder !== this.state.new_master_password_confirm_holder) || (this.state.new_master_password_holder === '')) {
        this.setState({
          new_master_password_holder: '',
          new_master_password_confirm_holder: '',
          error_message: 'Weird error, please contact channy@plusidentity.com'
        });
        return;
      }

      // THE ENTERED MASTER PASSWORD IS VALID!

      this.setState({
        awaiting_server_response: true,
        status_message: 'Securely recovering your account . . .'
      });

      /**************************************
      * 0. Validate registration_id
      ***************************************/
      const validate_registration_res = await axios.post(`/api/registrations/${this.state.preflight_registration_id}/validate`, {
        email_address: this.state.email_address,
        type: 'recover-account-device-preflight'
      });

      if (!validate_registration_res.data.success) {
        alert('Link is invalid - registration id is not valid');
        await this.props.history.push(`/login`);
        return;
      }

      /**************************************
      * 1. User specific keys
      ***************************************/

      // Encrypt 
      const master_password__unenc__string = this.state.new_master_password_holder;

      // Derive account_secret_key
      const master_password__unenc__uint8_array = string_to_uint8_array(master_password__unenc__string);

      const account_salt_1__unenc__uint8_array = CSPRNG(32);

      const account_secret_key__unenc__uint8_array = await PBKDF2(master_password__unenc__uint8_array, account_salt_1__unenc__uint8_array);

      // Derive account_unlock_key
      const account_salt_21__unenc__uint8_array = string_to_uint8_array(this.state.email_address);
      const account_salt_22__unenc__uint8_array = string_to_uint8_array(this.state.email_address.split('@')[0]);

      const intermediate_key__unenc__uint8_array = await HKDF(account_secret_key__unenc__uint8_array, account_salt_21__unenc__uint8_array, account_salt_22__unenc__uint8_array);

      const account_unlock_key__unenc__uint8_array = XOR(intermediate_key__unenc__uint8_array, master_password__unenc__uint8_array);

      // Generate account_private & account_public key pair
      const [account_private_key__unenc__uint8_array, account_public_key__unenc__uint8_array] = await GEN_KEY_PAIR();

      // Encrypt account_private_key with account_unlock_key
      const account_private_key__enc_auk__uint8_array = await SYM_ENCRYPT(account_unlock_key__unenc__uint8_array, account_private_key__unenc__uint8_array);







      /**************************************
      * 2. (executing recovery) Fetch device keychains and get to work with item_keys derivation (user) OR (not executing recovery AND device_id is empty since cache is not intact)
      ***************************************/

      let device_id = this.state.device_id;

      let device_item_keys;
      let recovery_metadata;
      let device_private_key__unenc__base64, device_public_key__unenc__base64;
      let device_private_key__unenc__uint8_array, device_public_key__unenc__uint8_array;

      let item_keys = {} // To be built and sent to server persistence 

      if (this.state.execute_recovery) {
        // Fetch device keychains from server
        const get_device_keychains_res = await axios.post(`/api/users/${this.state.user_id}/device-keychains/fetch`, {
          registration_id: this.state.preflight_registration_id,
          registration_type: 'recover-account-device-preflight'
        });

        if (!get_device_keychains_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with get device keychains'
          });
        }

        const device_keychains = get_device_keychains_res.data.device_keychains;

        device_item_keys = device_keychains[device_id].item_keys;

        recovery_metadata = this.state.recovery_metadata;

        device_private_key__unenc__base64 = this.state.device_private_key__unenc;
        device_public_key__unenc__base64 = this.state.device_public_key__unenc;

        device_private_key__unenc__uint8_array = base64_to_uint8_array(device_private_key__unenc__base64);
        device_public_key__unenc__uint8_array = base64_to_uint8_array(device_public_key__unenc__base64); 


        for (const item_id in device_item_keys) {
          // Decrypt item key using device private key
          const item_key__enc_dpubk__base64 = device_item_keys[item_id];
          const item_key__enc_dpubk__uint8_array = base64_to_uint8_array(item_key__enc_dpubk__base64);
  
          const item_key__unenc__uint8_array = await ASYM_DECRYPT(device_private_key__unenc__uint8_array, item_key__enc_dpubk__uint8_array)
  
  
          // Encrypt using new account public key
          const item_key__enc_apubk__uint8_array = await ASYM_ENCRYPT(account_public_key__unenc__uint8_array, item_key__unenc__uint8_array);
  
          const item_key__enc_apubk__base64 = uint8_array_to_base64(item_key__enc_apubk__uint8_array)
  
          // Add the item_keys
          item_keys[item_id] = item_key__enc_apubk__base64
        }
      }
      else if ((!this.state.execute_recovery) && (!device_id)) { 
        console.log('generating new device id');
        // Generate new device_id from server
        const gen_device_id_res = await axios.get('/api/devices/id/new');

        if (!gen_device_id_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with get new device id'
          });
          return;
        }

        device_id = gen_device_id_res.data.device_id;
      }

      /**************************************
      * 3. Save to client, and send & save to server DB
      ***************************************/

      // First, get server_public_key from server
      const get_res = await axios.get('/api/keys/server-public-key');
      if (!get_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Server public key get went wrong'
        });
        return;
      }

      const server_public_key__unenc__base64 = get_res.data.server_public_key__unenc; // Send to client persistence

      const server_public_key__unenc__uint8_array = base64_to_uint8_array(server_public_key__unenc__base64); 
      const account_salt_1__enc_spubk__uint8_array  = await ASYM_ENCRYPT(server_public_key__unenc__uint8_array, account_salt_1__unenc__uint8_array)

      const account_salt_1__enc_spubk__base64 = uint8_array_to_base64(account_salt_1__enc_spubk__uint8_array); // Send to server persistence

      const account_secret_key__unenc__base64 = uint8_array_to_base64(account_secret_key__unenc__uint8_array); // Send to client persistence

      const account_private_key__enc_auk__base64 = uint8_array_to_base64(account_private_key__enc_auk__uint8_array); // Send to client & server persistence

      const account_public_key__unenc__base64 = uint8_array_to_base64(account_public_key__unenc__uint8_array); // Send to client & server persistence

      // CLIENT PERSISTENCE

      // Delete first for safety
      await dbDel('server_public_key__unenc');
      await dbDel('account_secret_key__unenc');
      await dbDel('account_private_key__enc_auk');
      await dbDel('account_public_key__unenc');

      // Set
      await dbSet('server_public_key__unenc', server_public_key__unenc__base64);
      await dbSet('account_secret_key__unenc', account_secret_key__unenc__base64);
      await dbSet('account_private_key__enc_auk', account_private_key__enc_auk__base64);
      await dbSet('account_public_key__unenc', account_public_key__unenc__base64);


      const recover_account_res = await axios.post(`/api/users/${this.state.user_id}/recover-account`, {
        email_address: this.state.email_address,
        registration_id: this.state.preflight_registration_id,

        // device_id: device_id,

        account_private_key__enc_auk: account_private_key__enc_auk__base64,
        account_public_key__unenc: account_public_key__unenc__base64,
        account_salt_1__enc_spubk: account_salt_1__enc_spubk__base64,
        item_keys: item_keys
      });

      if (!recover_account_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with recover account command'
        });
        return;
      }





      /**************************************
      * 4. Now take care of all the solo_admin scenarios
      ***************************************/

      // NOW DO FOR TEAM-SPECIFIC ACTIONS FOR SOLO-ADMIN SCENARIOS

      if (this.state.execute_recovery) { // TODO consider switching this to this.state.cache_intact
        for (const team_id in recovery_metadata) {
          // THIS SHOULD NOT HAPPEN
          if (recovery_metadata[team_id].user_type === 'non_solo_admin') {
            // Do nothing, since request admin is necessitated
            continue;
          }
  
          else if (recovery_metadata[team_id].user_type === 'user') {
            // Don't need to do anything if the user_type is user, since the team key pair does not need to be updated
            continue;
          }
  
          // Solo admin scenario
          else if (recovery_metadata[team_id].user_type === 'solo_admin') {   
  
            // Create new team asym and unlock keys 
            const team_unlock_key__unenc__uint8_array = CSPRNG(32);
            const team_unlock_key__enc_apubk__uint8_array = await ASYM_ENCRYPT(account_public_key__unenc__uint8_array, team_unlock_key__unenc__uint8_array);
  
            const [team_private_key__unenc__uint8_array, team_public_key__unenc__uint8_array] = await GEN_KEY_PAIR();
  
            const team_private_key__enc_tuk__uint8_array = await SYM_ENCRYPT(team_unlock_key__unenc__uint8_array, team_private_key__unenc__uint8_array);
  
  
            const team_item_ids = recovery_metadata[team_id].item_ids
            let team_item_keys = {}; // To be built and sent to server persistence
            for (const team_item_id of team_item_ids) {
  
              // See if device_item_keys contain the item_id in question, and only if so, create item_key__enc_tpubk
              if (team_item_id in device_item_keys) {
                const team_item_key__enc_dpubk__base64 = device_item_keys[team_item_id];
                const team_item_key__enc_dpubk__uint8_array = base64_to_uint8_array(team_item_key__enc_dpubk__base64);
    
                const team_item_key__unenc__uint8_array = await ASYM_DECRYPT(device_private_key__unenc__uint8_array, team_item_key__enc_dpubk__uint8_array)
    
                // Encrypt using new team public key
                const item_key__enc_tpubk__uint8_array = await ASYM_ENCRYPT(team_public_key__unenc__uint8_array, team_item_key__unenc__uint8_array);
    
                const item_key__enc_tpubk__base64 = uint8_array_to_base64(item_key__enc_tpubk__uint8_array)
    
    
                // Add the item_keys
                team_item_keys[team_item_id] = item_key__enc_tpubk__base64;
              } else {
                continue; // skip if the solo admin does not have cryptographic access to the item
              }
            }
  
  
  
  
  
            // Convert and save keys to client & server 
            const team_unlock_key__enc_apubk__base64 = uint8_array_to_base64(team_unlock_key__enc_apubk__uint8_array); // Send to server persistence
  
            const team_private_key__enc_tuk__base64 = uint8_array_to_base64(team_private_key__enc_tuk__uint8_array) // Send to server persistence
  
            const team_public_key__unenc__base64 = uint8_array_to_base64(team_public_key__unenc__uint8_array); // Send to server persistence (overwritten in client at later fetch)
  
  
  
  
  
            const recover_solo_admin_res = await axios.post(`/api/teams/${team_id}/admins/recover-solo-admin`, {
              user_id: this.state.user_id,
              email_address: this.state.email_address,
              registration_id: this.state.preflight_registration_id,
            
              team_unlock_key__enc_apubk: team_unlock_key__enc_apubk__base64,
              team_private_key__enc_tuk: team_private_key__enc_tuk__base64,
              team_public_key__unenc: team_public_key__unenc__base64,
              item_keys: team_item_keys
            });
  
            if (!recover_solo_admin_res.data.success) {
              this.setState({
                awaiting_server_response: false,
                error_message: 'Something went wrong with recover solo admin command'
              });
              return;
            }
          }
        }
      }

      

      /**************************************
      * 5. Create new session
      ***************************************/

      // Create session details
      const create_session_res = await axios.post('/api/sessions/create', {
        user_id: this.state.user_id,
        device_id: device_id,
        type: 'web'
      });

      if (!create_session_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with create session command'
        });
        return;
      } 

      // Save session id and key in client persistence
      const session_token__unenc__base64 = create_session_res.data.session_token__unenc;
      const session_key__unenc__base64 = create_session_res.data.session_key__unenc;

      const session_key__unenc__uint8_array = base64_to_uint8_array(session_key__unenc__base64);
      const account_private_key__enc_sk__uint8_array = await SYM_ENCRYPT(session_key__unenc__uint8_array, account_private_key__unenc__uint8_array);

      const account_private_key__enc_sk__base64 = uint8_array_to_base64(account_private_key__enc_sk__uint8_array);


      // Delete first for safety
      await dbDel('session_token__unenc');
      await dbDel('account_private_key__enc_sk');

      // Set
      await dbSet('session_token__unenc', session_token__unenc__base64);
      await dbSet('account_private_key__enc_sk', account_private_key__enc_sk__base64);



      /**************************************
      * 5. (Generate device key pair and) Re-register this device
      ***************************************/

      // If not executing recovery (cache not intact, or only non-solo admin), then create a brand new 
      if (!this.state.execute_recovery) {
        
        // Generate device key pair   
        const [new_device_private_key__unenc__uint8_array, new_device_public_key__unenc__uint8_array] = await GEN_KEY_PAIR();

        const new_device_private_key__unenc__base64 = uint8_array_to_base64(new_device_private_key__unenc__uint8_array); // Send to client persistence
        const new_device_public_key__unenc__base64 = uint8_array_to_base64(new_device_public_key__unenc__uint8_array); // Send to client & server persistence

        // Delete first for safety
        await dbDel('device_private_key__unenc');
        await dbDel('device_public_key__unenc');

        // Set
        await dbSet('device_private_key__unenc', new_device_private_key__unenc__base64);
        await dbSet('device_public_key__unenc', new_device_public_key__unenc__base64);

        // Create device_keychain
        const device_keychain = {
          device_public_key__unenc: new_device_public_key__unenc__base64,
          item_keys: {}, // empty, since not executing recovery and only requesting admin
          // registered_at: new Date()
        }

        // Send to client
        const insert_device_keychain_res = await axios.put(`/api/users/${this.state.user_id}/device-keychain`, {
          device_id: device_id,  
          device_keychain: device_keychain
        });

        if (!insert_device_keychain_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Something went wrong with insert device keychain'
          });
          return;
        }
      }

      // Now create or overwrite the device entry to make sure it is registered
      const register_device_res = await axios.post(`/api/devices/${device_id}`, {
        user_id: this.state.user_id
      });

      if (!register_device_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with register device command'
        });
        return;
      } 



      /**************************************
      * 6. Update team_public_keys and persist on client-side
      ***************************************/

      const get_team_public_keys_res = await axios.get(`/api/users/${this.state.user_id}/team-public-keys`);
      if (!get_team_public_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Get team public keys went wrong'
        });
        return;
      }

      const team_public_keys = get_team_public_keys_res.data.team_public_keys;


      // Delete first for safety
      await dbDel('team_public_keys');

      // Set
      await dbSet('team_public_keys', team_public_keys);




      /**************************************
      * 7. Recovery post actions (remove all traces of all other devices and sessions, since the secret key and various tokens stored on those devices are no longer valid and they need to register again)
      ***************************************/

      const post_recovery_actions_res = await axios.post(`/api/users/${this.state.user_id}/recover-account-post-actions`, {
        email_address: this.state.email_address,
        registration_id: this.state.preflight_registration_id,  
        device_id: device_id,
        solo_admin_team_ids: this.state.solo_admin_team_ids,
        request_admin_team_ids: this.state.request_admin_team_ids
      });

      if (!post_recovery_actions_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with recovery post actions command'
        });
        return;
      } 






      /**************************************
      * 8. Delete registration 
      ***************************************/ 

      const delete_registration_res = await axios.delete(`/api/registrations/${this.state.preflight_registration_id}`);

      if (!delete_registration_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with delete registration command'
        });
        return;
      } 


      /**************************************
      * 9. Mixpanel
      ***************************************/

      mixpanel.track('web_account_recovery_executed', {  
        distinct_id: this.state.user_id,
        is_dev: this.props.mode_info.is_dev
      });








      /**************************************
      * 8. Redirect to dashboard
      ***************************************/

      // Reload
      window.location.reload(false);
    }
    catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="recover-account__container">
        <div className="recover-account__top">
          <span className="recover-account__title">R E C O V E R &nbsp;&nbsp;&nbsp; A C C O U N T</span>
          <div className="recover-account__plus-lock"><PlusLock/></div>
        </div>
        <div className="recover-account__bottom">
          {
            this.state.continue_clicked
            ? <> {/* Re-set master password */}
                <div className='recover-account__bottom-recover-container'>
                  {
                    ((this.state.request_admin) || (this.state.solo_admin_team_ids.length > 0)) // This happens to be only when the user is not an admin in any team that they belong to
                    ? <div 
                        className="recover-account__bottom-warning"
                      > 
                        {
                          this.state.request_admin
                          ? <div 
                              className="recover-account__bottom-warning-top-text"
                            >
                              {
                                this.state.execute_recovery
                                ? `By resetting your master password below, you'll be recovering your accounts for the following team(s): ${this.state.execute_recovery_team_names}.`
                                : `Reset your master password to log into your account temporarily. Recovering access to data requires manual action.`
                              }
                            </div>
                          : <></>
                        }
                        {
                          (this.state.solo_admin_team_ids.length > 0)
                          ? <div 
                              className="recover-account__bottom-warning-middle-text"
                            >
                              WARNING: You're the solo admin of the following team(s): {this.state.solo_admin_team_names}. Recovering solo admin's account temporarily disables admin-initiated account recovery functionality for the other members of your team(s).
                            </div>
                          : <></>
                        }
                        {
                          this.state.request_admin
                          ? <div 
                              className="recover-account__bottom-warning-bottom-text"
                            >
                              WARNING: For the {this.state.request_admin_team_names} team(s), you must request a team admin-initiated recovery from the Account page in Settings.
                            </div>
                          : <></>
                        }
                      </div>
                    : <></>
                  } 
                  <div className="recover-account__data-type">Email</div>
                  <div
                    className='recover-account__bottom-input-box'
                  >
                    <input 
                      className="recover-account__bottom-input-field disabled"
                      type="text"
                      value={ this.state.email_address }
                      disabled
                    />
                  </div>

                  <div className="recover-account__data-type">
                    Set new master password
                    <div className='recover-account__info-container'>
                      <InfoIcon 
                        className='recover-account__info-icon'
                      />
                      <div className='recover-account__info-description'>
                        Do not lose your master password. PlusIdentity never has access to your master password or any of its derivatives, so if you lose your master password, we may not be able to recover your account.
                      </div>
                    </div>
                  </div>

                  <div
                    className='recover-account__bottom-input-box password'
                  >
                    <input 
                      className="recover-account__bottom-input-field"
                      type={this.state.new_master_password_holder ? "password" : "text"}
                      disabled={this.state.awaiting_server_response}
                      placeholder="New master password"
                      value={ this.state.new_master_password_holder }
                      onChange={ this.handle_new_master_password_change }
                      autoComplete="new-password"
                    />
                  </div>
                  <div
                    className='recover-account__bottom-input-box confirm'
                  >
                    <input 
                      className="recover-account__bottom-input-field"
                      type={this.state.new_master_password_confirm_holder ? "password" : "text"}
                      disabled={this.state.awaiting_server_response}
                      placeholder="Confirm new master password"
                      value={ this.state.new_master_password_confirm_holder }
                      onChange={ this.handle_new_master_password_confirm_change }
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="recover-account__bottom-send-link-action">
                    {
                      this.state.awaiting_server_response
                      ? <ReactLoading
                          type='spokes'
                          color='#9696ad'
                          height={20}
                          width={20}
                        />
                      : this.state.new_master_password_valid
                        ? <input
                            className='recover-account__bottom-send-link-button'
                            type="submit"
                            value={ 'Recover account' }
                            onClick={ this.on_execute_recovery }
                          />
                        : <input
                            className='recover-account__bottom-send-link-button disabled'
                            type="submit"
                            value={ 'Recover account' }
                          />
                    }
                  </div>
                  <span className="recover-account__status-message">{ this.state.status_message }</span>
                </div>
              </>
            : <> {/* Checking status, then show continue button with maybe warning message as appropriate */}
                <div
                  className='recover-account__bottom-input-box pre-continue'
                >
                  <input 
                    className="recover-account__bottom-input-field disabled"
                    value={ this.state.email_address }
                    type="text" 
                    disabled
                  />
                </div>
                <div
                  className='recover-account__bottom-send-link-action'
                >
                  {
                    this.state.awaiting_server_response
                    ? <div 
                        className='recover-account__bottom-loading-container'
                      >
                        <ReactLoading
                          type='spokes'
                          color='#9696ad'
                          height={20}
                          width={20}
                        />
                      </div>
                    : <input
                        className='recover-account__bottom-continue-button'
                        type="submit"
                        value="Continue"
                        onClick={ this.on_continue }
                      />
                  }
                </div>
                <span className="recover-account__status-message">{ this.state.status_message }</span>
                <span className="recover-account__warning-message">{ this.state.warning_message }</span>
              </>
          }
          <span className="recover-account__error-message">{ this.state.error_message }</span>
        </div>
      </div>
    );
  }
}

export default RecoverAccount;
