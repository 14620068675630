import React from 'react';
import './ConnectAccount.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_ENCRYPT, ASYM_ENCRYPT, CSPRNG, GEN_KEY_PAIR } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string, uint8_array_to_base64 } from '../../../crypto/util';

import { send_slack_notification } from '../../../slack/util';

import { auth_axios } from '../../../auth/util';

import { set as dbSet, del as dbDel } from 'idb-keyval';


import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';




// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




class ConnectAccount extends React.Component {
  
  constructor(props) {
    super(props);

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const url_params = new URLSearchParams(`?${query__string}`);

    const registration_id = url_params.get('registration_id');
    const connect_user_id = url_params.get('connect_user_id');
    const existing_email_address = url_params.get('existing_email_address');
    const new_email_address = url_params.get('new_email_address');
    const slack_team_id = url_params.get('slack_team_id');
    const slack_user_id = url_params.get('slack_user_id');
    const is_installer = url_params.get('is_installer');

    const url_valid = (!((!registration_id) || (!connect_user_id) || (!existing_email_address) || (!new_email_address) || (!slack_team_id) || (!slack_user_id) || (!is_installer)));

    this.state = {
      awaiting_server_response: true,

      fatal_error_occurred: false,

      error_message: '',

      account_private_key__unenc__uint8_array: props.keychain.account_private_key__unenc__uint8_array,

      account_public_key__unenc__uint8_array: props.keychain.account_public_key__unenc__uint8_array,

      server_public_key__unenc__uint8_array: props.keychain.server_public_key__unenc__uint8_array,

      team_public_keys: props.keychain.team_public_keys,
      
      registration_id: registration_id,
      connect_user_id: connect_user_id,
      existing_email_address: existing_email_address,
      new_email_address: new_email_address,
      slack_team_id: slack_team_id,
      slack_user_id: slack_user_id,
      is_installer: (is_installer === 'true'),

      url_valid: url_valid,
    }
  }

  componentDidMount = async () => {
    try {

      /**************************************
      * 0. Check if correct user & validate registration
      ***************************************/

      if (this.state.connect_user_id !== this.props.user_id) {
        alert('Incorrect user - you do not have access to take this action');
        await this.props.history.push(`/dashboard`);
        return;
      }

      const validate_registration_res = await axios.post(`/api/registrations/${this.state.registration_id}/validate`, {
        email_address: this.state.existing_email_address,
        type: 'connect-account'
      });

      if (!validate_registration_res.data.success) {
        alert('Link is invalid - registration id is not valid');
        await this.props.history.push(`/dashboard`);
        return;
      }



      const is_installer = this.state.is_installer;

      /**************************************
      * 1. Get account public key
      ***************************************/

      const account_public_key__unenc__uint8_array = this.state.account_public_key__unenc__uint8_array;

      const account_public_key__unenc__base64 = uint8_array_to_base64(account_public_key__unenc__uint8_array);


      /**************************************
      * 2. If installer, create the team 
      ***************************************/

      let team_unlock_key__enc_apubk__base64, team_public_key__unenc__base64;

      const slack_team_id_check_result = await axios.get(`/api/util/slack-teams/${this.state.slack_team_id}/team-name`);
    
      if (!slack_team_id_check_result.data.success) {
        this.setState({
          team_exists: false,
          team_info_loading: false,
          first_user: true
        });
        return;
      }

      const team_name = slack_team_id_check_result.data.team_name;
 
      if (is_installer) {
        // Create team asym and unlock keys 
        const team_unlock_key__unenc__uint8_array = CSPRNG(32);
        const team_unlock_key__enc_apubk__uint8_array = await ASYM_ENCRYPT(account_public_key__unenc__uint8_array, team_unlock_key__unenc__uint8_array);

        const [team_private_key__unenc__uint8_array, team_public_key__unenc__uint8_array] = await GEN_KEY_PAIR();

        const team_private_key__enc_tuk__uint8_array = await SYM_ENCRYPT(team_unlock_key__unenc__uint8_array, team_private_key__unenc__uint8_array);

        // Convert and save keys to client & server 
        team_unlock_key__enc_apubk__base64 = uint8_array_to_base64(team_unlock_key__enc_apubk__uint8_array); // Send to server persistence
        const team_private_key__enc_tuk__base64 = uint8_array_to_base64(team_private_key__enc_tuk__uint8_array) // Send to server persistence
        team_public_key__unenc__base64 = uint8_array_to_base64(team_public_key__unenc__uint8_array); // Send to client & server persistence

        // Trigger team creation (plus accompanying slack_workspace update)
        const create_team_res = await axios.post('/api/teams/create', {
          slack_team_id: this.state.slack_team_id,
          team_name: team_name,
          team_private_key__enc_tuk: team_private_key__enc_tuk__base64,
          team_public_key__unenc: team_public_key__unenc__base64,
          email_address: this.state.new_email_address
        });

        if (!create_team_res.data.success) {
          this.setState({
            awaiting_server_response: false,
            error_message: 'Create new team went wrong'
          });
          return;
        }

      }

      /**************************************
      * 3. Get (newly created) team_id and fetch team public key if not installer
      ***************************************/

      // Get team_id from server using slack_team_id
      const team_id_res = await axios.get(`/api/util/slack-teams/${this.state.slack_team_id}/team-id`);
      if (!team_id_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Server team_id get went wrong'
        });
        return;
      }

      const team_id = team_id_res.data.team_id;

      // // If not installer, then we need to fetch the team_public_key
      // if (!is_installer) {
      //   const get_team_public_key_res = await axios.get(`/api/teams/${team_id}/team-public-key`);

      //   if (!get_team_public_key_res.data.success) {
      //     this.setState({
      //       awaiting_server_response: false,
      //       error_message: 'Get team public key went wrong'
      //     });
      //     return;
      //   }

      //   team_public_key__unenc__base64 = get_team_public_key_res.data.team_public_key__unenc;
      // }

      // const team_public_keys = {
      //   ...this.state.team_public_keys,
      //   [team_id]: team_public_key__unenc__base64
      // }

      const get_team_public_keys_res = await axios.get(`/api/users/${this.props.user_id}/team-public-keys`);
      if (!get_team_public_keys_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Get team public keys went wrong'
        });
        return;
      }

      const team_public_keys = get_team_public_keys_res.data.team_public_keys;

      // Delete first for safety
      await dbDel('team_public_keys');

      // Update team_public_keys on the client side
      await dbSet('team_public_keys', team_public_keys);
      




      /**************************************
      * 4. Connect new email to the existing account (existing email)
      ***************************************/


      const connect_account_res = await auth_axios.post(`/api/users/${this.props.user_id}/connect-account`, {
        registration_id: this.state.registration_id,
        existing_email_address: this.state.existing_email_address,
        new_email_address: this.state.new_email_address,
        slack_team_id: this.state.slack_team_id,
        slack_user_id: this.state.slack_user_id,
        team_id: team_id,
        account_public_key__unenc: account_public_key__unenc__base64,
        team_unlock_key__enc_apubk: team_unlock_key__enc_apubk__base64,
        is_installer: is_installer
      });

      if (!connect_account_res.data.success) {
        alert('Link is invalid - registration id is not valid');
        await this.props.history.push(`/dashboard`);
        return;
      }




      /**************************************
      * 5. Trigger post- connect account Slack server actions
      ***************************************/
      try {
        // Send POST request to slack.plusidentity.com server to indicate successful 

        const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

        const message = `:tada: Congratulations, you've successfully connected *${this.state.new_email_address}* to your existing account associated with *${this.state.existing_email_address}*!\n\n:rocket: Please visit the <slack://app?team=${this.state.slack_team_id}&id=${this.props.mode_info.is_dev ? 'A02AZNB3KUM' : 'A02Q9A55M46'}&tab=home|*Home tab*> to start using!`

        await send_slack_notification(url, team_id, this.props.user_id, message, {});
      }
      catch {
        console.error('CORS configurations not yet set up properly')
      }





      /**************************************
      * 6. Alert deep link back to Slack NOTE: Currently, this disappears upon refresh (no. 10)
      ***************************************/

      // Slack app plusidentity-dev : A02AZNB3KUM
      // Slack app plusidentity : A02Q9A55M46

      // Now alert deep link back to the messages tab of the app
      // let meta = document.createElement('meta');
      // meta.httpEquiv = 'refresh';
      // meta.content = this.props.mode_info.is_dev ? `0; URL=slack://app?team=${this.state.slack_team_id}&id=A02AZNB3KUM&tab=messages` : `0; URL=slack://app?team=${this.state.slack_team_id}&id=A02Q9A55M46&tab=messages`
      // document.head.appendChild(meta);



      /**************************************
      * 7. Final actions
      ***************************************/

      this.setState({
        awaiting_server_response: false
      }); 


      // /**************************************
      // * 8. Mixpanel tracking code
      // ***************************************/

      // if (is_installer) {
      //   mixpanel.track('web_installer_connect_account_succeeded', {
      //     distinct_id: this.props.user_id,
      //     team_id: team_id,
      //     is_dev: this.props.mode_info.is_dev
      //   });
      // }

      // else {
      //   mixpanel.track('web_connect_account_succeeded', {
      //     distinct_id: this.props.user_id,
      //     team_id: team_id,
      //     is_dev: this.props.mode_info.is_dev
      //   });
      // }

      /**************************************
      * 9. Trigger Installer Zap for Salesforce record creation
      ***************************************/

      try {
        let url;
        if (is_installer) {
          url = 'https://hooks.zapier.com/hooks/catch/10692832/b97sppm'; // Installer Zapier hook
        } else {
          url = 'https://hooks.zapier.com/hooks/catch/10692832/b5ugl0e'; // User Zapier hook
        }

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      
        const params = new URLSearchParams()
      
        params.append('team_name', team_name);
        params.append('display_name', this.props.user_id_display_name_maps[this.props.user_id]);
        params.append(`${is_installer ? 'installer_email_address' : 'user_email_address'}`, this.state.new_email_address);
      
        const zapier_res = await axios.post(url, params, config);
      } catch (error) {
        console.log(error);
      }
    
    }
    catch (error) {
      console.error(error)
      this.setState({
        error_message: 'fatal error',
        fatal_error_occurred: true
      });
    }
  }

  render() {
    return (
      <div className='connect-account__container'>
        {
          this.state.url_valid
          ? this.state.awaiting_server_response
            ? <div className="connect-account__awaiting_response">
                <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              </div>
            : <div className="connect-account__main">
                <CheckLogo 
                  className='connect-account__copied-check'
                />
                <div className="connect-account__text">
                  {this.state.new_email_address} was successfully added to your existing account
                </div>
                
              </div>
          : <div className='connect-account__error-message'>Invalid URL</div>
        }
      </div>
    );
  }
}

export default ConnectAccount;
