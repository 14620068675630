import React from 'react';
import './Settings.css';


import axios from 'axios';
import ReactLoading from 'react-loading';

import { Switch, Route, Link, Redirect } from 'react-router-dom';

import ProtectedRoute from '../../../../auth/ProtectedRoute';

import Account from './AccountPage/Account';
import TeamSettings from './TeamSettingsPage/TeamSettings';
import ManageUsers from './ManageUsersPage/ManageUsers';
import Billing from './BillingPage/Billing';
import BillingConfirmation from './BillingPage/BillingConfirmationPage/BillingConfirmation';

import { auth_axios } from '../../../../auth/util';

import { ReactComponent as ExpandIconMinWidthWhite } from '../media/svg/expand-icon-min-width-white.svg';
import { ReactComponent as CollapseIconMinWidthWhite } from '../media/svg/collapse-icon-min-width-white.svg';


import { ReactComponent as TalkToUsLogo } from '../media/svg/talk-to-us.svg';


// Mixpanel
import mixpanel from 'mixpanel-browser';

const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


class Settings extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
      error_message: '',
      is_admin: false,

      left_bar_open: false,
    }
  }
  
  componentDidMount = async () => {
    try {

      // Check if this person is admin and has the privileges to see
      const check_if_admin_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/validate`, {
        user_id: this.props.user_id
      });

      if (!check_if_admin_res) {
        console.error('checking if admin failed')
        return;
      }

      const is_admin = check_if_admin_res.data.privileged
      
      this.setState({
        awaiting_server_response: false,
        is_admin: is_admin
      });

      // Mixpanel web_settings_visited
      mixpanel.track('web_settings_visited', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });


      // Default page is account
      // await this.props.history.push('/dashboard/settings/account');
    }
    catch (error) {
      console.log(error);
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    try {
      if (this.props.selected_team_id !== prevProps.selected_team_id) {

        // Awaiting
        this.setState({
          awaiting_server_response: true,
        });

        // Check if this person is admin and has the privileges to see
        const check_if_admin_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/validate`, {
          user_id: this.props.user_id
        });

        if (!check_if_admin_res) {
          console.error('checking if admin failed')
          return;
        }

        const is_admin = check_if_admin_res.data.privileged
        
        this.setState({
          awaiting_server_response: false,
          is_admin: is_admin
        });
      }
    }
    catch (error) {
      console.log(error);
      this.setState({
        fatal_error_occurred: true
      });
    }
  }


  handle_left_bar_open = () => {
    this.setState({
      left_bar_open: !this.state.left_bar_open,
    });
  }
  
  render() {

    const get_class_name = () => {
      let class_name = ''

      if (this.props.screen_width < 960) {
        class_name = this.state.left_bar_open ? 'mobile-expanded' : 'mobile-hidden'
      }

      return class_name;
    }


    return (
      <div className='settings__container'>
        {
          (this.props.screen_width > 550)
          ? <button 
              className='settings__talk-to-us-button'
              onClick={ this.props.on_talk_to_us_modal_open }
              disabled={ this.state.awaiting_server_response }
            >
              <div
                className='settings__talk-to-us-button-text'
              >
                Talk to us
              </div>
              <TalkToUsLogo 
                className='settings__talk-to-us-button-logo'
              />
            </button>
          : <></>
        }
        

        <div className={`settings__left-bar ${get_class_name()}`}>
          <ul className='settings__left-bar-list'>
            <Link to={`${this.props.match.path}/account`} className='settings__left-bar-list-item'>Account</Link>
            {
              this.state.is_admin
              ? <>
                  <Link 
                    to={`${this.props.match.path}/team-settings`} className='settings__left-bar-list-item'
                  >
                    Team settings
                  </Link>
                  <Link 
                    to={`${this.props.match.path}/manage-users`} className='settings__left-bar-list-item'
                  >
                    Manage users
                  </Link>
                  <Link 
                    to={`${this.props.match.path}/billing`} className='settings__left-bar-list-item'
                  >
                    Billing
                  </Link>
                </>
              : <></>
            }
          </ul>
        </div>

        {
          this.props.screen_width < 960
          ? this.state.left_bar_open
            ? <CollapseIconMinWidthWhite 
                className='settings__left-bar-expand-button collapse'
                onClick={ this.handle_left_bar_open }
              />
            : <ExpandIconMinWidthWhite 
                className='settings__left-bar-expand-button'
                onClick={ this.handle_left_bar_open }
              />
          : <></>
        }


        <div className="settings__separator-vertical"></div>
        <div className='settings__contents'>
          {
            this.state.awaiting_server_response
            ? <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            : <Switch>
                <Route exact path={`${this.props.match.path}`}>
                  <Redirect to={`${this.props.match.path}/account`} />
                </Route>
                
                <ProtectedRoute 
                  path={`${this.props.match.path}/account`}
                  exact
                  component={Account} 
                  session_status={this.props.session_status} 

                  keychain={this.props.keychain} 
                  
                  user_id={this.props.user_id}
                  user_id_display_name_maps={this.props.user_id_display_name_maps}

                  email_addresses={this.props.email_addresses}
                  preference_settings={this.props.preference_settings}

                  selected_team_id={this.props.selected_team_id}
                  selected_team_status={this.props.selected_team_status}
                  selected_team_name={this.props.selected_team_name}
                  selected_team_admin_user_ids={this.props.selected_team_admin_user_ids}

                  item_keys={this.props.item_keys}

                  is_admin={this.state.is_admin}

                  screen_width={this.props.screen_width}
                  screen_height={this.props.screen_height}
                  
                  mode_info={this.props.mode_info}
                />
                <ProtectedRoute 
                  path={`${this.props.match.path}/team-settings`}
                  exact
                  component={TeamSettings} 
                  session_status={this.props.session_status} 

                  keychain={this.props.keychain} 
                  
                  user_id={this.props.user_id}
                  user_id_display_name_maps={this.props.user_id_display_name_maps}

                  selected_team_id={this.props.selected_team_id}
                  selected_team_name={this.props.selected_team_name}
                  selected_team_user_ids={this.props.selected_team_user_ids}

                  is_admin={this.state.is_admin}

                  screen_width={this.props.screen_width}
                  screen_height={this.props.screen_height}
                  
                  mode_info={this.props.mode_info}
                />
                <ProtectedRoute 
                  path={`${this.props.match.path}/manage-users`}
                  exact
                  component={ManageUsers} 
                  session_status={this.props.session_status} 

                  keychain={this.props.keychain} 
                  
                  user_id={this.props.user_id}
                  user_id_display_name_maps={this.props.user_id_display_name_maps}

                  selected_team_id={this.props.selected_team_id}
                  selected_team_name={this.props.selected_team_name}
                  selected_team_user_ids={this.props.selected_team_user_ids}

                  is_admin={this.state.is_admin}

                  screen_width={this.props.screen_width}
                  screen_height={this.props.screen_height}
                  
                  mode_info={this.props.mode_info}
                />
                <ProtectedRoute 
                  path={`${this.props.match.path}/billing`}
                  exact
                  component={Billing} 
                  session_status={this.props.session_status} 
                  
                  user_id={this.props.user_id}
                  selected_team_id={this.props.selected_team_id}
                  is_admin={this.state.is_admin}

                  screen_width={this.props.screen_width}
                  screen_height={this.props.screen_height}
                  
                  mode_info={this.props.mode_info}
                />
                <ProtectedRoute 
                  path={`${this.props.match.path}/billing/confirmation`}
                  exact
                  component={BillingConfirmation} 
                  session_status={this.props.session_status} 
                  
                  user_id={this.props.user_id}
                  selected_team_id={this.props.selected_team_id}
                  is_admin={this.state.is_admin}

                  screen_width={this.props.screen_width}
                  screen_height={this.props.screen_height}
                  
                  mode_info={this.props.mode_info}
                />
                <Route
                  path={`${this.props.match.path}`}
                  exact
                >
                  <>
                    SETTINGS
                  </>
                </Route>
              </Switch>
          }
        </div>
        <div className='settings__bottom'>
          <span className="signup__error-message">{ this.state.error_message }</span>
        </div>
      </div>
    );
  }
}

export default Settings;
