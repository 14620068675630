import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route 
      {...rest} 
      render={
        props => {
          if (rest.session_status === 'SESSION_VALID') {
            return <Component {...rest} {...props} />
          } 
          else {
            return <Redirect to={
              {
                pathname: '/login',
                state: {
                  from: props.location,
                  // intended_pathname: props.location.pathname,
                  // intended_search: props.location.search
                }
              }
            } />
          }
        }
      } 
    />
  )
}

export default ProtectedRoute;
