import React from 'react';
import './ShowUserListModal.css';

import ReactModal from 'react-modal';

import axios from 'axios';
import ReactLoading from 'react-loading';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 







ReactModal.setAppElement('body');


class ShowUserListModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
    }
  }

  componentDidMount = async () => {

    if (!this.props.is_admin) {
      alert('You do not have access to take this action');
      await this.props.history.push(`/dashboard`);
    }

    try {
      this.setState({
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }


  render() {
    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_show_user_list_modal_close();
          }
        }}
        style={{
          content: {
            height: 400,
            width: 240
          }
        }}
      >
        <div className="show-user-list-modal__container">
          <div
            className='show-user-list-modal__title' 
          >
            Users
          </div>
          <div
            className='show-user-list-modal__user-list' 
          >
            {
              this.props.selected_team_user_ids.map((user_id) => <div 
                className='show-user-list-modal__user'
                key={user_id}
              >
                {this.props.user_id_display_name_maps[user_id]}
              </div>
              )
            }
          </div>
          <a
            className='show-user-list-modal__close'
            onClick={() => {
              this.props.on_show_user_list_modal_close();
            }}
          >
            Close
          </a>
          <span>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default ShowUserListModal;
