import React from 'react';
import './ChangeLogoutIntervalModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';

import { get as dbGet, set as dbSet } from 'idb-keyval';
import { CSPRNG, PBKDF2, HKDF, XOR, GEN_KEY_PAIR, SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT } from '../../../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../../../crypto/util';

import {auth_axios} from '../../../../../auth/util';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');


class ChangeLogoutIntervalModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      new_logout_interval_holder: props.preference_settings.session_logout_interval.toString(),
      input_valid: false,

      error_message: '',
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({        
        awaiting_server_response: false,
      })
    }

    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.preference_settings.session_logout_interval !== prevProps.preference_settings.session_logout_interval) {
      this.setState({        
        new_logout_interval_holder: this.props.preference_settings.session_logout_interval.toString(),
        input_valid: false,
        error_message: '',
      })
    }
  }

  reset_modal = () => {
    this.setState({
      new_logout_interval_holder: this.props.preference_settings.session_logout_interval.toString(),
      input_valid: false,
      error_message: '',
    })
  }

  handle_logout_interval_change = (e) => {
    const new_logout_interval = e.value;

    this.setState({ 
      new_logout_interval_holder: new_logout_interval, 
      input_valid: (new_logout_interval !== this.props.preference_settings.session_logout_interval.toString()),
    });
  }



  update_logout_interval = async () => {

    try {
      //
      this.setState({
        error_message: '',
        awaiting_server_response: true
      });

      /**************************************
      * 1. Pre-processing
      ***************************************/

      // Master password
      const preference_settings = {
        ...this.props.preference_settings,
        session_logout_interval: parseInt(this.state.new_logout_interval_holder)
      }

      
      /**************************************
      * 2. Update preference settings API call
      ***************************************/

      const update_preference_settings_res = await auth_axios.post(`/api/users/${this.props.user_id}/preference-settings`, {
        preference_settings: preference_settings
      });

      if (!update_preference_settings_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with update preference settings'
        });
        return;
      }

      /**************************************
      * 3. Stop loading 
      ***************************************/

      this.setState({
        awaiting_server_response: false
      });



      /**************************************
      * 7. Mixpanel tracking
      ***************************************/

      mixpanel.track('web_change_logout_interval_modal_submitted', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });


      /**************************************
      * 8. Refresh page to see changes on dashboard immediately!
      ***************************************/

      // Reload
      window.location.reload(false);
    }




    catch (error) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Wrong master password'
      });
    }


    
  }



  render() {

    const logout_interval_options = Object.keys(this.props.logout_interval_mappings).map((interval) =>
      ({
        label: this.props.logout_interval_mappings[interval],
        value: interval
      })
    )

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={() => {
          if (!this.state.awaiting_server_response) {
            this.props.on_change_logout_interval_modal_close();
            this.reset_modal();
          }
        }}
        style={{
          content: {
            height: 240,
            width: 260
          }
        }}
      >
        <div className="change-logout-interval-modal__container">
          <div
            className='change-logout-interval-modal__text' 
          >
            Select the new automatic logout interval.
          </div>
          <Select
            options={logout_interval_options} 
            value={logout_interval_options.filter((logout_interval_option) => {
              return logout_interval_option.value === this.state.new_logout_interval_holder
            })}
            isSearchable
            onChange={ this.handle_logout_interval_change }
            isDisabled={this.state.awaiting_server_response}
            maxMenuHeight={100}
            className='change-logout-interval__fields-field-first change-logout-interval__search-select'
            classNamePrefix='change-logout-interval__search-select-child'
          />
          <div className="change-logout-interval-modal__action-buttons">
            {
              this.state.awaiting_server_response
              ? <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
              : <>
                  {
                    this.state.input_valid
                    ? <input
                        type='submit'
                        className={`change-logout-interval-modal__update-button`}
                        value={ 'Update' }
                        onClick={ this.update_logout_interval }
                      />
                    : <input
                        type='submit'
                        className={`change-logout-interval-modal__update-button disabled`}
                        value={ 'Update' }
                      />
                  }
                  <a
                    className='change-logout-interval-modal__cancel'
                    onClick={() => {
                      this.props.on_change_logout_interval_modal_close();
                      this.reset_modal();
                    }}
                  >
                    Cancel
                  </a>
                </>
            } 
          </div>
          <span className='change-logout-interval-modal__error-message'>{this.state.error_message}</span>
        </div>
      </ReactModal>
    );
  }
}

export default ChangeLogoutIntervalModal;
