import React from 'react';
import './LinkViewField.css';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { SYM_DECRYPT, ASYM_DECRYPT } from '../../../crypto/crypto';
import { base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { send_slack_notification } from '../../../slack/util';

import { auth_axios, sleep } from '../../../auth/util';


import { ReactComponent as ShowIcon } from './media/svg/field__show-icon.svg';
import { ReactComponent as HideIcon } from './media/svg/field__hide-icon.svg';
import { ReactComponent as CheckLogo } from './media/svg/black_check.svg';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 





const beautify_field_name = (field_name) => {
  const mapping = {
    'email_address': 'Email address',
    'password': 'Password',
    'username': 'Username',
    'phone_number': 'Phone number',
    'notes': 'Additional notes',

    // config specific fields
    'amplitude:org_url': 'Org URL',
    'awsiam:account_id': 'AWS account ID',
    'godaddy:customer_number': 'Customer number',
    'metabase:org_url': 'Org URL',
    'slack:org_url': 'Org URL',
  }

  if (field_name.startsWith('custom:')) {
    return field_name.slice(7);
  }

  else if (mapping[field_name]) {
    return mapping[field_name];
  }

  else {
    return field_name;
  }
}



class LinkViewField extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: false,
      awaiting_clipboard_copy: false,

      is_hidden: true,
      decrypted_value: '',

      field_value_copied: false
    }
  }


  componentDidMount = async () => {
    // Toggle off when switching between items
    this.setState({
      is_hidden: true,
      decrypted_value: ''
    })
  }

  handle_copy_unprotected_to_clipboard = async () => {
    // Copy and change to checkmark
    await navigator.clipboard.writeText(this.props.field_value);

    this.setState({
      field_value_copied: true
    });

    // Wait 1 second and copy back to text
    await sleep(1.0);

    this.setState({
      field_value_copied: false
    });

    // Mixpanel web_protected_field_copied
    mixpanel.track('web_protected_field_copied', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  render() {
    return (
      <>
        {
          this.props.is_protected
          ? (this.props.permission === 'owner' || this.props.permission === 'read_write' || this.props.permission === 'read_only' || this.props.permission === 'one_time')
            ? this.state.is_hidden 
                ? <>
                    {
                      this.state.awaiting_clipboard_copy
                      ? <div 
                          className='link-view__right-side-item-info-field-value-loading'
                        >
                          <ReactLoading
                            type='spokes'
                            color='#ffffff'
                            height={20}
                            width={20}
                          />
                        </div>
                      
                      : this.state.field_value_copied
                        ? <CheckLogo 
                            className='link-view__right-side-item-info-field-value-check'
                          />
                        : <div 
                            className="link-view__right-side-item-info-field-value-text protected"
                            // onClick={this.handle_copy_protected_to_clipboard}
                          >
                            ********
                            <div
                              className='link-view__right-hover-value-text'
                            >
                              Click text to copy
                            </div>
                          </div>
                    }
                    {
                      this.state.awaiting_server_response
                      ? <div 
                          className='link-view__right-side-item-info-field-value-toggle'
                        >
                          <ReactLoading
                            type='spokes'
                            color='#ffffff'
                            height={20}
                            width={20}
                          />
                        </div>
                      
                      : <ShowIcon 
                          className='link-view__right-side-item-info-field-value-toggle'
                          // onClick={this.handle_toggle_show}
                        />
                    }
                    
                  </>
                : <>
                    {
                      this.state.awaiting_clipboard_copy
                      ? <div 
                          className='link-view__right-side-item-info-field-value-loading'
                        >
                          <ReactLoading
                            type='spokes'
                            color='#ffffff'
                            height={20}
                            width={20}
                          />
                        </div>
                      
                      : this.state.field_value_copied
                        ? <CheckLogo 
                            className='link-view__right-side-item-info-field-value-check'
                          />
                        : <div 
                            className="link-view__right-side-item-info-field-value-text protected"
                            // onClick={this.handle_copy_protected_to_clipboard}
                          >
                            {this.state.decrypted_value}
                            <div
                              className='link-view__right-hover-value-text'
                            >
                              Click text to copy
                            </div>
                          </div>
                    }
                    
                    <HideIcon
                      className='link-view__right-side-item-info-field-value-toggle'
                      // onClick={this.handle_toggle_show}
                    />
                  </>
            : <>
                <div className="link-view__right-side-item-info-field-value-text protected">
                  ********
                </div>
              </>
          : this.state.field_value_copied
            ? <CheckLogo 
                className='link-view__right-side-item-info-field-value-check'
              />
            : <div 
                className="link-view__right-side-item-info-field-value-text"
                onClick={this.handle_copy_unprotected_to_clipboard}
              >
                {this.props.field_value}
                <div
                  className='link-view__right-hover-value-text'
                >
                  Click text to copy
                </div>
              </div>
        }
      </>
    );
  }
}

export default LinkViewField;
