import React from 'react';
import './Login.css';

import axios from 'axios';
import ReactLoading from 'react-loading';


import { HKDF, XOR, SYM_ENCRYPT, SYM_DECRYPT } from '../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { auth_axios } from '../../../auth/util';

import { get as dbGet, set as dbSet, del as dbDel } from 'idb-keyval';

import { ReactComponent as PlusLock } from '../../../logos/plusidentity-lock.svg';
import { ReactComponent as ContinueButton } from './media/svg/continue-button.svg';

import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


class Login extends React.Component {

  constructor(props) {
    super(props);

    const query__base64 = props.location.search.split('?')[1] || '';

    const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    const query_params = query__string.split(/email_address=|&user_id=/).slice(1);

    const [query_email_address, query_user_id] = (query_params.length === 2) ? query_params : ['', ''];

    const [email_valid, email_address__string] = this.validate_email_address(query_email_address);

    const [user_id_valid, user_id__string] = this.validate_user_id(query_user_id);

    this.state = {
      email_address_holder: email_address__string,
      master_password_holder: '',
      email_exists: email_valid && user_id_valid,
      email_address: email_valid ? email_address__string : '',
      error_message: '',
      awaiting_server_response: false,
      user_id: user_id__string
    }
  }

  validate_email_address = (query_email_address) => {
    // const query__string = uint8_array_to_string(base64_to_uint8_array(query__base64));

    let contains_one_at = (((query_email_address).match(/@/g) || []).length === 1);
    let at_least_one_dot_right = ((((query_email_address).split('@')[1] || '').match(/\./g) || []).length > 0);
  
    // TODO do more checking than just seeing if @ is contained...
    return [(contains_one_at && at_least_one_dot_right), query_email_address];
  }

  validate_user_id = (query_user_id) => {  
    // TODO
    return [true, query_user_id];
  }

  componentDidMount = () => {
    mixpanel.track('web_login_visited', {
      is_dev: this.props.mode_info.is_dev
    });
  }

  // NOT USED RIGHT NOW
  handle_email_address_change = (e) => {
    this.setState({ email_address_holder: e.target.value, error_message: '' });
  }

  handle_email_address_reset = async (e) => {
    this.setState({
      email_exists: false,
      email_address_holder: e.target.value, 
      error_message: ''
    })
    await this.props.history.push(`/login`);
  }

  handle_master_password_change = (e) => {
    this.setState({ master_password_holder: e.target.value, error_message: '' });
  }
 
  on_submit = async () => {
    try {

      this.setState({
        awaiting_server_response: true
      });

      /**************************************
      * 0. Login pre-flight
      ***************************************/

      const user_id = this.state.user_id;
      
      const login_pre_actions_res = await axios.get(`/api/users/${user_id}/login-preflight-check`);

      const pre_actions_success = login_pre_actions_res.data.success;

      if (!pre_actions_success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Your account has been locked. Please try again in 5 minutes.'
        });
        return;
      }
      
      /**************************************
      * 1. Encryption
      ***************************************/

      // Configure entered master password 
      const master_password__unenc__string = this.state.master_password_holder;
      const master_password__unenc__uint8_array = string_to_uint8_array(master_password__unenc__string)

      // Grab account secret key
      const account_secret_key__unenc__base64 = await dbGet('account_secret_key__unenc');

      const account_secret_key__unenc__uint8_array = base64_to_uint8_array(account_secret_key__unenc__base64);

      // Derive account_unlock_key
      const account_salt_21__unenc__uint8_array = string_to_uint8_array(this.state.email_address);
      const account_salt_22__unenc__uint8_array = string_to_uint8_array(this.state.email_address.split('@')[0]);

      const intermediate_key__unenc__uint8_array = await HKDF(account_secret_key__unenc__uint8_array, account_salt_21__unenc__uint8_array, account_salt_22__unenc__uint8_array);

      const account_unlock_key__unenc__uint8_array = XOR(intermediate_key__unenc__uint8_array, master_password__unenc__uint8_array);

      // Grab & derive account public key and account private key
      const account_public_key__unenc__base64 = await dbGet('account_public_key__unenc');
      const account_public_key__unenc__uint8_array = base64_to_uint8_array(account_public_key__unenc__base64)

      const account_private_key__enc_auk__base64 = await dbGet('account_private_key__enc_auk');
      const account_private_key__enc_auk__uint8_array = base64_to_uint8_array(account_private_key__enc_auk__base64);

      const account_private_key__unenc__uint8_array = await SYM_DECRYPT(account_unlock_key__unenc__uint8_array, account_private_key__enc_auk__uint8_array);


      
      /**************************************
      * 2. Create new session
      ***************************************/

      const device_id = this.props.device_id;

      const create_session_res = await axios.post('/api/sessions/create', {
        user_id: user_id,
        device_id: device_id,
        type: 'web'
      });

      if (!create_session_res.data.success) {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with create session command'
        });
        return;
      } 

      // Save session token and account private key enc_sk in client persistence
      const session_token__unenc__base64 = create_session_res.data.session_token__unenc;
      const session_key__unenc__base64 = create_session_res.data.session_key__unenc;

      const session_key__unenc__uint8_array = base64_to_uint8_array(session_key__unenc__base64);
      const account_private_key__enc_sk__uint8_array = await SYM_ENCRYPT(session_key__unenc__uint8_array, account_private_key__unenc__uint8_array);

      const account_private_key__enc_sk__base64 = uint8_array_to_base64(account_private_key__enc_sk__uint8_array);

      // Delete first for safety
      await dbDel('session_token__unenc');
      await dbDel('account_private_key__enc_sk');

      await dbSet('session_token__unenc', session_token__unenc__base64);
      await dbSet('account_private_key__enc_sk', account_private_key__enc_sk__base64);






      /**************************************
      * 3. Slack notification request
      ***************************************/

      // const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

      // // Send message to item adder (this user)

      // const message = `You logged in.`;
      // await send_slack_notification(url, ______, user_id, message, {});



      /**************************************
      * 4. Redirect and reload
      ***************************************/

      // If any intended destination, temporarily add the pathname and search to the URL
      if (this.props.location.state && this.props.location.state.from) {
        const from = this.props.location.state.from;

        const param__base64 = uint8_array_to_base64(string_to_uint8_array('intended_pathname=' + from.pathname + '&intended_search=' + from.search));

        await this.props.history.push(`/login?${param__base64}`);
      }

      // Reload to open protected route
      window.location.reload(false);
    }
    

    catch {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Wrong email or password'
      });


      // Update access_metadata's count

      const user_id = this.state.user_id;
      
      const login_failure_res = await axios.put(`/api/users/${user_id}/login-failure`, {});
    }

  }

  // Email address is entered in
  on_continue = async () => {

    const email_address = this.state.email_address_holder;

    this.setState({
      awaiting_server_response: true,
    });

    const email_check_res = await axios.post('/api/util/email-address/exists', {
      email_address: email_address
    });

    if (email_check_res) {
      if (email_check_res.data.success) {
        if (email_check_res.data.email_exists) {

          if (this.props.session_status === 'SESSION_TIMEOUT') {

            const device_id = this.props.device_id;

            const get_user_id_by_device_id_res = await axios.get(`/api/devices/${device_id}/user-id`);

            // TODO Some logic about if user_id does not exist even though device_id does and going to long term logout flow

            /**************************************
            * Short term logout
            ***************************************/

            // Correct user_id (from email adresss) on the correct device
            if (get_user_id_by_device_id_res.data.user_id === email_check_res.data.user_id) {

              // If this is a short term logout (SESSION_TIMEOUT), continue with login
              const param__base64 = uint8_array_to_base64(string_to_uint8_array('email_address=' + email_address + '&user_id=' + email_check_res.data.user_id));
              
              // if (false) {
              if (this.props.location.state && this.props.location.state.from) {
                await this.props.history.push({
                  pathname: `/login`,
                  search: `?${param__base64}`,
                  state: {
                    from: this.props.location.state.from
                  }
                });
              } else {
                await this.props.history.push(`/login?${param__base64}`);
              }
              
              this.setState({
                email_exists: true,
                email_address: email_address,
                user_id: email_check_res.data.user_id,
                awaiting_server_response: false,
                master_password_holder: '',
              });
            }

            /**************************************
            * Email exists, but not the correct device
            ***************************************/

            // Email exists but this is a 
            else {
              // Redirect to register device page
              const param__base64 = uint8_array_to_base64(string_to_uint8_array('email_address=' + email_address));
              await this.props.history.push(`/register-device?${param__base64}`);

            }
          }

          /**************************************
          * Long term logout OR new device OR cache cleared
          ***************************************/

          // Redirect to register device page 
          else if (this.props.session_status === 'SESSION_INVALID') {

            const param__base64 = uint8_array_to_base64(string_to_uint8_array('email_address=' + email_address));
            await this.props.history.push(`/register-device?${param__base64}`);
          }
        }

        // Server responded back not having found the email in its records
        else {
          // this.setState({
          //   awaiting_server_response: false,
          //   error_message: 'Entered email does not exist'
          // });

          // Register device will handle this email address for whom a user entry that does not exist
          const param__base64 = uint8_array_to_base64(string_to_uint8_array('email_address=' + email_address));
          await this.props.history.push(`/register-device?${param__base64}`);
        }
      }

      // Server responded back with failure
      else {
        this.setState({
          awaiting_server_response: false,
          error_message: 'Something went wrong with the server... sorry about that!'
        });
      }
    } 

    // Nothing came back from the server...
    else {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went terribly wrong with the server... sorry about that!'
      });
    }
  }

  on_forgot_password = async () => {
    const email_address = this.state.email_address_holder;
    const user_id = this.state.user_id;

    const param__base64 = uint8_array_to_base64(string_to_uint8_array('email_address=' + email_address + '&user_id=' + user_id));
    await this.props.history.push(`/forgot-password?${param__base64}`);
  }

  handleContinueKeypress = async (e) => {
    if (e.charCode === 13 && (!this.state.awaiting_server_response)) {
      await this.on_continue();
    }
  };

  handleSubmitKeypress = async (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === 13 && (!this.state.awaiting_server_response)) {
      await this.on_submit();
    }
  };

  // NOT USED RIGHT NOW
  on_back = async () => {
    // BEFORE CODE
    await this.props.history.push(`/login`);
    this.setState({
      email_exists: false,
      email_address: '',
      error_message: ''
    });
  }

  render() {
    return (
      <div className="login__container">
        <div className="login__top">
          <span className="login__title">L O G I N</span>
          <div className="login__plus-lock"><PlusLock/></div>
        </div>
        <div className="login__bottom">
          {
            this.state.email_exists
              ? <> {/* Email set */}

                  <div
                    className='login__bottom-input-box email'
                  >
                    <input 
                      className="login__bottom-input-field"
                      type="text" 
                      value={ this.state.email_address_holder }
                      onChange={ this.handle_email_address_reset }
                      disabled={ this.state.awaiting_server_response }
                      autoComplete="new-password"
                    />
                  </div>

                  <div
                    className='login__bottom-input-box password'
                  >
                    <input 
                      className="login__bottom-input-field"
                      type={this.state.master_password_holder ? "password" : "text"}
                      placeholder="Master password"
                      autoFocus
                      onKeyPress={ this.handleSubmitKeypress }
                      value={ this.state.master_password_holder }
                      onChange={ this.handle_master_password_change }
                      disabled={ this.state.awaiting_server_response }
                      autoComplete="new-password"
                    />
                    <div
                      className='login__bottom-input-continue-button'
                    >
                      {
                        this.state.awaiting_server_response
                        ? <ReactLoading
                            type='spokes'
                            color='#9696ad'
                            height={20}
                            width={20}
                          />
                        : <div 
                            onClick={ this.on_submit }
                          >
                            <ContinueButton />
                          </div>
                      }
                    </div>
                  </div>

                </>
              : <> {/* Pre */}
                  <div
                    className='login__bottom-input-box'
                  >
                    <input 
                      className="login__bottom-input-field"
                      type="text" 
                      placeholder="Email address"
                      autoFocus
                      onKeyPress={ this.handleContinueKeypress }
                      value={ this.state.email_address_holder }
                      onChange={ this.handle_email_address_change }
                      disabled={ this.state.awaiting_server_response }
                      autoComplete="new-password"
                    />
                    <div
                      className='login__bottom-input-continue-button'
                    >
                      {
                        this.state.awaiting_server_response
                        ? <ReactLoading
                            type='spokes'
                            color='#9696ad'
                            height={20}
                            width={20}
                          />
                        : <div 
                            onClick={ this.on_continue }
                          >
                            <ContinueButton />
                          </div>
                      }
                    </div>
                  </div>
                </>
          }
          <span className="login__error-message">{ this.state.error_message }</span>
          {
            this.state.email_exists
            ? <div
                className='login__forgot-password'
                onClick={this.on_forgot_password}
              >
                Forgot master password?
              </div>
            : <></>
          }
          
        </div>
      </div>
    );
  }
}

export default Login;
