import React from 'react';
import './AcceptAccessModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';

import axios from 'axios';
import ReactLoading from 'react-loading';

import { FaTimes } from 'react-icons/fa';

import { SYM_ENCRYPT, SYM_DECRYPT, ASYM_ENCRYPT, ASYM_DECRYPT } from '../../../crypto/crypto';
import { string_to_uint8_array, uint8_array_to_base64, base64_to_uint8_array, uint8_array_to_string } from '../../../crypto/util';

import { auth_axios } from '../../../auth/util';

import { send_slack_notification } from '../../../slack/util';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



ReactModal.setAppElement('body');


class AcceptAccessModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
      access_type: 'read_write' // TODO
    }
  }

  componentDidMount = async () => {
    try {

      this.setState({
        awaiting_server_response: false,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }


  accept_access = async () => {

    //
    this.setState({
      error_message: '',
      awaiting_server_response: true
    });


    const item_id = this.props.selected_item.item_id;

    const item_key__enc_apubk__base64 = this.props.item_keys[item_id];
    
    const item_key__enc_apubk__uint8_array = base64_to_uint8_array(item_key__enc_apubk__base64);

    const item_key__unenc__uint8_array = await ASYM_DECRYPT(this.props.account_private_key__unenc__uint8_array, item_key__enc_apubk__uint8_array);

    const add_to_user_id = this.props.access_request_user_id;


    /**************************************
    * 1. Accept access (update item entry's permissions, and delete from access_requests array)
    ***************************************/

    const accept_access_res = await auth_axios.post(`/api/items/${item_id}/access/request/accept`, {
      user_id: this.props.user_id,
      access_request_user_id: add_to_user_id,
      access_type: this.state.access_type
    });

    if (!accept_access_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with accept access'
      });
      return;
    }


    /**************************************
    * 2. Add item_key to user to add to
    ***************************************/

    // First, fetch and encrypt using account public key
    const get_account_public_key_res = await axios.get(`/api/users/${add_to_user_id}/account-public-key`);

    if (!get_account_public_key_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with get account public key'
      });
    }

    const shared_user_account_public_key__unenc__base64 = get_account_public_key_res.data.account_public_key__unenc;
    const shared_user_account_public_key__unenc__uint8_array = base64_to_uint8_array(shared_user_account_public_key__unenc__base64);

    const item_key__enc_shared_user_apubk__uint8_array = await ASYM_ENCRYPT(shared_user_account_public_key__unenc__uint8_array, item_key__unenc__uint8_array);
    const item_key__enc_shared_user_apubk__base64 = uint8_array_to_base64(item_key__enc_shared_user_apubk__uint8_array); // TO SAVE




    // Second, fetch and encrypt using device public keys

    const get_device_public_keys_res = await axios.get(`/api/users/${add_to_user_id}/device-public-keys`);

    if (!get_device_public_keys_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with get device public keys'
      });
    }

    const shared_user_device_public_keys = get_device_public_keys_res.data.device_public_keys

    let item_keys__enc_dpubk = {}; // TO PARSE AND SAVE

    for (const shared_user_device_id in shared_user_device_public_keys) {
      const shared_user_device_public_key__unenc__base64 = shared_user_device_public_keys[shared_user_device_id];

      const shared_user_device_public_key__unenc__uint8_array = base64_to_uint8_array(shared_user_device_public_key__unenc__base64);

      const item_key__enc_shared_user_dpubk__uint8_array = await ASYM_ENCRYPT(shared_user_device_public_key__unenc__uint8_array, item_key__unenc__uint8_array);

      const item_key__enc_shared_user_dpubk__base64 = uint8_array_to_base64(item_key__enc_shared_user_dpubk__uint8_array); // TO SAVE

      item_keys__enc_dpubk[shared_user_device_id] = item_key__enc_shared_user_dpubk__base64;
    }

    const insert_item_key_to_user_res = await auth_axios.put(`/api/users/${add_to_user_id}/item-key`, {
      item_id: item_id,
      item_key__enc_apubk: item_key__enc_shared_user_apubk__base64,
      item_keys__enc_dpubk: item_keys__enc_dpubk
    });

    if (!insert_item_key_to_user_res.data.success) {
      this.setState({
        awaiting_server_response: false,
        error_message: 'Something went wrong with insert item key to user'
      });
    }


    /**************************************
    * 3. Stop loading 
    ***************************************/

    this.setState({
      awaiting_server_response: false
    });


    


    /**************************************
    * 5. Slack notification request
    ***************************************/


    const url = `${this.props.mode_info.is_dev ? this.props.mode_info.slack_dev_url : 'https://slack.plusidentity.com'}/slack/notification`;

    const team_id = this.props.selected_team_id;

    const item_name = this.props.selected_item.item_name

    const user_id = this.props.user_id;
    const display_name = this.props.user_id_display_name_maps[user_id]

    const requestor_user_id = this.props.access_request_user_id
    const requestor_display_name = this.props.user_id_display_name_maps[requestor_user_id]



    // Message to decliner (this user)

    const message = `You accepted access request to ${item_name} from SLACK_USER_ID (${requestor_display_name}).`;
    await send_slack_notification(url, team_id, user_id, message, {
      TO_FORMAT_USER_ID: requestor_user_id
    });


    // Message to requestor

    const message2 = `SLACK_USER_ID (${display_name}) accepted your access request for ${item_name}.`;

    await send_slack_notification(url, team_id, requestor_user_id, message2, {
      TO_FORMAT_USER_ID: user_id, 
    });





    /**************************************
    * 4. Trigger dashboard items pull and close modal
    ***************************************/

    await this.props.on_accept_access_modal_close(true);
    await this.props.update_dashboard_items();

 


    /**************************************
    * 5. Mixpanel
    ***************************************/

    mixpanel.track('web_accept_access_modal_submitted', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });

    

  
    /**************************************
    * 4. Refresh page to see changes on dashboard immediately!
    ***************************************/

    // Redirect and reload
    //await this.props.history.push(`/dashboard`);
    // window.location.reload(false);

    
  }


  handle_access_type_change = (e) => {
    this.setState({
      access_type: e.value
    })
  }


  render() {
    let item_name = '';
    if (this.props.selected_item) {
      item_name = this.props.selected_item.item_name
    }

    const permission_options = [
      {label: 'Edit-access', value: 'read_write'},
      {label: 'Read-only', value: 'read_only'},
      {label: 'One-time read-only', value: 'one_time'},
    ]

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            await this.props.on_accept_access_modal_close(false);
          }
        }}
        style={{
          content: {
            height: screen_height > 680 ? 220 : 220,
            width: screen_width > 690 ? 400 : 300
          }
        }}
      > 
        <div className="accept-access-modal__container">
          {this.props.dashboard_awaiting_server_response
            ? <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            : <>
                <div
                  className='accept-access-modal__text' 
                > 
                  Accept access request from <span className='accept-access-modal__text-bold'>{this.props.user_id_display_name_maps[this.props.access_request_user_id]}</span> for <span className='accept-access-modal__text-bold'>{item_name}</span> ?
                </div>
                <div className='accept-access-modal__access-type'>
                  <div className='accept-access-modal__access-type-text'>
                    Access type
                  </div>
                  <Select
                    options={permission_options} 
                    value={permission_options.filter((field_name_option) => {
                      return field_name_option.value === this.state.access_type
                    })}
                    isSearchable
                    onChange={this.handle_access_type_change}
                    isDisabled={this.state.awaiting_server_response}
                    className='accept-access-modal__search-select'
                    classNamePrefix='accept-access-modal__search-select-child'
                  />
                </div>
                {
                  this.state.awaiting_server_response
                  ? <ReactLoading
                      type='spokes'
                      color='#9696ad'
                      height={20}
                      width={20}
                    />
                  : <>
                      <input
                        type='submit'
                        className='accept-access-modal__delete-button'
                        value={ 'Accept' }
                        onClick={ this.accept_access }
                      />
                      <a
                        className='accept-access-modal__cancel'
                        onClick={async () => {
                          await this.props.on_accept_access_modal_close(false);
                        }}
                      >
                        Cancel
                      </a>
                    </>
                }
                <span>{this.state.error_message}</span>
              </>
          }
        </div>
      </ReactModal>
    );
  }
}

export default AcceptAccessModal;
