import React from 'react';
import './PreAddItemModal.css';

import ReactModal from 'react-modal';
import Select from 'react-select';
import ReactLoading from 'react-loading';


import { ReactComponent as InfoIcon } from './media/svg/info-icon.svg';



import { ReactComponent as AccountIconConfigWhite } from './media/svg/account-icon-config-white.svg';
import { ReactComponent as FileIconWhite } from './media/svg/file-icon-white.svg'; 
import { ReactComponent as CustomIconWhite } from './media/svg/custom-icon-white.svg'; 

import { ReactComponent as AccountIconConfigBlack } from './media/svg/account-icon-config-black.svg';
import { ReactComponent as FileIconBlack } from './media/svg/file-icon-black.svg'; 
import { ReactComponent as CustomIconBlack } from './media/svg/custom-icon-black.svg'; 


import { auth_axios, sleep } from '../../../auth/util';

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




ReactModal.setAppElement('body');

const default_field_name_options = [
  {label: 'Email address', value: 'email_address'},
  {label: 'Username', value: 'username'},
  {label: 'Phone number', value: 'phone_number'},
  {label: 'Password', value: 'password'},
];



class PreAddItemModal extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,
      error_message: '',
      progress_message: '',

      item_type_holder: '',

      current_page: 'first:default',

      // Account
      config_holder: '',
      no_config_checked: false,

      config_options: [],
      config_login_credentials_maps: {},
      config_display_name_maps: {},


      // File
      file_dragging: false,

      files_holder: [], // [<File>, ...]

      // Logos color
      account_icon_is_white: true,
      file_icon_is_white: true,
      custom_icon_is_white: true,
    }
  }



  set_button_icon_to_black = (item_type) => {
    this.setState({
      [`${item_type}_icon_is_white`]: false
    })
  }

  set_button_icon_to_white = (item_type) => {
    this.setState({
      [`${item_type}_icon_is_white`]: true
    })
  }






  componentDidMount = async () => {
    try {
      this.setState({
        progress_message: 'Loading configs ...'
      })

      const get_configs_res = await auth_axios.get('/api/configs');

      if (!get_configs_res) {
        console.error('get configs failed');
        this.setState({
          awaiting_server_response: false,
          error_message: 'get configs failed',
          progress_message: ''
        });
        return;
      }

      this.setState({
        awaiting_server_response: false,
        progress_message: '',

        config_options: get_configs_res.data.options,
        config_login_credentials_maps: get_configs_res.data.login_credentials_maps,
        config_display_name_maps: get_configs_res.data.display_name_maps,
      });
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    try {
      if (this.props.is_open !== prevProps.is_open) {

        // Only update if modal is open
        if (this.props.is_open) {
      
          // Wait until React Modal loads
          await sleep(1.0);

          // If modal is still open, attach drag listeners
          if (this.props.is_open) {
            this.drag_counter = 0;
            let try_counter = 0;
  
            let pre_add_item_modal;
  
            do {
              pre_add_item_modal = document.getElementsByClassName('ReactModal__Overlay')[0];  
              try_counter++;
            } while(!pre_add_item_modal && try_counter < 100);
  
            pre_add_item_modal.addEventListener('dragenter', this.handle_file_drag_enter);
            pre_add_item_modal.addEventListener('dragleave', this.handle_file_drag_leave);
            pre_add_item_modal.addEventListener('dragover', this.handle_file_drag_over);
            pre_add_item_modal.addEventListener('drop', this.handle_file_drop);
          }
        }
      }     
    }
    catch {
      this.setState({
        fatal_error_occurred: true
      });
    }
  }






  prepend_file_dragging_overlay = () => {
    // First check if file_dragging_overlay already exists
    const file_dragging_overlay = document.getElementById('pre-add-item-modal__file-dragging-overlay');

    // Add if it doesn't exist already
    if (!file_dragging_overlay) {
      let try_counter = 0;
      let pre_add_item_modal;
      do {
        pre_add_item_modal = document.getElementsByClassName('ReactModal__Overlay')[0];
        try_counter++;
      } while(!pre_add_item_modal && try_counter < 100);
  
      // Prepend file_dragging_overlay
      const file_dragging_overlay = document.createElement('div');
      file_dragging_overlay.id = 'pre-add-item-modal__file-dragging-overlay';

      const description_text = document.createElement('div');
      description_text.className = 'pre-add-item-modal__file-dragging-description';
      description_text.innerHTML = 'Upload file'

      file_dragging_overlay.prepend(description_text);
      pre_add_item_modal.prepend(file_dragging_overlay);
    }
  }

  remove_file_dragging_overlay = () => {
    // Remove file_dragging_overlay
    const file_dragging_overlay = document.getElementById('pre-add-item-modal__file-dragging-overlay');

    if (file_dragging_overlay) {
      file_dragging_overlay.remove();
    }
  }

  handle_file_drag_enter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.drag_counter++;

    if (e.dataTransfer.items && e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].kind === 'file') { // only allow one file upload file for now // > 0) { 
      this.setState({
        file_dragging: true,
      })
    }

    this.prepend_file_dragging_overlay();
  }

  handle_file_drag_leave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.drag_counter--;
    if (this.drag_counter > 0) return;

    this.setState({
      file_dragging: false,
    });

    this.remove_file_dragging_overlay();
  }

  handle_file_drag_over = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handle_file_drop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.clear_holders();

    this.setState({
      current_page: 'second:file',
      file_dragging: false,
      files_holder: e.dataTransfer.files,
    });

    this.remove_file_dragging_overlay();
  }

  clear_holders = () => {
    this.setState({
      error_message: '',
      progress_message: '',

      current_page: 'first:default',

      item_type_holder: '',

      // Account
      config_holder: '',
      no_config_checked: false,

      // File
      file_dragging: false,
      files_holder: [], // [<File>, ...]

      // Logos color
      account_icon_is_white: true,
      file_icon_is_white: true,
      custom_icon_is_white: true,
    })
  };



  handle_item_type_change = (e) => {
    this.setState({ 
      item_type_holder: e.value, 
      error_message: '' 
    });
  }

  handle_config_change = (e) => {

    let value = '';

    if (e) {
      value = e.value;
      this.setState({ 
        no_config_checked: false, 
      });
    }

    // Do this for all
    this.setState({ 
      config_holder: value, 
      error_message: '' 
    });
  }

  toggle_no_config_checked = () => {
    this.setState({
      no_config_checked: !this.state.no_config_checked,
      config_holder: this.state.no_config_checked ? this.state.config_holder : ''
    })
  }




  handle_account_icon_click = () => {
    this.setState({
      current_page: 'second:account'
    });
  }

  handle_account_submit = async () => {
    this.props.handle_add_item_modal_prefill_type_change('account');
    this.props.handle_add_item_modal_prefill_config_change(this.state.config_holder);

    await this.props.on_pre_add_item_modal_close();
    this.props.on_add_item_modal_open();

    this.clear_holders();
  }

  handle_file_icon_click = () => {
    this.setState({
      current_page: 'second:file'
    });
  }

  handle_file_submit = async () => {
    this.props.handle_add_item_modal_prefill_type_change('file');
    this.props.handle_add_item_modal_prefill_files_change(this.state.files_holder);

    await this.props.on_pre_add_item_modal_close();
    this.props.on_add_item_modal_open();

    this.clear_holders();
  }

  handle_custom_icon_click = async () => {
    this.props.handle_add_item_modal_prefill_type_change('custom');
    
    await this.props.on_pre_add_item_modal_close();
    this.props.on_add_item_modal_open();

    this.clear_holders();
  }


  handle_back_click = () => {
    this.clear_holders();

    this.setState({
      current_page: 'first:default'
    });
  }


  handle_files_change = (e) => {

    const files = e.target.files;

    // If cancelled, don't do anything
    if (!files[0]) {
      return;
    }

    this.setState({ 
      files_holder: [
        files[0]
      ]
    });
  }

  





  
  render() {

    const screen_width = this.props.screen_width;
    const screen_height = this.props.screen_height;

    const get_page = (current_page) => {
      const [page_number, page_type] = current_page.split(':');

      switch (page_number) {
        case 'first': {
          return (
            <>
              <div className="pre-add-item-modal__item-type">

                <div
                  className="pre-add-item-modal__item-type-logo-wrapper"
                  onMouseEnter={ () => this.set_button_icon_to_black('account') }
                  onMouseLeave={ () => this.set_button_icon_to_white('account') }
                  onClick={ async () => this.handle_account_icon_click() }
                >
                  {
                    this.state.account_icon_is_white
                    ? <AccountIconConfigWhite
                        className='pre-add-item-modal__item-type-logo'
                      />
                    : <AccountIconConfigBlack
                        className='pre-add-item-modal__item-type-logo'
                      />
                  }
                  <span>Account</span> 
                </div>
                <div
                  className="pre-add-item-modal__item-type-logo-wrapper"
                  onMouseEnter={ () => this.set_button_icon_to_black('file') }
                  onMouseLeave={ () => this.set_button_icon_to_white('file') }
                  onClick={ async () => this.handle_file_icon_click() }
                >
                  {
                    this.state.file_icon_is_white
                    ? <FileIconWhite
                        className='pre-add-item-modal__item-type-logo'
                      />
                    : <FileIconBlack
                        className='pre-add-item-modal__item-type-logo'
                      />
                  }
                  <span>File</span> 
                </div>
                <div
                  className="pre-add-item-modal__item-type-logo-wrapper"
                  onMouseEnter={ () => this.set_button_icon_to_black('custom') }
                  onMouseLeave={ () => this.set_button_icon_to_white('custom') }
                  onClick={ async () => await this.handle_custom_icon_click() }
                >
                  {
                    this.state.custom_icon_is_white
                    ? <CustomIconWhite
                        className='pre-add-item-modal__item-type-logo'
                      />
                    : <CustomIconBlack
                        className='pre-add-item-modal__item-type-logo'
                      />
                  }
                  <span>Custom item</span> 
                </div>
              </div>
              
              <div className="pre-add-item-modal__action-buttons">
                {
                  this.state.awaiting_server_response
                  ? <ReactLoading
                      type='spokes'
                      color='#9696ad'
                      height={20}
                      width={20}
                    />
                  : <>
                      <a
                        className='pre-add-item-modal__cancel'
                        onClick={async () => {
                          this.clear_holders(); 
                          await this.props.on_pre_add_item_modal_close();
                        }}
                      >
                        Cancel
                      </a>
                    </>
                }
              </div>
            </>
          )
        }
        case 'second': {
          switch (page_type) {
            case 'account': {
              return (
                <>
                  <div
                    className='pre-add-item-modal__select-account-text'
                  >
                    Select account
                    <div className='pre-add-item-modal__info-container'>
                      <InfoIcon 
                        className='pre-add-item-modal__info-icon'
                      />
                      <div className='pre-add-item-modal__info-description select-account'>
                        Recommended (if available): amongst the dozens of accounts we support, select the appropriate account type for this item. At PlusIdentity, we make note of which credentials you need to access each account type, and will later be supporting convenient features such as auto-login.
                      </div>
                    </div>
                  </div>
                  <Select
                    options={this.state.config_options}
                    value={this.state.config_options.filter((config_option) => {
                      return config_option.value === this.state.config_holder
                    })}
                    isSearchable
                    onChange={this.handle_config_change}
                    isDisabled={this.state.awaiting_server_response}
                    isClearable={true}
                    className='pre-add-item-modal__search-select'
                    classNamePrefix='pre-add-item-modal__search-select-child'
                  />
                  <div
                    className='pre-add-item-modal__select-account-dne'
                    onClick={ this.toggle_no_config_checked }
                  > 
                    <input
                      type='checkbox'
                      checked={ this.state.no_config_checked }
                      value={ this.state.no_config_checked }
                      onChange={ this.toggle_no_config_checked }
                      disabled={this.state.awaiting_server_response}
                      className='pre-add-item-modal__select-account-dne-checkbox'
                    />
                    <span>The list does not contain the website for this account</span>
                  </div>
                  <div className="pre-add-item-modal__action-buttons account">
                    {
                      this.state.awaiting_server_response
                      ? <ReactLoading
                          type='spokes'
                          color='#9696ad'
                          height={20}
                          width={20}
                        />
                      : <>
                          <a
                            className='pre-add-item-modal__cancel'
                            onClick={() => {
                              this.clear_holders(); 
                              this.handle_back_click();
                            }}
                          >
                            {`< Back`}
                          </a>
                          {
                            this.state.config_holder || this.state.no_config_checked
                            ? <input
                                className='pre-add-item-modal__submit-button'
                                type='submit'
                                value={ 'Continue' }
                                onClick={ async () => await this.handle_account_submit() }
                              />
                            : <input
                                className='pre-add-item-modal__submit-button disabled'
                                type='submit'
                                value={ 'Continue' }
                              />
                          }
                          <a
                            className='pre-add-item-modal__cancel'
                            onClick={async () => {
                              this.clear_holders(); 
                              await this.props.on_pre_add_item_modal_close();
                            }}
                          >
                            Cancel
                          </a>
                        </>
                    }
                  </div>
                </>
              )
            }
            case 'file': {
              return (
                <>
                  <div
                    className='pre-add-item-modal__select-file-upload-area'
                  >
                    <div
                      className='pre-add-item-modal__select-file-text'
                    >
                      Select file
                      <div className='pre-add-item-modal__info-container'>
                        <InfoIcon 
                          className='pre-add-item-modal__info-icon'
                        />
                        <div className='pre-add-item-modal__info-description select-file'>
                          The file size cannot exceed 100 MB.
                        </div>
                      </div>
                    </div>

                    <input type='file' id='pre-add-item-modal__select-file-input' onChange={this.handle_files_change} /> {/* multiple */}
                    <label 
                      htmlFor='pre-add-item-modal__select-file-input'
                      className={`pre-add-item-modal__select-file-input ${this.state.files_holder.length > 0 ? 'selected' : ''}`}
                    >
                      {this.state.files_holder.length > 0 ? this.state.files_holder[0].name : 'Upload'}
                    </label>

                    <div
                      className='pre-add-item-modal__select-file-text'
                    >
                      or drag in
                    </div>
                  </div>
                  <div className="pre-add-item-modal__action-buttons file">
                    {
                      this.state.awaiting_server_response
                      ? <ReactLoading
                          type='spokes'
                          color='#9696ad'
                          height={20}
                          width={20}
                        />
                      : <>
                          <a
                            className='pre-add-item-modal__cancel'
                            onClick={() => {
                              this.clear_holders(); 
                              this.handle_back_click();
                            }}
                          >
                            {`< Back`}
                          </a>
                          {
                            this.state.files_holder.length > 0
                            ? <input
                                className='pre-add-item-modal__submit-button'
                                type='submit'
                                value={ 'Continue' }
                                onClick={ async () => await this.handle_file_submit() }
                              />
                            : <input
                                className='pre-add-item-modal__submit-button disabled'
                                type='submit'
                                value={ 'Continue' }
                              />
                          }
                          <a
                            className='pre-add-item-modal__cancel'
                            onClick={async () => {
                              this.clear_holders(); 
                              await this.props.on_pre_add_item_modal_close();
                            }}
                          >
                            Cancel
                          </a>
                        </>
                    }
                  </div>
                </>
              )
            }
            default: {
              return (
                <>
                </>
              )
            }
          }
        }
        default: {
          return <></>;
        }
      }
    }






    

    return (
      <ReactModal 
        isOpen={this.props.is_open}
        onRequestClose={async () => {
          if (!this.state.awaiting_server_response) {
            this.clear_holders(); 
            await this.props.on_pre_add_item_modal_close();
          }
        }}
        style={{
          content: {
            height: this.state.awaiting_server_response ? 200 : (screen_height > 680 ? 640 : 450),
            width: this.state.awaiting_server_response ? 200 : (screen_width > 690 ? 650 : 300)
          }
        }}
      >
        <div className="pre-add-item-modal__container">
          {
            this.state.awaiting_server_response
            ? <div className="pre-add-item-modal__loading">
                <ReactLoading
                  type='spokes'
                  color='#9696ad'
                  height={20}
                  width={20}
                />
                <div className="pre-add-item-modal__progress_message">
                  <span>{this.state.progress_message}</span>
                </div>
              </div>
            : <>
                <div className="pre-add-item-modal__title">
                  ADD ITEM
                </div>
                <div className="pre-add-item-modal__content">
                  { get_page(this.state.current_page) }
                </div>
                <div className="pre-add-item-modal__error_message">
                  <span>{this.state.error_message}</span>
                </div>
              </>
          }
        </div>
      </ReactModal>
    );
  }
}

export default PreAddItemModal;
