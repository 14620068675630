import React from 'react';
import './TeamSettings.css';


import axios from 'axios';
import ReactLoading from 'react-loading';



import EditTeamNameModal from './EditTeamNameModal';
import DeleteTeamModal from './DeleteTeamModal';

import ShowUserListModal from './ShowUserListModal';
import AddAdminModal from './AddAdminModal';


import { auth_axios } from '../../../../../auth/util';


// Mixpanel
import mixpanel from 'mixpanel-browser';
import { FaThList } from 'react-icons/fa';

const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



const beautify_admin_type = (type) => {
  const mapping = {
    'owner': 'Team owner',
    'admin': 'Team admin',
    'member': 'Team member',
  }

  if (mapping[type]) {
    return mapping[type];
  }

  else {
    return type;
  }
}

const beautify_tier = (tier) => {
  const mapping = {
    'plusidentity_business_free': 'Business (Free)',
    'plusidentity_business_paid': 'Business (Paid)',
    'plusidentity_advanced_paid': 'Advanced (Paid)',
  }

  if (mapping[tier]) {
    return mapping[tier];
  }

  else {
    return tier;
  }
}

const beautify_status = (status) => {
  const mapping = {
    'active': 'Active',
    'inactive': 'Inactive',
  }

  if (mapping[status]) {
    return mapping[status];
  }

  else {
    return status;
  }
}




class TeamSettings extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      awaiting_server_response: true,

      edit_team_name_modal_open: false,
      show_user_list_modal_open: false,
      delete_team_modal_open: false,
      add_admin_modal_open: false,

      admins: {},
      tier: '',
      status: '',

      error_message: '',
    }
  }
  
  componentDidMount = async () => {
    try {
      // Protect against url paste access
      if (!this.props.is_admin) {
        // 
        alert('You do not have permission to view this page');
        await this.props.history.push('/dashboard/settings');
        return;
      }

      // Get team info
      const team_info_res = await auth_axios.post(`/api/teams/${this.props.selected_team_id}/admins/team-info/fetch`, {
        user_id: this.props.user_id,
      });

      if (!team_info_res.data.success) {
        console.error('get account info failed')
        return;
      }

      const admins = team_info_res.data.admins;
      const tier = team_info_res.data.tier;
      const status = team_info_res.data.status;
      const admin_recovery_available = team_info_res.data.admin_recovery_available;

      this.setState({
        awaiting_server_response: false,

        admins: admins,
        tier: tier,
        status: status,
        admin_recovery_available: admin_recovery_available
      });

      // Mixpanel web_team_settings_visited
      mixpanel.track('web_team_settings_visited', {
        distinct_id: this.props.user_id,
        team_id: this.props.selected_team_id,
        is_dev: this.props.mode_info.is_dev
      });
      
    }
    catch {
      // 
      alert('Something went wrong that needs investigating...');
      await this.props.history.push('/dashboard/settings');
    }
  }


   

  on_edit_team_name_modal_open = () => {
    this.setState({
      edit_team_name_modal_open: true
    });

    // Mixpanel web_edit_team_name_modal_opened
    mixpanel.track('web_edit_team_name_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_edit_team_name_modal_close = () => {
    this.setState({
      edit_team_name_modal_open: false
    });
  }

  on_add_admin_modal_open = () => {
    this.setState({
      add_admin_modal_open: true
    });

    // Mixpanel web_add_admin_modal_opened
    mixpanel.track('web_add_admin_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_add_admin_modal_close = () => {
    this.setState({
      add_admin_modal_open: false
    });
  }


  on_show_user_list_modal_open = () => {
    this.setState({
      show_user_list_modal_open: true
    });

    // Mixpanel web_show_user_list_modal_opened
    mixpanel.track('web_show_user_list_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_show_user_list_modal_close = () => {
    this.setState({
      show_user_list_modal_open: false
    });
  }


  



  on_delete_team_modal_open = () => {
    this.setState({
      delete_team_modal_open: true
    });

    // Mixpanel web_delete_team_modal_opened
    mixpanel.track('web_delete_team_modal_opened', {
      distinct_id: this.props.user_id,
      team_id: this.props.selected_team_id,
      is_dev: this.props.mode_info.is_dev
    });
  }

  on_delete_team_modal_close = () => {
    this.setState({
      delete_team_modal_open: false
    });
  }


  
  render() {
    return (
      <div className='team-settings__container'>
        {
          this.state.awaiting_server_response
          ? <div className="team-settings__loading-container">
              <ReactLoading
                type='spokes'
                color='#9696ad'
                height={20}
                width={20}
              />
            </div>
          : <div className='team-settings__contents'>
              <div className='team-settings__section'>
                <div className='team-settings__section-left'>
                  Team profile
                </div>
                <div className='team-settings__section-right'>

                  <div className="team-settings__section-right-field">
                    <div className='team-settings__section-right-field-name'>
                      Team name
                    </div>
                    <div className='team-settings__section-right-field-value'>
                    {this.props.selected_team_name}
                    </div>
                    <div className="team-settings__section-right-action-container">
                      <button 
                        className="team-settings__edit-team-name-button"
                        onClick={ this.on_edit_team_name_modal_open }
                      >
                        Edit
                      </button>
                    </div>
                  </div>

                  <div className="team-settings__section-right-field">
                    <div className='team-settings__section-right-field-name'>
                      Admins
                    </div>
                    <div className='team-settings__section-right-field-value'>
                      {Object.keys(this.state.admins).map((admin_user_id) => 
                        <div 
                          key={admin_user_id}
                          className='team-settings__section-right-field-value-item'
                        >
                          {this.props.user_id_display_name_maps[admin_user_id]} ({beautify_admin_type(this.state.admins[admin_user_id])})
                        </div>
                      )}
                    </div> 
                    <div className="team-settings__section-right-action-container">
                      {
                        (this.state.admins[this.props.user_id] === 'owner')
                        ? <button 
                            className="team-settings__add-admin-button"
                            onClick={this.on_add_admin_modal_open}
                          >
                            Add admin
                            <div
                              className='team-settings__add-admin-error-text helper'
                            >
                              Admins can invite other members to create their PlusIdentity account, manage team settings & billings, and unlock users in case they forget their master password. However, only you (team owner) can add other admins.
                            </div>
                          </button>
                        : <button 
                            className="team-settings__add-admin-button disabled"
                          >
                            Add admin
                            <div
                              className='team-settings__add-admin-error-text'
                            >
                              Only the team owner can add admins.
                            </div>
                          </button>
                      }
                    </div> 
                  </div>
                  
                  <div className="team-settings__section-right-field">
                    <div className='team-settings__section-right-field-name'>
                      Number of users
                    </div>
                    <div className='team-settings__section-right-field-value'>
                      {this.props.selected_team_user_ids.length}
                    </div>  
                    <div className="team-settings__section-right-action-container">
                      <button 
                        className="team-settings__show-user-list-button"
                        onClick={ this.on_show_user_list_modal_open }
                      >
                        Show list
                      </button>
                    </div>
                  </div>

                  <div className="team-settings__section-right-field">
                    <div className='team-settings__section-right-field-name'>
                      Tier
                    </div>
                    <div className='team-settings__section-right-field-value'>
                      {beautify_tier(this.state.tier)}
                    </div>  
                    <div className="team-settings__section-right-action-container">
                      {/* <button 
                        className="team-settings__REPLACE-button"
                      >
                        Edit
                      </button> */}
                    </div>
                  </div>

                  <div className="team-settings__section-right-field">
                    <div className='team-settings__section-right-field-name'>
                      Status
                    </div>
                    <div className='team-settings__section-right-field-value'>
                      {beautify_status(this.state.status)}
                    </div>  
                    <div className="team-settings__section-right-action-container">
                      {/* <button 
                        className="team-settings__REPLACE-button"
                      >
                        Edit
                      </button> */}
                    </div>
                  </div>
                </div>

              </div>
              
              <div className="team-settings__separator-horizontal"></div>

              

              <div className='team-settings__section'>
                <div className='team-settings__section-left'>
                  Recovery settings
                </div>
                <div className='team-settings__section-right'>

                  

                  <div className="team-settings__section-right-field">
                    <div className='team-settings__section-right-field-name'>
                      Admin recovery
                    </div>
                    <div className='team-settings__section-right-field-value'>
                      {
                        this.state.admin_recovery_available
                        ? <div className=''>
                            Available
                          </div>
                        : <div className='team-settings__danger-text'>
                            Not available
                          </div>
                      }
                    </div> 
                    <div className="team-settings__section-right-action-container">
                      {/* <button 
                        className="team-settings__REPLACE-button"
                      >
                        Edit
                      </button> */}
                    </div> 
                  </div>
                  
                  
                </div>

              </div>
              
              <div className="team-settings__separator-horizontal"></div>




              <div className='team-settings__section'>

                <div className='team-settings__section-left'>
                  Delete team
                </div>

                <div className='team-settings__section-right'>

                  <div className="team-settings__section-right-field">
                    <div className="team-settings__section-right-description">
                      Deleting the team results in permanent removal of all data related to {this.props.selected_team_name}. Please proceed with caution.
                    </div>
                    <div className="team-settings__section-right-action-container">
                      {
                        (this.state.admins[this.props.user_id] === 'owner')
                        ? <button 
                            className="team-settings__delete-team-button"
                            onClick={ this.on_delete_team_modal_open }
                          >
                            Delete team
                          </button>
                        : <button 
                            className="team-settings__delete-team-button disabled"
                          >
                            Delete team
                            <div
                              className='team-settings__delete-team-error-text'
                            >
                              Only the team owner can delete the team.
                            </div>
                          </button>
                      }
                    </div>
                  </div>
                  
                </div>

              </div>


              
            </div>
        }

        <EditTeamNameModal
          is_open={this.state.edit_team_name_modal_open}
          on_edit_team_name_modal_close={this.on_edit_team_name_modal_close}

          selected_team_id={this.props.selected_team_id}
          selected_team_name={this.props.selected_team_name}
          user_id={this.props.user_id}
          
          is_admin={this.props.is_admin}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <ShowUserListModal
          is_open={this.state.show_user_list_modal_open}
          on_show_user_list_modal_close={this.on_show_user_list_modal_close}

          selected_team_user_ids={this.props.selected_team_user_ids}
          user_id_display_name_maps={this.props.user_id_display_name_maps}
          
          is_admin={this.props.is_admin}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <DeleteTeamModal
          is_open={this.state.delete_team_modal_open}
          on_delete_team_modal_close={this.on_delete_team_modal_close}

          selected_team_id={this.props.selected_team_id}
          selected_team_name={this.props.selected_team_name}
          user_id={this.props.user_id}

          is_admin={this.props.is_admin}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />

        <AddAdminModal
          is_open={this.state.add_admin_modal_open}
          on_add_admin_modal_close={this.on_add_admin_modal_close}

          keychain={this.props.keychain} 

          selected_team_id={this.props.selected_team_id}
          selected_team_user_ids={this.props.selected_team_user_ids}
          selected_team_admin_user_ids={Object.keys(this.state.admins)}
          user_id={this.props.user_id}

          user_id_display_name_maps={this.props.user_id_display_name_maps}
          
          is_admin={this.props.is_admin}

          screen_width={this.props.screen_width}
          screen_height={this.props.screen_height}

          mode_info={this.props.mode_info}
        />
      </div>
    );
  }
}

export default TeamSettings;
